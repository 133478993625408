
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.invoiceNumber = self.$route.params.invoiceNumber;

    if (self.invoiceNumber == 'new') {
      self.invoiceNumber = 'New Invoice';
      self.invoiceObject = {};
      self.isNewInvoice = true;
      self.editing = true;
      self.onScreenDataOriginal = {}; // NOTHING!

      self.isLoading = false;
    } else {
      self.isLoading = true;
      self.loadingInvoiceNumber = self.invoiceNumber;
    }

    self.turnOffLargeTitles = self.$root.turnOffLargeTitles;

    if (!self.turnOffLargeTitles) {
      self.turnOffLargeTitles = true; // Set it to true for now...
    }

    self.$app.data.pageStatusData = {
      invoiceOffset: 0,
      invoiceNumber: self.invoiceNumber
    };

    if (self.invoiceNumber == 'New Invoice') {
      self.$app.data.pageStatusData.invoiceNumber = 'new';
    } // self.startLoader();

  },
  data: function () {
    return {
      isLoading: true,
      firstTimeIn: true,
      loadingInvoiceNumber: '',
      invoiceNumber: '',
      isNewInvoice: false,
      editing: false,
      invoiceObject: {},
      onScreenDataOriginal: {},
      changesMade: false,
      slowLoaderTimeout: null,
      turnOffLargeTitles: false,
      addSalesLineDropDownAutoComplete: null,
      productsSearchTimeout: null,
      currentResultsFromTaskMaster: []
    };
  },
  mounted: function () {
    var self = this; // self.$app.params.panel.leftBreakpoint = 768; // TODO!

    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this; // self.$app.params.panel.leftBreakpoint = 0; // TODO!

    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'invoice') {
        // TODO: check if it's the one we asked for!
        self.invoiceObject = data.invoice;
        self.editing = false;
        self.firstTimeIn = false; // TODO: most of this should all be on the REFRESH function...

        self.invoiceNumber = self.invoiceObject.invoicenumber;

        for (var i = 0; i < self.invoiceObject.saleslines.length; i++) {
          var currentDisplayStatus = '';

          if (self.invoiceObject.saleslines[i].status) {
            currentDisplayStatus = '[' + self.invoiceObject.saleslines[i].status + ']';
          }

          if (currentDisplayStatus == '[*]') {
            currentDisplayStatus = '<i class="icon f7-icons size-18">wrench</i>';

            if (self.invoiceObject.saleslines[i].description.toLowerCase().indexOf('assemble') > -1) {// Do nothing, leave it at a wrench...
            } else if (self.invoiceObject.saleslines[i].description.toLowerCase().indexOf('delivery ') > -1) {
              currentDisplayStatus = '<i class="icon las la-truck"></i>';
            } else if (self.invoiceObject.saleslines[i].description.toLowerCase().indexOf('shipping') > -1 || self.invoiceObject.saleslines[i].description.toLowerCase().indexOf('ups') > -1) {
              currentDisplayStatus = '<i class="icon f7-icons size-18">cube_box</i>';
            } else {}
          }

          self.invoiceObject.saleslines[i].currentDisplayStatus = currentDisplayStatus;
        }

        self.isLoading = false;
        self.refreshPage(function () {
          self.loadingInvoiceNumber = '';

          if (self.slowLoaderTimeout !== null) {
            clearTimeout(self.slowLoaderTimeout);
          }
        });
      } else if (data.type == 'data' && data.data == 'invoiceLoadError') {
        // TODO: make sure it's PAST the latest invoice and not just missing... if missing then alert!  Different situation...
        self.$router.back('/taskmaster/', {
          force: true,
          animate: false
        });
      } else if (data.type == 'event' && data.event == 'invoiceUnlockResponse') {
        if (data.ok) {
          self.editing = true;
          self.refreshPage(function () {
            self.onScreenDataOriginal = self.getOnScreenDataObject(); // console.log(self.onScreenDataOriginal);
          });
        } else {
          self.$app.preloader.hideIn('.editInvoiceButton');
          var errorMessage = 'Could not unlock invoice, please try again.';

          if (data.error && data.error !== '') {
            errorMessage = data.error;
          }

          self.$app.dialog.alert(errorMessage.replace(/(?:\r\n|\r|\n)/g, '<br>'), 'Error');
        }
      }
    },
    openAdminOptions: function () {
      var self = this;
      var availableButtons = [];
      availableButtons.push({
        text: 'Check "Individual Commission"',
        onClick: function (dialog, e) {
          self.$router.navigate('/taskmaster/invoices/' + self.invoiceNumber + '/commission/'); // /taskmaster/invoices/{{invoiceNumber}}/commission/
        }
      });
      availableButtons.push({
        text: 'Cancel',
        color: 'red'
      });
      self.$app.dialog.create({
        title: 'Admin Options',
        //text: messageToConfirm,
        backdrop: true,
        closeByBackdropClick: true,
        buttons: availableButtons,
        verticalButtons: true,
        destroyOnClose: true
      }).open();
    },
    openFollowUpNotes: function () {
      var self = this; // TODO: check read-only vs editing invoice...
      // TODO: fix the "timestamp button on this popup...

      var followUpNotesPopup = self.$app.popup.create({
        content: '<div class="popup popupFollowUpNotes">' + '<div class="view">' + '<div class="page" data-name="pageFollowUpNotes">' + '<div class="navbar">' + '<div class="navbar-bg"></div>' + '<div class="navbar-inner">' + '<div class="left">' + '<a href="#" class="link icon-only popup-close md-only" data-popup=".popupFollowUpNotes"><i class="icon icon-back"></i></a>' + '<a href="#" class="link popup-close if-not-md" data-popup=".popupFollowUpNotes">Close</a>' + '</div>' + '<div class="title sliding">Follow Up Notes</div>' + '<div class="right">' + '</div>' + '</div>' + '</div>' + '<div class="page-content color-blue">' + '<div class="block block-strong no-margin">' + '<p class="no-margin"><strong>' + self.invoiceNumber + '</strong><br>' + self.invoiceObject.customerfirstname + ' ' + self.invoiceObject.customerlastname + '</p>' + '</div>' + '<div class="list no-hairlines" style="margin-top: 2px;">' + '<ul>' + '<li class="item-content item-input item-input-outline item-input-with-value">' + (self.editing ? '<div class="item-media" style="align-self: flex-start;">' + '<button class="col button button-raised button-fill button-round' + (self.editing ? '"' : ' disabled" style="background: #ccc;"') + '><i class="icon f7-icons" style="color: white;">calendar_today</i></button>' + '</div>' : '') + '<div class="item-inner">' + '<div class="item-title item-floating-label">Notes</div>' + '<div class="item-input-wrap">' + '<textarea name="followUpNotes" class="followUpNotes resizable" style="min-height: 350px; max-height: 400px; font-size: 14px;"' + (self.editing ? '' : ' readonly') + '>' + self.invoiceObject.employeenotes + '</textarea>' + '</div>' + '</div>' + '</li>' + '</ul>' + '</div>' + '</div>' + '</div>' + '</div>' + '</div>',
        on: {
          open: function () {// alert('opening popup!');
            // Set the event for the calendar button...
          },
          close: function () {// TODO: SAVE THE DATA HERE...
          },
          closed: function (popup) {
            popup.destroy();
            var tempTimeout = setTimeout(function () {
              self.$('.popupFollowUpNotes').remove();
            }, 100);
          }
        }
      }).open();
    },
    searchInvoices: function () {
      var self = this;
      self.$app.dialog.prompt('What Invoice #?', 'Invoice Number', function (result) {
        self.requestInvoice(result, 0);
      });
    },
    invoiceMenu: function () {
      var self = this;
    },
    customerCardClicked: function () {
      var self = this;
      var invoicesListHTML = '';
      var tempInvoiceList = ['S-999999', 'S-3333', 'N-99999', 'S-9999', 'O-9999'];

      for (var i = 0; i < tempInvoiceList.length; i++) {
        invoicesListHTML += '<li>' + '<a href="#" class="item-link">' + '<div class="item-content popoverInvoiceItemContent">' + '<div class="item-inner item-cell">' + '<div class="item-row">' + '<div class="item-cell popoverInvoiceColumn popoverInvoiceColumn1">00/00/00</div>' + '<div class="item-cell popoverInvoiceColumn popoverInvoiceColumn2">' + tempInvoiceList[i] + '</div>' + '<div class="item-cell popoverInvoiceColumn popoverInvoiceColumn3" data-tooltip="CONTAINS STUFF">' + //'<i class="icon f7-icons">briefcase_fill</i>'+
        //'<i class="icon f7-icons">rectangle_fill</i>'+
        '<i class="icon f7-icons tvicon contains containsReturn containsNew">tv_fill</i>' + //'<i class="icon f7-icons">wind</i>'+
        '</div>' + '</div>' + '</div>' + '</div>' + '</a>' + '</li>';
      } // Create dynamic Popover


      var dynamicPopover = self.$app.popover.create({
        targetEl: 'a.customerCard',
        content: '<div class="popover customerProfileLinksPopover">' + '<div class="popover-inner">' + '<div class="list media-list">' + '<ul>' + '<a class="list-button item-link" href="#">Open Customer Profile</a></li>' + '<li><a class="list-button item-link" href="#">+ Create New Invoice</a></li>' + invoicesListHTML + '</ul>' + '</div>' + '</div>' + '</div>',
        // Events
        on: {
          open: function (popover) {
            console.log('Popover open');
          },
          opened: function (popover) {
            console.log('Popover opened');
          }
        }
      });
      console.log('dynamicPopover', dynamicPopover);
      dynamicPopover.open();
    },
    relatedFilesOpen: function () {
      var self = this; // Force lazy load the images there...

      self.$el.find('a.imageABlock').each(function (index, elem) {
        self.$app.lazy.loadImage(this, function () {// console.log('Loaded!');
        });
      });
      var relatedFilesArray = [];

      for (var i = 0; i < self.invoiceObject.relatedFiles.length; i++) {
        relatedFilesArray.push({
          url: 'https://taskmaster.bedroomsandmore.com/4DACTION/getImage/InvoiceAttachment/' + self.invoiceNumber + '/' + self.invoiceObject.relatedFiles[i].id + '/' + encodeURI(self.invoiceObject.relatedFiles[i].filename.replace('.pdf', '.jpg')) + '?updated=' + encodeURI(self.invoiceObject.relatedFiles[i].uploadedDateTime) + '&sessionid=' + self.$app.data.mdWebSocket.sessionId
        });
      }

      self.relatedFilesPopup = self.$app.photoBrowser.create({
        photos: relatedFilesArray,
        theme: 'dark',
        toolbar: false,
        type: 'standalone',
        renderNavbar: function () {
          var navBarHTML = '';
          navBarHTML += '<div class="navbar">' + '<div class="navbar-bg"></div>' + '<div class="navbar-inner">' + '<div class="left">' + '<a href="#" class="link icon-only popup-close md-only"><i class="icon icon-back"></i></a>' + '<a href="#" class="link popup-close if-not-md">Close</a>' + '</div>' + '<div class="title sliding relatedFilesTitle">Related Files</div>' + '<div class="right">' + '</div>' + '</div>' + '</div>';
          return navBarHTML;
        },
        on: {
          open: function (photoBrowser) {
            photoBrowser.$el.find('.relatedFilesTitle').html(photoBrowser.swiper.activeIndex + 1 + ' of ' + photoBrowser.swiper.virtual.slides.length);
          },
          slideChange: function () {
            var photoBrowser = this;
            photoBrowser.$el.find('.relatedFilesTitle').html(photoBrowser.swiper.activeIndex + 1 + ' of ' + photoBrowser.swiper.virtual.slides.length);
          }
        }
      });
    },
    relatedFilesOpened: function () {
      var self = this; // TODO: this scroll and viewport code does not work on mobile
      //var accordionContent= self.$('.relatedFilesAccordionItem .accordion-item-content');
      // TODO: if NOT IN VIEWPORT...
      // console.log('self.$app.lazy.isInViewport(accordionContent)',self.$app.lazy.isInViewport(accordionContent[0]));
      // if (!self.$app.lazy.isInViewport(accordionContent[0])) {
      //self.$el.find('.page-content').scrollTop(accordionContent.offset().top);

      self.$el.find('.page-content').scrollTop(100000); // }
    },
    invoiceBack: function () {
      var self = this;
      self.requestInvoice(self.invoiceNumber, -1);
    },
    invoiceShare: function (e) {
      var self = this;

      if (self.invoiceNumber !== '' || self.invoiceNumber !== 'new') {
        self.$app.methods.printerListPopup({
          targetEl: '.invoiceShareLink',
          type: 'popover',
          onSuccess: function (printer, buttonText) {
            // self.$app.dialog.alert('You clicked: '+printer,'Printer');
            self.$root.mdWebSocket.sendOverWebSocket({
              action: 'invoicePrint',
              invoiceNumber: self.invoiceNumber,
              printer: printer
            });
            self.$app.dialog.alert('Your request has been sent to the server!  It should print or email soon!', 'Sent to Server');
          }
        });
      }
    },
    invoiceForward: function () {
      var self = this;
      self.requestInvoice(self.invoiceNumber, 1);
    },
    requestInvoice: function (invoiceNumber, offset) {
      var self = this;
      offset = typeof offset == 'number' ? offset : 0;
      var splitInvoiceNumberArray = invoiceNumber.split('-');
      var prefix = splitInvoiceNumberArray[0];
      var invoiceNumberNumsOnly = parseInt(splitInvoiceNumberArray[1]);
      self.loadingInvoiceNumber = prefix + '-' + (invoiceNumberNumsOnly + offset);
      self.isLoading = true;
      self.refreshPage(function () {
        self.$app.data.pageStatusData = {
          invoiceOffset: offset,
          invoiceNumber: invoiceNumber
        };
        self.$root.sendStatus();
      });
      /*
      self.$update(function() {
      	self.$app.data.pageStatusData= {
      		invoiceOffset: offset,
      		invoiceNumber: invoiceNumber
      	};
      	
      	self.$root.sendStatus();
      	
      	// TODO: if there are no more invoices FORWARD...
      	// TODO: no history...
      	//self.$router.back('/taskmaster/',{force: true});
      });
      */
    },
    customerGetFormJSON: function () {
      var self = this; // inspired by: http://github.com/jsonform/jsonform

      return [{
        type: 'row',
        items: [{
          type: 'text',
          name: 'customerFirstName',
          title: 'First Name',
          htmlClass: 'customerFirstName',
          value: self.invoiceObject.customerfirstname
        }, {
          type: 'text',
          name: 'customerLastName',
          title: 'Last Name',
          value: self.invoiceObject.customerlastname
        }, {
          type: 'text',
          name: 'customerPhone',
          title: 'Phone',
          value: self.invoiceObject.customerphone
        }]
      }, {
        type: 'row',
        items: [{
          type: 'text',
          name: 'customerAddress',
          title: 'Address',
          value: self.invoiceObject.customeraddress
        }, {
          type: 'text',
          name: 'customerAddressUnit',
          placeholder: 'Unit #',
          value: self.invoiceObject.customeraddressunit
        }, {
          type: 'text',
          name: 'customerPhoneAlt',
          title: 'Alt Phone',
          value: self.invoiceObject.customeraltphone
        }]
      }, {
        type: 'row',
        items: [{
          type: 'text',
          name: 'customerCity',
          title: 'City',
          value: self.invoiceObject.customercity
        }, {
          type: 'text',
          name: 'customerState',
          title: 'ST',
          value: self.invoiceObject.customerstate,
          htmlAttributes: [{
            name: 'maxlength',
            value: 2
          }]
        }, {
          type: 'text',
          name: 'customerZip',
          title: 'Zip',
          value: self.invoiceObject.customerzip
        }]
      }, {
        type: 'checkbox',
        name: 'customerHasDifferentShippingAddress',
        title: 'Different shipping address?',
        value: 'on',
        checked: self.isShippingAddressDifferentFromBillingAddress() // TODO: set this to what it needs to be...

      }, {
        type: 'row',
        items: [{
          type: 'text',
          name: 'customerShippingAddress',
          title: 'Shipping Address',
          value: self.invoiceObject.customershippingaddress
        }, {
          type: 'text',
          name: 'customerShippingAddressUnit',
          placeholder: 'Unit #',
          value: self.invoiceObject.customershippingaddressunit
        }, {
          type: 'hidden',
          name: 'emptyIgnore',
          value: ''
        }]
      }, {
        type: 'row',
        items: [{
          type: 'text',
          name: 'customerShippingCity',
          title: 'Shipping City',
          value: self.invoiceObject.customershippingcity
        }, {
          type: 'text',
          name: 'customerShippingState',
          title: 'Shipping ST',
          value: self.invoiceObject.customershippingstate,
          htmlAttributes: [{
            name: 'maxlength',
            value: 2
          }]
        }, {
          type: 'text',
          name: 'customerShippingZip',
          title: 'Shipping Zip',
          value: self.invoiceObject.customershippingzip
        }]
      }, {
        type: 'email',
        name: 'customerEmail',
        title: 'Email',
        value: self.invoiceObject.customeremail
      }];
    },
    isShippingAddressDifferentFromBillingAddress: function () {
      var self = this;
      var billingAddressHTML = self.invoiceObject.customeraddress + (self.invoiceObject.customeraddressunit !== '' ? ' #' + self.invoiceObject.customeraddressunit : '') + '\n' + self.invoiceObject.customercity + ', ' + self.invoiceObject.customerstate + ' ' + self.invoiceObject.customerzip;
      var shippingAddressHTML = self.invoiceObject.customershippingaddress + (self.invoiceObject.customershippingaddressunit !== '' ? ' #' + self.invoiceObject.customershippingaddressunit : '') + '\n' + self.invoiceObject.customershippingcity + ', ' + self.invoiceObject.customershippingstate + ' ' + self.invoiceObject.customershippingzip; // TODO: make sure the shipping does not only have a state difference... (ie: older invoices where the shipping sais "WA" unrelated to the billing...)

      return shippingAddressHTML !== billingAddressHTML;
    },
    setShippingToBillingAddress: function () {
      var self = this;
      self.invoiceObject.customershippingaddress = self.invoiceObject.customeraddress;
      self.invoiceObject.customershippingaddressunit = self.invoiceObject.customeraddressunit;
      self.invoiceObject.customershippingcity = self.invoiceObject.customercity;
      self.invoiceObject.customershippingstate = self.invoiceObject.customerstate;
      self.invoiceObject.customershippingzip = self.invoiceObject.customerzip;
    },
    changedCustomerInfo: function (e) {
      // TODO: does it matter here?
      var self = this;
      var needToRefresh = false; // console.log('EVENT',e);

      var targetElement;
      var customerDifferentShippingCheckboxElement = self.$('#customerHasDifferentShippingAddress');

      if (typeof e !== 'undefined') {
        targetElement = self.$(e.target);
      } else {
        targetElement = self.$('#customerHasDifferentShippingAddress');
      }

      var lowercaseName = targetElement[0].name.toLowerCase(); // console.log('lowercaseName',lowercaseName,Object.keys(self.invoiceObject));
      // TODO: okay to SAVE data from certain IDs? like: customerHasDifferentShippingAddress

      if (targetElement[0].name && (self.isNewInvoice || Object.keys(self.invoiceObject).indexOf(lowercaseName) > -1)) {
        //console.log('self.invoiceObject['+lowercaseName+']',self.invoiceObject[lowercaseName]);
        self.invoiceObject[lowercaseName] = targetElement.val(); //console.log('self.invoiceObject['+lowercaseName+']',self.invoiceObject[lowercaseName]);

        if (!customerDifferentShippingCheckboxElement[0].checked) {
          self.setShippingToBillingAddress();
        }

        needToRefresh = true;
      }

      if (targetElement.attr('id') == 'customerHasDifferentShippingAddress') {
        // TODO: need to ask what to do with the data...
        if (targetElement[0].checked) {// Check if need to ask about address...
        } else {// Clear the shipping address? (leave alone?)
          }

        var popupCustomerEdit = self.$app.popup.get('.popupCustomerEdit'); //console.log('Got here!',popupCustomerEdit);

        popupCustomerEdit.$el.find('.customerInfoForm input[name*="Shipping"]').each(function (index, element) {
          var thisElement = self.$(element);

          if (thisElement[0].name !== 'customerHasDifferentShippingAddress') {
            if (targetElement[0].checked) {
              // Clear the shipping address??
              thisElement.closest('li.item-content').css('display', '');
            } else {
              // If it's different, alert which address to use?
              thisElement.closest('li.item-content').css('display', 'none');
            }
          }
        });
      }

      if (needToRefresh) {
        // console.log('ABOUT TO UPDATE');
        //self.$update();
        self.refreshPage();
      }
    },
    customerGetFormHTML: function () {
      var self = this;
      return self.$app.methods.buildFormHTMLFromJSON(self.customerGetFormJSON(), {
        name: 'customerInfoForm'
      });
    },
    customerEdit: function () {
      var self = this; // self.$app.dialog.alert('Editing a Customer is not ready yet...','Edit Customer');

      var followUpNotesPopup = self.$app.popup.create({
        content: '<div class="popup popupCustomerEdit">' + '<div class="view">' + '<div class="page" data-name="popupCustomerEdit">' + '<div class="navbar">' + '<div class="navbar-bg"></div>' + '<div class="navbar-inner">' + '<div class="left">' + '<a href="#" class="link icon-only popup-close md-only" data-popup=".popupCustomerEdit"><i class="icon icon-back"></i></a>' + '<a href="#" class="link popup-close if-not-md" data-popup=".popupCustomerEdit">Close</a>' + '</div>' + '<div class="title sliding">' + self.invoiceNumber + ' Customer<!-- TODO #1--></div>' + '<div class="right">' + '</div>' + '</div>' + '</div>' + '<div class="page-content color-blue">' + self.customerGetFormHTML() + '</div>' + '</div>' + '</div>' + '</div>',
        on: {
          open: function (popup) {
            // alert('opening popup!');
            //console.log('OPENING POPUP!', popup.$el.find('.customerInfoForm input').length);
            popup.$el.find('.customerInfoForm input[type="text"]').on('keyup', self.changedCustomerInfo);
            popup.$el.find('.customerInfoForm input[type="email"]').on('keyup', self.changedCustomerInfo);
            popup.$el.find('.customerInfoForm input[type="checkbox"]').on('click', self.changedCustomerInfo); // Check the billing vs shipping address and check the box...

            self.changedCustomerInfo();
            /*
            popup.$el.find('.customerInfoForm input[name*="Shipping"]').each(function(index,element) {
            	var thisElement= self.$(element);
            	if (thisElement[0].name !== 'customerHasDifferentShippingAddress') {
            		thisElement.closest('li.item-content').css('display','none');
            	}
            });
            */
          },
          opened: function (popup) {
            // alert('opening popup!');
            if (popup.$el.find('.customerFirstName').val() == '') {
              popup.$el.find('.customerFirstName').focus();
            }
          },
          close: function (popup) {
            popup.$el.find('.customerInfoForm input[type="text"]').off('keyup', self.changedCustomerInfo);
            popup.$el.find('.customerInfoForm input[type="email"]').off('keyup', self.changedCustomerInfo);
            popup.$el.find('.customerInfoForm input[type="checkbox"]').off('click', self.changedCustomerInfo); // TODO: SAVE THE DATA HERE... how to tell save vs cancel...?
          },
          closed: function (popup) {
            popup.destroy();
            var tempTimeout = setTimeout(function () {
              self.$('.popupCustomerEdit').remove();
            }, 100);
          }
        }
      }).open();
    },
    invoiceEdit: function (e) {
      var self = this;
      self.$app.preloader.showIn(self.$(e.target).closest('.editInvoiceButton')); // TODO: Request Editing Invoice...

      self.$root.mdWebSocket.sendOverWebSocket({
        action: 'requestEditInvoice',
        invoiceNumber: self.invoiceNumber
      });
    },
    invoiceSave: function () {
      var self = this;
      var onScreenData = self.getOnScreenDataObject();
      self.changesMade = self.getChangesMade(); // console.log('CHANGES',self.changesMade);

      if (Object.keys(self.changesMade.old).length > 0) {
        /*
        self.$root.mdWebSocket.sendOverWebSocket({
        	action: 'saveChangedInvoiceData',
        	invoiceNumber: self.invoiceNumber,
        	recordData: self.changesMade.new
        });
        */
        // Preloader......
      } else {// Do nothing!  Nothing Changed!
        }

      self.editing = false;
      self.refreshPage();
    },
    getOnScreenDataObject: function () {
      var self = this;
      var onScreenData = {};

      if (!self.$app) {
        // TODO: this is just a temporary fix to allow calling a method from this component from elsewhere...
        self.$ = window.app.f7.$;
        self.$app = window.app.f7;
      }

      console.log('SELF', self);
      onScreenData = self.$app.form.convertToData(self.$('form.invoiceForm')); // TODO: convert on screen booleans...

      /*
      var fieldNames= Object.keys(onScreenData);
      for (var i= 0; i< fieldNames.length; i++) {
      	if (self.fieldTypes[fieldNames[i]] == 6) {
      		onScreenData[fieldNames[i]]= (onScreenData[fieldNames[i]].length > 0);
      	}
      }
      */

      return onScreenData;
    },
    clickedEmail: function () {
      var self = this; // TODO: future email compose feature... pass in options like "attach invoice, etc"

      if (self.invoiceObject.customeremail && self.invoiceObject.customeremail !== '') {
        var confirmDialog = self.$app.dialog.confirm('Send a new email to ' + self.invoiceObject.customeremail + '?', 'Email', function () {
          var urlForEmail = 'mailto:' + self.invoiceObject.customeremail + '?subject=Message From ' + self.$app.data.user.firstname + '&body=';

          if (self.$app.device.cordova) {
            window.open(urlForEmail, '_system');
          } else {
            window.location.href = urlForEmail;
          }
        });
        confirmDialog.params.closeByBackdropClick = true;
      }
    },
    clickedPhone: function (e) {
      var self = this;
      var thisATag = self.$(e.target).closest('a');
      var thisNumber = thisATag.attr('data-number');
      thisNumber = thisNumber.replace(/\D/g, ''); // NUMBERS ONLY!

      if (thisNumber !== '') {
        self.$app.methods.callNumber(thisNumber, self.invoiceObject.customerfirstname + ' ' + self.invoiceObject.customerlastname);
      }
    },
    getChangesMade: function () {
      var self = this;
      var onScreenData = self.getOnScreenDataObject();
      var changesObject = {
        old: {},
        new: {},
        types: {}
      };
      var fieldNames = Object.keys(self.onScreenDataOriginal);

      for (var i = 0; i < fieldNames.length; i++) {
        var changeMade = false; // TODO: booleans...

        changeMade = onScreenData[fieldNames[i]] !== self.onScreenDataOriginal[fieldNames[i]];

        if (changeMade) {
          changesObject.new[fieldNames[i]] = onScreenData[fieldNames[i]];
          changesObject.old[fieldNames[i]] = self.onScreenDataOriginal[fieldNames[i]];
        }
      }

      return changesObject;
    },
    clickedNotesTime: function () {
      var self = this;
      var notesToPrepend = self.getDateTimeNoteString() + '\n';
      var textArea = self.$el.find('textarea.notes');
      textArea.val(notesToPrepend + textArea.val());
      self.$app.input.resizeTextarea(textArea);
      var notesToPrependLength = notesToPrepend.length - 1;

      if (textArea[0].setSelectionRange) {
        textArea[0].focus();
        textArea[0].setSelectionRange(notesToPrependLength, notesToPrependLength);
      } else if (textArea[0].createTextRange) {
        var range = textArea[0].createTextRange();
        textArea[0].collapse(true);
        textArea[0].moveEnd('character', notesToPrependLength);
        textArea[0].moveStart('character', notesToPrependLength);
        textArea[0].select();
      }

      textArea.scrollTop(0);
      var tempTimeout = setTimeout(function () {
        textArea.scrollTop(0);
      }, 500); // TODO!  If the page is scrolled past...
    },
    getDateTimeNoteString: function () {
      var self = this;
      var now = new Date();
      var myString = '';
      var hour = now.getHours();
      var minute = now.getMinutes();
      var second = now.getSeconds();
      var monthnumber = now.getMonth() + 1;
      var monthday = now.getDate();
      var year = String(now.getFullYear()).substr(2, 4);
      var ap = "a";

      if (hour > 11) {
        ap = "p";
      }

      if (hour > 12) {
        hour = hour - 12;
      }

      if (hour == 0) {
        hour = 12;
      } //if (hour   < 10) { hour   = "0" + hour;   }


      if (minute < 10) {
        minute = "0" + minute;
      } //if (second < 10) { second = "0" + second; }


      myString = monthnumber + '/' + monthday + '/' + year + ' ' + hour + ':' + minute + ap + ' {' + self.$app.data.user.initials + '}: ';
      return myString;
    },
    colorCodeSalesLines: function () {
      var self = this;
      var minNumberOfLines = 8;

      if (!self.invoiceObject.saleslines) {
        self.invoiceObject.saleslines = [];
      }

      var numberOfSalesLinesCurrently = self.invoiceObject.saleslines.length; //console.log('CURR:',numberOfSalesLinesCurrently,'DIFF',(minNumberOfLines-numberOfSalesLinesCurrently));

      if (self.isNewInvoice) {
        //(self.$app.device.desktop) {
        if (numberOfSalesLinesCurrently < minNumberOfLines) {
          for (var i = 0; i < minNumberOfLines - numberOfSalesLinesCurrently; i++) {
            self.invoiceObject.saleslines.push({
              ID: 0,
              quantity: 0,
              status: '',
              description: '',
              totalamount: 0
            });
          }
        }
      } // console.log('self.invoiceObject.saleslines',self.invoiceObject.saleslines);


      var isOdd = true;

      for (var i = 0; i < self.invoiceObject.saleslines.length; i++) {
        self.invoiceObject.saleslines[i].hideAfter = false;

        if (self.invoiceObject.saleslines[i].associatedWIthLineNum > 0) {
          isOdd = !isOdd;
          self.invoiceObject.saleslines[i - 1].hideAfter = true; // Hide the line above...
        }

        self.invoiceObject.saleslines[i].styleClass = isOdd ? 'isOdd' : 'isEven';

        if (!self.invoiceObject.isOTD) {
          // if NOT otd...
          if (self.invoiceObject.saleslines[i].inCustomerPossession) {
            if (!self.editing) {
              self.invoiceObject.saleslines[i].styleClass += ' inCustomerPossessionFade';
            }
          }
        }

        isOdd = !isOdd;
      }
    },
    openPickStockWindow: function () {
      var self = this;
      self.$app.dialog.alert('STOCK POPUP WINDOW HERE!');
    },
    openSchedulingPopup: function () {
      var self = this;
      var followUpNotesPopup = self.$app.popup.create({
        content: '<div class="popup popupScheduling">' + '<div class="view">' + '<div class="page" data-name="popupScheduling">' + '<div class="navbar">' + '<div class="navbar-bg"></div>' + '<div class="navbar-inner">' + '<div class="left">' + '<a href="#" class="link icon-only popup-close md-only" data-popup=".popupScheduling"><i class="icon icon-back"></i></a>' + '<a href="#" class="link popup-close if-not-md" data-popup=".popupScheduling">Close</a>' + '</div>' + '<div class="title sliding">Scheduling</div>' + '<div class="right">' + '</div>' + '</div>' + '</div>' + '<div class="page-content">' + '<div class="block block-strong no-margin">' + '<p class="no-margin"><strong>' + self.invoiceNumber + '</strong><br>' + self.invoiceObject.customerfirstname + ' ' + self.invoiceObject.customerlastname + '</p>' + '</div>' + '</div>' + '</div>' + '</div>' + '</div>',
        on: {
          open: function () {// alert('opening popup!');
          },
          close: function () {// TODO: SAVE THE DATA HERE... how to tell save vs cancel...?
          },
          closed: function (popup) {
            popup.destroy();
            var tempTimeout = setTimeout(function () {
              self.$('.popupScheduling').remove();
            }, 100);
          }
        }
      }).open();
    },
    openShippingModal: function () {
      var self = this;
      self.pickupDateModal.open();
    },
    refreshPage: function (refreshOnSuccessHook) {
      var self = this;
      refreshOnSuccessHook = typeof refreshOnSuccessHook == 'function' ? refreshOnSuccessHook : function () {};
      self.isNewInvoice = self.invoiceNumber == 'new' || self.invoiceNumber == 'New Invoice';

      if (self.isNewInvoice) {
        // TODO: load recent NEW INVOICE maybe...
        self.invoiceObject.invoicetime = moment(); // TODO! Add this to the javascript timer so it changes automatically in the middle of the night?

        self.invoiceObject.invoicedate = self.invoiceObject.invoicetime.format('M/D/YY'); // TODO! Add this to the javascript timer so it changes automatically in the middle of the night?

        self.invoiceObject.invoicetimeString = self.invoiceObject.invoicetime.format('LT'); // TODO! Add this to the javascript timer so it changes automatically in the middle of the night?

        self.invoiceObject._output_employeeName = self.$root.user.name; // TODO!

        self.invoiceObject._output_writtenBy = self.$root.user.name; // TODO!
      } // DATA FIXES


      if (!self.invoiceObject.notes) {
        self.invoiceObject.notes = '';
      }

      if (self.invoiceObject.notes !== '') {
        self.invoiceObject.notesHTML = self.$root.externalURLsToLinks(self.invoiceObject.notes);
        self.invoiceObject.notesHTML = self.invoiceObject.notesHTML.replace(/\r\n/g, '<br>').trim(); // trim?

        self.invoiceObject.notesHTML = self.invoiceObject.notesHTML.replace(/\n/g, '<br>').trim(); // trim?

        self.invoiceObject.notesHTML = self.invoiceObject.notesHTML.replace(/\r/g, '<br>').trim(); // trim?
      }

      if (self.invoiceObject.saleslines && self.invoiceObject.saleslines.length > 0) {
        for (var i = 0; i < self.invoiceObject.saleslines.length; i++) {
          self.invoiceObject.saleslines[i].descriptionHTML = self.invoiceObject.saleslines[i].description.replace(/     -/g, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-');

          if (self.invoiceObject.saleslines[i].status == '//') {
            self.invoiceObject.saleslines[i].quantity = 0;
          } // TODO: make smarter with the actual size of the item...


          if (self.invoiceObject.saleslines[i].descriptionHTML.indexOf('TwinXL') > -1) {
            self.invoiceObject.saleslines[i].descriptionHTML = self.invoiceObject.saleslines[i].descriptionHTML.replace(/TwinXL/g, '<strong>TwinXL</strong>');
          } else {
            self.invoiceObject.saleslines[i].descriptionHTML = self.invoiceObject.saleslines[i].descriptionHTML.replace(/Twin/g, '<strong>Twin</strong>');
          }

          self.invoiceObject.saleslines[i].descriptionHTML = self.invoiceObject.saleslines[i].descriptionHTML.replace(/Full/g, '<strong>Full</strong>');

          if (self.invoiceObject.saleslines[i].descriptionHTML.indexOf('Split Queen') > -1) {
            self.invoiceObject.saleslines[i].descriptionHTML = self.invoiceObject.saleslines[i].descriptionHTML.replace(/Split Queen/g, '<strong>Split Queen</strong>');
          } else {
            self.invoiceObject.saleslines[i].descriptionHTML = self.invoiceObject.saleslines[i].descriptionHTML.replace(/Queen/g, '<strong>Queen</strong>');
          }

          if (self.invoiceObject.saleslines[i].descriptionHTML.indexOf('Cal King') > -1) {
            self.invoiceObject.saleslines[i].descriptionHTML = self.invoiceObject.saleslines[i].descriptionHTML.replace(/Cal King/g, '<strong>Cal King</strong>');
          } else if (self.invoiceObject.saleslines[i].descriptionHTML.indexOf('SuperKing') > -1) {
            self.invoiceObject.saleslines[i].descriptionHTML = self.invoiceObject.saleslines[i].descriptionHTML.replace(/SuperKing/g, '<strong>SuperKing</strong>');
          } else if (self.invoiceObject.saleslines[i].descriptionHTML.indexOf('Split King') > -1) {
            self.invoiceObject.saleslines[i].descriptionHTML = self.invoiceObject.saleslines[i].descriptionHTML.replace(/Split King/g, '<strong>Split King</strong>');
          } else {
            self.invoiceObject.saleslines[i].descriptionHTML = self.invoiceObject.saleslines[i].descriptionHTML.replace(/- King/g, '- <strong>King</strong>');
          }

          self.invoiceObject.saleslines[i].descriptionHTML = self.invoiceObject.saleslines[i].descriptionHTML.replace(/Crib/g, '<strong>Crib</strong>');
          self.invoiceObject.saleslines[i].descriptionHTML = self.invoiceObject.saleslines[i].descriptionHTML.replace(/Standard/g, '<strong>Standard</strong>');
          self.invoiceObject.saleslines[i].descriptionHTML = self.invoiceObject.saleslines[i].descriptionHTML.replace(/Travel/g, '<strong>Travel</strong>'); // self.invoiceObject.saleslines[i].descriptionHTML= self.invoiceObject.saleslines[i].descriptionHTML.replace(/Twin/g, '<strong>Twin</strong>');
        }
      }

      self.invoiceObject.schedulingSummaryCode = ''; // blank is none!

      self.invoiceObject.schedulingSummaryHTML = 'None';
      var dateToConvert = '';
      var startingText = '';
      var endingText = '';
      self.invoiceObject.hasASchedulingDateSet = false;
      self.invoiceObject.hasDeliveryDate = false;
      self.invoiceObject.hasPickupDate = false;
      self.invoiceObject.hasShippingDate = false;
      self.invoiceObject.hasServiceDate = false;

      if (self.invoiceObject.daterequestdelivery !== '0000-00-00T00:00:00.000Z') {
        dateToConvert = self.invoiceObject.daterequestdelivery;
        startingText = '<i class="icon las la-truck" style="font-size: 24px;"></i> B&M Delivery ';
        self.invoiceObject.hasDeliveryDate = true;
        self.invoiceObject.hasASchedulingDateSet = true;
        self.invoiceObject.schedulingSummaryCode = 'delivery';
      } else if (self.invoiceObject.daterequestpickup !== '0000-00-00T00:00:00.000Z') {
        dateToConvert = self.invoiceObject.daterequestpickup;
        startingText = '<i class="icon material-icons" style="font-size: 24px;">directions_walk</i> Pickup ';
        self.invoiceObject.hasPickupDate = true;
        self.invoiceObject.hasASchedulingDateSet = true;
        self.invoiceObject.schedulingSummaryCode = 'pickup';
      } else if (self.invoiceObject.daterequestshipping !== '0000-00-00T00:00:00.000Z') {
        dateToConvert = self.invoiceObject.daterequestshipping;
        startingText = '<i class="icon f7-icons">cube_box</i> Shipping ';
        endingText = ' <em>' + self.invoiceObject.shippingTrackingNumber + '</em>';
        self.invoiceObject.hasShippingDate = true;
        self.invoiceObject.hasASchedulingDateSet = true;
        self.invoiceObject.schedulingSummaryCode = 'shipping';
      } else if (self.invoiceObject.daterequestservice !== '0000-00-00T00:00:00.000Z') {
        dateToConvert = self.invoiceObject.daterequestservice;
        startingText = '<i class="icon f7-icons">wrench</i> Service ';
        self.invoiceObject.hasServiceDate = true;
        self.invoiceObject.hasASchedulingDateSet = true;
        self.invoiceObject.schedulingSummaryCode = 'service';
      }

      if (self.invoiceObject.schedulingSummaryHTML == 'None') {
        if (self.invoiceObject.isOTD) {
          self.invoiceObject.schedulingSummaryCode = 'otd';
          self.invoiceObject.schedulingSummaryHTML = '<i class="icon f7-icons" style="font-size: 24px;">hand_thumbsup</i> OTD';
        } else {
          self.invoiceObject.schedulingSummaryHTML = '<i class="icon f7-icons" style="font-size: 24px;">question</i> <span style="text-decoration: underline;">None</span>';
        }
      }

      if (dateToConvert !== '') {
        self.invoiceObject.schedulingSummaryHTML = startingText + '<span style="text-decoration: underline; white-space: nowrap;">' + moment(new Date(dateToConvert)).format('ddd M/DD/YY') + '</span>' + endingText;
      }

      self.invoiceObject.paymentSummaryHTML = '';

      if (self.invoiceObject.payments && self.invoiceObject.payments.length > 0) {
        for (var i = 0; i < self.invoiceObject.payments.length; i++) {
          var currentTempText = self.invoiceObject.payments[i].MoneyType;

          if (self.invoiceObject.payments[i].MoneyTypeOption !== '' && self.invoiceObject.payments[i].MoneyType !== 'Admin Write Off' && self.invoiceObject.payments[i].MoneyType !== 'PayStation') {
            currentTempText = self.invoiceObject.payments[i].MoneyTypeOption;
          }

          if (currentTempText.toLowerCase().indexOf('write') < 0) {
            // Make sure not admin write off...
            if (self.invoiceObject.paymentSummaryHTML.indexOf(currentTempText) < 0) {
              // If NOT FOUND
              if (self.invoiceObject.paymentSummaryHTML !== '') {
                self.invoiceObject.paymentSummaryHTML += '/';
              }

              self.invoiceObject.paymentSummaryHTML += currentTempText;
            }
          }
        }
      }

      if (self.invoiceObject.paymentSummaryHTML == '') {
        self.invoiceObject.paymentSummaryHTML = 'None';
      } // VISUAL FIXES


      self.colorCodeSalesLines(); // colors...
      // Close the related files spot...

      var openedAccordionItem = self.$el.find('.relatedFilesAccordionItem.accordion-item-opened');

      if (openedAccordionItem.length > 0) {
        if (false) {
          openedAccordionItem.find('.accordion-item-content').css('height', 'unset'); // okay?

          openedAccordionItem.removeClass('accordion-item-opened');
        } else {
          self.$app.accordion.close('.relatedFilesAccordionItem.accordion-item-opened');
        }
      } // Name is ABOVE here...


      self.invoiceObject.customerSummaryHTML = '';

      if (true) {
        // TODO: this should be handled differently...
        if (!self.invoiceObject.customeraddress) {
          self.invoiceObject.customeraddress = '';
        }

        if (!self.invoiceObject.customeraddressunit) {
          self.invoiceObject.customeraddressunit = '';
        }

        if (!self.invoiceObject.customercity) {
          self.invoiceObject.customercity = '';
        }

        if (!self.invoiceObject.customerstate) {
          self.invoiceObject.customerstate = '';
        }

        if (!self.invoiceObject.customerzip) {
          self.invoiceObject.customerzip = '';
        }

        if (!self.invoiceObject.customershippingaddress) {
          self.invoiceObject.customershippingaddress = '';
        }

        if (!self.invoiceObject.customershippingaddressunit) {
          self.invoiceObject.customershippingaddressunit = '';
        }

        if (!self.invoiceObject.customershippingcity) {
          self.invoiceObject.customershippingcity = '';
        }

        if (!self.invoiceObject.customershippingstate) {
          self.invoiceObject.customershippingstate = '';
        }

        if (!self.invoiceObject.customershippingzip) {
          self.invoiceObject.customershippingzip = '';
        }
      }

      if (true) {
        // Redo the customer HTML and check against every part of the address not just the street part...
        if (self.invoiceObject.customeraddress && self.invoiceObject.customeraddress !== '') {
          if (self.isShippingAddressDifferentFromBillingAddress()) {
            self.invoiceObject.customerSummaryHTML += '<table cellpadding="0" cellspacing="0" border="0" style="border-collapse: collapse;">' + '<tbody>' + '<tr>' + '<td style="padding-right: 0.5em; border-right: 1px solid #ccc;">' + 'BILL TO' + '</td>' + '<td style="padding-left: 0.5em; font-weight: bold; white-space: nowrap;">' + 'SHIP TO <i class="icon f7-icons color-gray">arrow_left</i>' + '</td>' + '</tr>' + '<tr>' + '<td style="padding-right: 0.5em; border-right: 1px solid #ccc; vertical-align: top;">' + self.invoiceObject.customeraddress + (self.invoiceObject.customeraddressunit !== '' ? ' #' + self.invoiceObject.customeraddressunit : '') + '<br>' + self.invoiceObject.customercity + ', ' + self.invoiceObject.customerstate + ' ' + self.invoiceObject.customerzip + '</td>' + '<td style="padding-left: 0.5em; border-left: 1px solid #ccc; vertical-align: top;">' + self.invoiceObject.customershippingaddress + (self.invoiceObject.customershippingaddressunit !== '' ? ' #' + self.invoiceObject.customershippingaddressunit : '') + '<br>' + self.invoiceObject.customershippingcity + ', ' + self.invoiceObject.customershippingstate + ' ' + self.invoiceObject.customershippingzip + '</td>' + '</tr>' + '</tbody>' + '</table>';
          } else {
            self.invoiceObject.customerSummaryHTML += self.invoiceObject.customeraddress + (self.invoiceObject.customeraddressunit !== '' ? ' #' + self.invoiceObject.customeraddressunit : '') + '<br>' + self.invoiceObject.customercity + ', ' + self.invoiceObject.customerstate + ' ' + self.invoiceObject.customerzip + '<br>';
          }
        }

        if (self.invoiceObject.customerphone && self.invoiceObject.customerphone !== '') {
          if (!self.editing) {
            self.invoiceObject.customerSummaryHTML += '<a class="color-blue" @click="clickedPhone" data-number="' + self.invoiceObject.customerphone + '">' + self.invoiceObject.customerphone + '</a>';
          } else {
            self.invoiceObject.customerSummaryHTML += self.invoiceObject.customerphone;
          }

          if (self.invoiceObject.customeraltphone && self.invoiceObject.customeraltphone !== '') {
            self.invoiceObject.customerSummaryHTML += '<br class="hasAltNumber">';
            self.invoiceObject.customerSummaryHTML += '<span class="altLabel">ALT: </span>';
          } else {
            self.invoiceObject.customerSummaryHTML += '<br>';
          }
        }

        if (self.invoiceObject.customeraltphone && self.invoiceObject.customeraltphone !== '') {
          if (!self.editing) {
            self.invoiceObject.customerSummaryHTML += '<a class="color-blue" @click="clickedPhone" data-number="' + self.invoiceObject.customeraltphone + '">' + self.invoiceObject.customeraltphone + '</a><br>';
          } else {
            self.invoiceObject.customerSummaryHTML += self.invoiceObject.customeraltphone + '<br>';
          }
        }

        if (self.invoiceObject.customeremail && self.invoiceObject.customeremail !== '') {
          if (!self.editing) {
            self.invoiceObject.customerSummaryHTML += '<a class="color-blue" @click="clickedEmail">' + self.invoiceObject.customeremail + '</a><br>';
          } else {
            self.invoiceObject.customerSummaryHTML += self.invoiceObject.customeremail;
          }
        }
      }

      if (self.isNewInvoice && self.invoiceObject.customerSummaryHTML == '') {
        // TODO: if NEW and no data given (like a customer...)
        self.invoiceObject.customerSummaryHTML = 'Enter New Customer Info';
      }

      self.$update(function () {
        self.$app.preloader.hideIn('.editInvoiceButton');
        self.$('textarea.resizable').each(function (e) {
          var thisItem = self.$(this);
          self.$app.input.resizeTextarea(thisItem);
        }); // TODO: fix navbar...
        //self.$app.navbar.size(self.$el);

        /*
        self.$('textarea,input').each(function(e) {
        	var thisItem= self.$(this);
        	self.$app.input.checkEmptyState(thisItem);
        });
        */

        if (self.uppy) {
          self.uppy.setMeta({
            invoiceNumber: self.invoiceNumber
          });
        }

        self.$app.methods.fixDraggableElements(self.$el);
        refreshOnSuccessHook();
      });
    },
    openUppyDashboard: function () {
      var self = this;
      self.uppy.getPlugin('Dashboard').openModal();

      if (!self.$app.device.desktop) {
        var tempTimeout = setTimeout(function () {
          self.$('.uppy-Dashboard-browse').trigger('click');
        }, 250);
      }
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.refreshPage();
      self.$el.on('click', '.saleslineDescriptionInput', function (e) {
        var thisInput = self.$(e.target).closest('.saleslineDescriptionInput');
        var thisIndex = parseInt(thisInput.closest('.item-row').attr('data-index'));

        if (self.editing && self.isNewInvoice) {
          self.invoiceObject.saleslines[thisIndex].quantity = 1;
          self.invoiceObject.saleslines[thisIndex].status = '//'; //self.invoiceObject.saleslines[thisIndex].description= 'TESTING THIS';
          //self.invoiceObject.saleslines[thisIndex].totalamount= 99.95;
          // TESTING...

          self.refreshPage(function () {
            self.$el.find('.saleslineDescriptionInput').attr('autocomplete', 'off');
            /*
            // NEEDED?
            if (self.addSalesLineDropDownAutoComplete.$inputEl) {
            	self.addSalesLineDropDownAutoComplete.$inputEl.f7Autocomplete= null;
            }
            */

            self.addSalesLineDropDownAutoComplete.detachEvents();
            self.addSalesLineDropDownAutoComplete.$inputEl = thisInput;
            self.addSalesLineDropDownAutoComplete.inputEl = thisInput[0];
            self.addSalesLineDropDownAutoComplete.items = [];
            self.addSalesLineDropDownAutoComplete.value = [undefined];
            thisInput.f7Autocomplete = self.addSalesLineDropDownAutoComplete;
            self.addSalesLineDropDownAutoComplete.attachEvents(); // TODO: is this good?
            //console.log('self.addSalesLineDropDownAutoComplete',self.addSalesLineDropDownAutoComplete);

            self.addSalesLineDropDownAutoComplete.open();

            if (false) {
              // MOVED TO CSS
              //var inputWidth= thisInput.width();
              //console.log('width',inputWidth);
              var paddingAmount = self.addSalesLineDropDownAutoComplete.$dropdownEl.find('.autocomplete-dropdown-inner').css('padding-left');

              if (paddingAmount !== '0px') {
                self.addSalesLineDropDownAutoComplete.$dropdownEl.find('.autocomplete-dropdown-inner').css('padding-left', '0px');
                self.addSalesLineDropDownAutoComplete.$dropdownEl.css('margin-left', paddingAmount);
              }
            }
          });
        }
      });
      self.$el.on('focusin', '.saleslineDescriptionCell', function (e) {
        var thisDescriptionElement = self.$(e.target).closest('.saleslineDescriptionCell');
        console.log('thisDescriptionElement', thisDescriptionElement);
      });
      /* // TODO: salesline edit saving here (for new invoices and quotes)
      document.getElementById("editor").addEventListener("input", function() {
      		console.log("input event fired");
      }, false);
      */
      // Dropdown with ajax data

      self.addSalesLineDropDownAutoComplete = self.$f7.autocomplete.create({
        inputEl: '#autocompleteFixTempInput',
        //dropdownContainerEl: '.invoicePageContent',
        openIn: 'dropdown',
        // requestSourceOnOpen: true,
        preloader: false,
        //enable preloader

        /* If we set valueProperty to "id" then input value on select will be set according to this property */
        valueProperty: 'id',
        //object's "value" property name
        textProperty: 'name',
        //object's "text" property name
        limit: 20,
        //limit to 20 results
        dropdownPlaceholderText: 'Type to Search',
        updateInputValueOnSelect: false,
        renderItem: function (item, index) {
          var ac = this; //console.log('item',item,index);

          const tempArray2 = self.currentResultsFromTaskMaster.filter(tempItem => {
            if (tempItem.id == item.value) {
              return true;
            }
          }); //console.log('tempArray2',tempArray2);

          let f7icon = ''; // None...

          if (tempArray2.length && tempArray2[0].type) {
            if (tempArray2[0].type == 'inventory') {
              f7icon = 'cube_box';
            } else if (tempArray2[0].type == 'product') {
              f7icon = 'bag';
            } else if (tempArray2[0].type == 'package') {
              f7icon = 'gift';
            } else if (tempArray2[0].type == 'discount') {
              f7icon = 'tag';
            }
          }

          var itemHTML = '<li>' + '<label class="item-radio item-content" data-value="' + item.value + '">' + ( //'<input type="radio" name="'+item.inputName+'" value="'+item.value+'">'+
          f7icon !== '' ? '<div class="item-media"><i class="icon f7-icons">' + f7icon + '</i></div>' : '') + '<div class="item-inner">' + '<div class="item-title white-space-normal">' + item.text + '</div>' + '</div>' + '</label>' + '</li>';
          return itemHTML;
        },
        source: function (query, render) {
          var autocomplete = this;
          var results = [];

          if (query.length === 0) {
            render(results);
            return;
          } // Show Preloader
          //autocomplete.preloaderShow();


          if (true) {
            if (self.productsSearchTimeout !== null) {
              clearTimeout(self.productsSearchTimeout);
              self.productsSearchTimeout = null;
            }

            self.productsSearchTimeout = setTimeout(function () {
              // TODO: use websocket...?
              self.$root.mdWebSocket.ajaxToServer({
                method: 'POST',
                showSpinner: false,
                url: '/4DACTION/api/searchAvailableSalesLines',
                data: {
                  action: 'searchAvailableSalesLines',
                  q: query,
                  extraInfo: true
                },
                timeout: 5000,
                success: function (data) {
                  self.$app.preloader.hide();

                  if (data.ok) {
                    self.currentResultsFromTaskMaster = data.results;
                    render(data.results);
                  } else {
                    self.currentResultsFromTaskMaster = [];
                    render([]); // TODO: alert error...
                  }
                },
                error: function (data) {
                  self.$app.preloader.hide();
                  self.currentResultsFromTaskMaster = [];
                  render([]); // TODO: alert error...
                }
              });
            }, 500);
          } else {
            // TODO!
            results = [{
              'name': 'test',
              'id': 'sku1111'
            }, {
              'name': 'test2',
              'id': 'sku2222'
            }, {
              'name': 'test3',
              'id': 'sku3333'
            }];
            render(results);
          } // Do Ajax request to Autocomplete data

          /*
               self.$f7.request({
                 url: './autocomplete-languages.json',
                 method: 'GET',
                 dataType: 'json',
                 //send "query" to server. Useful in case you generate response dynamically
                 data: {
                   query: query,
                 },
                 success: function (data) {
                   // Find matched items
                   for (var i = 0; i < data.length; i++) {
                     if (data[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(data[i]);
                   }
                   // Hide Preoloader
                   autocomplete.preloaderHide();
                   // Render items by passing array with result items
                   render(results);
                 }
               });
          */

        },
        on: {
          change: function (value) {
            var ac = this; // console.log('ac.value',value);

            /*
            EXAMPLE "value"
            [
            		{
            				"id": "pkg17342",
            				"type": "package",
            				"brand": "45th Street Bedding",
            				"nameWithoutBrand": "Woodlawn Plush - Queen",
            				"name": "45th Street Bedding Woodlawn Plush - Queen",
            				"nameHTML": "45th Street Bedding Woodlawn Plush - <strong>Queen</strong>",
            				"price": 2799.95,
            				"priceString": "$2,799.95"
            		}
            ]
            */

            if (value && value.length && value[0]) {
              //console.log('ac',ac);
              var thisIndex = parseInt(ac.$inputEl.closest('.item-row').attr('data-index'));

              if (thisIndex > -1) {
                self.invoiceObject.saleslines[thisIndex].acValue = value[0]; //console.log('rowIndex',thisIndex);

                var needToRefresh = false;

                if (value[0].type == 'inventory' || value[0].type == 'package') {
                  var tempTimeout = setTimeout(function () {
                    // TODO: need salesline index thisIndex
                    // Also need SKU or ID of inventory or package
                    self.openPickStockWindow();
                  }, 0);
                } else if (value[0].type == 'delivery') {
                  self.invoiceObject.saleslines[thisIndex].quantity = 1;
                  self.invoiceObject.saleslines[thisIndex].status = '*';
                  self.invoiceObject.saleslines[thisIndex].description = 'Delivery ' + value[0].name;
                  self.invoiceObject.saleslines[thisIndex].totalamount = value[0].price;
                  needToRefresh = true;
                } else if (value[0].type == 'discount') {
                  self.invoiceObject.saleslines[thisIndex].quantity = 1;
                  self.invoiceObject.saleslines[thisIndex].status = '-';
                  self.invoiceObject.saleslines[thisIndex].description = value[0].name;
                  self.invoiceObject.saleslines[thisIndex].totalamount = value[0].price;
                } else {
                  self.invoiceObject.saleslines[thisIndex].quantity = 1;
                  self.invoiceObject.saleslines[thisIndex].status = 'SO';
                  self.invoiceObject.saleslines[thisIndex].description = value[0].name;
                  self.invoiceObject.saleslines[thisIndex].totalamount = value[0].price;
                  needToRefresh = true;
                }

                if (needToRefresh) {
                  self.refreshPage();
                }

                var tempTimeout = setTimeout(function () {
                  ac.items = [];
                  ac.value = [undefined];
                }, 500);
              }
            }
          }
        }
      });
      /*
      self.pickupDateModal= self.$app.calendar.create({
      	openIn: 'customModal',
      	header: true,
      	footer: true,
      });
      
      self.shippingDateModal= self.$app.calendar.create({
      	inputEl: '#shippingDateInput',
      	openIn: 'customModal',
      	header: true,
      	footer: true,
      });
      
      self.serviceDateModal= self.$app.calendar.create({
      	inputEl: '#serviceDateInput',
      	openIn: 'customModal',
      	header: true,
      	footer: true,
      });
      */

      self.$el.on('click', 'a.imageABlock', function (e) {
        var relatedImageElement = self.$(e.target).closest('a.imageABlock');
        self.relatedFilesPopup.open(parseInt(relatedImageElement.attr('data-index')));
      });
      self.uppy = Uppy({
        debug: false,
        autoProceed: true,
        allowMultipleUploads: true,
        restrictions: {
          maxFileSize: 10000000,
          minNumberOfFiles: 1,
          allowedFileTypes: ['*/*']
        },
        meta: {
          action: 'addFileToInvoice',
          version: 2,
          invoiceNumber: self.invoiceNumber,
          sessionid: self.$root.mdWebSocket.sessionId
        }
      }).use(UppyDashboard, {
        inline: false,
        target: '#app',
        closeAfterFinish: true,
        disableThumbnailGenerator: true,
        showSelectedFiles: false,
        closeModalOnClickOutside: true,
        onRequestCloseModal: function () {
          self.uppy.getPlugin('Dashboard').closeModal();
          self.$('.uppy-DashboardContent-back').trigger('click');
          self.uppy.cancelAll();
        }
      }).use(UppyXHRUpload, {
        endpoint: 'https://taskmaster.bedroomsandmore.com/4DACTION/api/addFileToInvoice',
        formData: true,
        fieldName: 'file'
      });

      if (self.$app.device.desktop) {
        // If Desktop...
        self.uppy.use(UppyWebcam, {
          target: UppyDashboard,
          modes: ['picture']
        });
      }

      self.uppy.on('complete', result => {
        // console.log('successful files:', result.successful);
        // console.log('failed files:', result.failed);
        if (result.successful.length > 0) {
          self.$root.user.imageType = result.successful[0].response.body.imageType;
          self.$root.user.imageUpdatedDate = result.successful[0].response.body.imageUpdatedDate;
          self.$root.user.imageUpdatedTime = result.successful[0].response.body.imageUpdatedTime;
          self.$root.user.imageUpdatedDateTime = result.successful[0].response.body.imageUpdatedDateTime; // self.$root.noCacheStrings.profileImage= Math.random();
          //self.$root.user.imageUpdatedDateTime= Math.random(); // TODO... use the actual date time updated...
          // TODO!

          /*
          self.$root.updateProfileImage(self.$root.getImageURL(self.$root.user.id,self.$root.user.imageType,self.$root.user.imageUpdatedDateTime));
          
          if (app.f7.data.localStorageData) {
          	if (app.f7.data.localStorageData.saveLogin) {
          		self.$app.data.localStorageData.user= self.$app.data.user;
          		self.$app.methods.setItemLocalStorage('localStorageData',self.$app.data.localStorageData);
          	}
          }
          */
        } else {// TODO: alert error...
          }

        self.uppy.reset();

        if (!self.editing) {
          // Just ask for a reload of the current invoice...
          self.$app.data.pageStatusData = {
            invoiceOffset: 0,
            invoiceNumber: self.invoiceNumber
          };
          self.$root.sendStatus();
        } else {// TODO: if editing... how to refresh without ruining current unsaved edits...
        }
      });
    },
    pageAfterOut: function (e, page) {
      var self = this;
      self.$app.methods.emitEventToApp('changedOnScreenInvoiceDetails', {
        invoiceNumber: ''
      });
    }
  },
  id: '193446ea05',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page taskMasterInvoicePage" data-name="taskMasterInvoicePage">\n		';
      r += Template7Helpers.if.call(ctx_1, ctx_1.editing, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="testingOverlay">TESTING / DOES NOT SAVE</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n		<div class="navbar"';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' data-collapse-large-title-on-scroll="false"';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '>\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					';
      r += Template7Helpers.unless.call(ctx_1, false, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <!-- TODO: when we go live, add this: $app.device.electron -->\n					<!-- TODO: this should be generic not specific to this report as in if -2 is NOT another invoice, then go back to it... if it is then force back to the main... -->\n					';
          r += Template7Helpers.js_if.call(ctx_2, "((this.$router.history.length-2) > -1) && (!this.$root.matchRuleShort(this.$router.history[(this.$router.history.length-2)], '/taskmaster/invoices/*/'))", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n					';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<a href="';
              r += c(ctx_3.$root.invoiceBackHref, ctx_3);
              r += '" class="link back" data-force="true">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n					';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n					';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/taskmaster/search/" class="button invoiceElectronSearchButton button-raised" style="height: 1.75em; line-height: 1.75em;"><i class="icon f7-icons if-not-md">search</i><i class="icon material-icons md-only">search</i><span class="lessPriority">Search</span></a>\n					<!--<a href="#" class="link icon-only panel-open openLeftPanelLinkTop" data-panel=".mainScreenLeftPanel"><i class="icon f7-icons">menu</i></a>-->\n					';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n				<div class="title" style="opacity: 1;">';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.isLoading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.if.call(ctx_2, ctx_2.$app.device.electron, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<a href="#" class="link icon-only invoiceElectronArrows';
              r += Template7Helpers.if.call(ctx_3, ctx_3.isLoading, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' disabled';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '" @click="invoiceBack" style="display: inline-block; padding: 0px 1em;"><i class="icon f7-icons">arrow_left</i></a>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += c(ctx_2.invoiceNumber, ctx_2);
          r += Template7Helpers.if.call(ctx_2, ctx_2.$app.device.electron, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<a href="#" class="link icon-only invoiceElectronArrows';
              r += Template7Helpers.if.call(ctx_3, ctx_3.isLoading, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' disabled';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.isNewInvoice, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.isNewInvoice, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' disabled';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '" @click="invoiceForward" style="display: inline-block; padding: 0px 1em;"><i class="icon f7-icons">arrow_right</i></a>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += c(ctx_2.loadingInvoiceNumber, ctx_2);
          r += '&nbsp;&nbsp;<div class="preloader color-white"></div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<!--\n					<a href="#" class="link icon-only';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isLoading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" @click="invoicePrint">\n						<i class="icon f7-icons if-not-md">printer</i>\n						<i class="icon material-icons if-md">print</i>\n					</a>\n					-->\n					';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.editing, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="#" class="link icon-only';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isLoading, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' editInvoiceButton" @click="invoiceEdit">\n						<i class="icon material-icons">lock</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isLoading, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' page-content-loading';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '">';
          r += c(ctx_2.invoiceObject.customerfirstname, ctx_2);
          r += ' ';
          r += c(ctx_2.invoiceObject.customerlastname, ctx_2);
          r += '</div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="toolbar toolbar-bottom">\n			<div class="toolbar-inner">\n		        <a href="#" class="link icon-only panel-open openLeftPanelLinkBottom" data-panel=".mainScreenLeftPanel"><i class="icon f7-icons">menu</i></a>\n		        <!--\n				<a href="#" class="link icon-only popover-open';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isLoading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" @click="invoiceMenu" data-popover=".invoicePopoverMenu"><i class="icon f7-icons">gear</i></a>-->\n				';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isNewInvoice, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<!--<a href="/taskmaster/orders/" class="link">      Open Orders</a>-->\n				';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isLoading, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.isLoading, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.isLoading, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n				';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.editing, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<a href="#" class="link icon-only';
              r += Template7Helpers.if.call(ctx_3, ctx_3.isLoading, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' disabled';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '" @click="invoiceBack"><i class="icon f7-icons">arrow_left</i></a>\n				<a href="#" class="link icon-only invoiceShareLink';
              r += Template7Helpers.if.call(ctx_3, ctx_3.isLoading, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' disabled';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '" @click="invoiceShare"><i class="icon f7-icons">square_arrow_up</i><span class="lessPriority">Print</span class="lessPriority"></a>\n				<a href="#" class="link icon-only';
              r += Template7Helpers.if.call(ctx_3, ctx_3.isLoading, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' disabled';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '" @click="invoiceForward"><i class="icon f7-icons">arrow_right</i></a>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				';
      r += Template7Helpers.if.call(ctx_1, ctx_1.editing, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<button class="col disabled button button-big button-raised button-fill standOutButton" style="max-width: 6em;" @click="invoiceSave">';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isNewInvoice, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'Record';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += 'Save';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</button>\n				';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isLoading, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<a class="link';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isLoading, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' editInvoiceButton" @click="invoiceEdit">Edit</a>\n				';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="page-content invoicePageContent color-blue';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isLoading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' page-content-loading';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += Template7Helpers.if.call(ctx_1, ctx_1.editing, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' page-content-editing';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" style="background: var(--bnm-invoice-right-color); color: var(--bnm-invoice-right-text-color); overflow-x: hidden; -webkit-user-select: unset;">\n			<form class="invoiceForm" name="invoiceForm" onsubmit="return false;" autocomplete="off">\n				<input id="autocompleteFixTempInput" autocomplete="address-level4" type="text" style="position: absolute; left: 10000px;">\n				';
      r += Template7Helpers.js_if.call(ctx_1, "(this.isLoading) && (this.firstTimeIn)", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="block" style="text-align: center;">\n					<p>Loading...</p><!--<div class="preloader" style="width: 44px; height: 44px;"></div>-->\n				</div>\n				';
          r += Template7Helpers.js_if.call(ctx_2, "String(this.invoiceObject._output_employeeName).indexOf(this.invoiceObject._output_writtenBy) == -1", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "String(this.invoiceObject._output_employeeName).indexOf(this.invoiceObject._output_writtenBy) == -1", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "this.status == '//'", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "this.status == '-'", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "this.status == '*'", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "(this.status !== '//') && (this.status !== '*') && (this.status !== '-')", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "this.totalamount < 0", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "((this.quantity !== 0) || ((this.status !== '//') && (this.status !== '*')))", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "((this.quantity !== 0) || ((this.status !== '//') && (this.status !== '*')))", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "((this.totalamount !== 0) || ((this.status !== '//') && (this.status !== '*')))", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.js_if.call(ctx_3, "((this.totalamount !== 0) || (this.quantity !== 0))", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "this.invoiceObject.paymentSummaryHTML !== 'None'", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "this.invoiceObject.paymentSummaryHTML !== 'None'", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "this.invoiceObject.paymentSummaryHTML == 'None'", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "this.invoiceObject.notesHTML !== ''", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "(this.invoiceObject.employeenotes !== '') || (this.editing)", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "this.invoiceObject.relatedFiles", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "this.invoiceObject.relatedFiles", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="block contactInfoBlock no-margin-bottom">\n					<div class="row">\n						<div style="max-width: 66%;">\n							';
          r += Template7Helpers.if.call(ctx_2, ctx_2.editing, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							<div class="elevation-1 elevation-hover-10 elevation-pressed-3 elevation-transition customerWrapperDiv" @click="customerEdit" style="color: #000">\n								';
              r += Template7Helpers.if.call(ctx_3, ctx_3.isNewInvoice, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<i class="icon f7-icons addCustomerIcon">person_badge_plus</i>';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<i class="icon f7-icons editIcon">pencil_circle</i>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n							';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n							<div class="elevation-1 customerWrapperDiv" style="color: #000;">\n							';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								';
          r += Template7Helpers.if.call(ctx_2, ctx_2.turnOffLargeTitles, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n								<p style="font-weight: bold; margin: 0px;">';
              r += c(ctx_3.invoiceObject.customerfirstname, ctx_3);
              r += ' ';
              r += c(ctx_3.invoiceObject.customerlastname, ctx_3);
              r += '</p>\n								';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								';
          r += c(ctx_2.invoiceObject.customerSummaryHTML, ctx_2);
          r += '\n							';
          r += Template7Helpers.if.call(ctx_2, ctx_2.editing, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							</div>\n							';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n							</div>\n							';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						</div>\n						<div style="max-width: 33%; word-break: keep-all; text-align: right; z-index: 1;">\n							<span style="font-size: 1.5em;">';
          r += c(ctx_2.invoiceObject.invoicedate, ctx_2);
          r += '</span><br>\n							';
          r += Template7Helpers.if.call(ctx_2, ctx_2.invoiceObject.invoicetime, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.invoiceObject.invoicetimeString, ctx_3);
              r += '<br>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n							<!--&nbsp;<br>-->\n							<strong>';
          r += c(ctx_2.invoiceObject._output_employeeName, ctx_2);
          r += '</strong>';
          r += Template7Helpers.js_if.call(ctx_2, "String(this.invoiceObject._output_employeeName).indexOf(this.invoiceObject._output_writtenBy) == -1", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <em style="opacity: 0.5">(';
              r += c(ctx_3.invoiceObject._output_writtenBy, ctx_3);
              r += ')</em>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						</div>\n					</div>\n				</div>\n				<!-- TODO: Designer? Phone Order? LINKED INVOICES? -->\n				<div class="card salesLinesCard elevation-1" style="color: #000;">\n					<div class="card-header card-header-slim">\n						<span class="salesLineCardHeaderColumnOTD"><label class="checkbox';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.editing, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" style="padding-left: 0.62em; display: flex;"><input type="checkbox"';
          r += Template7Helpers.if.call(ctx_2, ctx_2.invoiceObject.isOTD, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' checked';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '><i class="icon icon-checkbox';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.editing, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' color-gray';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"></i><span>All Items OTD</span></label></span>\n						<div class="linkedInvoicesButtons salesLineCardHeaderColumnLinkButtons">\n							<a href="#" class="link button button-small button-raised';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isLoading, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"';
          r += Template7Helpers.if.call(ctx_2, ctx_2.invoiceObject._old_prevlink, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' @click="requestInvoice(this.invoiceObject._old_prevlink)"';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' style="visibility: hidden;"';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '><i class="icon f7-icons">arrow_left</i></a>\n							<a href="#" class="link customerCard button button-small button-raised';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isLoading, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.isNewInvoice, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="customerCardClicked"><i class="icon f7-icons">person_crop_rectangle</i></a>\n							<a href="#" class="link button button-small button-raised';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isLoading, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"';
          r += Template7Helpers.if.call(ctx_2, ctx_2.invoiceObject._old_nextlink, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' @click="requestInvoice(this.invoiceObject._old_nextlink)"';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' style="visibility: hidden;"';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '><i class="icon f7-icons">arrow_right</i></a>\n						</div>\n						<!--<div class="block no-margin salesLineCardHeaderColumnEmployeeName"><strong>';
          r += c(ctx_2.invoiceObject._output_employeeName, ctx_2);
          r += '</strong>';
          r += Template7Helpers.js_if.call(ctx_2, "String(this.invoiceObject._output_employeeName).indexOf(this.invoiceObject._output_writtenBy) == -1", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <em style="color: #ccc;">(';
              r += c(ctx_3.invoiceObject._output_writtenBy, ctx_3);
              r += ')</em>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>-->\n					</div>\n					<div class="card-content saleslinesCardContent">\n						<div class="list saleslinesList';
          r += Template7Helpers.if.call(ctx_2, ctx_2.editing, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' isEditing';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '">\n							<ul>\n								';
          r += Template7Helpers.if.call(ctx_2, ctx_2.editing, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n								<li class="item-content item-input ';
              r += c(ctx_3.styleClass, ctx_3);
              r += '">\n									<div class="item-inner item-cell">\n										<div class="item-row" style="width: 100%">\n											<div class="item-cell" style="flex: 9%; max-width: 40px;">R</div>\n											<div class="item-cell" style="flex: 11%; max-width: 40px;">Q</div>\n											<div class="item-cell" style="flex: 11%; max-width: 40px;">S</div>\n											<div class="item-cell" style="flex: 50%; text-align: left;">Item</div>\n											<div class="item-cell" style="flex: 19%; max-width: 80px; text-align: right;">Price</div>\n										</div>\n									</div>\n								</li>\n								';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								';
          r += Template7Helpers.each.call(ctx_2, ctx_2.invoiceObject.saleslines, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n									';
              r += Template7Helpers.if.call(ctx_3, root.editing, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n									<li class="item-content item-input ';
                  r += c(ctx_4.styleClass, ctx_4);
                  r += '">\n										<div class="item-inner item-cell">\n											<div class="item-row" style="width: 100%" data-index="';
                  r += c(data_4 && data_4.index, ctx_4);
                  r += '">\n												<div class="item-cell" style="flex: 9%; max-width: 40px;">\n													<label class="checkbox"><input type="checkbox"';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.inCustomerPossession, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' checked';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '><i class="icon-checkbox"';
                  r += Template7Helpers.unless.call(ctx_4, ctx_4.inCustomerPossession, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' style="border-color: #000;"';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '></i></label>\n												</div>\n												<div class="item-cell" style="flex: 11%; max-width: 40px;">\n													<input name="saleslineQuantityInput';
                  r += c(data_4 && data_4.index, ctx_4);
                  r += '" type="text" value="';
                  r += c(ctx_4.quantity, ctx_4);
                  r += '" style="text-align: center;" />\n												</div>\n												<div class="item-cell" style="flex: 11%; max-width: 40px;">\n													<input name="saleslineStatusInput';
                  r += c(data_4 && data_4.index, ctx_4);
                  r += '" readonly="readonly" type="text" value="';
                  r += c(ctx_4.status, ctx_4);
                  r += '" style="text-align: center;" />\n												</div>\n												<div class="item-cell" style="flex: 50%;">\n													<input name="saleslineDescriptionInput';
                  r += c(data_4 && data_4.index, ctx_4);
                  r += '" class="saleslineDescriptionInput" type="text" value="';
                  r += c(ctx_4.description, ctx_4);
                  r += '" style="text-align: left;" />\n												</div>\n												<div class="item-cell" style="flex: 19%; max-width: 80px;">\n													<input name="saleslineTotalAmountInput';
                  r += c(data_4 && data_4.index, ctx_4);
                  r += '" type="text" value="';
                  r += c(ctx_4.totalamount, ctx_4);
                  r += '" style="text-align: right;" />\n												</div>\n											</div>\n										</div>\n									</li>\n									';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.hideAfter, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += Template7Helpers.if.call(ctx_4, ctx_4.quantity, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.inCustomerPossession, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += Template7Helpers.if.call(ctx_5, root.isNewInvoice, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += Template7Helpers.if.call(ctx_4, root.isNewInvoice, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += Template7Helpers.if.call(ctx_4, root.isNewInvoice, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n									<li class="item-content ';
                  r += c(ctx_4.styleClass, ctx_4);
                  r += '">\n										<div class="item-inner item-cell';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.hideAfter, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' hideAfter';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '">\n											<div data-index="';
                  r += c(data_4 && data_4.index, ctx_4);
                  r += '" class="item-row item-row-salesline';
                  r += Template7Helpers.js_if.call(ctx_4, "this.status == '//'", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' saleslineNote';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += Template7Helpers.js_if.call(ctx_4, "this.status == '-'", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' saleslineDiscount';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += Template7Helpers.js_if.call(ctx_4, "this.status == '*'", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' saleslineService';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += Template7Helpers.js_if.call(ctx_4, "(this.status !== '//') && (this.status !== '*') && (this.status !== '-')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' saleslineProduct';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += Template7Helpers.js_if.call(ctx_4, "this.totalamount < 0", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' saleslineNegative';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '">\n												';
                  r += Template7Helpers.js_if.call(ctx_4, "((this.quantity !== 0) || ((this.status !== '//') && (this.status !== '*')))", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div class="item-cell saleslineQuantityAndStatusCell">';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.quantity, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '<div class="row no-gap"><div class="col-100 xsmall-33">';
                          r += Template7Helpers.unless.call(ctx_6, root.isLoading, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '<label class="checkbox';
                              r += Template7Helpers.unless.call(ctx_7, root.editing, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += ' color-gray disabled';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                              });
                              r += '" style="left: 4px;"><input type="checkbox"';
                              r += Template7Helpers.if.call(ctx_7, ctx_7.inCustomerPossession, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += ' checked';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                              });
                              r += '><i class="icon-checkbox"';
                              r += Template7Helpers.unless.call(ctx_7, ctx_7.inCustomerPossession, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += ' style="border-color: #000;"';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                              });
                              r += '></i></label>';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '&nbsp;</div><div class="col-100 xsmall-66 saleslineStockStatusCell"><span';
                          r += Template7Helpers.if.call(ctx_6, root.isNewInvoice, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += ' contenteditable="true"';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += ' class="saleslineQuantityCell">';
                          r += c(ctx_6.quantity, ctx_6);
                          r += '</span>&nbsp;';
                          r += c(ctx_6.currentDisplayStatus, ctx_6);
                          r += '</div></div>';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '&nbsp;';
                          return r;
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</div>';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n												<div';
                  r += Template7Helpers.if.call(ctx_4, root.isNewInvoice, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' contenteditable="true"';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' class="item-cell saleslineDescriptionCell';
                  r += Template7Helpers.js_if.call(ctx_4, "((this.quantity !== 0) || ((this.status !== '//') && (this.status !== '*')))", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += ' saleslineDescriptionAlone';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '">';
                  r += c(ctx_4.descriptionHTML, ctx_4);
                  r += '</div>\n												';
                  r += Template7Helpers.js_if.call(ctx_4, "((this.totalamount !== 0) || ((this.status !== '//') && (this.status !== '*')))", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div';
                      r += Template7Helpers.if.call(ctx_5, root.isNewInvoice, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += ' contenteditable="true"';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += ' class="item-cell saleslineDollarCell">';
                      r += Template7Helpers.js_if.call(ctx_5, "((this.totalamount !== 0) || (this.quantity !== 0))", {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += Template7Helpers.js.call(ctx_6, "Number(this.totalamount).formatMoney()", {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '&nbsp;';
                          return r;
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</div>';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n											</div>\n										</div>\n									</li>\n									';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n								';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n							</ul>\n						</div>\n					</div>\n					<div class="card-footer">\n						';
          r += Template7Helpers.if.call(ctx_2, ctx_2.editing, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							<div style="flex-grow: 1;">\n								<input type="hidden" name="deliveryDateInput" id="deliveryDateInput" value="';
              r += c(ctx_3.invoiceObject.daterequestdelivery, ctx_3);
              r += '">\n								<input type="hidden" name="pickupDateInput" id="pickupDateInput" value="';
              r += c(ctx_3.invoiceObject.daterequestpickup, ctx_3);
              r += '">\n								<input type="hidden" name="shippingDateInput" id="shippingDateInput" value="';
              r += c(ctx_3.invoiceObject.daterequestshipping, ctx_3);
              r += '">\n								<input type="hidden" name="serviceDateInput" id="serviceDateInput" value="';
              r += c(ctx_3.invoiceObject.daterequestservice, ctx_3);
              r += '">\n								<div class="block no-margin no-padding schedulingButtonsWrapperDiv" style="text-align: center;">\n									<div class="row">\n										<div class="col-50 xsmall-25"';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasASchedulingDateSet, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.unless.call(ctx_4, ctx_4.invoiceObject.hasDeliveryDate, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' style="opacity: 0.5;"';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' @click="openSchedulingPopup">\n											<p class="no-margin"><strong>Delivery<span class="lessPriority"> Date</span></strong></p>\n											<p class="no-margin">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasDeliveryDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span class="lessPriority">';
                  r += Template7Helpers.js.call(ctx_4, "moment(new Date(this.invoiceObject.daterequestdelivery)).format('ddd')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</span>';
                  r += Template7Helpers.js.call(ctx_4, "moment(new Date(this.invoiceObject.daterequestdelivery)).format('M/DD/YY')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span></span>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</p>\n											<button class="button button-small button-raised';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasDeliveryDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' button-fill';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '"><i class="icon las la-truck" style="font-size: 18px;"></i><span class="lessPriority">Delivery</span></button>\n											';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasDeliveryDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<label class="checkbox disabled"><input type="checkbox"';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.invoiceObject.tentativeDelivery, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' checked';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '><i class="icon-checkbox';
                  r += Template7Helpers.unless.call(ctx_4, ctx_4.editing, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' color-gray';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '" style="float: left; margin-right: 3px; margin-top: 3px;"></i><span class="lessPriority">Tentative</span></label>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n										</div>\n										<div class="col-50 xsmall-25"';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasASchedulingDateSet, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.unless.call(ctx_4, ctx_4.invoiceObject.hasPickupDate, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' style="opacity: 0.5;"';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' @click="openSchedulingPopup">\n											<p class="no-margin"><strong>Pickup<span class="lessPriority"> Date</span></strong></p>\n											<p class="no-margin">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasPickupDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span class="lessPriority">';
                  r += Template7Helpers.js.call(ctx_4, "moment(new Date(this.invoiceObject.daterequestpickup)).format('ddd')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' </span>';
                  r += Template7Helpers.js.call(ctx_4, "moment(new Date(this.invoiceObject.daterequestpickup)).format('M/DD/YY')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span></span>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</p>\n											<button class="button button-small button-raised';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasPickupDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' button-fill';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '"><i class="icon material-icons" style="font-size: 18px;">directions_walk</i><span class="lessPriority">Pick up</span></button>\n										</div>\n										<div class="col-50 xsmall-25"';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasASchedulingDateSet, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.unless.call(ctx_4, ctx_4.invoiceObject.hasShippingDate, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' style="opacity: 0.5;"';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' @click="openSchedulingPopup">\n											<p class="no-margin"><strong>Shipping<span class="lessPriority"> Date</span></strong></p>\n											<p class="no-margin">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasShippingDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span class="lessPriority">';
                  r += Template7Helpers.js.call(ctx_4, "moment(new Date(this.invoiceObject.daterequestshipping)).format('ddd')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' </span>';
                  r += Template7Helpers.js.call(ctx_4, "moment(new Date(this.invoiceObject.daterequestshipping)).format('M/DD/YY')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span></span>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</p>\n											<button class="button button-small button-raised';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasShippingDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' button-fill';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '"><i class="icon f7-icons" style="font-size: 18px;">cube_box</i><span class="lessPriority">Shipping</span></button>\n											';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.shippingTrackingNumber, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<button class="button" @click="$app.dialog.alert(this.invoiceObject.shippingTrackingNumber,\'Tracking Number\')">Tracking No.</button>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n										</div>\n										<div class="col-50 xsmall-25"';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasASchedulingDateSet, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.unless.call(ctx_4, ctx_4.invoiceObject.hasServiceDate, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' style="opacity: 0.5;"';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' @click="openSchedulingPopup">\n											<p class="no-margin"><strong>Service<span class="lessPriority"> Date</span></strong></p>\n											<p class="no-margin">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasServiceDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span class="lessPriority">';
                  r += Template7Helpers.js.call(ctx_4, "moment(new Date(this.invoiceObject.daterequestservice)).format('ddd')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' </span>';
                  r += Template7Helpers.js.call(ctx_4, "moment(new Date(this.invoiceObject.daterequestservice)).format('M/DD/YY')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span></span>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</p>\n											<button class="button button-small button-raised';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasServiceDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' button-fill';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '"><i class="icon f7-icons" style="font-size: 18px;">wrench</i><span class="lessPriority">Service</span></button>\n										</div>\n									</div>\n								</div>\n								';
              r += Template7Helpers.if.call(ctx_3, ctx_3.editing, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n								<p class="no-margin" style="text-align: center;"><a href="#" class="button button-inline button-small button-raised';
                  r += Template7Helpers.js_if.call(ctx_4, "this.invoiceObject.paymentSummaryHTML !== 'None'", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' button-fill';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '" style="margin-top: 0.5em;"><i class="icon f7-icons">money_dollar_circle</i><span>';
                  r += Template7Helpers.js_if.call(ctx_4, "this.invoiceObject.paymentSummaryHTML !== 'None'", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += c(ctx_5.invoiceObject.paymentSummaryHTML, ctx_5);
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += 'Payments';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</span></a></p>\n								';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n							</div>\n						';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n							<div class="block-header no-margin no-padding">\n								<span>';
              r += Template7Helpers.unless.call(ctx_3, ctx_3.invoiceObject.schedulingSummaryCode, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'Scheduling: ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '<span style="color: #000;">';
              r += c(ctx_3.invoiceObject.schedulingSummaryHTML, ctx_3);
              r += '</span><br>\n								<i class="icon f7-icons" style="margin-right: 5px;">money_dollar_circle</i>';
              r += Template7Helpers.js_if.call(ctx_3, "this.invoiceObject.paymentSummaryHTML == 'None'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'Payments: ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '<span style="text-decoration: underline; color: #000;">';
              r += c(ctx_3.invoiceObject.paymentSummaryHTML, ctx_3);
              r += '</span>\n								</span>\n								&nbsp;\n							</div>\n						';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						<div class="totalsRightSide" style="text-align: right; align-self: end;">\n							\n							<table class="invoiceTotalsTable" cellpadding="0" cellspacing="0" border="0" style="color: #000; margin-left: auto;">\n								<tbody>\n									<tr>\n										<td class="leftSideTitle">AMT:</td>\n										<td style="white-space: nowrap;">';
          r += Template7Helpers.js.call(ctx_2, "Number(this.invoiceObject.subtotalamount).formatMoney()", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</td>\n									</tr>\n									<tr>\n										<td class="leftSideTitle">TAX:</td>\n										<td style="white-space: nowrap;">';
          r += Template7Helpers.js.call(ctx_2, "Number(this.invoiceObject.taxamount).formatMoney()", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</td>\n									</tr>\n									<tr>\n										<td class="leftSideTitle">TOT:</td>\n										<td style="white-space: nowrap;">';
          r += Template7Helpers.js.call(ctx_2, "Number(this.invoiceObject.totalamount).formatMoney()", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</td>\n									</tr>\n									<tr>\n										<td class="leftSideTitle">DWN:</td>\n										<td style="white-space: nowrap;">';
          r += Template7Helpers.js.call(ctx_2, "Number(this.invoiceObject.downamount).formatMoney()", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</td>\n									</tr>\n									<tr>\n										<td class="leftSideTitle';
          r += Template7Helpers.if.call(ctx_2, ctx_2.invoiceObject.balanceowedamount, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' highlightCellLeft';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '">BAL:</td>\n										<td class="';
          r += Template7Helpers.if.call(ctx_2, ctx_2.invoiceObject.balanceowedamount, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'highlightCellRight';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" style="white-space: nowrap;">';
          r += Template7Helpers.js.call(ctx_2, "Number(this.invoiceObject.balanceowedamount).formatMoney()", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</td>\n									</tr>\n								</tbody>\n							</table>\n							';
          r += Template7Helpers.if.call(ctx_2, ctx_2.editing, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							<p class="no-margin"><a href="#" class="button button-inline button-small button-raised">Tax Options</a></p>\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						</div>\n					</div>\n				</div>\n				<!--\n				<input type="hidden" name="deliveryDateInput" id="deliveryDateInput" value="';
          r += c(ctx_2.invoiceObject.daterequestdelivery, ctx_2);
          r += '">\n				<input type="hidden" name="pickupDateInput" id="pickupDateInput" value="';
          r += c(ctx_2.invoiceObject.daterequestpickup, ctx_2);
          r += '">\n				<input type="hidden" name="shippingDateInput" id="shippingDateInput" value="';
          r += c(ctx_2.invoiceObject.daterequestshipping, ctx_2);
          r += '">\n				<input type="hidden" name="serviceDateInput" id="serviceDateInput" value="';
          r += c(ctx_2.invoiceObject.daterequestservice, ctx_2);
          r += '">\n				';
          r += Template7Helpers.if.call(ctx_2, ctx_2.editing, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<div class="block no-margin" style="text-align: center;">\n					<div class="row">\n						<div class="col-50 xsmall-25"';
              r += Template7Helpers.unless.call(ctx_3, ctx_3.invoiceObject.hasDeliveryDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' style="opacity: 0.5;"';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' @click="openSchedulingPopup">\n							<p class="no-margin"><strong>Delivery Date</strong></p>\n							<p class="no-margin">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasDeliveryDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.js.call(ctx_4, "moment(new Date(this.invoiceObject.daterequestdelivery)).format('ddd M/DD/YY')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span>00/00/00</span>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</p>\n							<button class="button button-small button-raised';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasDeliveryDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' button-fill';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '">Delivery</button>\n						</div>\n						<div class="col-50 xsmall-25"';
              r += Template7Helpers.unless.call(ctx_3, ctx_3.invoiceObject.hasPickupDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' style="opacity: 0.5;"';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' @click="openSchedulingPopup">\n							<p class="no-margin"><strong>Pickup Date</strong></p>\n							<p class="no-margin">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasPickupDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.js.call(ctx_4, "moment(new Date(this.invoiceObject.daterequestpickup)).format('ddd M/DD/YY')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span>00/00/00</span>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</p>\n							<button class="button button-small button-raised';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasPickupDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' button-fill';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '">Pick up</button>\n						</div>\n						<div class="col-50 xsmall-25"';
              r += Template7Helpers.unless.call(ctx_3, ctx_3.invoiceObject.hasShippingDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' style="opacity: 0.5;"';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' @click="openSchedulingPopup">\n							<p class="no-margin"><strong>Shipping Date</strong></p>\n							<p class="no-margin">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasShippingDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.js.call(ctx_4, "moment(new Date(this.invoiceObject.daterequestshipping)).format('ddd M/DD/YY')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span>00/00/00</span>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</p>\n							<button class="button button-small button-raised';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasShippingDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' button-fill';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '">Shipping</button>\n							';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.shippingTrackingNumber, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<button class="button" @click="$app.dialog.alert(this.invoiceObject.shippingTrackingNumber,\'Tracking Number\')">Tracking No.</button>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n						</div>\n						<div class="col-50 xsmall-25"';
              r += Template7Helpers.unless.call(ctx_3, ctx_3.invoiceObject.hasServiceDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' style="opacity: 0.5;"';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' @click="openSchedulingPopup">\n							<p class="no-margin"><strong>Service Date</strong></p>\n							<p class="no-margin">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasServiceDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.js.call(ctx_4, "moment(new Date(this.invoiceObject.daterequestservice)).format('ddd M/DD/YY')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span>00/00/00</span>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</p>\n							<button class="button button-small button-raised';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.hasServiceDate, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' button-fill';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '">Service</button>\n						</div>\n					</div>\n				</div>\n				-->\n				<div class="card elevation-1" style="color: #000;">\n					<div class="card-content">\n						<div class="list no-hairlines" style="margin-top: 2px;">\n							<ul>\n								<li class="item-content item-input item-input-outline item-input-with-value">\n									<div class="item-media" style="align-self: flex-start;">\n										<button class="col button button-raised button-fill button-round" @click="clickedNotesTime"><i class="icon f7-icons" style="color: white;">calendar_today</i></button>\n									</div>\n									<div class="item-inner">\n										<div class="item-title item-floating-label">Notes</div>\n										<div class="item-input-wrap">\n											<textarea id="notes" name="notes" class="notes resizable" style="min-height: 80px; max-height: 400px;">';
              r += c(ctx_3.invoiceObject.notes, ctx_3);
              r += '</textarea>\n										</div>\n									</div>\n								</li>\n							</ul>\n						</div>\n					</div>\n				</div>\n				';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<!--\n				<div class="block no-margin';
              r += Template7Helpers.unless.call(ctx_3, ctx_3.editing, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' mobile-only';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '">\n					<div class="block-header">Scheduling: <span style="color: #000;">';
              r += c(ctx_3.invoiceObject.schedulingSummaryHTML, ctx_3);
              r += '</span></div>\n				</div>\n				-->\n				';
              r += Template7Helpers.js_if.call(ctx_3, "this.invoiceObject.notesHTML !== ''", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n				<div class="card elevation-1" style="color: #000;">\n					<div class="card-content" style="padding-bottom: 1em;"><div class="block">';
                  r += c(ctx_4.invoiceObject.notesHTML, ctx_4);
                  r += '</div></div>\n				</div>\n				';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n				';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				\n				';
          r += Template7Helpers.js_if.call(ctx_2, "(this.invoiceObject.employeenotes !== '') || (this.editing)", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<div class="block no-margin">\n					<div class="row">\n						<div class="col-75 xsmall-33">\n							<a href="#" class="link button';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.employeenotes, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' button-fill';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' button-raised" @click="openFollowUpNotes">Follow Up Notes</a>\n						</div>\n					</div>\n				</div>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				\n				<div class="block" style="margin: 10px 0px;">\n					<div class="accordion-item relatedFilesAccordionItem" style="position: relative;" @accordion:open="relatedFilesOpen" @accordion:opened="relatedFilesOpened">\n						<div class="row">\n							<div class="col">\n								';
          r += Template7Helpers.if.call(ctx_2, ctx_2.invoiceObject.relatedFiles, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceObject.relatedFiles.length, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<a class="button button-inline button-fill button-raised accordion-item-toggle">Related Files: ';
                  r += Template7Helpers.js_if.call(ctx_4, "this.invoiceObject.relatedFiles", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += Template7Helpers.js.call(ctx_5, "this.invoiceObject.relatedFiles.length", {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '0';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</a>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								<a class="button button-inline button-raised" @click="openUppyDashboard"><i class="icon f7-icons">paperclip</i><span>Add File(s)</span></a>\n							</div>\n						</div>\n						<div class="accordion-item-content">\n							';
          r += Template7Helpers.each.call(ctx_2, ctx_2.invoiceObject.relatedFiles, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n								<!--';
              r += Template7Helpers.if.call(ctx_3, ctx_3.isPicture, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<i class="icon f7-icons">photo</i>';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<i class="icon f7-icons">doc</i>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '-->\n								<a class="imageABlock lazy lazy-fade-in" data-index="';
              r += c(data_3 && data_3.index, ctx_3);
              r += '" data-background="https://taskmaster.bedroomsandmore.com/4DACTION/getImage/InvoiceAttachment/';
              r += c(root.invoiceNumber, ctx_3);
              r += '/';
              r += c(ctx_3.id, ctx_3);
              r += '/';
              r += Template7Helpers.js.call(ctx_3, "encodeURI(this.filename.replace('.pdf','.jpg'))", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '?updated=';
              r += Template7Helpers.js.call(ctx_3, "encodeURI(this.uploadedDateTime)", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '&sessionid=';
              r += c(root.$app.data.mdWebSocket.sessionId, ctx_3);
              r += '" style="background-color: #ccc;"></a>\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						</div>\n					</div>\n				</div>\n\n				<!--\n				<div class="list media-list accordion-list">\n					<ul>\n						<li class="accordion-item">\n							<a class="button button-fill button-raised" style="display: inline-block; position: absolute; right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right)); top: 0.15em; z-index: 1;" @click="openUppyDashboard">Add File(s)</a>\n							<a href="#" class="item-link item-content">\n								<div class="item-inner">\n									<div class="item-title-row">\n										<div class="item-title">Related Files: ';
          r += Template7Helpers.js_if.call(ctx_2, "this.invoiceObject.relatedFiles", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.js.call(ctx_3, "this.invoiceObject.relatedFiles.length", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '0';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\n									</div>\n								</div>\n							</a>\n							<div class="accordion-item-content">\n								<div class="block">\n								';
          r += Template7Helpers.each.call(ctx_2, ctx_2.invoiceObject.relatedFiles, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n									';
              r += Template7Helpers.if.call(ctx_3, ctx_3.isPicture, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<i class="icon f7-icons">photo</i>';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<i class="icon f7-icons">doc</i>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n									';
              r += c(ctx_3.filename, ctx_3);
              r += '\n								';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								</div>\n							</div>\n						</li>\n					</ul>\n				</div>\n				-->\n\n				<div class="block" style="margin: 0px; margin-bottom: 10px;">\n					<div class="row">\n						<div class="col-50 medium-33">\n							<a href="#" class="link button button-small button-raised" @click="openAdminOptions"><i class="icon f7-icons" style="font-size: 20px;">star_fill</i><span>&nbsp;&nbsp;Admin</span></a>\n						</div>\n						<div class="col-50 medium-33">\n							<a href="/taskmaster/invoices/';
          r += c(ctx_2.invoiceNumber, ctx_2);
          r += '/history/" class="link button button-small button-raised"><i class="icon f7-icons" style="font-size: 20px;">timer</i><span>&nbsp;&nbsp;History</span></a>\n						</div>\n					</div>\n				</div>\n				';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n				<div class="block no-margin">\n					<div class="block-header">Written by: <span style="color: var(--bnm-invoice-right-text-color);">';
      r += c(ctx_1.invoiceObject._output_writtenBy, ctx_1);
      r += '</span></div>\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.invoiceObject.referredbyname, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="block-header">Referred by: <span style="color: var(--bnm-invoice-right-text-color);">';
          r += c(ctx_2.invoiceObject.referredbyname, ctx_2);
          r += '</span></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<div class="block-header">Payments: <span style="text-decoration: underline; color: var(--bnm-invoice-right-text-color);">';
      r += c(ctx_1.invoiceObject.paymentSummaryHTML, ctx_1);
      r += '</span></div>\n				</div>\n			</form>\n			<!-- TODO: Linked Invoices -->\n			\n		</div>\n		<div class="popover invoicePopoverMenu">\n			<div class="popover-angle"></div>\n			<div class="popover-inner">\n				<div class="list">\n					<ul>\n						<li><a href="#" class="list-button item-link popover-close" @click="searchInvoices">Search Invoices</a></li>\n					</ul>\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `

	.testingOverlay {
		position: absolute;
		z-index: 10000;
		left: -5%;
		bottom: 50%;
		font-weight: bold;
		color: #f00;
		opacity: 0.1;
		text-align: center;
		font-size: 8vh;
		transform: rotate(45deg);
		pointer-events: none;
		width: 100%;
	}

	/* .page-content.page-content-loading */
	.title-large-text.page-content-loading {
		opacity: 0.5;

	}

	.invoicePageContent .button {
		background-color: #fff;
	}

	.invoicePageContent .button:not(.button-fill) {
		background-color: #fff !important;
	}

	.aurora.device-desktop .invoicePageContent .button:not(.active-state):not(.no-hover):hover {
		box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
    	box-shadow: var(--f7-elevation-1) !important;
	}

	.invoicePageContent .button.button-fill {
		background-color: var(--f7-theme-color);
	}

	.taskMasterInvoicePage .navbar-bg, .taskMasterInvoicePage .toolbar {
		background-color: var(--bnm-invoice-right-darker-color);
	}

	.taskMasterInvoicePage .navbar, .taskMasterInvoicePage .navbar a, .taskMasterInvoicePage .toolbar, .taskMasterInvoicePage .toolbar a {
		color: #fff;
	}

	.customerWrapperDiv {
		float: left;
		background: #fff;
		position: relative;
		padding: 0.5em;
		padding-right: 2.25em; /* For the icon */
		border-radius: var(--f7-card-border-radius);
	}

	.page-content-editing .customerWrapperDiv {
		cursor: pointer;
		-webkit-user-select: none; /* Safari 3.1+ */
		-moz-user-select: none; /* Firefox 2+ */
		-ms-user-select: none; /* IE 10+ */
		user-select: none; /* Standard syntax */
	}

	.customerWrapperDiv i {
		position: absolute;
		opacity: 0.5;
	}

	/* TODO: this does not work with ios theme... FIX */
	.customerWrapperDiv i.editIcon {
		right: -0em;
		top: -0.25em;
	}

	.customerWrapperDiv i.addCustomerIcon {
		right: 0.5em;
		top: 0.25em;
	}

	@media (min-width: 768px) {
		.contactInfoBlock {
			bsackground: #eee;/* var(--f7-theme-color); */
		}
	}

	.contactInfoBlock {
		padding-top: 16px;
		margin-top: 0px;
		padding-left: calc(var(--f7-card-margin-horizontal) + 0px);
    	padding-left: calc(var(--f7-card-margin-horizontal) + var(--f7-safe-area-left));
		padding-right: calc(var(--f7-card-margin-horizontal) + 0px);
    	padding-right: calc(var(--f7-card-margin-horizontal) + var(--f7-safe-area-left));
	}

	.contactInfoBlock label {
		display: block;
		text-align: center;
		font-weight: bold;

	}

	.contactInfoBlock input {
		width: 100%;
		background: #fff;
		border-bottom: 1px solid #ccc;
		/* border-style: inset; */

	}

	.salesLineCardHeaderColumnOTD, .salesLineCardHeaderColumnLinkButtons, .salesLineCardHeaderColumnEmployeeName {
		flex: 1;
	}

	.salesLineCardHeaderColumnOTD, .salesLineCardHeaderColumnLinkButtons {
		white-space: nowrap;
	}

	.salesLineCardHeaderColumnOTD .icon-checkbox {
		margin-right: 0.3em;
	}

	.aurora .salesLineCardHeaderColumnOTD .icon-checkbox {
		margin-top: 0.3em;
	}

	.salesLineCardHeaderColumnEmployeeName {
		text-align: right;
		white-space: normal;
		word-wrap: break-word;
	}



	.schedulingButtonsWrapperDiv span.lessPriority, .lessPriority {
		display: none;
	}

	@media (min-width: 675px) {
		.schedulingButtonsWrapperDiv span.lessPriority, .lessPriority {
			display: unset;
		}
	}

	span.altLabel {
		display: none;
	}

	@media (min-width: 512px) {
		.salesLineCardHeaderColumnEmployeeName {
			flex: 2;
		}

		span.altLabel {
			display: unset;
		}

		br.hasAltNumber {
			display: inline;
			content: '';
		}

		br.hasAltNumber:after {
			content: ',_';
			color: #fff;
			display: inline-block;
		}
	}

	@media (min-width: 1024px) {
		.device-desktop .openLeftPanelLinkTop, .device-desktop .invoiceElectronSearchButton, .device-desktop .invoiceElectronArrows {
			display: none !important;
		}

		.device-desktop .openLeftPanelLinkBottom {
			visibility: hidden !important;
		}
		
		/*
		.device-desktop .contactInfoBlock {
			min-height: 155px;
		}
		*/

	}

	/* Moved from the min-width section so all desktop invoices have a min height... */
	.device-desktop .contactInfoBlock {
		min-height: 85px;
	}

	.contactInfoBlock .customerWrapperDiv {
		min-height: 3em;
	}

	/*
	.device-desktop .contactInfoBlock .customerWrapperDiv {
		min-height: 4em;
	}
	*/

	.device-desktop .salesLinesCard .card-content {
		/* min-height: 25vh; */
		overflow: auto;
	}

	.popover.customerProfileLinksPopover {
		width: 260px;
	}

	.popover.customerProfileLinksPopover .popoverInvoiceItemContent {
		padding-left: 4px;
	}

	.popover.customerProfileLinksPopover .media-list .item-link .popoverInvoiceItemContent .item-inner, li.media-item .item-link .popoverInvoiceItemContent .item-inner {
		padding-right: 4px;
	}

	.popover.customerProfileLinksPopover .list .item-cell {
		margin-left: 0px;
	}

	.popover.customerProfileLinksPopover .list .item-cell.popoverInvoiceColumn1  {
		text-align: left;
		width: 30%;
		opacity: 0.5;
	}

	.popover.customerProfileLinksPopover .list .item-cell.popoverInvoiceColumn2  {
		text-align: left;
		width: 38%;
	}

	.popover.customerProfileLinksPopover .list .item-cell.popoverInvoiceColumn3  {
		text-align: right;
		width: 32%;
	}

	.popover.customerProfileLinksPopover .list .item-cell.popoverInvoiceColumn3 i {
		opacity: 0.2;
	}

	.popover.customerProfileLinksPopover .list .item-cell.popoverInvoiceColumn3 i.contains {
		opacity: 0.8;
	}

	.popover.customerProfileLinksPopover .list .item-cell.popoverInvoiceColumn3 i.containsReturn {
		color: #f00;
	}

	.popover.customerProfileLinksPopover .list .item-cell.popoverInvoiceColumn3 i.containsReturn.containsNew {
		/* BOTH!  TODO: a split black/red icon for this */
		/* probably need to use a :after to duplicate the icon with a black color and overlay it halfway on the other */
		position: relative;
	}

	.popover.customerProfileLinksPopover .list .item-cell.popoverInvoiceColumn3 i.tvicon.containsReturn.containsNew:after {
		content: 'tv_fill';
		position: absolute;
		left: 0px;
		color: #000;
		-webkit-clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
  		clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
	}

	.linkedInvoicesButtons a {
		display: inline-block;
		padding-left: 1em;
		padding-right: 1em;
	}

	.aurora .linkedInvoicesButtons a i {
		line-height: 1.3;
	}

	.button.button-inline {
		display: inline-block !important;

	}
	
	a.imageABlock {
		float: left;
		width: 100px;
		height: 100px;
		background-size: cover !important;
		border: 1px solid #000;
    	margin: 0.5em;
	}

	textarea.notes {
		font-size: 14px;
	}
	
	.invoicePageContent .autocomplete-dropdown-inner {
		padding-left: 0px !important;
	}

	.list.saleslinesList.isEditing .item-cell {
		text-align: center;
		margin-left: 0px;
		padding-left: 2px;
		border-left: 1px solid #ccc;
	}

	.list.saleslinesList.isEditing .item-cell:first-child {
		padding-left: 0px;
		border-left: 0px;
	}

	.list.saleslinesList .item-inner:after {
		content: unset;
	}
	
	.list.saleslinesList .saleslineNote {
		font-style: italic !important;
		color: #666 !important;
	}
	
	.list.saleslinesList .saleslineNegative {
		color: #f00 !important;
	}
	
	.list.saleslinesList .saleslineDiscount {
		color: #f00 !important;
	}
	
	.list.saleslinesList .saleslineDiscount .saleslineQuantityAndStatusCell {
		visibility: hidden;
	}
	
	.list.saleslinesList .saleslineQuantityAndStatusCell {
		flex: 5;
		max-width: 80px;
		text-align: center;
	}

	.list.saleslinesList .saleslineQuantityAndStatusCell .saleslineQuantityCell {
		display: inline-block;
		min-width: 2em;
	}
	
	.list.saleslinesList .saleslineDescriptionCell {
		flex: 15;
		word-break: normal;
		margin-left: 0px;
	}
	
	.list.saleslinesList .saleslineDescriptionCell.saleslineDescriptionAlone {
		padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right));

	}
	
	.list.saleslinesList .saleslineDollarCell {
		flex: 2;
		max-width: 80px;
		flex-basis: auto;
		text-align: right;
		margin-left: 0px;
	}
	
	.list.saleslinesList .saleslineDiscount .saleslineDescriptionCell {
		font-style: italic !important;
	}
	
	.saleslinesCardContent {
		background: #eee;
	}

	.list.saleslinesList li.isOdd {
		background: #fff;
	}

	.list.saleslinesList li.isEven {
		background: #eee;
	}
	
	.page-content-editing .list.saleslinesList li.isEven {
		background: #d6ddff;
	}

	.list.saleslinesList li.inCustomerPossessionFade .saleslineDescriptionCell, .list.saleslinesList li.inCustomerPossessionFade .saleslineDollarCell, .list.saleslinesList li.inCustomerPossessionFade .saleslineStockStatusCell {
		opacity: 0.5;
	}
	
	.list.saleslinesList .item-inner {
		padding-top: 0px;
		padding-bottom: 0px;
	}
	
	.list.saleslinesList li.item-content {
		padding-left: 0px;
	}
	
	.list.saleslinesList li.item-content, .list.saleslinesList .item-inner, .list.saleslinesList .item-row {
		min-height: 24px;
	}
	
	.list.saleslinesList .item-inner.hideAfter:after {
		display: none;
	}
	
	.list.saleslinesList .item-cell {
		font-size: 0.95em;
	}
	
	table.invoiceTotalsTable td {
		text-align: right;
	}
	
	@media (min-width: 480px) {
		table.invoiceTotalsTable td {
			min-width: 80px;
		}
	}

	table.invoiceTotalsTable td.leftSideTitle {
		padding-right: 15px;
	}
	
	table.invoiceTotalsTable td.highlightCellLeft, table.invoiceTotalsTable td.highlightCellRight {
		font-weight: bold;
		font-size: 1em;
		/* border: 4px dashed #fcc; */
	}
	
	table.invoiceTotalsTable td.highlightCellLeft {
		text-decoration: underline;
		/*
		border-right: none;
		padding-left: 1em;
		*/
	}
	/*
	table.invoiceTotalsTable td.highlightCellRight {
		border-left: none;
	}
	*/
	
	.size-18 { font-size: 18px; }
	
`,
  styleScoped: false
};
    