
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {
      currentUpList: [],
      currentUpListDisplay: [],
      showAllUsersToggle: null,
      showAllUsers: false,
      optionsPopup: null,
      busyTimeInterval: null,
      tooltips: []
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'employees') {
        self.refreshList();
      } else if (data.type == 'data' && data.data == 'locations') {
        self.refreshList();
      } else if (data.type == 'data' && data.data == 'upListSettings') {
        //self.optionsPopup.close(false);
        //self.optionsPopup.destroy();
        //self.optionsPopup= null;
        //self.upListOptionsOpenPopup(false);
        self.updateUpListOptions();
      }
    },
    checkedShowAllUsers: function () {
      var self = this;
      self.showAllUsers = self.showAllUsersToggle.checked;
      self.refreshList();
    },
    upListOptions: function (e) {
      var self = this;
      self.upListOptionsOpenPopup();
    },
    updateUpListOptions: function () {
      var self = this;
    },
    upListOptionsOpenPopup: function (animate) {
      var self = this;
      animate = typeof animate == 'boolean' ? animate : true;

      if (self.$('.popup.popupUpListOptions.modal-in').length == 0) {
        if (self.optionsPopup == null) {
          var mainActive = self.$app.data.taskMasterData.upListSettings.active;
          var mainColor = 'teal';
          var popupContent = '<div class="popup popupUpListOptions elevation-10 popup-swipe-to-close">' + '<div class="block" style="position: relative;">' + '<div class="block-title block-title-medium">Up List Extra Settings</div>' + '<form class="list" id="my-form">' + '<ul>' + '<li class="item-content">' + '<div class="item-inner item-cell">' + '<div class="item-row">' + '<div class="item-cell text-align-left">' + '<div class="chip clickChip locationsActiveChip' + (mainActive ? ' color-' + mainColor : '') + '" data-color="' + mainColor + '">' + '<input type="hidden" name="active" value="' + (mainActive ? 'true' : '') + '">' + '<div class="chip-media ' + (mainActive ? 'bg-color-' + mainColor : 'bg-color-gray') + '">' + (mainActive ? '<i class="icon f7-icons if-not-md">check_round</i><i class="icon material-icons md-only">check_box</i>' : '<i class="icon f7-icons if-not-md">circle</i><i class="icon material-icons md-only">check_box_outline_blank</i>') + '</div>' + '<div class="chip-label">Activate Location Assignments</div>' + '</div>' + '</div>' + '</div>' + '</div>' + '</li>';

          for (var i = 0; i < self.$app.data.taskMasterData.upListSettings.locations.length; i++) {
            var currentLocation = self.$app.data.taskMasterData.upListSettings.locations[i];
            popupContent += '<li class="item-content listItemsToHide"' + (!mainActive ? ' style="display: none;"' : '') + '>' + '<div class="item-inner">' + '<div class="item-title">' + currentLocation.name + '</div>' + '<div class="item-after">' + '<label class="toggle">' + '<input class="locationActiveCheckbox" data-locationId="' + currentLocation.id + '" type="checkbox" name="isActive' + currentLocation.id + '" value="yes"' + (currentLocation.active ? ' checked' : '') + '><i class="toggle-icon"></i>' + '</label>' + '</div>' + '</div>' + '</li>' + '<li class="listItemsToHide' + currentLocation.id + '"' + (!mainActive || !currentLocation.active ? ' style="display: none;"' : '') + '>' + '<ul>' + // SORTABLE... TODO...
            '<li class="item-content">' + '<div class="item-inner item-cell">' + '<div class="item-row">' + '<div class="item-cell item-inner item-input item-input-outline item-input-with-value">' + '<div class="item-title item-floating-label">Min. People</div>' + '<div class="item-input-wrap">' + '<input type="text" placeholder="Minimum People" class="input-with-value minPeopleInput" data-locationId="' + currentLocation.id + '" value="' + currentLocation.miniumumNumberOfEmployees + '">' + '</div>' + '</div>' + '<div class="item-cell item-inner item-input item-input-outline item-input-with-value">' + '<div class="item-title item-floating-label">Start Assignment Position</div>' + '<div class="item-input-wrap">' + '<input type="text" placeholder="What position to start assignments" class="input-with-value startingPositionInput" data-locationId="' + currentLocation.id + '" value="' + currentLocation.startAssignmentPosition + '">' + '</div>' + '</div>' + '</div>' + '</div>' + '</li>' + '<li class="item-divider listItemsToHide' + currentLocation.id + '"' + (!mainActive || !currentLocation.active ? ' style="display: none;"' : '') + '>Assignments</li>' + '<li class="item-content">' + '<div class="item-title">Floor 4</div>' + '</li>' + '<li class="item-content">' + '<div class="item-title">Floor 3</div>' + '</li>' + '</ul>' + '</li>';
          }

          popupContent += '</ul>' + '</form>' + '<p><a href="#" class="link popup-close" data-popup=".popupUpListOptions" style="position: absolute; top: -1.5em; left: 1em;">Cancel</a></p>' + '</div>' + '</div>';
          self.optionsPopup = self.$app.popup.create({
            swipeToClose: true,
            content: popupContent,
            on: {
              open: function (popup) {
                popup.$el.on('change', 'input.locationActiveCheckbox', function (e) {
                  var inputElement = self.$(e.target).closest('input');
                  var locationId = parseInt(inputElement.attr('data-locationId'));

                  if (inputElement[0].checked) {
                    popup.$el.find('.listItemsToHide' + locationId).show();
                  } else {
                    popup.$el.find('.listItemsToHide' + locationId).hide();
                  }

                  var indexOfThisLocation = self.$app.data.taskMasterData.upListSettings.locations.map(function (elem) {
                    return elem.id;
                  }).indexOf(locationId);

                  if (indexOfThisLocation > -1) {
                    self.$app.data.taskMasterData.upListSettings.locations[indexOfThisLocation].active = inputElement[0].checked;
                  }
                });
                popup.$el.on('keyup change', '.minPeopleInput', function (e) {
                  var newNumber = 0;
                  var thisInput = self.$(e.target).closest('input.minPeopleInput');
                  var thisLocationId = parseInt(thisInput.attr('data-locationId'));

                  if (thisInput.val() !== '') {
                    newNumber = parseInt(thisInput.val());

                    if (isNaN(newNumber)) {
                      newNumber = 0;
                    }
                  }

                  self.$root.mdWebSocket.sendOverWebSocket({
                    event: 'updateUpListSettings',
                    locationId: thisLocationId,
                    miniumumNumberOfEmployees: newNumber
                  });
                });
                popup.$el.on('keyup change', '.startingPositionInput', function (e) {
                  var newNumber = 0;
                  var thisInput = self.$(e.target).closest('input.startingPositionInput');
                  var thisLocationId = parseInt(thisInput.attr('data-locationId'));

                  if (thisInput.val() !== '') {
                    newNumber = parseInt(thisInput.val());

                    if (isNaN(newNumber)) {
                      newNumber = 0;
                    }
                  }

                  self.$root.mdWebSocket.sendOverWebSocket({
                    event: 'updateUpListSettings',
                    locationId: thisLocationId,
                    startAssignmentPosition: newNumber
                  });
                });
                popup.$el.on('click', '.chip.locationsActiveChip', function (e) {
                  var thisElement = self.$(e.target).closest('.chip');
                  var inputElement = thisElement.find('input'); // console.log('here',e,thisElement);

                  var color = thisElement.attr('data-color');
                  var inputNewValue = '';

                  if (inputElement.val() == '') {
                    thisElement.addClass('color-' + color);
                    thisElement.find('.chip-media').removeClass('bg-color-gray');
                    thisElement.find('.chip-media').addClass('bg-color-' + color);
                    thisElement.find('.icon.if-not-md').text('check_round');
                    thisElement.find('.icon.md-only').text('check_box');
                    inputNewValue = 'true';
                  } else {
                    thisElement.removeClass('color-' + color);
                    thisElement.find('.chip-media').removeClass('bg-color-' + color);
                    thisElement.find('.chip-media').addClass('bg-color-gray');
                    thisElement.find('.icon.if-not-md').text('circle');
                    thisElement.find('.icon.md-only').text('check_box_outline_blank');
                  }

                  inputElement.val(inputNewValue); // console.log('BEFORE',self.$app.data.taskMasterData.upListSettings);

                  self.$app.data.taskMasterData.upListSettings.active = inputNewValue == 'true' ? true : false; // console.log('AFTER',self.$app.data.taskMasterData.upListSettings);

                  if (inputNewValue == 'true') {
                    popup.$el.find('.listItemsToHide').show();

                    for (var i = 0; i < self.$app.data.taskMasterData.upListSettings.locations.length; i++) {
                      if (self.$app.data.taskMasterData.upListSettings.locations[i].active) {
                        popup.$el.find('.listItemsToHide' + self.$app.data.taskMasterData.upListSettings.locations[i].id).show();
                      }
                    }
                  } else {
                    popup.$el.find('.listItemsToHide').hide();

                    for (var i = 0; i < self.$app.data.taskMasterData.upListSettings.locations.length; i++) {
                      popup.$el.find('.listItemsToHide' + self.$app.data.taskMasterData.upListSettings.locations[i].id).hide();
                    }
                  }

                  self.$root.mdWebSocket.sendOverWebSocket({
                    event: 'updateUpListSettings',
                    active: inputNewValue == 'true' ? true : false
                  });
                });
              },
              opened: function (popup) {// console.log('Popup opened');
              },
              closed: function (popup) {
                var tempTimeout = setTimeout(function () {
                  popup.destroy();
                  self.optionsPopup = null;
                }, 250);
              }
            }
          });
        }

        self.optionsPopup.open(animate);
      }
    },
    buildCurrentListDisplayHTML: function () {
      var self = this;
      var upListHTML = '<div class="list sortable media-list no-chevron upListSortableList" style="margin-top: 0px;">' + '<ul>';
      var currentLocationIndex = -1;

      for (var i = 0; i < self.currentUpList.length; i++) {
        var employeeIsBusy = self.currentUpList[i].statusId > 1; // If NOT available...

        if (!employeeIsBusy) {
          employeeIsBusy = self.currentUpList[i].order >= 1000;
        }

        if (self.currentUpList[i].isLocation) {
          upListHTML += '<li class="item-divider" data-locationId="' + self.currentUpList[i].id + '">' + self.currentUpList[i].name + '</li>';
        } else {
          if (self.currentUpList[i].isBlank) {
            upListHTML += '<li>&nbsp;<br>&nbsp;<br>&nbsp;</li>';
          } else {
            // self.currentUpList[i].assignment= 'Creative Room';
            upListHTML += '<li>' + '<a href="#" class="item-link item-content" data-index="' + i + '" draggable="false">' + '<div class="item-media">' + '<div class="image-crop-circle" style="height: 40px; width: 40px; border: 2px solid #fff; background: #fff url(\'https://taskmaster.bedroomsandmore.com/4DACTION/getImage/Employees/' + self.currentUpList[i].employeeId + '.' + self.currentUpList[i].imageType + '?updated=' + self.currentUpList[i].imageUpdatedDateTime + '\') top center; background-size: cover;"></div>' + '</div>' + '<div class="item-inner">';

            if (employeeIsBusy) {
              upListHTML += '<div class="item-title-row" style="font-style: italic; color: #999;">' + self.currentUpList[i].employeeName + '</div>';

              if (self.currentUpList[i].statusName && self.currentUpList[i].statusName !== '') {
                upListHTML += '<div class="item-text" style="font-style: italic; color: #999;">' + self.currentUpList[i].statusName + '<span class="busyTime" data-time="' + self.currentUpList[i].statusDateTimeBusy + '"></span></div>';
              }
            } else {
              upListHTML += '<div class="item-title-row">#' + self.currentUpList[i].order + ' ' + self.currentUpList[i].employeeName + '</div>';
            }

            if (self.currentUpList[i].assignment && self.currentUpList[i].assignment !== '') {
              upListHTML += '<div class="item-text" style="word-wrap: normal; white-space: normal;"><span style="font-style: normal; color: #999;">' + self.currentUpList[i].assignment + '</span></div>';
            }

            upListHTML += '</div>' + '</a>' + '<div class="sortable-handler"></div>' + '</li>';
          }
        }
      }

      upListHTML += '</ul>' + '</div>';
      return upListHTML;
    },
    refreshList: function (onSuccessRefresh) {
      var self = this;
      onSuccessRefresh = typeof onSuccessRefresh == 'function' ? onSuccessRefresh : function () {};

      if (self.$app.data.taskMasterData && self.$app.data.taskMasterData.locations) {
        var allLocations = self.$app.data.taskMasterData.locations.filter(function (currentElement) {
          return currentElement.hasUpList;
        });

        if (self.$app.data.taskMasterData.employees) {
          var leftoverUpListEmployees = self.$app.data.taskMasterData.employees.filter(function (currentElement) {
            var showInUpList = false;

            if (currentElement.isClockedIn) {
              showInUpList = self.showAllUsers;

              if (!showInUpList) {
                showInUpList = currentElement.upList;

                if (!showInUpList) {
                  showInUpList = typeof currentElement.upListObject !== 'undefined';

                  if (!showInUpList) {
                    showInUpList = currentElement.department == 'Sales';
                  }
                }
              }
            }

            return showInUpList;
          }); // console.log('BEFORE_LIST',Object.assign({},self.currentUpList));

          self.currentUpList = [];

          for (var i = 0; i < allLocations.length; i++) {
            self.currentUpList.push({
              isLocation: true,
              name: allLocations[i].name,
              id: allLocations[i].id
            });
            var currentLocationEmployees = leftoverUpListEmployees.filter(function (currentElement) {
              var validEmployee = false;

              if (typeof currentElement.upListObject !== 'undefined') {
                if (currentElement.upListObject.locationId) {
                  validEmployee = currentElement.upListObject.locationId == allLocations[i].id;
                }
              }

              return validEmployee;
            });

            if (currentLocationEmployees.length > 0) {
              // Sort them...
              currentLocationEmployees.sort(function (a, b) {
                if (a.upListObject.order < b.upListObject.order) return -1;
                if (a.upListObject.order > b.upListObject.order) return 1;
                return 0;
              });

              for (var j = 0; j < currentLocationEmployees.length; j++) {
                self.currentUpList.push({
                  isLocation: false,
                  order: currentLocationEmployees[j].upListObject.order,
                  employeeId: currentLocationEmployees[j].id,
                  employeeName: currentLocationEmployees[j].name,
                  imageType: currentLocationEmployees[j].imageType,
                  imageUpdatedDateTime: currentLocationEmployees[j].imageUpdatedDateTime,
                  statusId: currentLocationEmployees[j].upListObject.statusId,
                  statusName: currentLocationEmployees[j].upListObject._statusName,
                  statusDateTimeBusy: currentLocationEmployees[j].upListObject.dateTimeBusy,
                  locationId: allLocations[i].id,
                  locationName: allLocations[i].name,
                  assignment: currentLocationEmployees[j].upListObject.assignment,
                  onUpList: true
                });
                var tempSpotInArray = leftoverUpListEmployees.map(function (currentElement) {
                  return currentElement.id;
                }).indexOf(currentLocationEmployees[j].id);

                if (tempSpotInArray > -1) {
                  leftoverUpListEmployees.splice(tempSpotInArray, 1);
                }
              }
            } else {// No employees for this location...
            }
          }

          self.currentUpList.push({
            isLocation: true,
            name: 'Off List',
            id: -1
          });

          if (leftoverUpListEmployees.length > 0) {
            for (var j = 0; j < leftoverUpListEmployees.length; j++) {
              self.currentUpList.push({
                isLocation: false,
                order: 1000 + j,
                employeeId: leftoverUpListEmployees[j].id,
                employeeName: leftoverUpListEmployees[j].name,
                imageType: leftoverUpListEmployees[j].imageType,
                imageUpdatedDateTime: leftoverUpListEmployees[j].imageUpdatedDateTime,
                statusId: 1,
                statusName: '',
                locationId: -1,
                locationName: 'Off List',
                onUpList: false
              });
            }
          } else {
            self.currentUpList.push({
              isBlank: true
            });
          } // console.log('AFTER_LIST',Object.assign({},self.currentUpList));


          if (true) {
            for (var i = 0; i < self.tooltips.length; i++) {
              self.tooltips[i].destroy();
            }

            var upListHTML = self.buildCurrentListDisplayHTML();
            self.$el.find('.page-content').html(upListHTML);
            self.busyTimeIntervalStart(0);
            self.tooltips = [];
            self.$el.find('.image-crop-circle').each(function (index) {
              var thisElement = self.$(this);
              var aTag = thisElement.closest('a');
              var clickedIndex = parseInt(aTag.attr('data-index'));
              var currentEmployeeObject = self.currentUpList[clickedIndex];
              thisElement.addClass('imageNumber' + index);
              self.tooltips.push(self.$app.tooltip.create({
                targetEl: '.imageNumber' + index,
                text: '<div class="image-crop-circle" style="height: 75vmin; width: 75vmin; max-width: 300px; max-height: 300px; border: 2px solid #fff; background: #fff url(\'https://taskmaster.bedroomsandmore.com/4DACTION/getImage/Employees/' + currentEmployeeObject.employeeId + '.' + currentEmployeeObject.imageType + '?updated=' + currentEmployeeObject.imageUpdatedDateTime + '\') top center; background-size: cover;"></div><p style="text-align: center;">' + currentEmployeeObject.employeeName + '</p>'
              }));
            });
            var canSortItems = self.$app.data.user.upListObject && self.$app.data.user.upListObject.statusId;

            if (self.$app.data.user.isAdmin || canSortItems || self.$app.data.user.isClockedIn && self.$app.data.user.department == 'Sales') {
              self.$app.sortable.enable('.upListSortableList');
            } else {
              self.$el.find('.sortable-handler').remove();
              self.$el.find('.sortable').removeClass('sortable');
            }

            onSuccessRefresh();
          } else {
            /*
            	self.buildCurrentListDisplay();
            	// TO FIX SOME SORT OF REFRESH BUG...
            //var tempList= self.currentUpListDisplay;
            
            // TODO: this causes a very ugly white flash on the screen... find out how to get rid of this bug...
            
            //self.$setState({
            //	currentUpListDisplay: []
            //});
            	
            self.$setState({
            	currentUpList: self.currentUpList
            },function() {
            	
            	var canSortItems= ((self.$app.data.user.upListObject) && (self.$app.data.user.upListObject.statusId));
            	
            	if ((self.$app.data.user.isAdmin) || (canSortItems) || ((self.$app.data.user.isClockedIn) && (self.$app.data.user.department == 'Sales'))) {
            		self.$app.sortable.enable('.upListSortableList');
            		
            	} else {
            		self.$el.find('.sortable-handler').remove();
            		self.$el.find('.sortable').removeClass('sortable');
            		
            	}
            	
            	// console.log('BEFORE_LIST',self.currentUpList);
            	
            	onSuccessRefresh();
            });
            */
          }
        }
      }
    },
    busyTimeIntervalStart: function (timeToWait) {
      var self = this;
      self.busyTimeIntervalClear();
      self.busyTimeInterval = setTimeout(function () {
        var rightNowDate = new Date();
        self.$el.find('.busyTime').each(function (index) {
          var thisElement = self.$(this);
          var startingTime = thisElement.attr('data-time');
          var thisStartDate = new Date(startingTime);
          var differenceObject = self.dateDifference(thisStartDate, rightNowDate);
          var textToShow = '';
          textToShow = differenceObject.seconds;

          if (differenceObject.minutes > 0) {
            textToShow = differenceObject.minutes + ':' + (differenceObject.seconds < 10 ? '0' : '') + textToShow;
          }

          if (differenceObject.hours > 0) {
            textToShow = differenceObject.hours + ':' + (differenceObject.minutes < 10 ? '0' : '') + textToShow;
          }

          if (textToShow == differenceObject.seconds) {
            textToShow += 's';
          }

          thisElement.html(' (' + textToShow + ')');
        }); // .html(moment(rightNowDate).format("h:mm:ss A"));

        self.busyTimeIntervalStart(1000);
      }, timeToWait);
    },
    busyTimeIntervalClear: function () {
      var self = this;

      if (self.busyTimeInterval !== null) {
        clearTimeout(self.busyTimeInterval);
        self.busyTimeInterval = null;
      }
    },
    dateDifference: function (startDate, endDate) {
      var self = this;
      var diff = endDate.getTime() - startDate.getTime();
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * (1000 * 60 * 60);
      var minutes = Math.floor(diff / 1000 / 60);
      diff -= minutes * (1000 * 60);
      var seconds = Math.floor(diff / 1000); // If using time pickers with 24 hours format, add the below line get exact hours

      if (hours < 0) {
        hours = hours + 24;
      }

      return {
        hours: hours,
        minutes: minutes,
        seconds: seconds
      }; //return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes + ":" + (seconds<= 9 ? "0" : "") + seconds;
    }
  },
  on: {
    popupClose: function (e, popup) {
      var self = this;
      self.busyTimeIntervalClear();

      if (self.optionsPopup !== null) {
        self.optionsPopup.close(false);
        self.optionsPopup.destroy();
      }
    },
    popupOpen: function (e, popup) {
      var self = this;

      if (self.$app.data.user.isAdmin) {
        self.$el.find('.showAllUsers').show();
        self.showAllUsersToggle = self.$app.toggle.create({
          el: '.showAllUsers',
          on: {
            change: self.checkedShowAllUsers
          }
        });
      } else {
        self.$el.find('.showAllUsers').hide();
      }

      self.refreshList();
      self.busyTimeIntervalStart(0);
      self.$el.on(app.f7.touchEvents.start, '.image-crop-circle', function (e) {
        e.stopPropagation(); // THIS IS FOR THE TOOLTIP Image to prevent it from clicking the link below...
      });
      self.$el.on('click', '.upListSortableList a', function (e) {
        var aTag = self.$(e.target).closest('a');
        var clickedIndex = parseInt(aTag.attr('data-index'));
        var currentEmployeeObject = self.currentUpList[clickedIndex];
        var thisIsMe = currentEmployeeObject.employeeId == self.$app.data.user.id;
        var canSortItems = self.$app.data.user.upListObject && self.$app.data.user.upListObject.statusId; //console.log('currentEmployeeObject',currentEmployeeObject);
        //console.log('thisIsMe',thisIsMe);

        if (self.$app.data.user.isAdmin || thisIsMe || canSortItems || self.$app.data.user.isClockedIn && self.$app.data.user.department == 'Sales') {
          if (currentEmployeeObject.onUpList) {
            var buttons = [];

            if (currentEmployeeObject.onUpList) {
              if (currentEmployeeObject.statusId == 1) {
                // Available
                // Skip Available...
                for (var i = 1; i < self.$app.data.taskMasterData.employeeStatuses.length; i++) {
                  buttons.push({
                    text: self.$app.data.taskMasterData.employeeStatuses[i].name,
                    status: self.$app.data.taskMasterData.employeeStatuses[i],
                    upListObject: currentEmployeeObject,
                    onClick: function () {
                      var thisButton = this;
                      var objectToSend = {
                        employeeId: this.upListObject.employeeId,
                        employeeName: this.upListObject.employeeName,
                        imBusyReason: thisButton.status.name,
                        statusId: thisButton.status.id,
                        isBusy: true,
                        event: 'upListStatusChange'
                      };
                      self.$root.mdWebSocket.sendOverWebSocket(objectToSend);
                    }
                  });
                }
              } else {
                buttons.push({
                  text: 'Make Available',
                  upListObject: currentEmployeeObject,
                  onClick: function () {
                    var objectToSend = {
                      employeeId: this.upListObject.employeeId,
                      employeeName: this.upListObject.employeeName,
                      imBusyReason: '',
                      statusId: 1,
                      isBusy: false,
                      event: 'upListStatusChange'
                    };
                    self.$root.mdWebSocket.sendOverWebSocket(objectToSend);
                  }
                });
              }

              buttons.push({
                text: 'Move off list',
                upListObject: currentEmployeeObject,
                onClick: function () {
                  var thisButton = this;
                  var objectToSend = {
                    employeeId: this.upListObject.employeeId,
                    employeeName: this.upListObject.employeeName,
                    statusId: 1,
                    locationId: -1,
                    locationName: 'Off List',
                    order: 0.05,
                    action: 'upListMoveEmployee'
                  };
                  self.$root.mdWebSocket.sendOverWebSocket(objectToSend);
                }
              });
            } else {// Off list!
            }

            buttons.push({
              text: 'Cancel',
              color: 'red'
            });
            var employeeText = 'Off List';

            if (currentEmployeeObject.onUpList) {
              if (currentEmployeeObject.statusId == 1) {
                employeeText = '<div class="chip color-green"><div class="chip-label" style="font-weight: bold;">' + currentEmployeeObject.statusName + '</div></div>';
              } else {
                employeeText = '<div class="chip color-red"><div class="chip-label" style="font-weight: bold;">' + currentEmployeeObject.statusName + '</div></div>';
              }
            }

            employeeText += '<br>' + currentEmployeeObject.locationName; //Change Status to:';

            var locationsDialog = self.$app.dialog.create({
              title: '<div class="image-crop-circle dialogImage" style="height: 40px; width: 40px; border: 2px solid #fff; position: absolute; background: #fff url(\'' + self.$root.getImageURL(currentEmployeeObject.employeeId, currentEmployeeObject.imageType, currentEmployeeObject.imageUpdatedDateTime) + '\') top center; background-size: cover;"></div> ' + currentEmployeeObject.employeeName,
              text: employeeText,
              buttons: buttons,
              cssClass: 'upListEmployeeEditAlert',
              verticalButtons: true,
              closeByBackdropClick: true,
              destroyOnClose: true
            }).open();
          }
        }
      });
      self.$el.on('sortable:sort', '.upListSortableList li', function (e) {
        // console.log('here!');
        //console.log('e.detail',e.detail);
        //console.log('self.currentUpList',self.currentUpList);
        var fromIndex = e.detail.from;
        var toIndex = e.detail.to;

        if (fromIndex !== toIndex) {
          // Index... Numbers
          // console.log('BEFORE MOVE',Object.assign({},self.currentUpList));
          // console.log('BEFORE MOVE',Object.assign({},self.currentUpListDisplay));
          // Move it in the data....
          self.$root.array_move(self.currentUpList, fromIndex, toIndex); // self.buildCurrentListDisplay();
          // console.log('AFTER MOVE',Object.assign({},self.currentUpList));
          // console.log('AFTER MOVE',Object.assign({},self.currentUpListDisplay));

          var closestLocationId = -1; // -1 is "Off List"

          var closestLocationName = 'Off List'; // -1 is "Off List"

          if (toIndex > 0) {
            // Move BACKWARD
            for (var i = toIndex; i >= 0; i--) {
              if (self.currentUpList[i].isLocation) {
                closestLocationId = self.currentUpList[i].id;
                closestLocationName = self.currentUpList[i].name;
                break; // Get out of the for loop...
              }
            }
          } else {
            // Move UPWARD
            for (var i = 0; i < self.currentUpList.length; i++) {
              if (self.currentUpList[i].isLocation) {
                closestLocationId = self.currentUpList[i].id;
                closestLocationName = self.currentUpList[i].name;
                break; // Get out of the for loop...
              }
            }
          }

          var tempStatusId = self.currentUpList[toIndex].statusId;
          var dataToSend = {
            employeeId: self.currentUpList[toIndex].employeeId,
            employeeName: self.currentUpList[toIndex].employeeName,
            statusId: tempStatusId,
            locationId: closestLocationId,
            locationName: closestLocationName,
            order: 0.05
          };

          if (toIndex > 1) {
            // Check against neighbors!
            if (self.currentUpList[toIndex - 1].isLocation || closestLocationId == -1) {// Nothing, we are good!
            } else {
              dataToSend.order = self.currentUpList[toIndex - 1].order + 0.05;
            }
          } // console.log('dataToSend');
          // console.log(dataToSend);


          dataToSend.action = 'upListMoveEmployee';
          self.$root.mdWebSocket.sendOverWebSocket(dataToSend);
        }
      });
    }
  },
  id: 'e40b2b2a37',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="popup popup-tablet-fullscreen upListPopup">\n		<div class="view">\n			<div class="page" data-name="upListPopup">\n				<div class="navbar">\n					<div class="navbar-bg"></div>\n					<div class="navbar-inner sliding">\n						<div class="left">\n							<a href="#" class="link icon-only popup-close md-only" data-popup=".upListPopup"><i class="icon icon-back"></i></a>\n							<a href="#" class="link popup-close if-not-md" data-popup=".upListPopup">Close</a>\n						</div>\n						<div class="title">Sales Up List</div>\n						<div class="right">\n							';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							<a href="/bugreport/" class="link icon-only">\n								<i class="icon material-icons">bug_report</i>\n							</a>\n							';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n							';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.user.isAdmin, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							<!--\n							<a href="/settings/1/" class="link icon-only">\n								<i class="icon f7-icons if-not-md">slider_horizontal_3</i>\n								<i class="icon material-icons md-only">tune</i>\n							</a>\n							-->\n							';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n							<label class="toggle color-green showAllUsers">\n								<input type="checkbox">\n								<span class="toggle-icon"></span>\n							</label>\n						</div>\n					</div>\n				</div>\n				<div class="page-content">\n					<div class="block block-strong" style="text-align: center;">\n						<p>Loading...</p>\n					</div>\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.upListPopup .page-content {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.ios .upListPopup .sortable-handler	{
		width: 70px;
	}
	
	.md .upListPopup .sortable-handler	{
		width: 100px;
	}
	
	.upListEmployeeEditAlert .dialog-title {
		position: relative;
	}
	
	.upListEmployeeEditAlert .dialog-text {
		margin-top: 0px !important;
	}
	
	.upListEmployeeEditAlert .dialog-inner {
		padding-bottom: 10px !important;
	}
	
	.ios .upListPopup .item-divider, .md .upListPopup .item-divider, .ios .upListPopup .list-group-title, .md .upListPopup .list-group-title {
		background: #666;
		color: #fff;
		
	}
	
	.md .toggle.showAllUsers {
		padding-right: 3em;
		
	}
	
	.md .image-crop-circle.dialogImage, .aurora .image-crop-circle.dialogImage {
		right: 0px;
	}
	
	.popup.popupUpListOptions {
		width: auto;
		height: auto;
		top: 3em;
		left: 2em;
		right: 2em;
		bottom: 2em;
	}
	
	.upListSortableList .item-divider {
		position: relative;
		position: -webkit-sticky;
		position: sticky;
		top: 0px;
		z-index: 20;
	}

	/*
	a.item-link:hover {
		zoom: 2;
	}

	a.item-link:hover .image-crop-circle {
		zoom: 2;
	}
	*/

	/*
	a.item-link {
		-webkit-transform-origin: 0 0;
		-moz-transform-origin: 0 0;
		-ms-transform-origin: 0 0;
		-o-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	a.item-link:hover {
		-webkit-transform: scaleX(2) scaleY(2);
		-moz-transform: scaleX(2) scaleY(2);
		-ms-transform: scaleX(2) scaleY(2);
		-o-transform: scaleX(2) scaleY(2);
		transform: scaleX(2) scaleY(2);
		
	}
	*/

`,
  styleScoped: false
};
    