import $$ from 'dom7';

//import parsePhoneNumberFromString from 'libphonenumber-js';
//import SignaturePad from 'signature_pad/dist/signature_pad.min.js';
/*import accounting from '../lib/accounting_js/js/accounting.min.js';
import moment from 'moment';
import Chart from 'chart.js';*/
// import Instascan from 'instascan'; // Need this?

//import jsQR from "jsqr";

// To use Html5QrcodeScanner (more info below)
//import {Html5QrcodeScanner} from "html5-qrcode";
import {Html5Qrcode} from "html5-qrcode";
//window.Html5QrcodeScanner = Html5QrcodeScanner;
//window.Html5Qrcode = Html5Qrcode;


var methods= {
	updateApp: function() {
		var $app= this;
		
		var updateDialogTitle= 'Update';
		var updateDialogText= 'Force Update?';
		
		if (($app.data.taskMasterData)
			&& ($app.data.taskMasterData.deviceRecord)
				&& ($app.data.taskMasterData.deviceRecord.updateObject)
					&& ($app.data.taskMasterData.deviceRecord.updateObject.updateAvailable)) {
			
			var currentVersion= $app.data.taskMasterData.deviceRecord.updateObject.currentVersion;
			var newVersion= $app.data.taskMasterData.deviceRecord.updateObject.newVersion;
			
			updateDialogTitle= 'New Update';
			updateDialogText= 'Your Version: '+currentVersion+'<br><strong>New Version: '+newVersion+'</strong><br>---<br>'+$app.data.taskMasterData.deviceRecord.updateObject.updateText;
			
		}

		$app.dialog.create({
			title: updateDialogTitle,
			text: updateDialogText,
			buttons: [
              {
                text: 'Later',
                color: 'red'
              },
              {
                text: 'Update Now',
                bold: true,
                onClick: function() {
	                if (!$app.device.cordova) { // TODO: this will go away with the electron app...
		                // Just refresh the browser...
		                window.location.reload(true);
		                
	                } else if (window.device.platform == 'Android') {
		                // Run update plugin...
		                
		                // app.preloader.show(); // TODO: show toast for a bit...
		                
		                var updateUrl = 'https://taskmaster.bedroomsandmore.com/4DACTION/web_updateApp/android/version/?sessionid='+$app.data.mdWebSocket.sessionId;
						window.AppUpdate.checkAppUpdate(function() {
							// Success...
							console.log('Success!');
							
							app.preloader.hide();
							
						}, function() {
							// On error...	
							console.log('Error!');
							
							// TODO: Then fallback to the download...
							
						}, updateUrl,
						{
							skipPromptDialog: true
						});
		                
	                } else {
	                	window.open('https://taskmaster.bedroomsandmore.com/4DACTION/web_updateApp/ios/?sessionid='+$app.data.mdWebSocket.sessionId,'_system');
	                	
	                }
                }
              }
           ],
           destroyOnClose: true
		}).open();
		
		// $app.dialog.alert('test','test');
		
	},
	checkForAppUpdate: function() {
		var $app= this;

		console.log('Got here!',$app.data.user.name);

		if (($app.data.user) && ($app.data.user.name)) {
			// TODO: if electron then do something else, codePush is for mobile devices only! f7.device.electron
			if (window.codePush) {
			  
			  // rootComponent.user.username
  
			  // TODO: this should be a setting from 4D on the user account...
			  var isDevelopment= ($app.data.user.name.toLowerCase() == 'mattdavis');
  
			  var deploymentKey= '6Bi1F4TaVUqlJMlhkUM-dMDkOd-bH94WUW_5X4'; // iOS
			  if (isDevelopment) {
				deploymentKey= '_hKBcRDpn1TLpcIZPWoumOk36coa5_vf48YmC'; // iOS-devel
			  }
			  if ($app.device.android) {
				deploymentKey= 'MGaFgl74aEkNWt0J4fWoPIFdN1NqlzxUT348G4'; // Android
				if (isDevelopment) {
					deploymentKey= 'JqbHvdD5z_QDlj4MDFW19eYWt9x9ymLmCCz8w'; // Android-Devel
				}
			  }
  
			  console.log('checkForAppUpdate',$app.data.user.name,deploymentKey);
			  
			  //if (e.type == 'resume') {
				window.codePush.sync(null, {
				  updateDialog: true,
				  installMode: InstallMode.IMMEDIATE,
				  deploymentKey: deploymentKey
				});
			  /*
			  } else {
				window.codePush.sync(null, {
				  updateDialog: true,
				  installMode: InstallMode.IMMEDIATE,
				  deploymentKey: deploymentKey
				});
			  }
			  */
			}
		  }
	},
	updateProfileImage: function(imageURL) {
		var $app= this;
		
		if (imageURL == '') {
			imageURL= 'static/images/user.png';
		}
		
		$app.$('.profile-image').css('background-image','url("'+imageURL+'")');
	},
	getImageURL: function(employeeId,imageType,imageUpdatedDateTime) {
		var $app= this;
		imageType= ((typeof(imageType) == 'string')?imageType:'jpg');
		imageUpdatedDateTime= ((typeof(imageUpdatedDateTime) == 'string')?imageUpdatedDateTime:'');
		if ((imageUpdatedDateTime == '') || (imageUpdatedDateTime == 'TODO')) {
			var indexOfEmployee= $app.data.taskMasterData.employees.map(function(elem){ return elem.id; }).indexOf(employeeId);
			if (indexOfEmployee > -1) {
				imageUpdatedDateTime= $app.data.taskMasterData.employees[indexOfEmployee].imageUpdatedDateTime;
			}
		}
		return 'https://taskmaster.bedroomsandmore.com/4DACTION/getImage/Employees/'+employeeId+'.'+imageType+'?updated='+imageUpdatedDateTime;
	},
	checkIfOnline: function() {
		var $app= this;

		if (typeof($app.data.appIsInBackground) == 'undefined') {
			$app.data.appIsInBackground= false;
		}

		console.log('***checkIfOnline STARTED');
		console.log('****** $app.data.appIsInBackground = '+$app.data.appIsInBackground);
		console.log('****** window.navigator.onLine = '+window.navigator.onLine);
		console.log('****** window.app.f7.data.mdWebSocket.wsIsOnline = '+window.app.f7.data.mdWebSocket.wsIsOnline);
		console.log('***checkIfOnline STARTED');

		if (!$app.data.appIsInBackground) { // If it is NOT in the background, try to reconnect :)
			
			if (!window.navigator.onLine) {
				
				$app.methods.closeWebSocket(function() {
					console.log('just closed websocket!');
				});

			} else {
				// We are ONLINE, check websocket...
				if (!$app.data.mdWebSocket.wsIsOnline) {
					$app.methods.closeWebSocket(function() {
						$app.methods.connectWebSocket();
					});
				}

				// We are ONLINE, check PHONE...
				if (!window.app.f7.data.phoneIsActive) {
					$app.methods.connectPhone();
				}
			}
			
			// console.log('checkIfOnline',($app.data.mdWebSocket.wsIsOnline && (window.navigator.onLine)));

			if ($app.data.disconnectedToastTimeout !== null) {
				clearTimeout($app.data.disconnectedToastTimeout);
				$app.data.disconnectedToastTimeout= null;
			}

			if ((!$app.data.mdWebSocket.wsIsOnline) || (!window.navigator.onLine)) {
				
				window.app.f7.data.mdWebSocket.wsIsOnline= false; // If the network goes down!
				window.app.f7.data.phoneIsActive= false;

				if (window.app.f7.data.mdWebSocket.sessionId !== '') {
					if (!window.app.f7.data.appIsInBackground) {
						// window.app.f7.data.disconnectedToast.open();
						window.app.f7.data.disconnectedToastTimeout= setTimeout(function() {
							// $app.data.disconnectedToast.open(); // TODO: removed 8/6, the checkOnline does not work for newer Android...
						}, 0); // Right away...
					}
				}

			} else {
				window.app.f7.data.disconnectedToast.close();
				var tempTimeout= setTimeout(function() {
					window.app.f7.$('.toast.disconnectedToast').remove();
				},250);

			}
		}

		console.log('***checkIfOnline END');
		console.log('****** $app.data.appIsInBackground = '+$app.data.appIsInBackground);
		console.log('****** window.navigator.onLine = '+window.navigator.onLine);
		console.log('****** window.app.f7.data.mdWebSocket.wsIsOnline = '+window.app.f7.data.mdWebSocket.wsIsOnline);
		console.log('***checkIfOnline END');
	},
	disconnectPhone: function() {
		var $app= this;

		window.app.f7.data.phoneIsActive= false;
		$app.data.PHONE.disconnect();
		
	},
	connectPhone: function() {
		var $app= this;

		$app.data.settingUpPermissions= true;
		
		if (($app.data.PHONE) && ($app.data.PHONE.setup) && (!window.app.f7.data.phoneIsActive)) {
			// We have the CAPABILITY... Check Credentials next...

			$app.data.PHONE.connectingInProgress= true;

			if (($app.data.localStorageData.phoneLogin)
					&& ($app.data.localStorageData.phoneLogin.username !== '')
					&& ($app.data.localStorageData.phoneLogin.password !== '')
					&& ($app.data.localStorageData.phoneLogin.extension !== '')) {

				if (($app.data.localStorageData.phoneLogin.externalApp)
						&& ($app.data.localStorageData.phoneLogin.externalApp !== '')) {
							window.app.f7.data.phoneIsActive= true; // Just say true, an external app is controlling it...

				} else {
					
					/*
					$app.data.PHONE.setup(function() {
						// console.log('HERE! #1');
						
						$app.data.settingUpPermissions= false;
						$app.data.PHONE.register($app.data.localStorageData.phoneLogin.username,$app.data.localStorageData.phoneLogin.password,$app.data.localStorageData.phoneLogin.extension,function() {
							// console.log('HERE! #2');
							window.app.f7.data.phoneIsActive= true;
						});
					},function() {
						// Error couldn't setup phone...
					});
					*/
				}
			}
		}
	},
	closeWebSocket: function(afterCloseFunction) {
		var $app= this;

		afterCloseFunction= ((typeof(afterCloseFunction) == 'function')?afterCloseFunction:function() {});

		if (($app.data.mdWebSocket) && ($app.data.mdWebSocket.ws)) {
			$app.data.mdWebSocket.closeWebSocket(afterCloseFunction);
			// afterCloseFunction();
		}
	},
	connectWebSocket: function() {
		var $app= this;
		
		if ((window.navigator.onLine) && (!$app.data.mdWebSocket.wsIsOnline)) {
			$app.methods.closeWebSocket();
			$app.data.mdWebSocket.connectWebSocket({
				onMessage: function(e) {
					$app.methods.onPushReceived(JSON.parse(e.data));
					
				},
				onOpen: function(e) {
					
					console.log('websocket ON OPEN');

					var tempTimeout= setTimeout(function() {
						if (!window.device) {
							window.device= {};

						}
						
						if (!window.BuildInfo) {
							window.BuildInfo= {};
							
						}
						
						$app.data.mdWebSocket.sendOverWebSocket({
							event: 'deviceInfo',
							device: window.device,
							deviceF7: $app.device,
							appVersionF7: $app.version,
							buildInfo: window.BuildInfo,
							deviceType: ($app.data.md.mobile() ? ($app.data.md.tablet() ? 'Tablet' : 'Phone') : 'Computer'),
							deviceNavigator: $app.methods.object_getJSONOnly(window.navigator)
						});
						
						var tempTimeout2= setTimeout(function() {
							$app.methods.sendStatus();
						}, 500);
					}, 250); // 1.5 seconds
					
					$app.methods.checkIfOnline();

				},
				onClose: function(e) {
					
					$app.methods.checkIfOnline();
					
					
				},
				onTimeout: function() {
					// TODO: is this right?
					// $app.methods.logout(false); // false means don't clear login data...
					
				}
			});
		}
	},
	afterLoggedIn: function() {
		var $app= this;
		
		if ($app.device.electron) { // $app.device.desktop
			/*
			$app.methods.setAppTheme({
				main: $app.data.user.settings.theme.invoice_rightSideColor,
				secondary: $app.data.user.settings.theme.invoice_leftSideColor,
				font: '#000000'
			});
			*/
			$app.methods.setAppTheme({
				main: $app.data.user.settings.theme.mobileColorMain,
				secondary: $app.data.user.settings.theme.mobileColorSecondary,
				font: $app.data.user.settings.theme.mobileColorFont
			});

			if (($app.data.isMainWindow) && (!window.justDoAppOnDesktop)) {
				if ($$(window).width() >= 768) {
					$$('.mainScreenLeftPanel').addClass('panel-in-breakpoint');
					$$('.view-main').css('margin-left','400px');
				} else {
					$app.data.mainLeftSidePanel.open();

				}
			}

		} else {
			$app.methods.setAppTheme({
				main: $app.data.user.settings.theme.mobileColorMain,
				secondary: $app.data.user.settings.theme.mobileColorSecondary,
				font: $app.data.user.settings.theme.mobileColorFont
			});
		}
		
		if (false) { // TODO: use this instead...?
			/*
			$app.methods.onPushReceived({
				data: 'loggedIn',
				type: 'loggedIn'
				
			});
			*/
		} else {
			$app.methods.emitEventToApp('loggedIn',{user: $app.data.user});
			
		}
		
		if ($app.data.isMainWindow) {
			$app.methods.connectWebSocket();

			$app.methods.checkForAppUpdate();
		} else {
			// Need to hook onto some IPC events instead...
			
		}

		if (!$app.data.isDesktopSafari) { // TODO... This should be a better if statement...
			if ($app.data.user.settings.hasPhone) {
				$app.data.localStorageData.phoneLogin= $app.data.user.settings.phoneLogin;
				
				$app.methods.setItemLocalStorage('localStorageData',$app.data.localStorageData);
				
				/*
				if ($app.device.cordova) {
					
					NativeStorage.setItem('localStorageData', JSON.stringify($app.data.localStorageData), function() {
						// Success, do nothing...
					}, function() {
						// Error, do nothing...
					});
					
				} else {
					window.localStorage.setItem('localStorageData', JSON.stringify($app.data.localStorageData));
					
				}
				*/
				
				$app.methods.connectPhone();
			}
		}
		
		$app.loginScreen.close('#my-login-screen');

		$app.methods.getItemLocalStorage('printTagPrintList',function(returnedValue) {
			$app.data.printTagPrintList= returnedValue;
			
		},function() {
			// If failed...
			$app.data.printTagPrintList= [];
			
		});

		// alert(window.app.f7.views.main.router.url);
		
		if (!$app.device.desktop) { // Should prevent electron too...

			//console.log('$app.device',$app.device);
			//console.log('$app.data.md',$app.data.md);

			// Make sure we register push notifications 
			if ($app.data.PUSH) {
				
				// TODO: I should know this... and store it in app.data...
				var allowVOIPPush= !$app.data.isIOS9;
				
				var iosOptions= {
					sound: 'true',
					alert: 'true',
					clearBadge: 'true',
					categories: {
						incomingCall: {
							yes: {
								callback: 'answerCall',
								title: 'Answer',
								foreground: true,
								destructive: false
							},
							no: {
								callback: 'rejectCall',
								title: 'Reject',
								foreground: true,
								destructive: false
							}
						}
					}
				};
				
				if (allowVOIPPush) {
					iosOptions= {
						voip: allowVOIPPush
					};
				}
				
				// console.log('iosOptions',iosOptions);
				
				$app.data.settingUpPermissions= true;
				
			    $app.data.PUSH= PushNotification.init({
					android: {},
				    browser: {
				        pushServiceURL: 'http://push.api.phonegap.com/v1/push'
				    },
					ios: iosOptions,
					windows: {}
				});
				
				$app.data.PUSH.on('registration', function(data) {
			        var previousRegistrationID= $app.data.localStorageData.pushNotificationId;
					
					console.log('PREVIOUS PUSH REGISTRATION ID: '+previousRegistrationID);
					console.log(data);
					
					if (true) {//(data.registrationId !== previousRegistrationID) {
						
						// LET TM KNOW...
						var tempTimeout= setTimeout(function() {
							
							$app.data.mdWebSocket.sendOverWebSocket({
								action: 'pushNotificationRegistered',
								registrationId: data.registrationId,
								registrationType: data.registrationType,
								useLocalNotifications: allowVOIPPush
							});
							
						}, 1000);
						
						$app.data.localStorageData.pushNotificationId= data.registrationId;
						$app.methods.setItemLocalStorage('localStorageData',$app.data.localStorageData);
						
						console.log('PUSH saved/updated, changed');
						
					} else {
						console.log('PUSH skipped, no change');
						
					}
					
					$app.data.settingUpPermissions= false;
					//afterRegistrationFunction(currentRegistrationID);
					
					//alert('Got here! Reg ID: '+currentRegistrationID);
					
			        // TODO: set to secure storage
					
			    });
				
			    $app.data.PUSH.on('error', function(e) {
					console.log("push error = " + e.message);
					// TODO: send the error to TM here...
					
				});
				
				$app.data.PUSH.on('notification', function(data) {
					// TODO: figure out whether the notification is a BACKGROUND notification or a regular one...
					
					// TODO: if app.data.isIOS9 then it's a regular notification they clicked on...
					
					console.log('notification event!!',data);
					
					if (data.additionalData.foreground) {
						
						
						data.type= 'pushNotification';
						$app.methods.onPushReceived(data);
						
						// data.additionalData.coldstart ???
						// data.additionalData.dismissed ???
						
					} else {
						// Sent while not awake....
						
						window.tempData= data;
						
						// TODO: if use local stuff...
						
						
						// if it's a call... then do the call thing!
						
						
						if (data.additionalData.pushType == 'alert') {
							
							console.log('GOT HERE!');
							
							cordova.plugins.notification.local.schedule({
							    title: data.additionalData.title,
							    text: data.additionalData.message,
							    priority: 2,
							    foreground: true
							});
						
						} else if (data.additionalData.pushType == 'call') {
							
							if (false) { // IF USE CALL KIT
								cordova.plugins.CordovaCall.setAppName('Bedrooms & More');
								cordova.plugins.CordovaCall.receiveCall(data.additionalData.invoice);
								
							} else {
								
								
								for (var i= 0; i< 5; i++) {
									
									var tempTimeout= setTimeout(function() {
										
										if (true) { // TODO: if notAlreadyAnswered...
											cordova.plugins.notification.local.schedule({
												id: 444,
											    title: 'Incoming Call',
											    text: data.additionalData.invoice,
											    priority: 2,
											    foreground: true,
											    vibrate: true,
											    actions: [
													{ id: 'answerCall', title: 'Answer' },
													{ id: 'rejectCall',  title: 'Reject' }
												]
											});
										}
										
									}, (3000*i));
									
								}
								
								//,trigger: { every: 'second', count: 15 }
							}
							
						} else {
							// data... TODO: do something?
							
						}
						
						// TODO: use callkit for calls and/or local notifications if necessary...

					}
					
					//if (window.device.platform == 'iOS') {
					if ($app.device.ios) {
						
						$app.data.PUSH.finish(function() {
							console.log('processing of push data is finished');
							
						},function() {
							console.log('something went wrong with push.finish for ID = '+data.additionalData.notId);
							
						},data.additionalData.notId);
						
					}
					
					
					
					
				});
			    
			    if (allowVOIPPush) {
				   // NEED TO USE LOCAL NOTIFICATION PLUGIN HERE...
				    
				    // TODO: how best to keep track of app permissions...
				    
				    cordova.plugins.notification.local.requestPermission(function (granted) {
					    console.log('NOTIFICATIONS granted: '+granted);
				    });
				    
			    }
		    }
		}
	},
	logout: function (clearLocalStorageToo) {
		clearLocalStorageToo= ((typeof(clearLocalStorageToo) == 'boolean')?clearLocalStorageToo:true);
		
		var $app= this;
		
		// TODO: let TM know this was a logout...
		$app.data.mdWebSocket.closeWebSocket(function() {
			// Do nothing... empty on close function...
		});
		
		$app.methods.resetLocalData(clearLocalStorageToo);
		
		// TODO: SHOULD I REFRESH THE CURRENT PAGE?
		$app.data.mainView.router.refreshPage();
		
		// $setState ?
		//$app.methods.updateProfileImage('');
		
		$app.methods.emitEventToApp('loggedOut');

		$app.data.lockScreen.open();
		
	},
	searchInvoices: function() {
		var $app= this;
		
		$app.dialog.alert('Search invoices!');
			
	},
	setItemLocalStorage: function(variableName, incomingVariable) {
		var $app= this;
		
		// Wrap it in an object that can be retrieved easily with the type...
		
		var objectToSave= {
			//name: variableName,
			value: incomingVariable,
			type: typeof(incomingVariable)
		};
		
		if ((!$app.device.desktop) && (window.NativeStorage)) {
			NativeStorage.setItem(variableName, JSON.stringify(objectToSave), function() {
				// Success, do nothing...
			}, function() {
				// Error, do nothing...
			});
			
		} else {
			window.localStorage.setItem(variableName, JSON.stringify(objectToSave));
			
		}
	},
	getItemLocalStorage: function(variableName,onSuccess,onError) {
		onSuccess= ((typeof(onSuccess) == 'function')?onSuccess:function() {});
		onError= ((typeof(onError) == 'function')?onError:function() {});
		
		var $app= this;
		
		if ((!$app.device.desktop) && (window.NativeStorage)) {
			NativeStorage.getItem(variableName,function(variableObject) {
				variableObject= JSON.parse(variableObject);
				
				onSuccess(variableObject.value);
				
			},function(error) {
				onError();
				
			});
			
		} else {
			var variableObject= window.localStorage.getItem(variableName);
			if (variableObject) {
				variableObject= JSON.parse(variableObject);
				
				onSuccess(variableObject.value);
				
			} else {
				onError();
				
			}
		}
		
	},
	removeItemLocalStorage: function(variableName,propertyName) {
		propertyName= ((typeof(propertyName) == 'string')?propertyName:'');
		var $app= this;
		
		if (propertyName == '') {
			
			// Basic remove...
			if ((!$app.device.desktop) && (window.NativeStorage)) {
				NativeStorage.remove(variableName, function() {
					// Success do nothing...	
				}, function() {
					// Error do nothing...
				});
			} else {
				window.localStorage.removeItem(variableName);
			}
			
		} else {
			// Remove only a part of the object!
			
			// TODO: get the item, remove the part, then resave...
			
		}
	},
	onElectronMessageReceived: function(eventName,event,eventData) {
		var $app= this;



	},
	onPushReceived: function(data) {
		var $app= this;
		
		if (!data.type) {
			data.type= '';
			
		}
		
		if (data.type == 'pushNotification') {
			
			// TODO: need to check foreground true or false to know if they clicked the push notification to open the app...
			
			if ((data.additionalData.pushType.toLowerCase() == 'alert') ||
					(data.additionalData.pushType.toLowerCase() == 'uplistchange')) {
				
				var notificationClickToClose= $app.notification.create({
					icon: '<i class="icon f7-icons">exclamationmark_triangle</i>',
					title: 'Alert',
					titleRightText: 'now',
					subtitle: data.title,
					text: data.body,
					closeOnClick: true,
					closeTimeout: 6000
				}).open();
				
			} else if ((data.additionalData.pushType.toLowerCase() == 'message') || (data.additionalData.pushType.toLowerCase() == 'sticker')) {
				// this is an employee message sent...
				// I need to add this into the conversation itself...
				
				
				if (($app.data.mainView.router.url.indexOf('/messages/') !== 0)
						|| ($app.data.mainView.router.url.indexOf('/messages/'+data.additionalData.conversationId+'/') !== 0)) {
						
						
					var onDifferentConversationPage= /\/messages\/\d+\/.+/.test($app.data.mainView.router.url);
	
					// TODO: if onDifferentConversationPage is true it doesn't run the "init" script...
					// when loading... it's broken...
					
					var onClickFunction= function() {};
					if (!onDifferentConversationPage) {
						onClickFunction= function() {
							var tempTimeout= setTimeout(function() {
								$app.data.mainView.router.navigate('/messages/'+data.additionalData.conversationId+'/'+encodeURIComponent(data.additionalData.conversationName)+'/',{
									reloadCurrent: onDifferentConversationPage,
									ignoreCache: onDifferentConversationPage
								});
							},0);
						};
					}
					
					var notificationClickToClose= $app.notification.create({
						icon: '<i class="icon f7-icons">chat_bubble</i>',
						title: 'New Message...',
						titleRightText: 'now',
						subtitle: data.title,
						text: data.body,
						closeOnClick: true,
						closeTimeout: 6000,
						on: {
							click: onClickFunction,
							close: function (notification) {
								// If closed without clicking it...
							}
						}
					}).open();
				}
				
				if (false) {
					$app.data.taskMasterData.conversations[indexOfConversation].newMessageStatusBool= true;
					
					// TODO: need to add the date/time...
					// TODO: fix "message" key for "sticker...."
					
					$app.data.taskMasterData.conversations[indexOfConversation].recentMessages.unshift({
						id: data.additionalData.messageId,
						message: data.additionalData.actualMessage,
						messageType: '',
						sender: data.additionalData.fromEmployeeId
					});
					if (indexOfConversation > 0) {
						$app.methods.array_move($app.data.taskMasterData.conversations,indexOfConversation,0);
						
					}
					
					// TODO: then "emit" a getConversations push....
					
					console.log($app.data.taskMasterData.conversations);
				} else {
					$app.data.mdWebSocket.sendOverWebSocket({
						action: 'getConversations'
					});
				}
				
			}
		} else if (data.type == 'action') {
			
			if (data.action == 'logout') {
				
				$app.methods.logout(true); // TODO: clear local storage?
				
				if (data.alert) {
					$app.dialog.alert(data.alert,'Alert');
				}
				
			} else if (data.action == 'alert') {
								
				if (data.alert) {
					var alertTitle= 'Alert';

					if (data.alertTitle) {
						alertTitle= data.alertTitle;
					}

					$app.dialog.alert(data.alert,alertTitle);
				}
				
			}
			
		} else if (data.type == 'data') {
			
			// TODO: I want to clean up all this data... and utilize sending partial data as needed...
			
			// console.log(data.data+data.bucketId,data[data.data]);

			if (data.data == 'bucketScans') {
				
				$app.data.taskMasterData[data.data+data.bucketId]= data[data.data];
			}

			if (data.data == 'schedules') {
				if (!$app.data.taskMasterData.schedules) { // If it doesn't exist...
					$app.data.taskMasterData.schedules= {};
					
				}
				
				for (var i= 0; i< data.schedulesReferences.length; i++) {
					var currentRef= data.schedulesReferences[i];
					$app.data.taskMasterData.schedules[currentRef]= data.schedules[currentRef];
				}
				
				$app.data.taskMasterData.schedulesCalendars= data.schedulesCalendars;
				
			} else if (data.data == 'calendars') {
				$app.data.taskMasterData[data.data]= data[data.data];

			} else if (data.data == 'calendarEvents') {

				// Facilitate updated data...
				if (!$app.data.taskMasterData.calendarEvents) {
					$app.data.taskMasterData.calendarEvents= data.calendarEvents;
					
				} else {
					if (data.calendarEvents) {
						var incomingEventIds= data.calendarEvents.map(function(currElem) { return currElem.id; });

						$app.data.taskMasterData.calendarEvents= $app.data.taskMasterData.calendarEvents.filter(function(currElem) {
							return (incomingEventIds.indexOf(currElem.id) == -1);
						});
						
						$app.data.taskMasterData.calendarEvents= $app.data.taskMasterData.calendarEvents.concat(data.calendarEvents);
					}
				}

			} else if (data.data == 'devices') {
				
				// This one is unique...
				if (!$app.data.taskMasterData.devices) { // If it doesn't exist...
					$app.data.taskMasterData.devices= {};
					
				}
				
				for (var i= 0; i< data.types.length; i++) {
					
					if (($app.data.taskMasterData.devices[data.types[i]]) && (data.dataAction) && (data.dataAction == 'update')) {
						var updateProperty= data.dataUpdateProperty;
						
						for (var j= 0; j< data[data.types[i]].length; j++) {
							var foundIndex= $app.data.taskMasterData.devices[data.types[i]].map(function(e) { return e[updateProperty]; }).indexOf(data[data.types[i]][j][updateProperty]);
														
							if (foundIndex > 0) {
								$app.data.taskMasterData.devices[data.types[i]][foundIndex]= data[data.types[i]][j];
								
							}
						}
						
					} else {
						$app.data.taskMasterData.devices[data.types[i]]= data[data.types[i]];
					
					}
				}
			
			} else if (data.data == 'payOnData') {
				
				if (data.startOfData == true) {
					$app.data.taskMasterData[data.data]= data[data.data];
				
				} else if ((data.subData == 'invoices') || (data.subData == 'poas')) {
					if (data.justStarted == true) {
						$app.data.taskMasterData.payOnData.groupCommissionData.store[data.subData]= data[data.subData];
						
					} else {
						$app.data.taskMasterData.payOnData.groupCommissionData.store[data.subData]= $app.data.taskMasterData.payOnData.groupCommissionData.store[data.subData].concat(data[data.subData]);
						
					}
					
				} else if (data.subData == 'employees') {
					
				}
				
			} else {
				
				
				
				var doneReceivingData= true;
				
				
				// CHECKING IF SOMEONE MOVED YOU ON THE UPLIST...
				var myUpListBefore= $app.data.user.upList;
				var myUpListObjectBefore= (($app.data.user.upListObject)?$app.data.user.upListObject:undefined);
				
				if (($app.data.taskMasterData[data.data]) && (data.dataAction) && (data.dataAction == 'update')) {
					var updateProperty= data.dataUpdateProperty;
					
					for (var i= 0; i< data[data.data].length; i++) {
						var foundIndex= $app.data.taskMasterData[data.data].map(function(e) { return e[updateProperty]; }).indexOf(data[data.data][i][updateProperty]);
						
						// console.log('CRAZY',data[data.data][i][updateProperty]);
						
						if (foundIndex > 0) {
							$app.data.taskMasterData[data.data][foundIndex]= data[data.data][i];
							
						}
					}
					
				} else {
					
					if (data.data !== 'reportData') {
						if (typeof(data.justStarted) !== 'undefined') {
							doneReceivingData= data.done;
							
							console.log(data.data,data);
							
							if (data.justStarted == true) {
								$app.data.taskMasterData[data.data]= data[data.data];
								
							} else {
								$app.data.taskMasterData[data.data]= $app.data.taskMasterData[data.data].concat(data[data.data]);
								
							}
							
						} else {
							$app.data.taskMasterData[data.data]= data[data.data];
							
						}
					}
					
				}
				
				// console.log('DATA',$app.data.taskMasterData);
				// doneReceivingData...				
				// TODO: if employees, update myself...

				if (data.data == 'employees') {
					
					var myEmployeeRecord= $app.data.taskMasterData.employees.filter(function(e) { return (e.id == $app.data.user.id); });
					
					if (myEmployeeRecord.length == 1) {
						$app.data.user.isClockedIn= myEmployeeRecord[0].isClockedIn;
						$app.data.user.upList= myEmployeeRecord[0].upList;
						$app.data.user.upListObject= myEmployeeRecord[0].upListObject;
						$app.data.user.department= myEmployeeRecord[0].department;
						$app.data.user.departmentId= myEmployeeRecord[0].departmentId;
						
						if ($app.data.localStorageData.saveLogin) {
							$app.data.localStorageData.user= $app.data.user;
							$app.methods.setItemLocalStorage('localStorageData',$app.data.localStorageData);
						}
						
					}
					
					// console.log('BEFORE',myUpListBefore,myUpListObjectBefore);
					// console.log('AFTER',$app.data.user.upList,$app.data.user.upListObject);
					
					var uplistChangedForYou= false;
					if (typeof(myUpListObjectBefore) !== typeof($app.data.user.upListObject)) {
						uplistChangedForYou= true;
					} else if (typeof($app.data.user.upListObject) == 'object') {
						if ($app.data.user.upListObject.statusId == 1) { // ONLY IF AVAILABLE...
							if ((myUpListObjectBefore.locationId !== $app.data.user.upListObject.locationId)
									|| (myUpListObjectBefore.order !== $app.data.user.upListObject.order)) {
								uplistChangedForYou= true;
							}
						}
					}
					
					if ((uplistChangedForYou) && ($app.data.mainView.router.url !== '/uplist/')) { //  && ($app.data.mainView.router.url !== '/')
						
						var textForToast= 'UP LIST<br>You have been moved OFF the list.';
						if (typeof($app.data.user.upListObject) == 'object') {
							textForToast= 'UP LIST<br>You are now #'+$app.data.user.upListObject.order+' in the<br>'+$app.data.user.upListObject._locationName+'.';
						}
						
						$app.data.upListToast= $app.toast.create({
							icon: $app.theme === 'ios' ? '<i class="icon f7-icons">arrow_up_circle_fill</i>' : '<i class="icon material-icons">arrow_upward</i>',
							text: textForToast,
							position: 'center',
							closeTimeout: 4000,
							cssClass: 'changedOrderUpListToast',
							destroyOnClose: true
						}).open();
						
					}
					//console.log('uplistChangedForYou',uplistChangedForYou,);
					
				} else if (data.data == 'timecards') {
					$app.data.user.timecards= data['timecards'];
					$app.data.user.timecardsChangeRequests= data['timecardsChangeRequests'];
					
				} else if (data.data == 'conversations') {
					$app.data.taskMasterData.unreadMessages= data.unreadMessages;
					
				} else if (data.data == 'activeCalls') {
					
					var activeCallsUL= $$('div.activeCallList');
					
					var outsideActiveCalls= $app.data.taskMasterData.activeCalls.filter(function(elem) {
						return elem.isOutside;
					});
					
					// TODO: incoming call notification, close it if necessary...
					
					
					//console.log('outsideActiveCalls',outsideActiveCalls);
					//console.log('$app.data.taskMasterData.activeCalls',$app.data.taskMasterData.activeCalls);
					
					// console.log('weHaveOutsideCalls!',(outsideActiveCalls.length > 0));
					
					$app.methods.stopActiveCallsTimer();
					
					if (outsideActiveCalls.length > 0) {
						
						$$('.fab-activeCalls').show();
						
						var activeCallsHTML= '<ul>';
						
						for (var i= 0; i< outsideActiveCalls.length; i++) {
							var iconHTML= '<i class="icon f7-icons if-not-md">arrow_down_circle_fill</i><i class="icon material-icons md-only">arrow_downward</i>';
							if (!outsideActiveCalls[i].isIncomingCall) {
								iconHTML= '<i class="icon f7-icons if-not-md">arrow_up</i><i class="icon material-icons md-only">arrow_upward</i>';
								
							}
							// <i class="icon f7-icons if-not-md">arrow_up</i><i class="icon material-icons md-only">add</i>
							
							var peerInfo= '&nbsp;';
							if (outsideActiveCalls[i].peerCallerId) {
								peerInfo= outsideActiveCalls[i].peerCallerId+' - '+$app.methods.formatPhoneNumber(outsideActiveCalls[i].peerExtension);
							}
							
							var secondsText= '';
							
							activeCallsHTML+= '<li>'+
													'<a href="#" class="item-link item-content fab-close">'+
														'<div class="item-media">'+iconHTML+'</div>'+
														'<div class="item-inner">'+
															'<div class="item-title-row">'+
																'<div class="item-title">'+outsideActiveCalls[i].CallerIDName+'</div>'+
																'<div class="item-after secondsText" data-epoch="'+outsideActiveCalls[i].Uniqueid+'">0:00</div>'+
															'</div>'+
															'<div class="item-subtitle">'+$app.methods.formatPhoneNumber(outsideActiveCalls[i].CallerIDNum)+'</div>'+
															'<div class="item-text">'+peerInfo+'</div>'+
														'</div>'+
													'</a>'+
												'</li>';
							
							// activeCallsHTML+= '<li><a href="#" class="fab-close">'+outsideActiveCalls[i].CallerIDName+' '+outsideActiveCalls[i].CallerIDNum+'</a></li>';
						}
						
						activeCallsHTML+= '</ul>';
						activeCallsUL.html(activeCallsHTML);
						
						$app.methods.startActiveCallsTimer();
						
					} else {
						
						// TODO: this should be in a timeout thing...
						activeCallsUL.html('<p class="block">No Active Calls currently.</p>');
						
						// TODO: a delay...?
						// IF open...
						
						if ($$('.demo-fab-fullscreen-sheet.active-calls-sheet').hasClass('fab-morph-target-visible')) {
							// Do nothing...
							
						} else {
							$$('.fab-activeCalls').hide();
							
						}
						
						$app.methods.stopActiveCallsTimer();
						
					}
				}
			}
			
		} else if (data.type == 'call') {
			// INCOMING CALL TO ME, ALERT MESSAGE...
			
			if (false) { // IF USE CALL KIT...
				
			} else {
				
				var notificationParams= {
					icon: '<i class="icon f7-icons">phone_fill</i>',
					title: "Incoming Call",
					titleRightText: 'now',
					subtitle: data.subject,
					text: data.message,
					cssClass: 'callNotification',
					closeOnClick: true,
					closeTimeout: 18000,
					on: {
						click: function() {
							
							var tempTimeout= setTimeout(function() {
								
								$app.data.mdWebSocket.sendOverWebSocket({
									action: 'redirectChannel',
									channel: data.channelName
								});
								
								//$app.dialog.alert('Picking up call!','Call!');
								
								// TODO: find out the specific spot unstead of guessing...
								/*
								app.preloader.show();
								TM.redirectChannel(data.channelName,function(data) {
									app.preloader.hide();
									if (data.ok) {
										
										// TODO: save some variables to get ready for the incoming call!
										
									} else {
										app.dialog.alert(data.error,'Error');
										
									}
								});
								*/
							},0);
							
						},
						close: function () {
							
							// If closed without clicking it...
							
						}
					}
				};
				
				// If not already in a call!!!
				if ($app.data.mainView.router.url !== '/phoneInCall/') {
					$app.data.incomingCallNotification= $app.notification.create(notificationParams);
					$app.data.incomingCallNotification.open();
				}
				
			}
			
		}
		
		if (data.data == 'deviceRecord') {
			$app.data.deviceId= data.deviceRecord.id;
			$app.methods.setItemLocalStorage('taskMasterDeviceId',$app.data.deviceId);

			$app.methods.emitEventToApp('appUpdate');

			if (($app.data.taskMasterData)
				&& ($app.data.taskMasterData.deviceRecord)
					&& ($app.data.taskMasterData.deviceRecord.updateObject)
						&& ($app.data.taskMasterData.deviceRecord.updateObject.updateAvailable)) {
				
				// TODO: if they accept...! Do not assume...
				if ((window.cordova) && (window.cordova.plugins) && (window.cordova.plugins.notification)) {
					window.cordova.plugins.notification.badge.set(1); // TODO: I need to have a global badge method to consider everything!

				}
				
				// TODO: if the version is SUPER OLD, then alert here now!

			} else {
				if ((window.cordova) && (window.cordova.plugins) && (window.cordova.plugins.notification)) {
					window.cordova.plugins.notification.badge.set(0); // TODO: I need to have a global badge method to consider everything!

				}
			}

		}

		if (data.data == 'employee') { // UPDATE IT!
			$app.data.user= data.employee;
			if ($app.data.localStorageData.saveLogin) {
				$app.data.localStorageData.user= $app.data.user;
				$app.methods.setItemLocalStorage('localStorageData',$app.data.localStorageData);
			}

			$app.methods.emitEventToApp('appUpdate');
		}
		
		$app.emit('webSocketMessage', data);
	},
	sendStatus: function(pageURL,pageStatusData) {
		var $app= this;
		
		// console.log('$app.views.current.router.url',$app.views.current.router.url);

		var overridePage= false;
		var overridePageData= false;
		if (typeof(pageURL) !== 'undefined') {
			overridePage= true;
		} else {
			pageURL= $app.views.current.router.url;
			if (pageURL == '/taskmaster/leftPanel/') {
				pageURL= $app.data.mainView.router.url;

			}
		}

		if (typeof(pageStatusData) !== 'undefined') {
			overridePageData= true;
		} else {
			pageStatusData= $app.data.pageStatusData;
		}

		$app.data.mdWebSocket.sendOverWebSocket({
			action: 'status',
			page: pageURL,
			data: pageStatusData
		});
		
		if (!overridePageData) {
			$app.data.pageStatusData= {};
		}
		
	},
	scanBarcode: function(options) {
		var $app= this;
		
		options= ((typeof(options) !== 'undefined') ? options: {});
		
		if (!options.hasOwnProperty('success')) {
			options.success= function(result) {
				
				// console.log('result/options',result,options);

				if (options.destination == 'askWhereToGo') {
					
					var validItem= false;
					
					var isClockInBarcode= false;
					
					console.log('result',result);

					var scannedString= result; // result.data;
					
					if (scannedString.indexOf('https://bedroomsandmore.com/qr/') > -1) {
						scannedString= scannedString.replace('https://bedroomsandmore.com/qr/', '');
						validItem= true;
					}
					
					if (scannedString.indexOf('https://45thstreetbedding.com/qr/') > -1) {
						scannedString= scannedString.replace('https://45thstreetbedding.com/qr/', '');
						validItem= true;
					}
					
					if (scannedString.indexOf('clockin;;;;;') == 0) {
						isClockInBarcode= true;
					}
					
					console.log('validItem',validItem);

					if (validItem) {

						

						$app.preloader.show();
						$app.data.mdWebSocket.ajaxToServer({
							method: 'GET',
				            showSpinner: false,
				            url: '/4DACTION/api/getItemInfo',
				            data: {
					            action: 'getItemInfo',
					            sku: scannedString
					        },
				            timeout: 10000,
				            success: function(data) {
					            $app.preloader.hide();
					            if (data.ok) {
				    				
				    				console.log(data);
				    				
						    	} else {
							    	$app.dialog.alert(data.error,'Error');
						    	}
					        },
					        error: function(data) {
								$app.preloader.hide();
								// TODO: Error alert?
								
					        }
						});
						
						
					} else if (isClockInBarcode) {
						
						var userIsClockedIn= $app.data.user.isClockedIn;
						
						if (!userIsClockedIn) {
							$app.methods.actualClockIn(scannedString,'');

						} else {
							$app.methods.actualClockOut(scannedString,'');

						}

					} else {
						$app.dialog.alert(result,'Scan Result');
					}
					
				} else {
					
					$app.dialog.alert(result.data,'Alert');
				}

				
			};
		}
		
		options.actualSuccess= options.success;
		
		options.success= function(result) {
			$app.popup.close('.popupScanBarcode');
			options.actualSuccess(result);
		};
		
		if (!options.hasOwnProperty('error')) {
			options.error= function(error) {}; //console.error(error); };
		}
		if (!options.hasOwnProperty('validation')) {
			options.validation= function(result) {				
				return ((result.data.indexOf('https://bedroomsandmore.com/qr/') == 0) || (result.data.indexOf('https://45thstreetbedding.com/qr/') == 0));
			};
		}
		if (!options.hasOwnProperty('multiple')) {
			options.multiple= false;
		}
		if (!options.hasOwnProperty('canvasId')) {
			options.canvasId= 'popup';
		}
		if (!options.hasOwnProperty('destination')) {
			options.destination= 'askWhereToGo';
		}
		
		// alert($app.device.desktop);

		if (false) {//(!$app.device.desktop) {
			cordova.plugins.barcodeScanner.scan(
				function (result) {
					
					// console.log('result',result);
					
					if (!result.cancelled) {
						options.success({
							data: result.text
						});
					}
					
				},
				function (error) {
					  $app.dialog.alert(error,'Scanning failed');
				},
				{
					  preferFrontCamera: false, // iOS and Android
					  showFlipCameraButton: false, // iOS and Android
					  showTorchButton: true, // iOS and Android
					  torchOn: false, // Android, launch with the torch switched on (if available)
					  //saveHistory: true, // Android, save scan history (default false)
					  prompt: "Place a barcode inside the scan area", // Android
					  resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
					  formats: "QR_CODE", // default: all but PDF_417 and RSS_EXPANDED
					  orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
					  disableAnimations: false, // iOS
					  disableSuccessBeep: false // iOS and Android
				}
		   );
		} else {
			$app.methods.scanBarcode_start(options);
		}
		
	},
	scanBarcode_start: function(options) {
		var $app= this;
		
		$app.data.jsQR.counter= 1;
		
		var scanningActuallyStart= function(canvasId) {
			options.canvasId= canvasId;

			if (true) {
				
				// This method will trigger user permissions
				Html5Qrcode.getCameras().then(devices => {
					/**
					 * devices would be an array of objects of type:
					 * { id: "id", label: "label" }
					 */
					if (devices && devices.length) {
						var cameraId = devices[0].id;
						// .. use this to start scanning.
						options.cameraId = cameraId;
						$app.methods.scanBarcode_actualScan(options);
					}
				}).catch(err => {
					// handle err
					console.log('Error Getting Cameras...');
				});

				
			} else {

				if ($app.data.jsQR.beep == null) {
					$app.data.jsQR.beep= new Audio();
					$app.data.jsQR.beep.loop= false;
					$app.data.jsQR.beep.src= 'static/media/notification-beep.wav';
				}
				$app.data.jsQR.video= document.createElement("video");
				// {width: {min: 320, ideal: 320, max: 1920}, frameRate: { ideal: 10, max: 30 },
				navigator.mediaDevices.getUserMedia({
					audio: false,
					video: {
						facingMode:'environment',
						width: {
							min: 320,
							max: 640
						}
					}
				}).then(function(stream) {
					$app.data.jsQR.video.srcObject= stream;
					$app.data.jsQR.video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
					$app.data.jsQR.video.play();
					$$('.loadingMessage').html('⌛ Loading video...');
					options.canvasElement = document.getElementById(options.canvasId);
					options.canvas = options.canvasElement.getContext("2d");
					$app.methods.scanBarcode_actualScan(options);
					//$app.data.jsQR.animationFrame= window.requestAnimationFrame(function(timestamp) {
					//    $app.methods.scanBarcode_actualScan(options);
					//});
				});
			}
		};
		
		if (options.canvasId !== 'popup') {
			// Try to find the element...!
			
			if (true) { // If element found...
				scanningActuallyStart(options.canvasId);
				
			} else {
				options.error('couldn\'t find the canvasId: "'+options.canvasId+'"');
				
			}
		}
		
		if (options.canvasId == 'popup') {
			var dynamicPopup = $app.popup.create({
			  content: '<div class="popup popup-tablet-fullscreen popupScanBarcode">'+
			  				'<div class="view">'+
			  					'<div class="page pageScanBarcode" data-name="scanBarcode">'+
			  						'<div class="navbar">'+
			  							'<div class="navbar-bg"></div>'+
										'<div class="navbar-inner">'+
											'<div class="left">'+
												((!$app.device.md)?'<a href="#" class="link popup-close" data-popup=".popupScanBarcode">Close</a>':'')+
												(($app.device.md)?'<a href="#" class="link icon-only popup-close" data-popup=".popupScanBarcode"><i class="icon icon-back"></i></a>':'')+
											'</div>'+
											'<div class="title sliding">Scan Barcode</div>'+
										'</div>'+
									'</div>'+
									'<div class="page-content">'+
										'<div id="barcodeReader" width="600px"></div>'+
										//'<div class="loadingMessage block">🎥 Unable to access video stream (please make sure you have a webcam enabled)</div>'+
										//'<canvas id="qrCodeCameraCanvas" hidden></canvas>'+
										//'<video id="deviceCameraVideo" width="100%" height="80%"></video>'+
									'</div>'+
			  					'</div>'+
			  				'</div>'+
			  			'</div>',
			  // Events
			  on: {
			    opened: function (popup) {
				  
						scanningActuallyStart('barcodeReader');

					},
			    open: function (popup) {
						//scanningActuallyStart('qrCodeCameraCanvas');
			    },
			    close: function (popup) {
			      $app.methods.scanBarcode_stop();
			      
			    }
			  }
			}).open();
		}
		
        
	},
	scanBarcode_actualScan: function(options) {
		var $app= this;
		
		var continueWithScanning= true;
		
		if (true) {
			// console.log('GOT HERE!');
			window.html5QrCodeVariable = new Html5Qrcode(options.canvasId,{ formatsToSupport: [ 0 ] }); // QR_CODE = 0
			window.html5QrCodeVariable.start(
				{ facingMode: "environment" }, // options.cameraId
				{
					fps: 5,    // Optional, frame per seconds for qr code scanning
					qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
				},
				(decodedText, decodedResult) => {
					// do something when code is read
					if (!options.multiple) {
						continueWithScanning= false;
					}
					options.success(decodedText);
				},
				(errorMessage) => {
					// parse error, ignore it.
					console.log('not valid barcode!', error);
				})
			.catch((err) => {
				// Start failed, handle it.
				console.log('Error loading camera!');
			});


		} else if ($app.data.jsQR.video) {
			if ($app.data.jsQR.video.readyState === $app.data.jsQR.video.HAVE_ENOUGH_DATA) {
				options.canvasElement.hidden = false;
				
				if (!options.loadedVideo) {
					options.loadedVideo= true;
					$$('.loadingMessage').hide();
				}
				
				options.canvasElement.height = $app.data.jsQR.video.videoHeight;
				options.canvasElement.width = $app.data.jsQR.video.videoWidth;
				options.canvasElement.style.width = '100vw';
				options.canvas.drawImage($app.data.jsQR.video, 0, 0, options.canvasElement.width, options.canvasElement.height);
				var imageData= options.canvas.getImageData(0, 0, options.canvasElement.width, options.canvasElement.height);
				
				// TODO: speed up this...
				
				var qrCodeFound= false;
				if (($app.data.jsQR.counter % 50) == 0) {
					var qrCodeFound= jsQR(imageData.data, imageData.width, imageData.height, {
						inversionAttempts: 'dontInvert'
					});
					
					$app.data.jsQR.counter= 1;
					
				} else {
					$app.data.jsQR.counter++;
					
				}
				if (qrCodeFound) {
					if (options.validation(qrCodeFound)) {
						$app.data.jsQR.beep.play();
						if (!options.multiple) {
							continueWithScanning= false;
						}
						
						$app.methods.scanBarcode_drawLine(options.canvas,qrCodeFound.location.topLeftCorner, qrCodeFound.location.topRightCorner, "#FF3B58");
						$app.methods.scanBarcode_drawLine(options.canvas,qrCodeFound.location.topRightCorner, qrCodeFound.location.bottomRightCorner, "#FF3B58");
						$app.methods.scanBarcode_drawLine(options.canvas,qrCodeFound.location.bottomRightCorner, qrCodeFound.location.bottomLeftCorner, "#FF3B58");
						$app.methods.scanBarcode_drawLine(options.canvas,qrCodeFound.location.bottomLeftCorner, qrCodeFound.location.topLeftCorner, "#FF3B58");
						
		            	options.success(qrCodeFound);
		            	
		            } else {
			            console.log('not valid!', qrCodeFound.data);
			            
		            }
					
				} else {
					// Do nothing...
					
				}
				
			}
		} else {
			continueWithScanning= false;
			
		}
		/*
		if (continueWithScanning) {
			var tempTimeout= setTimeout(function() {
				$app.methods.scanBarcode_actualScan(options);
			}, 0);
		}
		*/
	},
	scanBarcode_drawLine: function(canvas, begin, end, color) {
		// var $app= this;
		
		canvas.beginPath();
		canvas.moveTo(begin.x, begin.y);
		canvas.lineTo(end.x, end.y);
		canvas.lineWidth = 4;
		canvas.strokeStyle = color;
		canvas.stroke();
		
	},
	scanBarcode_stop: function() {
		var $app= this;
		
		// console.log('STOP SCANNING!');

		if (window.html5QrCodeVariable) {
			window.html5QrCodeVariable.stop().then((ignore) => {
				// QR Code scanning is stopped.
			}).catch((err) => {
				// Stop failed, handle it.
			});
		}

		/*
		if ($app.data.jsQR.video !== null) {
			$app.data.jsQR.video.srcObject.getTracks()[0].stop();
			
			var tempTimeout= setTimeout(function() {
				$app.data.jsQR.video= null;
			}, 500);
		}
		*/

	},
	callNumber: function(numberToDial,numberName,messageOfAlert) {
		numberName= ((typeof(numberName) == 'undefined')?'':numberName);
		messageOfAlert= ((typeof(messageOfAlert) == 'undefined')?'':messageOfAlert);

		var $app= this;

		if (($app.device.desktop) && ($app.data.user.id !== 16)) { // && ((!$app.data.phoneIsActive) || ($app.data.user.id !== 16))) {

			var toastLargeMessage= $app.toast.create({
				text: ((numberName !== '')?'<span style="font-size: 0.8em;">'+numberName+'</span><br><br>':'')+$app.methods.formatPhoneNumber(numberToDial),
				cssClass: 'large',
				position: 'center',
				closeTimeout: 10000,
			});

			toastLargeMessage.open();

		} else {

			var availableButtons= [];

			var appName= 'MyBnM';
			if ((window.BuildInfo) && (window.BuildInfo.displayName)) {
				appName= window.BuildInfo.displayName;
			}

			if ($app.data.phoneIsActive) {

				if ($app.data.localStorageData.phoneLogin.externalApp !== '') {
					availableButtons.push({
						text: 'Call using External App \''+$app.data.localStorageData.phoneLogin.externalApp+'\'',
						onClick: function(dialog,e) {
							window.open($app.data.localStorageData.phoneLogin.externalApp+':'+(numberToDial).replace(/\D/g, '')+'?dialAction=voiceCall', '_system');
							// window.location.href='groundwire:'+(numberToDial).replace(/\D/g, '');
						}
					});
				}	else {
					availableButtons.push({
						text: 'Call using '+appName,
						onClick: function(dialog,e) {
							var tempTimeout= setTimeout(function() {
								$app.data.PHONE.call((numberToDial).replace(/\D/g, ''), numberName);
							}, 0);
						}
					});
				}
			}

			if (numberToDial.length >= 7) {

				availableButtons.push({
					text: (($app.device.desktop)?'Try with this computer':'Call with my own cell phone'),
					color: 'gray',
					onClick: function(dialog,e) {
						if (($app.device.cordova) && (cordova.plugins.CordovaCall) && ($app.device.ios)) {
							cordova.plugins.CordovaCall.callNumber((numberToDial).replace(/\D/g, ''));

						} else {
							var urlToOpen= 'tel:'+(numberToDial).replace(/\D/g, '');
							if ($app.device.cordova) {
								window.open(urlToOpen,'_system');
							} else {
								window.location.href= urlToOpen;
							}

						}
					}
				});
			}

			availableButtons.push({
				text: 'Cancel',
				color: 'red'
			});
			
			
			var messageToConfirm= '';
			var verticalButtons= true;

			if (availableButtons.length > 2) {
				messageToConfirm+= 'How would you like to call this number?<br><br>';

			} else {
				// Swap buttons...
				verticalButtons= false;
				availableButtons= availableButtons.reverse();
				availableButtons[1].text= 'Call';
			}

			if (messageOfAlert == '') {
				if (numberName !== '') {
					messageToConfirm+= '<strong>'+numberName+'</strong><br>';
				}
				messageToConfirm+= $app.methods.formatPhoneNumber(numberToDial);

			} else {
				messageToConfirm= messageOfAlert;
			}

			$app.dialog.create({
				title: 'Call Number',
				text: messageToConfirm,
				backdrop: true,
				closeByBackdropClick: true,
				buttons: availableButtons,
				verticalButtons: verticalButtons,
				destroyOnClose: true
			}).open();
		}
	},
	resetLocalData: function(clearLocalStorageToo) {
		clearLocalStorageToo= ((typeof(clearLocalStorageToo) == 'boolean')?clearLocalStorageToo:true);
		
		var $app= this;
		
		// Clear user data & TaskMaster data...
		$app.data.user= {
			id: 0,
			name: '',
			fullname: '',
			department: '',
			settings: {
				hasPhone: false,
				phoneLogin: {
					extension: 0
				}
			}
		};
		
		$app.data.taskMasterData= {
			buckets: [],
			devices: {},
			conversations: [],
			cameras: {
				dc: [30,17,20,23,74,18,29,25,31,24,27,26,22,19,28,21,77,78,79],
				newStore: [80,32,33,34,36,35,37,38,59,40,41,42,46,43,39,44,45,47,48,49,50,51,52,53,54,87,55,56,58,57,16,84,61,60,65,66,76],
				store: [81,13,5,15,4,2,3,7,14,82,83,9,11,10,12,1,6,8,64,88,75,67,68,69,86,70,85,71,72,73]
			},
			locations: [
				{
					id: 11,
					name: 'Flagship Store',
					shortName: 'Flagship',
					isPhysicalLocation: true,
					inventoryAbbreviation: 'N',
					retailInvoicePrefix: 'N',
					retailInvoiceLogoSrc: 'img/logos/bedroomsandmore-new.svg',
					hasUpList: true
				},
				{
					id: 1,
					name: 'Clearance Center',
					shortName: 'Clearance',
					isPhysicalLocation: true,
					inventoryAbbreviation: 'C',
					retailInvoicePrefix: 'C',
					retailInvoiceLogoSrc: 'img/logos/bedroomsandmore-textonly.svg',
					hasUpList: true
				},
				{
					id: 8,
					name: 'Bedrooms & More Online',
					shortName: 'Online',
					isPhysicalLocation: false,
					inventoryAbbreviation: '',
					retailInvoicePrefix: 'E',
					retailInvoiceLogoSrc: 'img/logos/bedroomsandmore.png',
					hasUpList: false
				},
				{
					id: 7,
					name: '45th Street Bedding',
					shortName: '45th',
					isPhysicalLocation: false,
					inventoryAbbreviation: '',
					retailInvoicePrefix: '45',
					retailInvoiceLogoSrc: 'img/logos/45th-street-bedding.svg',
					hasUpList: false
				},
				{
					id: 6,
					name: 'Hospitality',
					shortName: 'Hospitality',
					isPhysicalLocation: false,
					inventoryAbbreviation: '',
					retailInvoicePrefix: 'H',
					retailInvoiceLogoSrc: 'img/logos/45th-street-hospitality.svg',
					hasUpList: false
				},
				{
					id: 9,
					name: 'Philanthropy',
					shortName: 'Philanthropy',
					isPhysicalLocation: false,
					inventoryAbbreviation: '',
					retailInvoicePrefix: 'P',
					retailInvoiceLogoSrc: 'img/logos/bedroomsandmore.png',
					hasUpList: false
				},
				{
					id: 2,
					name: 'Distribution Center',
					shortName: 'D.C.',
					isPhysicalLocation: true,
					inventoryAbbreviation: 'W',
					retailInvoicePrefix: '',
					retailInvoiceLogoSrc: '',
					hasUpList: false
				}

			],
			phonebook: {
				contacts: {
					departments: [
						{
							id: 16, // Phone System ID
							name: 'Sales',
							extension: '1100',
							otherNumbers: {
								work: '2066334494',
								workext: ''
							}
						},
						{
							id: 22, // Phone System ID
							name: 'Sales WEST',
							extension: '1002',
							otherNumbers: {
								work: '2066334494',
								workext: ''
							}
						},
						{
							id: 21, // Phone System ID
							name: 'Sales EAST',
							extension: '1001',
							otherNumbers: {
								work: '2066334494',
								workext: ''
							}
						},
						{
							id: 3, // Phone System ID
							name: 'Office',
							extension: '1200',
							otherNumbers: {
								work: '2066334505',
								workext: ''
							}
						},
						{
							id: 2, // Phone System ID
							name: 'Dist. Center',
							extension: '2100',
							otherNumbers: {
								work: '2065230061',
								workext: ''
							}
						},
						{
							id: 4, // Phone System ID
							name: 'Outlet',
							extension: '2200',
							otherNumbers: {
								work: '2065230062',
								workext: ''
							}
						},
						{
							id: 19, // Phone System ID
							name: 'Creative Team',
							extension: '2300',
							otherNumbers: {
								work: '2066334498',
								workext: '2300'
							}
						},
						{
							id: 11, // Phone System ID
							name: 'H.R.',
							extension: '2400',
							otherNumbers: {
								work: '2066334498',
								workext: '2400'
							}
						}
					]
				}
			},
			reports: {/*
				admin: [
					'Sales by Salesperson'
				],
				sales: [
					'Calc Daily Sales & Funds',
					'Product Category Popularity',
					'Print All Floor Models & Damaged',
					'Delivery Days and Areas',
					'DC Stock and Available',
					'Discontinued Items Still In Stock',
					'Items Normally Stocked in DC',
					'Recently Viewed Invoices',
					'Recently Discontinued Items',
					'Delivery Schedule List',
					'Oversold Items',
					'Prices by Category, Size',
					'Store Minimum Stock Report',
					'Sales Days and Months Comparison',
					'Items Sold Report',
					'Outlet Sales Review',
					'View Items on Display'
				],
				dc: [
					'Snapshot of all DC Inventory',
					'Print All Floor Models & Damaged',
					'Master Quest / Should be tagged',
					'Oversold in any location',
					'In Stock & Not Scanned Report',
					'Build Packages from Stock',
					'Calculated Minimum Stock Levels (Normally Stocked Items)',
					'Calculated Minimum Stock Levels (All Items)',
					'Quick View of Items on Order',
					'Standard Mattress Prices',
					'Volume of Stock by Vendor',
					'Container Ordering Report',
					'6 Months Sales Totals',
					'Quick View of In Stock and Available',
					'Historical Sales',
					'Mattress Return Rates',
					'Number of Weeks Worth of Stock',
					'Mattress Sales Condensed',
					'Show all "Live" Items',
					'Drews Mattress Return Rates',
					'Incoming Containers Report',
					'Thane\'s Report',
					'History of Same Day Deliveries'
				]*/
			},
			priceTagTemplates: [
				{
					name: 'Generic',
					sizes: ['quarter'],
					categoryWhiteList: [],
					categoryBlackList: []
				},
				{
					name: 'Mattress Full Page',
					sizes: ['full'],
					categoryWhiteList: ['Mattresses'],
					categoryBlackList: []
				},/*
				{
					name: 'Specialty Bedding sm',
					sizes: ['quarter'],
					categoryWhiteList: ['Bedding'],
					categoryBlackList: []
				},
				{
					name: 'Specialty Bedding med',
					sizes: ['quarter'],
					categoryWhiteList: ['Bedding'],
					categoryBlackList: []
				},*/
				{
					name: 'Specialty Bedding lg',
					sizes: ['half'],
					categoryWhiteList: [],// TODO: ['Bedding'],
					categoryBlackList: []
				}
			]
		};
		
		if (clearLocalStorageToo) {
 			// window.localStorage.clear();
 			//$app.methods.setItemLocalStorage('localStorageData',{});
 			$app.methods.removeItemLocalStorage('localStorageData');
		}
		
	},
	setAppTheme: function(themeObject) {
		var $app= this;
		
		themeObject= ((typeof(themeObject) == 'object') ? themeObject: {});
		if (!themeObject.hasOwnProperty('main')) { themeObject.main= '#0f0'; }
		if (!themeObject.hasOwnProperty('secondary')) { themeObject.secondary= '#f00'; }
		if (!themeObject.hasOwnProperty('font')) { themeObject.font= '#fff'; }
		if (!themeObject.hasOwnProperty('onSuccess')) { themeObject.onSuccess= function() {}; }
		if (!themeObject.hasOwnProperty('skipSave')) { themeObject.skipSave= false; }
		
		if ($app.data.localStorageData.saveLogin) {
			$app.data.localStorageData.user.settings.theme.mobileColorMain= themeObject.main;
			$app.data.localStorageData.user.settings.theme.mobileColorSecondary= themeObject.secondary;
			$app.data.localStorageData.user.settings.theme.mobileColorFont= themeObject.font;
			$app.methods.setItemLocalStorage('localStorageData',$app.data.localStorageData);
		}

		var themeCSS=''+
        	'.bnmtheme .statusbar {'+
			'	background-color: '+themeObject.main+';'+
			//'	background-color: #000;'+
			'}'+
        	'.bnmtheme .statusbar.inCall {'+
			'	background-color: #19a22b;'+
			'}'+
			'.bnmtheme .page.launchscreen .page-content {'+
			'	color: #fff;'+
			'}'+
			'.bnmtheme .page.launchscreen .launchscreenTop {'+
			'	color: #fff;'+
			'	background-color: '+themeObject.secondary+';'+
			'}'+
			'.bnmtheme .page.launchscreen .page-content .communicationBar {'+
			'	background-color: '+themeObject.main+';'+
			'}'+
			'.bnmtheme .page.launchscreen .page-content .communicationBar, .bnmtheme .page.launchscreen .page-content .employeeBlock {'+
			'	color: '+themeObject.font+';'+
			'}'+
			'.bnmtheme .page.launchscreen .page-content .communicationBar a {'+
			'	color: '+themeObject.font+';'+
			'}'+
			'.bnmtheme .page.launchscreen .page-content a,.bnmtheme .page.launchscreen .page-content .block-title {'+
			'	color: #fff;'+
			'}'+
			'.navbar-bg, .ios .bnmtheme .toolbar, .md .bnmtheme .toolbar {'+
			'	color: '+themeObject.font+';'+
			'	background-color: '+themeObject.main+' !important;'+
			'}'+
			'.ios .bnmtheme .navbar, .ios .bnmtheme .navbar:after, .ios .bnmtheme .toolbar, .ios .bnmtheme .subnavbar, .ios .bnmtheme .navbar .title-large, .ios .bnmtheme .navbar .title-large:after, .ios .bnmtheme .button.button-active, .ios .bnmtheme .button.tab-link-active {'+
			'	color: '+themeObject.font+';'+
			'	basckground-color: '+themeObject.main+';'+
			'}'+
			'.ios .bnmtheme .photo-browser-dark .navbar, .ios .bnmtheme .photo-browser-page-dark .navbar, .ios .bnmtheme .view.with-photo-browser-page-dark .navbar, .ios .bnmtheme .photo-browser-dark .toolbar, .ios .bnmtheme .photo-browser-page-dark .toolbar, .ios .bnmtheme .view.with-photo-browser-page-dark .toolbar {'+
				'color: #fff;'+
				'background: rgba(27, 27, 27, 0.8);'+
			'}'+
			'.ios .toolbar:before {'+
			'	display: none;'+
			'}'+
			'.md .bnmtheme .subnavbar {'+
			'	color: '+themeObject.font+';'+
			'	background-color: '+themeObject.main+';'+
			'}'+
			'.md .bnmtheme .subnavbar .title:first-child {'+
			'	margin-left: 0px;'+
			'}'+
			'.ios .bnmtheme .toolbar.messagebar {'+
			'	background-color: #fff;'+
			'}'+
			'.md .bnmtheme .toolbar.messagebar {'+
			'	background-color: #fff;'+
			'}'+
			'.ios .bnmtheme .navbar .autocomplete-dropdown-inner {'+
			'	color: #000;'+
			'}'+
			'.ios .bnmtheme .navbar a, .ios .bnmtheme .navbar .subtitle, .ios .bnmtheme .toolbar a, .ios .bnmtheme .subnavbar a {'+
			'	color: '+themeObject.font+';'+
			'}'+
			'.ios .bnmtheme .toolbar.messagebar a {'+
			'	color: rgb(0, 122, 255);'+
			'}'+
			'.md .bnmtheme .toolbar.messagebar a {'+
			'	color: rgb(0, 122, 255);'+
			'}'+
			'.md .bnmtheme .navbar, .md .bnmtheme .tabbar, .md .bnmtheme .toolbar, .md .bnmtheme .title-large, .md .navbar .title-large:after {'+
			'	color: '+themeObject.font+';'+
			'	background-color: '+themeObject.main+';'+
			'}'+
			'.md .bnmtheme .photo-browser-dark .navbar, .md .bnmtheme .photo-browser-page-dark .navbar, .md .bnmtheme .view.with-photo-browser-page-dark .navbar, .md .bnmtheme .photo-browser-dark .toolbar, .md .bnmtheme .photo-browser-page-dark .toolbar, .md .bnmtheme .view.with-photo-browser-page-dark .toolbar {'+
				'color: #fff;'+
				'background: rgba(27, 27, 27, 0.8);'+
			'}'+
			'.md .bnmtheme .calendar .toolbar {'+
			'   background-color: '+themeObject.main+' !important;'+ // This is to fix what's already labelled 'important' in framework7 ugh...
			'}'+
			'.md .bnmtheme .navbar .autocomplete-dropdown-inner {'+
			'	color: #000;'+
			'}'+
			'.md .bnmtheme .navbar a {'+
			'	color: '+themeObject.font+';'+
			'}'+
			'.page.launchscreen .page-content .app-swiper a {'+
			'	color: #fff;'+
			'	background-color: '+themeObject.secondary+';'+
			'}'+
			'.page.launchscreen .page-content .app-swiper a.disabled {'+
			'	color: #fff;'+
			'	background-color: #eee;'+
			'}'+
			'.ios .bnmtheme .button {'+
			'	border: 1px solid '+themeObject.main+';'+
			'}'+
			'.ios .bnmtheme .button, .md .bnmtheme .button {'+
			'	color: '+themeObject.main+';'+
			'}'+
			'.ios .bnmtheme .button.button-fill, .ios .bnmtheme .button.button-fill-ios, .aurora .bnmtheme .button.button-fill, .aurora .bnmtheme .button.button-fill-aurora {'+
			'	color: '+themeObject.font+';'+
			'	background: '+themeObject.main+';'+
			'}'+
			'.md .bnmtheme .toolbar a, .md .bnmtheme .button.button-active, .md .bnmtheme .button.button-fill, .md .bnmtheme .button.button-fill-md, .md .bnmtheme .button.tab-link-active {'+
			'	color: '+themeObject.font+';'+
			'	background: '+themeObject.main+';'+
			'}';
		
		if (true) {
			document.documentElement.style.setProperty('--bnm-theme-color', themeObject.main);
			document.documentElement.style.setProperty('--bnm-secondary-color', themeObject.secondary);
			document.documentElement.style.setProperty('--bnm-text-color', themeObject.font);

			if (($app.data.user) && ($app.data.user.settings) && ($app.data.user.settings.theme)) {

				if (!$app.data.user.settings.theme.invoice_rightSideColorOriginal) {
					$app.data.user.settings.theme.invoice_rightSideColorOriginal= $app.data.user.settings.theme.invoice_rightSideColor;
				}

				$app.data.user.settings.theme.invoice_rightSideColor= $app.methods.LightenDarkenColor($app.data.user.settings.theme.invoice_rightSideColorOriginal,-25);

				document.documentElement.style.setProperty('--bnm-invoice-left-color', $app.data.user.settings.theme.invoice_leftSideColor);
				document.documentElement.style.setProperty('--bnm-invoice-right-color', $app.data.user.settings.theme.invoice_rightSideColor);

				document.documentElement.style.setProperty('--bnm-invoice-left-darker-color', $app.methods.LightenDarkenColor($app.data.user.settings.theme.invoice_leftSideColor,-100));
				document.documentElement.style.setProperty('--bnm-invoice-left-lighter-color', $app.methods.LightenDarkenColor($app.data.user.settings.theme.invoice_leftSideColor,100));
				
				var leftSideTextColor= '';
				if ($app.methods.lightOrDark($app.data.user.settings.theme.invoice_leftSideColor) == 'light') {
					leftSideTextColor= 'rgba(0,0,0,0.7)';//$app.methods.LightenDarkenColor($app.data.user.settings.theme.invoice_leftSideColor,-100);
				} else {
					leftSideTextColor= 'rgba(255,255,255,0.7)';//$app.methods.LightenDarkenColor($app.data.user.settings.theme.invoice_leftSideColor,100);
				}
				document.documentElement.style.setProperty('--bnm-invoice-left-text-color', leftSideTextColor);

				var rightSideTextColor= '';
				if ($app.methods.lightOrDark($app.data.user.settings.theme.invoice_rightSideColor) == 'light') {
					rightSideTextColor= 'rgba(0,0,0,0.7)';//$app.methods.LightenDarkenColor($app.data.user.settings.theme.invoice_rightSideColor,-100);
				} else {
					rightSideTextColor= 'rgba(255,255,255,0.7)';//$app.methods.LightenDarkenColor($app.data.user.settings.theme.invoice_rightSideColor,100);
				}
				document.documentElement.style.setProperty('--bnm-invoice-right-text-color', rightSideTextColor);

				document.documentElement.style.setProperty('--bnm-invoice-right-darker-color', $app.methods.LightenDarkenColor($app.data.user.settings.theme.invoice_rightSideColor,-100));
				document.documentElement.style.setProperty('--bnm-invoice-right-lighter-color', $app.methods.LightenDarkenColor($app.data.user.settings.theme.invoice_rightSideColor,100));
			}

			document.documentElement.style.setProperty('--f7-theme-color', themeObject.main);
			document.documentElement.style.setProperty('--f7-theme-secondary-color', themeObject.secondary);
			document.documentElement.style.setProperty('--f7-theme-color-rgb', themeObject.main);
			//document.documentElement.style.setProperty('--f7-bars-bg-color', themeObject.main);
			//document.documentElement.style.setProperty('--f7-bars-bg-color-rgb', themeObject.main);
			document.documentElement.style.setProperty('--f7-button-fill-text-color', themeObject.font);
			document.documentElement.style.setProperty('--f7-button-text-color', '#000');
			document.documentElement.style.setProperty('--f7-bars-text-color', themeObject.font);
			document.documentElement.style.setProperty('--f7-bars-link-color', themeObject.font);
			document.documentElement.style.setProperty('--f7-table-actions-link-color', themeObject.font);
			
			var inactiveLinkColor= 'rgba(255,255,255,0.54);';
			var splitUpColorRGBs= $app.methods.splitUpColorRGBs(themeObject.font);
			if (splitUpColorRGBs.length == 3) {
				inactiveLinkColor= 'rgba('+splitUpColorRGBs[0]+','+splitUpColorRGBs[1]+','+splitUpColorRGBs[2]+',0.54)';
			}
			document.documentElement.style.setProperty('--f7-tabbar-link-inactive-color', inactiveLinkColor);
			
			//document.documentElement.style= '--f7-theme-color:'+themeObject.main+'; --f7-theme-color-rgb:'+themeObject.main+'; --f7-button-fill-bg-color:'+themeObject.main+'; --f7-bars-bg-color:'+themeObject.main+'; --f7-bars-bg-color-rgb:'+themeObject.main+';';
			
		}
		
		if (true) {
			
			themeCSS= '.bnmtheme .statusbar.inCall {'+
						'	background-color: #19a22b;'+
						'}'+
						'.bnmtheme .page.launchscreen .page-content {'+
						'	color: #fff;'+
						'}'+
						'.bnmtheme .page.launchscreen .launchscreenTop {'+
						'	color: #fff;'+
						'	background-color: '+themeObject.secondary+';'+
						'}'+
						'.bnmtheme .page.launchscreen .page-content .communicationBar {'+
						'	background-color: '+themeObject.main+';'+
						'}'+
						'.bnmtheme .page.launchscreen .page-content .communicationBar, .bnmtheme .page.launchscreen .page-content .employeeBlock {'+
						'	color: '+themeObject.font+';'+
						'}'+
						'.bnmtheme .page.launchscreen .page-content .communicationBar a {'+
						'	color: '+themeObject.font+';'+
						'}'+
						'.bnmtheme .page.launchscreen .page-content a,.bnmtheme .page.launchscreen .page-content .block-title {'+
						'	color: #fff;'+
						'}'+
						'.page.launchscreen .page-content .app-swiper a {'+
						'	color: #fff;'+
						'	background-color: '+themeObject.secondary+';'+
						'}'+
						'.page.launchscreen .page-content .app-swiper a.disabled {'+
						'	color: #fff;'+
						'	background-color: #eee;'+
						'}';
			
			if ($app.data.isIOS9) {
				// Status bar and some padding fixes...
				
				themeCSS+= '.navbar.theme-dark, .navbar.theme-dark .title, .navbar.theme-dark a {'+
								'color: #fff !important;'+
							'}'+
							'.bnmtheme .page-content {'+
								'padding-top: 44px !important;'+
							'}'+
							'.bnmtheme .toolbar.toolbar-bottom {'+
								'bottom: 20px !important;'+
							'}'+
							'.bnmtheme .popup.popup-tablet-fullscreen {'+
								'top: 20px !important;'+
							'}'+
							'.bnmtheme .statusbar {'+
								'height: 20px;'+
								'background-color: '+themeObject.main+';'+
							'}';
							// toolbar toolbar-bottom
			}
		}
		
		$$('style.bnmthemeCSS').html(themeCSS);
		
        //$$('style.bnmthemeCSS').append($$(document.head));
        
	},
	getPastTimeCardList: function(startDate,endDate,showAllDays,linkTo) {
		var $app= this;
		
		showAllDays= ((typeof(showAllDays) !== 'undefined') ? showAllDays: false);
		linkTo= ((typeof(linkTo) !== 'undefined') ? linkTo: 'fixTimeCard');

		var returnString= '';
		
		if ($app.data.user.timecards) {
			var timecards= $app.data.user.timecards;
			var timecardsChangeRequests= $app.data.user.timecardsChangeRequests;
			
			var currentDate= new Date();
			var thisIncludesCurrentDate= false;
			
			//var amountOfDays= endDate.getDate()-startDate.getDate();
			var amountOfDays= $app.methods.days_between(startDate,endDate);
			var loopDate= startDate;
			
			var howManyDaysPrinted= 0;
			var currentMonth= -1;
			var totalHours= 0;
			
			for (var j= 0; j<= amountOfDays; j++) {
				if (j > 0) {
					loopDate.setDate(loopDate.getDate()+1); // Add for each day...
				}
				
				//console.log('loopDate',loopDate);

				// Filter timecards for the day...
				var currentDayTimeCards= timecards.filter(function(currentElement) {
					var d2= new Date(currentElement.clockInDateTime);
					return $app.methods.datesAreSameDay(d2,loopDate);
				});

				//console.log('currentDayTimeCards',currentDayTimeCards);
				
				var hasTimeCards= (currentDayTimeCards.length > 0);
				
				if ((hasTimeCards) || (showAllDays)) {
					if (loopDate.getMonth() !== currentMonth) { // TODO: also year....
						if (currentMonth !== -1) {
							returnString+='</ul></div>';
						}
						returnString+= '<div class="list-group"><ul>';
						returnString+= '<li class="list-group-title">'+$app.data.monthNames[loopDate.getMonth()]+' '+loopDate.getFullYear()+'</li>';
						
						currentMonth= loopDate.getMonth();
					}

					var linkString= '/timeclock/requestFix/'+encodeURIComponent(loopDate.toUTCString())+'/';
					if (linkTo == '') {
						linkString= '#';
					}
					
					var itemHeaderHTML= '';

					var tempTotalHoursString= '0';
					if (hasTimeCards) {
						
						// SORT THEM!
						currentDayTimeCards.sort(function(a,b){
							var key1 = new Date(a.clockInDateTime);
							var key2 = new Date(b.clockInDateTime);
						
							// TODO: if ZERO I want it after.....!
						
							if (key1 < key2) {
								return -1;
							} else if (key1 == key2) {
								return 0;
							} else {
								return 1;
							}
						});
						
						var currentDateTotalPTOHours= 0;
						var currentDateTotalHours= 0;
						
						for (var i= 0; i< currentDayTimeCards.length; i++) {
							var d2= new Date(currentDayTimeCards[i].clockInDateTime);
							
							if (currentDayTimeCards[i].ptoAmount !== 0) {

								if (currentDayTimeCards[i].ptoAmount > 0) {
									itemHeaderHTML+='PTO: '+currentDayTimeCards[i].ptoAmountReason;
									currentDateTotalPTOHours+= currentDayTimeCards[i].ptoAmount;
								}

							} else if (currentDayTimeCards[i].clockInTime > 0) { // If there is a start!
								if (currentDayTimeCards[i].clockOutTime > 0) {
									var d3= new Date(currentDayTimeCards[i].clockOutDateTime);
									
									if (i > 0) {
										itemHeaderHTML+= '<br>';
									}
									
									itemHeaderHTML+= moment(d2).format('h:mma')+' - '+moment(d3).format('h:mma');
									
									currentDateTotalHours+= currentDayTimeCards[i]._output_numberOfHours;//(Math.abs(d3 - d2) / 36e5);
								} else {
									// Clocked in but not out...
									if (i > 0) {
										itemHeaderHTML+= '<br>';
									}
									itemHeaderHTML+= moment(d2).format('h:mma')+' <span style="font-style: italic; text-decoration: underline;">Clocked In</span>';
								}
							}
						}
						
						tempTotalHoursString= '';

						if (currentDateTotalHours > 0) {
							tempTotalHoursString= currentDateTotalHours.toFixed(2);
							if (currentDateTotalPTOHours > 0) {
								tempTotalHoursString+='<br>';
							}
						}

						if (currentDateTotalPTOHours > 0) {
							tempTotalHoursString+='PTO: '+currentDateTotalPTOHours.toFixed(2);
						} else {
							tempTotalHoursString+= ' hours';
						}
						
						totalHours+= currentDateTotalHours+currentDateTotalPTOHours; // BOTH
					} else {
						itemHeaderHTML+= '<span style="color: #ccc; font-style: italic;">No Hours Logged</span>';
					}
					
					var thisIsCurrentDate= $app.methods.datesAreSameDay(loopDate,currentDate);
					if (thisIsCurrentDate) {
						thisIncludesCurrentDate= true;
					}
					
					// ((thisIsCurrentDate)?'Today':
					
					returnString+= '<li>'+
									'<a href="'+linkString+'" class="item-link item-content'+((thisIsCurrentDate) ? ' currentDateTimecardItemLink': '')+'">'+
										'<div class="item-media" style="text-align: center;"><span><span style="font-weight: bold;">'+loopDate.getDate()+'</span><br><span style="font-size: 0.7em;">'+$app.data.dayNamesShort[loopDate.getDay()]+'</span></span></div>'+
										'<div class="item-inner">'+
										'<div class="item-title">'+
											'<div class="item-header">';
					
					returnString+=				itemHeaderHTML;
					
					returnString+= 			'</div>'+
										'</div>'+
									'<div class="item-after">'+tempTotalHoursString+'</div>'+
								'</div>'+
							'</a>'+
							'</li>';
					
					howManyDaysPrinted+= 1; // Add one...
					
				}
			}
		}

		if (returnString !== '') { // TODO...
			var totalHoursString= '0';
			if (totalHours> 0) {
				totalHoursString= totalHours.toFixed(2);
				//if (totalHours < 1) {
				//	totalHoursString= totalHours.toFixed(2);
				//}
			}
			
			if (howManyDaysPrinted > 1) {
				returnString+= '<li>'+
							    	'<li class="item-content">'+
							          '<div class="item-title timecardPeriodTotal'+((thisIncludesCurrentDate) ? ' thisIncludesCurrentDate': '')+'" data-original-total="'+totalHoursString+'">Total: '+totalHoursString+' Hours</div>'+
							    	'</li>'+
							    '</li>';
			}
			returnString= '<div class="list previousTimeCardList">'+returnString+'</ul></div></div>';
		}
		
		return returnString;
	},
	//
	// ******************* CLOCK IN/OUT
	//
	clockInOrOut: function() {
		var $app= this;
		
		if (!$app.data.user.isClockedIn) {
			// CLOCK IN!!
			$app.methods.clockIn();
			
		} else {
			// CLOCK OUT!!
			$app.methods.clockOut();
			
		}
	},
	actualClockIn: function(scannedData,softClockReason) {
		var $app= this;
		
		if ((scannedData !== '') || (softClockReason !== '')) {
			
			$app.data.mdWebSocket.sendOverWebSocket({
				action: 'clockIn',
				scannedData: scannedData,
				softClockReason: softClockReason
			});
			
		} else {
			// TODO: alert error!
			
		}
		
	},
	clockIn: function() {
		var $app= this;
		
		if ($app.data.user.department !== 'Exec') {
			
			var clockInButtons= [];
			
			if (!$app.device.desktop) {
				clockInButtons.push({
					text: 'Scan in',
					onClick: function() {

						$app.methods.scanBarcode({
							success: function(result) {
								$app.methods.actualClockIn(result.data,'');
							}
						});

					}
				});
			}
			
			clockInButtons.push({
				text: 'Off Site Start Request',
				color: 'orange',
				onClick: function() {
					var tempDialog= $app.dialog.prompt('Please provide a reason for the soft clock instead of scanning in.','Reason',function(value) {
						if (value !== '') {
								
							// TODO: save value....
							// If blank need to ask again!
							
							$app.methods.actualClockIn('',value);
							
						} else {
							// TODO: ask again...
						}
						
					});
					var tempTimeout= setTimeout(function() {
						tempDialog.$el.find('input').focus();
					}, 0);
				}
			});
			
			clockInButtons.push({
				text: 'Cancel',
				color: 'red'
			});
			
			$app.dialog.create({
				title: 'Clock In',
				text: 'Ready to clock in?',
				buttons: clockInButtons,
				verticalButtons: true,
				destroyOnClose: true,
				closeByBackdropClick: true
		    }).open();
		    
	    } else {
		    // Just Clock in....
		    $app.methods.actualClockIn('','Exec');
	    }
	},
	clockOut: function() {
		var $app= this;
		
		// $app.dialog.confirm('Are you sure you want to clock out?','Clock Out',function() {
		
		if ($app.data.user.department !== 'Exec') {
		
			var clockOutButtons= [];
			
			var clockInButtons= [];
			
			if (!$app.device.desktop) {
				clockOutButtons.push({
					text: 'Scan out',
					onClick: function() {
						$app.methods.scanBarcode({
							success: function(result) {
								$app.methods.actualClockOut(result.data,'');
							}
						});
					}
				});
			}
			
			clockOutButtons.push({
				text: 'Off Site Stop Request',
				color: 'orange',
				onClick: function() {
					var tempDialog= $app.dialog.prompt('Please provide a reason for the soft clock instead of scanning out.','Reason',function(value) {
						if (value !== '') {
							// TODO: save value....
							// If blank need to ask again!
							$app.methods.actualClockOut('',value);
						} else {
							// TODO: ask again...
						}
					});
					
					// console.log('tempDialog',tempDialog);
					var tempTimeout= setTimeout(function() {
						tempDialog.$el.find('input').focus();
					}, 0);
					
				}
			});
			
			clockOutButtons.push({
				text: 'Cancel',
				color: 'red'
			});
		
			$app.dialog.create({
				title: 'Clock Out',
				text: 'Ready to clock out?',
				buttons: clockOutButtons,
				verticalButtons: true,
				destroyOnClose: true,
				closeByBackdropClick: true
		    }).open();
		    
		} else {
			$app.methods.actualClockOut('','Exec');
			
		}
	},
	actualClockOut: function(scannedData,softClockReason) {
		var $app= this;
		
		// console.log('TODO: actualClockOut',scannedData,softClockReason)
		
		if ((scannedData !== '') || (softClockReason !== '')) {
			
			$app.data.mdWebSocket.sendOverWebSocket({
				action: 'clockOut',
				scannedData: scannedData,
				softClockReason: softClockReason
			});
			
		} else {
			// TODO: alert error!
			
		}
		
		/*		
		$app.preloader.show();
		TM.timeclockEvent('clockout',{softClockReason: softClockReason, scannedData: scannedData},function(data) {
			$app.preloader.hide();
			if (data.ok) {
				$app.data.user.isClockedIn= data.isClockedIn;
				$app.data.user.timecards= data.timecards;
				$app.data.user.timecardsChangeRequests= data.timecardsChangeRequests;
				
				onSuccess();
				
			} else {
				$app.dialog.alert(data.error,'Error');
				
			}
		});
		*/
		
	},
	emitEventToApp: function(eventName,eventData) {
		var $app= this;
		eventData= ((typeof(eventData) !== 'undefined')?eventData:{});
		
		$app.emit(eventName, eventData);

		if (window.ipc) {
			if (window.ipc.send) {
				window.ipc.send(eventName, eventData);
			}
		}
	},
	barCodeEntered: function(result) {
		var $app= this;
		/*
		if (self.barcodeEntryInputKeyUpTimeout !== null) {
			clearTimeout(self.barcodeEntryInputKeyUpTimeout);
			self.barcodeEntryInputKeyUpTimeout= null;
		}
		*/
		$app.data.previousBarcodeEntryInputValue= '';
		result.event.target.value= '';
		result.event.target.classList.remove('input-with-value');

		// TODO: this should be true ONLY if auto-scanned....
		if (true) {/*((textValue.indexOf('https://bedroomsandmore.com/qr/') > -1)
				|| (textValue.indexOf('https://45thstreetbedding.com/qr/') > -1)) { */

				$app.emit('barcodeEntered',result);

		} else {
			// Invalid scan! // alert toast!
			
		}
		

		// console.log('Got here!',textValue,e,self);

	},
	autoKeepFocusInput: function(dom7Element,pageRequirement) {
		var $app= this;

		// self.removeAutoKeepFocusInput(dom7Element); // TODO: breaks the focus command...

		dom7Element.focus();

		dom7Element.on('blur',function () {
			if ($app.views.main.router.url == pageRequirement) {
				setTimeout(function() {
				dom7Element.focus();
				}, 10);
			}
		});

	},
	removeAutoKeepFocusInput: function(dom7Element) {
		var $app= this;
		
		// Cheap hack I am thinking is okay for simple inputs...
		dom7Element[0].outerHTML = dom7Element[0].outerHTML;
	},
	fixDraggableElements: function(containerElement) {
		var $app= this;

		// TODO: only if iOS 9 and using the polyfill...

		/*
		if (false) {//(typeof(containerElement) !== 'undefined') {
			containerElement.find('a:not([draggable])').attr('draggable','false');

		} else {
			$app.$('a:not([draggable])').attr('draggable','false');
			$app.$('button:not([draggable])').attr('draggable','false');
			
		}
		*/
	},
	printerListPopup: function(options) {
		var $app= this;

		options= ((typeof(options) !== 'undefined')?options:{});

		if (!options.hasOwnProperty('onSuccess')) {
			options.onSuccess= function() {};
		}

		if (!options.hasOwnProperty('onError')) {
			options.onError= function() {};
		}

		if (!options.hasOwnProperty('type')) {
			options.type= '';
		}

		if (!options.hasOwnProperty('targetEl')) {
			options.targetEl= '';
		}
		
		if (false) {//((options.type == 'popover') && (options.targetEl !== '')) {
			var oldHTML= $app.$(options.targetEl).html();
			$app.$(options.targetEl).html('<div class="preloader color-black"></div>');

		} else {
			$app.preloader.show();
		}
		$app.data.mdWebSocket.ajaxToServer({
			method: 'POST',
			showSpinner: false,
			url: '/4DACTION/api/listPrinters',
			data: {
				action: 'listPrinters'
			},
			timeout: 5000,
			success: function(data) {
				if (false) {//((options.type == 'popover') && (options.targetEl !== '')) {
					$app.$(options.targetEl).html(oldHTML);

				} else {
					$app.preloader.hide();
				}
				
				if (data.ok) {
					
					if (options.type == 'popover') {

						var popoverLinksString= '';
						if ($app.data.user.emailaddress !== '') {
							popoverLinksString+= '<li class="item-divider">Email</li>';
							popoverLinksString+= '<li><a class="list-button popover-close item-link" href="#" data-printerName="email:'+$app.data.user.emailaddress+'">Email Me ('+$app.data.user.name+')</a></li>';
						}
						if (data.altnames.length > 0) {
							popoverLinksString+= '<li class="item-divider">Printers</li>';
							for (var i= 0; i< data.altnames.length; i++) {
								popoverLinksString+= '<li><a class="list-button popover-close item-link" href="#" data-printerName="'+data.names[i]+'">'+data.altnames[i]+'</a></li>';
							}
						}
						var dynamicPopover= $app.popover.create({
							targetEl: options.targetEl,
							content: '<div class="popover">'+
										'<div class="popover-inner">'+
										  '<div class="list">'+
											'<ul>'+
												popoverLinksString+
											'</ul>'+
										  '</div>'+
										'</div>'+
									  '</div>',
							// Events
							on: {
							  open: function (popover) {

								popover.$el.on('click','.item-link',function(e) {
									var thisLink= popover.app.$(e.target);
									options.onSuccess(thisLink.attr('data-printerName'),thisLink.text());
								});

							  },
							  closed: function (popover) {
								var tempTimeout= setTimeout(function() {
									popover.destroy();
								}, 100);
							  }
							}
						  }).open();
					} else {
						
						var printButtons= [];
						
						if ($app.data.user.emailaddress !== '') {
							printButtons.push({
								text: 'Email Me ('+$app.data.user.name+')',
								actualPrinterName: 'email:'+$app.data.user.emailaddress,
								optionsObject: options,
								onClick: function() {
									var thisButton= this;
									thisButton.optionsObject.onSuccess(thisButton.actualPrinterName,thisButton.text);
								}
							});
						}
						
						for (var i= 0; i< data.altnames.length; i++) {
							printButtons.push({
								text: data.altnames[i],
								actualPrinterName: data.names[i],
								optionsObject: options,
								onClick: function() {
									var thisButton= this;
									thisButton.optionsObject.onSuccess(thisButton.actualPrinterName,thisButton.text);
								}
							});
						}

						printButtons.push({
							text: 'Cancel',
							color: 'red',
							optionsObject: options,
							onClick: function() {
								var thisButton= this;
								thisButton.optionsObject.onError('User Pressed the Cancel button',thisButton.text);
							}
						});
						
						$app.dialog.create({
							title: 'Choose Printer:',
							buttons: printButtons,
							verticalButtons: true,
							destroyOnClose: true,
							closeByBackdropClick: true
						}).open();
					}
					
					
				} else {
					// TODO: alert error...
					
				}
			},
			error: function(data) {
				$app.preloader.hide();
				// TODO: alert error...
				options.onError('Error ajax timed out.');
			}
		});
	},
	stopActiveCallsTimer: function() {
		var $app= this;
		
		if ($app.data.activeCallsTimer !== null) {
			clearTimeout($app.data.activeCallsTimer);
			$app.data.activeCallsTimer= null;
		}
		
	},
	startActiveCallsTimer: function() {
		var $app= this;
		
		if ($app.data.activeCallsTimer !== null) {
			$app.methods.stopActiveCallsTimer();
			
		}
		
		$$('.secondsText').each(function(e) {
			var thisItem= $$(this);
			
			var rightNow= new Date();
			var thenDate= new Date(0);
			thenDate.setUTCSeconds(thisItem.attr('data-epoch'));
			
			var tempSeconds=Math.floor((rightNow-thenDate)/1000);
			
			var message= '';
			if (false) {//(tempSeconds<60) {
				message= tempSeconds+'s';
				
			} else {
				var hours = Math.floor(tempSeconds / 3600);
				tempSeconds -= hours * 3600;
				var minutes = Math.floor(tempSeconds / 60);
				tempSeconds -= minutes * 60;
				var seconds = parseInt(tempSeconds % 60, 10);
				if (hours > 0) {
					if (hours< 10) { hours= '0'+hours; }
					hours= hours+':';
				} else {
					hours= '';
				}
				if (minutes< 10) { minutes= '0'+minutes; }
				minutes= minutes+':';
				if (seconds< 10) { seconds= '0'+seconds; }
				
				message= hours+minutes+seconds;
			}
			
			thisItem.text(message);
		});
		
		$app.data.activeCallsTimer= setTimeout(function() {
			$app.methods.startActiveCallsTimer();
		}, 1000); // every second...
		
	},
	splitUpColorRGBs: function(incomingString) {
		var responseArray= [];
		
		if (incomingString.indexOf('#') == 0) {
			responseArray.push(parseInt(incomingString.substring(1,3),16));
			responseArray.push(parseInt(incomingString.substring(3,5),16));
			responseArray.push(parseInt(incomingString.substring(5,7),16));
			
		} else if (incomingString.indexOf('rgb') == 0) {
			// TODO, split it up...
			
		}
		
		return responseArray;
	},
	formatPhoneNumber: function(tel) {
		var $app= this;
		
		var toString= String(tel),
		    phoneNumber= toString.replace(/[^0-9]/g, "");
	    	    
		if ((phoneNumber.length == 11) && (phoneNumber[0] == '1')) {
			phoneNumber= phoneNumber.substr(1);
			
		}
	    
		if (phoneNumber.length == 7) {
			phoneNumber= '206'+phoneNumber;
			
		}
		
		var countArrayStr = phoneNumber.split(""),
		    numberVar= countArrayStr.length,
		    closeStr= countArrayStr.join("");
		    
		    /*
		    console.log('toString',toString);
		    console.log('phoneNumber',phoneNumber);
		    console.log('countArrayStr',countArrayStr);
		    console.log('numberVar',numberVar);
		    console.log('closeStr',closeStr);
		    */
		    
		if (numberVar == 10) {
		    var phone = closeStr.replace(/(\d{3})(\d{3})(\d{4})/, "$1.$2.$3"); // Change number symbols here for numbers 10 digits in length. Just change the periods to what ever is needed.
		    
		} else if (numberVar > 10) {
		    var howMany = closeStr.length,
		        subtract = (10 - howMany),
		        phoneBeginning = closeStr.slice(0, subtract),
		        phoneExtention = closeStr.slice(subtract),
		        disX = "x", // Change the extension symbol here
		        phoneBeginningReplace = phoneBeginning.replace(/(\d{3})(\d{3})(\d{4})/, "$1.$2.$3"), // Change number symbols here for numbers greater than 10 digits in length. Just change the periods and to what ever is needed. 
		        array = [phoneBeginningReplace, disX, phoneExtention],
		        afterarray = array.splice(1, 0, " "),
		        phone = array.join("");
		
		} else {
		    var phone= tel; // Couldn't do anything to it...
		    
		}
		
		return phone;
		//return libphonenumber.format(phoneNumber, 'US', 'National');
	},
	getTextWidth: function(textToCheck) {
		var $app= this;
		
		textToCheck= ((typeof(textToCheck) !== 'undefined') ? textToCheck: '');
		
		textToCheck+= '';
		
		if (!$app.data.tempDiv) {
			$app.data.tempDiv= $app.$('#textWidthDiv');
		}
		
		$app.data.tempDiv.html(textToCheck);
		
		return $app.data.tempDiv[0].offsetWidth;
	},
	buildFormHTMLFromJSON: function(formJSONObject,formOptions) {
		var $app= this;

		formOptions= ((typeof(formOptions) == 'object')?formOptions:{});

		var returnHTML= '';

		if ((!formOptions.name) || (formOptions.name == '')) {
			formOptions.name= ('form'+Math.random()); // TODO: random based off of the date/time as well?
		}

		returnHTML+= '<form name="'+formOptions.name+'" class="list no-margin-top '+formOptions.name+'">'+
						'<ul>';

		for (var i= 0; i< formJSONObject.length; i++) {
			returnHTML+= $app.methods.buildFormHTMLFromJSONHelper(formJSONObject[i]);
		}

		returnHTML+= 	'</ul>'+
					 '</form>';

		// console.log('returnHTML',returnHTML);
		return returnHTML;
	},
	buildFormHTMLFromJSONHelper: function(formElementJSONObject,options) {
		var $app= this;
		options= ((typeof(options) == 'object')?options:{ level: 0 });

		var returnHTML= '';
		if (formElementJSONObject.type == 'row') {
			returnHTML+= '<li class="item-content">'+
							'<div class="item-inner item-cell">'+
								'<div class="item-row">';

			for (var i= 0; i< formElementJSONObject.items.length; i++) {
				returnHTML+= '<div class="item-cell">';
				returnHTML+= $app.methods.buildFormHTMLFromJSONHelper(formElementJSONObject.items[i],{inARow: true}); // TODO: inARow should be ADDED to the options...
				returnHTML+= '</div>';
			}

			returnHTML+= 		'</div>'+
							'</div>'+
						'</li>';
			// end a row...
		} else {
			returnHTML= '';
			if (!options.inARow) {
				returnHTML+= '<li class="item-content item-input">';
			}

			returnHTML+=(((formElementJSONObject.icon) && (formElementJSONObject.icon !== '') && (!options.inARow))?'<div class="item-media"><i class="icon f7-icons">'+formElementJSONObject.icon+'<i></div>':'')+
						'<div class="'+((options.inARow)?'item-input':'item-inner')+'">';
			
			if (formElementJSONObject.type == 'checkbox') {
				returnHTML+='<label class="item-input-wrap checkbox" for="'+formElementJSONObject.name+'">'+
								'<input type="'+formElementJSONObject.type+'"'+((formElementJSONObject.htmlClass)?' class="'+formElementJSONObject.htmlClass+'"':'')+' id="'+formElementJSONObject.name+'" name="'+formElementJSONObject.name+'" value="'+((formElementJSONObject.value)?formElementJSONObject.value:'')+'"'+((formElementJSONObject.checked)?' checked':'')+'><i class="icon icon-checkbox" style="display: inline-block;"></i> '+((formElementJSONObject.title)?formElementJSONObject.title:'&nbsp;')+
							'</label>';

			} else {
				returnHTML+='<div class="item-title item-label">'+(((formElementJSONObject.icon) && (formElementJSONObject.icon !== '') && (options.inARow))?'<i class="icon f7-icons">'+formElementJSONObject.icon+'<i> ':'')+((formElementJSONObject.title)?formElementJSONObject.title:'&nbsp;')+'</div>'+
							'<div class="item-input-wrap">'+
								'<input type="'+formElementJSONObject.type+'"'+((formElementJSONObject.htmlClass)?' class="'+formElementJSONObject.htmlClass+'"':'')+' name="'+formElementJSONObject.name+'" value="'+((formElementJSONObject.value)?formElementJSONObject.value:'')+'"'+((formElementJSONObject.placeholder)?' placeholder="'+formElementJSONObject.placeholder+'"':'')+'>'+
								((formElementJSONObject.showClearButton)?'<span class="input-clear-button"></span>':'')+
							'</div>';
			}
			
			returnHTML+='</div>';
			
			if (!options.inARow) {
				returnHTML+= '</li>';
			}
			
		}
		
		// console.log('SINGLE ITEM:',returnHTML);
		return returnHTML;
	},
	mdChoiceList: function(options,onSuccess,onCancel) {
		var $app= this;
		
		options= ((typeof(options) == 'object')?options:{});
		onSuccess= ((typeof(onSuccess) == 'function')?onSuccess:function() {});
		onCancel= ((typeof(onCancel) == 'function')?onCancel:function() {});

		if (!options.title) { options.title= 'ChoiceList';}
		if (!options.on) { options.on= {}; }
		if (!options.array) { options.array= []; }

		var getRandomNumbers= function() {
			const typedArray = new Uint8Array(10);
			const randomValues = window.crypto.getRandomValues(typedArray);
			return randomValues.join('');
		};
		var randomNumber = getRandomNumbers();

		var popupContent= '<div class="popup popupChoiceList'+randomNumber+'">'+
												'<div class="view">'+
													'<div class="page pageChoiceList" data-name="ChoiceList">'+
														'<div class="navbar">'+
															'<div class="navbar-bg"></div>'+
															'<div class="navbar-inner">'+
																'<div class="left">'+
																	((!$app.device.md)?'<a href="#" class="link popup-close" data-popup=".popupChoiceList'+randomNumber+'">Close</a>':'')+
																	(($app.device.md)?'<a href="#" class="link icon-only popup-close" data-popup=".popupChoiceList'+randomNumber+'"><i class="icon icon-back"></i></a>':'')+
																'</div>'+
																'<div class="title sliding">'+options.title+'</div>'+
															'</div>'+
														'</div>'+
														'<div class="page-content">'+
															'<div class="list media-list no-margin">'+
																'<ul>';
		for (var i= 0; i< options.array.length; i++) {
			popupContent+='<li>'+
											'<a href="#" data-index="'+i+'" class="item-link item-content item-choicelist'+randomNumber+'">';
			
			if (options.array[i].media) {
				popupContent+= 	'<div class="item-media">'+options.array[i].media+'</div>';
			}
			popupContent+= 		'<div class="item-inner">';

			if ((options.array[i].title) || (options.array[i].after)) {
				popupContent+=			'<div class="item-title-row">';
				if (options.array[i].title) {
					popupContent+=				'<div class="item-title">'+options.array[i].title+'</div>';
				}
				if (options.array[i].after) {
					popupContent+=				'<div class="item-after" style="display: unset;">'+options.array[i].after+'</div>';
				}
				popupContent+=			'</div>';
			}
			if (options.array[i].subtitle) {
				popupContent+= 			'<div class="item-subtitle">'+options.array[i].subtitle+'</div>';
			}
			if (options.array[i].text) {
				popupContent+= 			'<div class="item-text">'+options.array[i].text+'</div>';
			}
			popupContent+= 		'</div>'+
											'</a>'+
										'</li>';
		}

		
			popupContent+= 						'</ul>'+
															'</div>'+
														'</div>'+
													'</div>'+
												'</div>'+
											'</div>';

		var functionOnClick= function(e) {
			console.log('e',e);
			var thisATag= $app.$(e.target).closest('a.item-choicelist'+randomNumber);
			var indexPicked= parseInt(thisATag.attr('data-index'));

			var popupElementVariable= $app.popup.get('.popupChoiceList'+randomNumber);
			
			popupElementVariable.el.indexPicked= indexPicked;
			// onSuccess,onCancel
			$app.popup.close('.popupChoiceList'+randomNumber);
			//self.relatedFilesPopup.open(parseInt(relatedImageElement.attr('data-index')));
		};

		// options.on
		var dynamicPopup = $app.popup.create({
			content: popupContent,
			on: {
				opened: function (popup) {
					
				},
				open: function (popup) {
					popup.$el.on('click','a.item-choicelist'+randomNumber,functionOnClick);
				},
				close: function (popup) {
					popup.$el.off('click','a.item-choicelist'+randomNumber,functionOnClick);
					
				},
				closed: function (popup) {
					
					if ((popup.el.indexPicked !== undefined) && (popup.el.indexPicked >= 0)) {
						onSuccess(popup.el.indexPicked);

					} else {
						onCancel();

					}
					
				}
			}
		}).open();

	},
	array_move: function(arr, old_index, new_index) {
	    while (old_index < 0) {
	        old_index += arr.length;
	    }
	    while (new_index < 0) {
	        new_index += arr.length;
	    }
	    if (new_index >= arr.length) {
	        var k = new_index - arr.length + 1;
	        while (k--) {
	            arr.push(undefined);
	        }
	    }
	    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
	    //return arr; // for testing purposes
	},
	array_dynamicSort: function(property) {
	    var sortOrder = 1;
	
	    if(property[0] === "-") {
	        sortOrder = -1;
	        property = property.substr(1);
	    }
	
	    return function (a,b) {
	        if(sortOrder == -1){
	            return b[property].localeCompare(a[property]);
	        }else{
	            return a[property].localeCompare(b[property]);
	        }        
	    }
	},
	array_sumProperty: function(array,propName) {
	    var total= 0;
	    for (var i= 0; i < array.length; i++) {
	        total+= array[i][propName];
	    }
	    return total;
	},
	object_isEquivalent: function(a, b) {
	    // Create arrays of property names
	    var aProps = Object.getOwnPropertyNames(a);
	    var bProps = Object.getOwnPropertyNames(b);
	
	    // If number of properties is different,
	    // objects are not equivalent
	    if (aProps.length != bProps.length) {
	        return false;
	    }
	
	    for (var i = 0; i < aProps.length; i++) {
	        var propName = aProps[i];
	
	        // If values of same property are not equal,
	        // objects are not equivalent
	        if (a[propName] !== b[propName]) {
	            return false;
	        }
	    }
	
	    // If we made it this far, objects
	    // are considered equivalent
	    return true;
	},
	object_getJSONOnly: function(obj) {
		var $app= this;

		var result = {}, _tmp;
		for (var i in obj) {
			// enabledPlugin is too nested, also skip functions
			if (i === 'enabledPlugin' || typeof obj[i] === 'function') {
				continue;
			} else if (typeof obj[i] === 'object') {
				// get props recursively
				_tmp = $app.methods.object_getJSONOnly(obj[i]);
				// if object is not {}
				if (Object.keys(_tmp).length) {
					result[i] = _tmp;
				}
			} else {
				// string, number or boolean
				result[i] = obj[i];
			}
		}
		return result;
	},
	datesAreSameDay: function(d1, d2, useUTCTime) {
		useUTCTime= (typeof(useUTCTime) == 'boolean') ? useUTCTime: true;
		
		// UTC is default so they are compared with the same timezone...
		
		useUTCTime= false; // UTC time doesn't work... investigate...
		
		if (useUTCTime) {
			return d1.getUTCFullYear() === d2.getUTCFullYear() &&
				    d1.getUTCMonth() === d2.getUTCMonth() &&
				    d1.getUTCDate() === d2.getUTCDate();
		} else {
			return d1.getFullYear() === d2.getFullYear() &&
				    d1.getMonth() === d2.getMonth() &&
				    d1.getDate() === d2.getDate();
		}
	},
	days_between: function(date1, date2) {
	    // The number of milliseconds in one day
	    var ONE_DAY= 1000 * 60 * 60 * 24;
	    // Convert both dates to milliseconds
	    var date1_ms= date1.getTime();
	    var date2_ms= date2.getTime();
	    // Calculate the difference in milliseconds
	    var difference_ms= Math.abs(date1_ms-date2_ms);
	    // Convert back to days and return
	    return Math.round(difference_ms/ONE_DAY);
	},
	getWeekNumber: function(d,dowOffset) {
		dowOffset= ((typeof(dowOffset) == 'int') ? dowOffset : 0); //default dowOffset to zero (ie: sunday)
		if (false) {
			var newYear = new Date(d.getFullYear(),0,1);
			var day = newYear.getDay() - dowOffset; //the day of week the year begins on
			day = (day >= 0 ? day : day + 7);
			var daynum = Math.floor((d.getTime() - newYear.getTime() - 
			(d.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;
			var weeknum;
			//if the year starts before the middle of a week
			if(day < 4) {
				weeknum = Math.floor((daynum+day-1)/7) + 1;
				if(weeknum > 52) {
					var nYear = new Date(d.getFullYear() + 1,0,1);
					var nday = nYear.getDay() - dowOffset;
					nday = nday >= 0 ? nday : nday + 7;
					/*if the next year starts before the middle of
					the week, it is week #1 of that year*/
					weeknum = nday < 4 ? 1 : 53;
				}
			}
			else {
				weeknum = Math.floor((daynum+day-1)/7);
			}
			return [newYear.getUTCFullYear(), weeknum];
		} else {

			var onejan = new Date(d.getFullYear(), 0, 1);
			var weekNo = Math.ceil((((d - onejan) / 86400000) + onejan.getDay() + 1) / 7);

			// console.log('RETURNING: ',[d.getFullYear(), weekNo]);
			return [d.getFullYear(), weekNo];
		}

	},
	parseTimeStringIntoPickerArray: function(timeString,returnMilitaryTime) {
		var hourNumber= 8;
		var minuteNumber= 0;
		var amOrPMString= 'AM';

		returnMilitaryTime= ((typeof(returnMilitaryTime) == 'boolean')?returnMilitaryTime:false);

		var tempSplitStringArray= timeString.split(':');
		if (tempSplitStringArray.length> 1) {
			// ex '00:00' OR '00:00AM' OR '00:00:00' OR '00:00:00AM'

			hourNumber= parseInt(tempSplitStringArray[0]);
			minuteNumber= parseInt(tempSplitStringArray[1]);
			var isMilitaryTime= ((hourNumber == 0) || (hourNumber>12));

			console.log(hourNumber,minuteNumber);

			var startIsAM= true; // Assume true until told otherwise
			if ((!isMilitaryTime) && (tempSplitStringArray[(tempSplitStringArray.length-1)].toLowerCase().indexOf('p')>-1)) {
				startIsAM= false;
			}
			if (startIsAM) {
				if ((isMilitaryTime) && (hourNumber>11)) {
					startIsAM= false;
				}
			}

			console.log(startIsAM);
			
			if ((isMilitaryTime) && (hourNumber == 0)) {
				hourNumber= 12;
			} else if ((isMilitaryTime) && (hourNumber > 12)) {
				hourNumber-= 12;
			}

			amOrPMString= ((startIsAM)?'AM':'PM');

		} else {
			// TODO: error
			hourNumber= '00';
			minuteNumber= '00';
			amOrPMString= 'AM';

		}

		if (returnMilitaryTime) {
			if (((amOrPMString.toLowerCase().indexOf('p') > -1) && (hourNumber < 12))
				|| ((amOrPMString.toLowerCase().indexOf('a') > -1) && (hourNumber == 12))) {
				hourNumber+=12;
			}

			if (hourNumber == 24) {
				hourNumber= 0;
			}

			return [('00'+hourNumber).substr(-2),('00'+minuteNumber).substr(-2)];
		}

		// TODO: pad hour for non military time as well? return [('00'+hourNumber).substr(-2),('00'+minuteNumber).substr(-2),amOrPMString];
		return [(''+hourNumber),('00'+minuteNumber).substr(-2),amOrPMString];
	},
	LightenDarkenColor: function(col, amt) {
		return '#' + col.slice(1).match(/../g).map(function (x) {
		  return (x = +"0x".concat(x) + amt, x < 0 ? 0 : x > 255 ? 255 : x).toString(16).padStart(2, 0);
		}).join('');
	},
	lightOrDark: function(color) {
		var r,g,b,hsp;

		// Check the format of the color, HEX or RGB?
		if (color.match(/^rgb/)) {
	  
		  // If HEX --> store the red, green, blue values in separate variables
		  color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
	  
		  r = color[1];
		  g = color[2];
		  b = color[3];
		} 
		else {
	  
		  // If RGB --> Convert it to HEX: http://gist.github.com/983661
		  color = +("0x" + color.slice(1).replace( 
			color.length < 5 && /./g, '$&$&'
		  )
				   );
	  
		  r = color >> 16;
		  g = color >> 8 & 255;
		  b = color & 255;
		}
	  
		// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
		hsp = Math.sqrt(
		  0.299 * (r * r) +
		  0.587 * (g * g) +
		  0.114 * (b * b)
		);
	  
		// Using the HSP value, determine whether the color is light or dark
		if (hsp>127.5) { return 'light'; }  else { return 'dark'; }
	},
	getFirstDayOfTheWeek: function(incomingDate,dowOffset) {
		dowOffset= ((typeof(dowOffset) == 'int') ? dowOffset : 0); //default dowOffset to zero (ie: sunday)
		var newDate= new Date(incomingDate.getTime());
		var day= newDate.getDay();
	    if( day !== (dowOffset) ) {
			newDate.setHours(-24 * (day)); // TODO: dowOffset
		}
	    return newDate;
	},
	getLastDayOfTheWeek: function(incomingDate,dowOffset) {
		dowOffset= ((typeof(dowOffset) == 'int') ? dowOffset : 0); //default dowOffset to zero (ie: sunday)
		var newDate= new Date(incomingDate.getTime());
		var day= newDate.getDay();
	    if( day !== 6 ) // TODO: dowOffset
	        newDate.setHours(24*(6-day)); // TODO: dowOffset
	    return newDate;
	},
	matchRuleShort: function(str, rule) {
		var escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
		return new RegExp("^" + rule.split("*").map(escapeRegex).join(".*") + "$").test(str);
	},
	externalURLsToLinks: function(string){
		var urls= string.match(/(((ftp|https?):\/\/)[\-\w@:%_\+.~#?,&\/\/=]+)/g);
		if (urls) {
			urls.forEach(function (url) {
				string= string.replace(url, '<a class="color-blue external" target="_system" href="'+url+'">'+url+"</a>");
			});
		}
		return string.replace("(", "<br/>(");
	}
};

export default methods;
