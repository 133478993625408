
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
    self.$app.on('barcodeEntered', self.barcodeEntered);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
    self.$app.off('barcodeEntered', self.barcodeEntered);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;
    },
    barcodeEntered: function (result) {
      var self = this;
      var barcodeText = result.text;
      var e = result.event;

      if (e.target.id == 'homePageKeyboardInput') {
        barcodeText = barcodeText.replace('https://bedroomsandmore.com/qr/', '').replace('https://45thstreetbedding.com/qr/', '');
        self.$root.removeAutoKeepFocusInput(self.$('#homePageKeyboardInput'));
        self.$app.dialog.alert(barcodeText, 'Barcode Scanned', function () {
          self.$root.autoKeepFocusInput(self.$('#homePageKeyboardInput'), '/');
        });
      }
    },
    scanWithCamera: function () {
      var self = this;
      self.$app.dialog.alert('Scan with camera!', 'TODO');
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
    },
    pageBeforeOut: function (e, page) {
      var self = this;
      self.$root.removeAutoKeepFocusInput(self.$('#homePageKeyboardInput'));
    },
    pageAfterIn: function (e, page) {
      var self = this;
      self.$root.autoKeepFocusInput(self.$('#homePageKeyboardInput'), '/');
    }
  },
  id: 'aefe4a6288',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page" data-name="home">\n    <!-- Top Navbar -->\n    <div class="navbar">\n      <div class="navbar-bg"></div>\n      <div class="navbar-inner">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n        <div class="title sliding">TaskMaster Scanner</div>\n        <!--\n        <div class="right">\n          <a href="#" class="link icon-only panel-open" data-panel="right">\n            <i class="icon f7-icons if-not-md">menu</i>\n            <i class="icon material-icons if-md">menu</i>\n          </a>\n        </div>\n        -->\n      </div>\n    </div>\n    <div class="page-content">\n      <div class="block block-strong text-align-center no-margin';
      r += Template7Helpers.js_if.call(ctx_1, "this.$app.theme == 'md'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' no-padding-bottom';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n        <div class="row">\n          <div class="col-100" style="position: relative;';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.hasCamera, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' min-height: var(--f7-button-large-height);';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n            <input type="text" id="homePageKeyboardInput" class="barcodeEntryInput';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.hasCamera, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' barcodeEntryWithCameraButton';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" value="" placeholder="Barcode Entry">\n            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.hasCamera, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n            <a href="#" class="button icon-only button-fill button-large button-inline" @click="scanWithCamera" style="position: absolute; top: 0px; right: 0px;"><i class="icon f7-icons">barcode</i></a>\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n          </div>\n          <div class="col-100">\n            <a href="#" class="button button-large button-inline mainButton"><i class="icon f7-icons if-not-md">search</i><i class="icon material-icons if-md">search</i><span>Search Inventory</span></a>\n          </div>\n        </div>\n      </div>\n      <div class="list links-list no-margin-top">\n        <ul>\n          <li class="item-divider">Inventory Control</li>\n          <li><a href="/tmscan/buckets/">Scanning Buckets</a></li>\n          <li><a href="/tmscan/transfers/">Transfers</a></li>\n        </ul>\n      </div>\n    </div>\n  </div>\n';
      return r;
    }(this);
  },

  style: `
  .mainButton {
    width: 100%;
    max-width: 250px;
  }
`,
  styleScoped: false
};
    