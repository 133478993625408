
import NotFoundPage from '../pages/404.f7.html';

import LaunchScreen from '../pages/launchscreen.f7.html';
import BugReportPopup from '../pages/bugreport.f7.html';
import Settings from '../pages/settings.f7.html'; // App Specific
import SettingsPopup from '../pages/settingsPopup.f7.html'; // Company Specific
import AboutPage from '../pages/about.f7.html';
import EmailPage from '../pages/email.f7.html';
import MessagesPage from '../pages/messages.f7.html';
import ConversationPage from '../pages/messagesConversation.f7.html';
import PhonePage from '../pages/phone.f7.html';
import PhoneInCallPopup from '../pages/phoneInCall.f7.html';

import CustomerTextsListPage from '../pages/customerTextsList.f7.html';
import CustomerTextsConversationPage from '../pages/customerTextsConversation.f7.html';

import SalesUpListPopup from '../pages/salesUpListNEW.f7.html';
import ClockInOutPage from '../pages/clockInOut.f7.html';
import ClockInOutRequestFixPage from '../pages/clockInOut_RequestFix.f7.html';
import ReportsPage from '../pages/reports.f7.html';
import ReportsPickPage from '../pages/reportsPick.f7.html';
import ReportsSinglePage from '../pages/reportsSingle.f7.html';
import SecurityPage from '../pages/security.f7.html';
import SecurityNewPage from '../pages/securityNEW.f7.html';
import CalendarPage from '../pages/calendar.f7.html';
import CalendarEventPopup from '../pages/calendarEvent.f7.html';
import TasksPage from '../pages/tasks.f7.html';
import MemosPage from '../pages/memos.f7.html';
import MemosDetailPopup from '../pages/memosDetail.f7.html';
import TMAdminPage from '../pages/tmAdmin.f7.html';
import EmployeesPage from '../pages/employees.f7.html';
import EmployeesDetailPage from '../pages/employeesDetail.f7.html';
import HRPage from '../pages/hr.f7.html';
import PriceTagsPage from '../pages/priceTags.f7.html';
import PriceTagsPrintPage from '../pages/priceTagsPrint.f7.html';
import DevicesPage from '../pages/devices.f7.html';
//import SalesPayonPage from '../pages/salesPayon.f7.html';
import SyncDrivePage from '../pages/syncDrive.f7.html';
import NextCloudPage from '../pages/nextcloud.f7.html';
import LeaveMessagePage from '../pages/leaveMessage.f7.html';
import WorkSchedulePage from '../pages/workSchedule.f7.html';
import TaskMasterPage from '../pages/taskMaster.f7.html';
import TaskMasterTablePage from '../pages/taskMaster_tables.f7.html';
import TaskMasterTableOutputPage from  '../pages/taskMaster_tablesOutput.f7.html';
import TaskMasterTableInputPage from  '../pages/taskMaster_tablesInput.f7.html';
import TaskMasterServerPage from '../pages/taskMasterServer.f7.html';
import TaskMasterInvoicePage from '../pages/taskMaster_Invoice.f7.html';
import TaskMasterInvoiceHistoryPopup from '../pages/taskMaster_InvoiceHistory.f7.html';
import TaskMasterInvoiceCommissionPopup from '../pages/taskMaster_InvoiceCommission.f7.html';
import TaskMasterLeftPanel from '../pages/taskMaster_leftPanel.f7.html';
import TaskMasterSearchPopup from '../pages/taskMaster_search.f7.html';
import TaskMasterSearchResultsPage from '../pages/taskMaster_searchResults.f7.html';
import AppRequestsPage from '../pages/appRequests.f7.html';


import TMScanPage from '../pages/tmScan.f7.html';
import TMTransfersPage from '../pages/tmTransfers.f7.html';
import TMBucketListPage from '../pages/tmBucketList.f7.html';
import TMBucketScanPage from '../pages/tmBucketScan.f7.html';


var routes= [
	{
		path: '/',
		component: LaunchScreen
	},
	{
    path: '/index.html',
    redirect: '/',
  },
	{
		path: '/bugreport/',
		popup: {
			component: BugReportPopup
		}
	},
	{
		path: '/settings/',
		component: Settings
	},
	{
		path: '/settings/:id/',
		popup: {
			component: SettingsPopup
		}
	},
	{
		path: '/about/',
		component: AboutPage
	},
	{
		path: '/messages/',
		component: MessagesPage
	},
	{
		path: '/messages/:id/:name/',
		component: ConversationPage
	},
	{
		path: '/phone/',
		component: PhonePage
	},
	{
		path: '/customerTexts/',
		component: CustomerTextsListPage,
		routes: [
			{
				path: ':number/:name/',
				component: CustomerTextsConversationPage
			}
		]
	},
	{
		path: '/phoneInCall/',
		popup: {
			component: PhoneInCallPopup
		}
	},
	{
		path: '/email/',
		component: EmailPage
	},
	{
		path: '/uplist/',
		popup: {
			component: SalesUpListPopup
		}
	},
	{
		path: '/taskmaster/',
		component: TaskMasterPage,
		routes: [
			{
				path: 'leftPanel/',
				component: TaskMasterLeftPanel // This is just a page... The panel is Global on index.html
			},
			{
				path: 'server/',
				component: TaskMasterServerPage
			},
			{
				path: 'search/',
				redirect: '/taskmaster/search/invoices/'
			},
			{
				path: 'search/:searchType/',
				popup: {
					component: TaskMasterSearchPopup
				},
				routes: [
					{
						path: 'results/',
						component: TaskMasterSearchResultsPage
					}
				]
			},
			{
				path: 'tables/',
				component: TaskMasterTablePage,
				routes: [
					{
						path: ':tableNumber/',
						component: TaskMasterTableOutputPage,
						routes: [
							{
								path: ':recordId/',
								component: TaskMasterTableInputPage
							}
						]
					}
				]
			},
			{
				path: 'invoices/:invoiceNumber/',
				component: TaskMasterInvoicePage,
				routes: [
					{
						path: 'history/',
						popup: {
							component: TaskMasterInvoiceHistoryPopup
						}
					},
					{
						path: 'commission/',
						popup: {
							component: TaskMasterInvoiceCommissionPopup
						}
					}
				],
				beforeLeave: function (routeTo, routeFrom, resolve, reject) {
					var $app= this.app;

					// console.log('routeFrom',routeFrom);
					
					var changesMade= {};
					
					// TODO: get this working! Perhaps it needs to be done from a global method instead...
					//var changesMade= routeFrom.route.component.methods.getChangesMade();

					// console.log('changesMade',changesMade);
					
					if ((changesMade.old) && (Object.keys(changesMade.old).length > 0)) {
						$app.dialog.confirm('Are you sure you want to leave this invoice without saving?',
							function () {
								// proceed navigation
								resolve();
							},
							function () {
								// stay on page
								reject();
							}
						);
					} else {
						resolve();
					}
				}
			}
		]
	},
	{
		path: '/timeclock/',
		component: ClockInOutPage,
		routes: [
			{
				path: 'requestFix/:dateToFix/',
				//component: ClockInOutRequestFixPage
				async(routeTo, routeFrom, resolve, reject) {
					var thisRoute= this;
					thisRoute.app.dialog.alert('Requesting a fix on a timecard is not ready yet...','Not Ready');
					reject();
				}
			}
		]
	},
	{
		path: '/tasks/',
		component: TasksPage
	},
	{
		path: '/appRequests/',
		component: AppRequestsPage/*,
		routes: [
			{
				path: ':appRequestId/',

			}
		]*/  // TODO:
	},
	{
		path: '/memos/',
		component: MemosPage
	},
	{
		path: '/memos/:memoId/',
		popup: {
			component: MemosDetailPopup
		}
	},
	{
		path: '/tmAdmin/',
		component: TMAdminPage
	},
	{
		path: '/calendar/',
		component: CalendarPage
	},
	{
		path: '/calendar/:calendarId/event/:eventId/',
		popup: {
			component: CalendarEventPopup
		}
	},
	{
		path: '/workSchedule/',
		redirect: '/workSchedule/me/'
		//component: WorkSchedulePage
	},
	{
		path: '/workSchedule/:reference/',
		component: WorkSchedulePage/*
		async(routeTo, routeFrom, resolve, reject) {
			var thisRoute= this;

			if ((thisRoute.app.data.user.isAdmin)) {
				resolve({ component: WorkSchedulePage });
			} else {
				thisRoute.app.dialog.alert('Work Schedule has not been released yet...','Not Ready');
				reject();
			}
		}
		*/
	},
	{
		path: '/reports/',
		component: ReportsPage,
		routes: [
			{
				path: ':reportsCategory/',
				component: ReportsPickPage,
				routes: [
					{
						path: ':reportName/',
						component: ReportsSinglePage
					}
				]
			}
		]
	},
	{
		path: '/security/',
		component: SecurityNewPage
	},
	{
		path: '/employees/',
		component: EmployeesPage,
		routes: [
			{
				path: ':employeeId/',
				component: EmployeesDetailPage
			}
		]
	},
	{
		path: '/devices/',
		component: DevicesPage
	},
	{
		path: '/nextcloud/',
		component: NextCloudPage
	},
	{
		path: '/syncDrive/',
		component: SyncDrivePage
	},
	{
		path: '/leaveMessage/',
		component: LeaveMessagePage
	},
	{
		path: '/priceTags/',
		component: PriceTagsPage,
		routes: [
			{
				path: 'print/',
				component: PriceTagsPrintPage
			}
		]
	},
	{
		path: '/hr/',
		async(routeTo, routeFrom, resolve, reject) {
			var thisRoute= this;
			var tempDialogPassword= thisRoute.app.dialog.password('Re-enter your password to continue.',thisRoute.app.data.user.name,function(password) {
		    	thisRoute.app.preloader.show();
		    	
		    	// TODO: websocket...
				thisRoute.app.data.mdWebSocket.ajaxToServer({
					method: 'POST',
		            showSpinner: false,
		            url: '/4DACTION/api/verifyCurrentLogin',
		            data: {
			            action: 'verifyCurrentLogin',
			            password: password
			        },
		            timeout: 10000,
		            success: function(data) {
			            thisRoute.app.preloader.hide();
			            if (data.ok) {
		    				resolve({ component: HRPage });
		    				
				    	} else {
					    	// TODO: Error alert?
					    	reject();
					    	
				    	}
			        },
			        error: function(data) {
						thisRoute.app.preloader.hide();
						// TODO: Error alert?
						reject();
			        }
				});
		    	
	    	},function() {
		    	reject();
		    	
	    	});
	    	
	    	var tempTimeout= setTimeout(function() {
				tempDialogPassword.$el.find('input').focus();
				tempDialogPassword.$el.find('input')[0].select();
			}, 0);
		}
	},
	/*
	{
		path: '/salesPayon/',
		async(routeTo, routeFrom, resolve, reject) {
			var thisRoute= this;
			// Not specific to anyone as of 11/1/2020
			resolve({ component: SalesPayonPage });
		}
	},
	*/
	{
		path: '/tmscan/',
		component: TMScanPage,
	},
	{
		path: '/tmscan/transfers/',
		component: TMTransfersPage,
	},
	{
		path: '/tmscan/buckets/',
		component: TMBucketListPage,
		routes: [
			{
			path: '/:id/:name/',
			component: TMBucketScanPage
			}
		]
	},
	{
		path: '(.*)',
		//component: NotFoundPage
		redirect: '/'
	}
];

export default routes;
