var cordovaApp = {
  f7: null,
  /*
  This method hides splashscreen after 2 seconds
  */
  handleSplashscreen: function() {
    var f7 = cordovaApp.f7;
    if (!window.navigator.splashscreen || f7.device.electron) return;
    setTimeout(() => {
      window.navigator.splashscreen.hide();
    }, 2000);
  },
  /*
  This method prevents back button tap to exit from app on android.
  And allows to exit app on backbutton double tap
  */
  handleAndroidBackButton: function () {
    var f7 = cordovaApp.f7;
    const $ = f7.$;
    if (f7.device.electron) return;
    
    // TODO: make sure it's allowed to closed dialogs...

    document.addEventListener('backbutton', function (e) {
      if ($('.actions-modal.modal-in').length) {
        f7.actions.close('.actions-modal.modal-in');
        e.preventDefault();
        return false;
      }
      if ($('.dialog.modal-in').length) {
        f7.dialog.close('.dialog.modal-in');
        e.preventDefault();
        return false;
      }
      if ($('.sheet-modal.modal-in').length) {
        f7.sheet.close('.sheet-modal.modal-in');
        e.preventDefault();
        return false;
      }
      if ($('.popover.modal-in').length) {
        f7.popover.close('.popover.modal-in');
        e.preventDefault();
        return false;
      }
      if ($('.popup.modal-in').length) {
        if ($('.popup.modal-in>.view').length) {
          const currentView = f7.views.get('.popup.modal-in>.view');
          if (currentView && currentView.router && currentView.router.history.length > 1) {
            currentView.router.back();
            e.preventDefault();
            return false;
          }
        }
        f7.popup.close('.popup.modal-in');
        e.preventDefault();
        return false;
      }

      const currentView = f7.views.current;

      if (currentView && currentView.router && currentView.router.url == '/') {
        f7.dialog.confirm('Exit App?','Exit',function() {
          navigator.app.exitApp();
        });

      } else {
        if (currentView && currentView.router && currentView.router.history.length > 1) {
          currentView.router.back();
          e.preventDefault();
          return false;
        }
      }

      if ($('.panel.panel-in').length) {
        f7.panel.close('.panel.panel-in');
        e.preventDefault();
        return false;
      }
    }, false);


    /*
    cordovaApp.backButtonTimestamp = new Date().getTime();
    document.addEventListener('backbutton', function (e) {

      if (new Date().getTime() - cordovaApp.backButtonTimestamp < 250) {
        cordovaApp.backButtonTimestamp = new Date().getTime();
        if (window.navigator.app && window.navigator.app.exitApp) {
          window.navigator.app.exitApp();
        }
        return true;
      }

      cordovaApp.backButtonTimestamp = new Date().getTime();
      

      console.log('Back button!'); // TODO: go back a page until at the home screen...
      
      e.preventDefault();
      return false;
    }, false);
    */
  },
  handlePause: function() {
	  var pauseFunction= function(e) {
		  
			app.f7.data.appIsInBackground= true;
      
      if (!app.f7.data.settingUpPermissions) {
        if (!app.f7.data.phoneInCall) { // IF NOT IN A CALL...
          app.f7.methods.disconnectPhone();
        }
        app.f7.methods.closeWebSocket(function() {
					console.log('just closed websocket!');
				});
      }
		  
		  // console.log('PAUSE CALLED, app.f7.data.appIsInBackground:',app.f7.data.appIsInBackground);
		  
	  };
	  
	  // document.addEventListener('resign', pauseFunction,false); // iOS Only
	  document.addEventListener('pause', pauseFunction, false);
  },
  handleResume: function() {
	  
	  window.app.f7.data.inResumeFunction= false;
	  
	  var resumeFunction= function(e) {
		 	if (window.app.f7.data.inResumeFunction == false) {
			 	var tempTimeout= setTimeout(function() {
          window.app.f7.data.inResumeFunction= true;
          window.app.f7.data.appIsInBackground= false;
          
          window.app.f7.methods.checkIfOnline();
          window.app.f7.methods.checkForAppUpdate();

          // console.log('RESUME CALLED, app.f7.data.appIsInBackground:',app.f7.data.appIsInBackground);
          
          // console.log('RESUME CALLED');
          
          if (window.app.f7.data.PUSH) {
            // TODO: this doesn't work on ios 9... need to check newer devices as well...
            // clear all push notifications when you open the app..
            window.app.f7.data.PUSH.clearAllNotifications(function() {
              console.log('PUSH: Cleared all Notifications');
              
            }, function() {
              console.log('PUSH: error clearing all notifications');
              
            });
          }
          
          // app.f7.methods.connectWebSocket();
          // TODO: check if session is still valid...?  Or a timeout saying it couldn't establish websocket?
          
          var tempTimout= setTimeout(function() {
            window.app.f7.data.inResumeFunction= false;

          }, 1000);
        },0);
			}
	  };
	  
	  // document.addEventListener('active', resumeFunction,false); // iOS Only
	  document.addEventListener('resume', resumeFunction, false);
  },
  /*
  This method does the following:
    - provides cross-platform view "shrinking" on keyboard open/close
    - hides keyboard accessory bar for all inputs except where it required
  */
  handleKeyboard: function () {
    var f7 = cordovaApp.f7;
    if (!window.Keyboard || !window.Keyboard.shrinkView || f7.device.electron) return;
    var $ = f7.$;
    window.Keyboard.shrinkView(false);
    window.Keyboard.disableScrollingInShrinkView(true);
    window.Keyboard.hideFormAccessoryBar(true);
    window.addEventListener('keyboardWillShow', () => {
      f7.input.scrollIntoView(document.activeElement, 0, true, true);
    });
    window.addEventListener('keyboardDidShow', () => {
      f7.input.scrollIntoView(document.activeElement, 0, true, true);
    });
    window.addEventListener('keyboardDidHide', () => {
      if (document.activeElement && $(document.activeElement).parents('.messagebar').length) {
        return;
      }
      window.Keyboard.hideFormAccessoryBar(false);
    });
    window.addEventListener('keyboardHeightWillChange', (event) => {
      var keyboardHeight = event.keyboardHeight;
      if (keyboardHeight > 0) {
        // Keyboard is going to be opened
        document.body.style.height = `calc(100% - ${keyboardHeight}px)`;
        $('html').addClass('device-with-keyboard');
      } else {
        // Keyboard is going to be closed
        document.body.style.height = '';
        $('html').removeClass('device-with-keyboard');
      }

    });
    $(document).on('touchstart', 'input, textarea, select', function (e) {
      var nodeName = e.target.nodeName.toLowerCase();
      var type = e.target.type;
      var showForTypes = ['datetime-local', 'time', 'date', 'datetime'];
      if (nodeName === 'select' || showForTypes.indexOf(type) >= 0) {
        window.Keyboard.hideFormAccessoryBar(false);
      } else {
        window.Keyboard.hideFormAccessoryBar(true);
      }
    }, true);
  },
  init: function (f7) {
    // Save f7 instance
    cordovaApp.f7 = f7;
	
    // Handle Android back button
    cordovaApp.handleAndroidBackButton();
	
    // Handle Statusbar
    cordovaApp.handleSplashscreen();
	
    // Handle Keyboard
    cordovaApp.handleKeyboard();
    
    // Handle Pause
    cordovaApp.handlePause();
    
    // Handle Resume
    cordovaApp.handleResume();
        
  },
};
export default cordovaApp;
