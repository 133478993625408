
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.searchType = self.$route.params.searchType;
    self.searchRequestObject = self.$app.data.taskMasterData.searchResultRequest[self.searchType];
    self.$app.data.pageStatusData = {
      searchType: self.searchType,
      searchData: self.$app.data.taskMasterData.searchResultRequest[self.searchType].searchData,
      doSearch: true // TODO: find out if we can use local cached results for later...

    };
  },
  data: function () {
    return {
      searchType: '',
      searchRequestObject: {},
      searchResults: {},
      loading: true,
      actionOnClose: false
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'searchResults') {
        // TODO: IF it was this page specific search results....
        if (true) {
          self.searchResults = self.$app.data.taskMasterData.searchResults; // console.log('self.searchResults',self.searchResults);

          if (self.searchResults.array.length == 1) {
            self.clickToIndex(0, false); // Auto pick the only one there!
          } else {
            for (var i = 0; i < self.searchResults.array.length; i++) {
              self.searchResults.array[i].thisIndex = i;
            }

            self.$setState({
              loading: false
            }, function () {
              var itemTemplateText = '';
              var itemTemplateEachHeight = self.$app.theme === 'ios' ? 63 : self.$app.theme === 'md' ? 73 : 46;

              if (self.searchType == 'invoices') {
                itemTemplateEachHeight = self.$app.theme === 'ios' ? 79 : self.$app.theme === 'md' ? 89 : 62;
                itemTemplateText = '<li>' + '<a href="#" class="item-link item-content resultItem" data-invoice="{{invoiceNumber}}" data-results-index="{{thisIndex}}">' + '<div class="item-inner">' + '<div class="item-title-row">' + '<div class="item-title">{{invoiceNumber}}</div>' + '<div class="item-after">{{js "Number(this.balanceOwedAmount).formatMoney()"}}</div>' + '</div>' + '<div class="item-subtitle">{{customerLastName}}, {{customerFirstName}}</div>' + '<div class="item-text">{{invoiceDateTimeString}}</div>' + '</div>' + '</a>' + '</li>';
              } else if (self.searchType == 'inventory') {
                itemTemplateText = '<li>' + '<a href="#" class="item-link item-content resultItem" data-id="{{id}}" data-results-index="{{thisIndex}}">' + '<div class="item-inner">' + '<div class="item-title-row">' + '<div class="item-title">{{nameHTML}}</div>' + '<div class="item-after">{{js "Number(this.price).formatMoney()"}}</div>' + '</div>' + '<div class="item-text">{{id}}</div>' + '</div>' + '</a>' + '</li>';
              } else if (self.searchType == 'customers') {
                itemTemplateText = '<li>' + '<a href="#" class="item-link item-content resultItem" data-id="{{id}}" data-results-index="{{thisIndex}}">' + '<div class="item-inner">' + '<div class="item-title-row">' + '<div class="item-title">{{lastName}}, {{firstName}}</div>' + '</div>' + '</div>' + '</a>' + '</li>';
              }

              if (self.virtualList) {
                // TODO: is this okay?
                self.virtualList.params.itemTemplate = itemTemplateText;
                self.virtualList.replaceAllItems(self.searchResults.array);
              } else {
                self.virtualList = self.$app.virtualList.create({
                  // List Element
                  el: '.virtual-list',
                  // Pass array with items
                  items: self.searchResults.array,
                  // Custom search function for searchbar
                  searchAll: function (query, items) {
                    var found = [];

                    for (var i = 0; i < items.length; i++) {
                      if (items[i].title.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
                    }

                    return found; //return array with mathced indexes
                  },
                  // List item Template7 template
                  itemTemplate: itemTemplateText,
                  // Item height
                  height: itemTemplateEachHeight
                });
              }
            });
          }
        }
      }
    },
    clickToIndex: function (indexClicked, animatePopupAndPanel) {
      var self = this;
      animatePopupAndPanel = typeof animatePopupAndPanel !== 'undefined' ? animatePopupAndPanel : true;
      self.$app.popup.close('.taskmasterSearchPopup', animatePopupAndPanel);
      self.$app.panel.close('.panel-left', animatePopupAndPanel);
      var tempTimeout = setTimeout(function () {
        if (self.searchType == 'invoices') {
          self.$app.data.mainView.router.navigate('/taskmaster/invoices/' + self.searchResults.array[indexClicked].invoiceNumber + '/', {
            animate: false
          });
        } else if (self.searchType == 'inventory') {
          self.$app.dialog.alert('The Inventory pick stock is not live yet...', 'Not Ready'); // TODO
        } else if (self.searchType == 'customers') {
          self.$app.dialog.alert('The Customers Profile view is not live yet...', 'Not Ready'); // TODO
        } else {// error unkown type...
          }
      }, 600); // Not sure if this timing is right... it's mainly for transitions to finish...
    },
    refreshResults: function () {
      var self = this; // TODO!
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.$el.on('click', '.resultItem', function (e) {
        var thisATag = self.$(e.target).closest('a'); // console.log('CLICKED RESULT',thisATag.attr('data-results-index'),thisATag);

        self.clickToIndex(thisATag.attr('data-results-index'));
      });
    },
    pageBeforeOut: function (e, page) {
      var self = this;
      window.lastEventNameAndPage = ''; // This is to allow a research if necessary...
      // console.log('LEAVING SEARCH RESULTS PAGE');
    }
  },
  id: '4fd11ac7ed',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="searchResultsPage">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back" data-view=".searchView"><!-- TODO: need to force back to the search screen... -->\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title" style="text-transform: capitalize;">Results';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' (';
          r += Template7Helpers.js.call(ctx_2, "this.searchResults.array.length", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ')';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<!--\n					<a class="link icon-only searchbar-enable" data-searchbar=".searchResultsSearchBar">\n						<i class="icon f7-icons if-not-md">search</i>\n						<i class="icon material-icons md-only">search</i>\n					</a>\n					-->\n					<a href="#" class="link icon-only popup-close" data-popup=".taskmasterSearchPopup"><i class="icon material-icons">cancel</i></a>\n				</div>\n			</div>\n		</div>\n		<div class="page-content">\n			';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="block block-strong loadingBlock">\n				<p><div class="preloader"></div> Loading...</p>\n			</div>\n			';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="list media-list searchResultsList virtual-list no-margin">\n				\n			</div>\n			';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.loadingBlock {
		text-align: center;
	}

	.list.searchResultsList .item-title {
		white-space: normal;

	}
`,
  styleScoped: false
};
    