
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {
      useLazyLoading: false,
      dcViewAllowed: true,
      storeViewAllowed: true,
      timeoutInterval: null,
      newStorePopup: null,
      storePopup: null,
      //outletPopup: null,
      dcPopup: null,
      showExtraInfo: false
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this; // TODO: this should update when a device changes (and showExtraInfo == true)
      // This should update when cameraIds change...

      if (data.type == 'data' && data.data == 'cameras') {
        self.$update(function () {
          self.refreshPage();
        });
      }
    },
    restartZoneMinderServer: function () {
      var self = this;
      self.$app.dialog.confirm('Are you sure you want to restart the ZoneMinder camera proxy?  This is not harmful but you will not see any cameras in the app for about 4-5 minutes.', 'Restart Cameras?', function () {
        // self.$app.preloader.show();
        self.$root.mdWebSocket.sendOverWebSocket({
          action: 'restartZoneMinderServer'
        }); // If okay...

        self.$app.dialog.alert('Please allow up to 4 to 5 minutes for the cameras to come back online.', 'Restarted');
      });
    },
    refreshCameras: function () {
      var self = this;

      if (self.$app.data.mainView.router.url == '/security/') {
        // Only when they are visible...
        // console.log('REFRESHING CAMERAS');
        var newTime = new Date().getTime();

        if (true) {
          // Refresh only what is visible on the screen!
          self.$el.find('.tab.tab-active .cameraImage').each(function (index, elem) {
            if (self.$app.lazy.isInViewport(this)) {
              this.src = this.src.replace(/\btime=[^&]*/, 'time=' + newTime);
            }
          });
        } else {
          self.$el.find('.tab.tab-active .cameraImage').each(function (index, elem) {
            this.src = this.src.replace(/\btime=[^&]*/, 'time=' + newTime);
          });
        }
      }
    },
    stopRefresh: function () {
      var self = this;

      if (self.timeoutInterval !== null) {
        clearInterval(self.timeoutInterval);
        self.timeoutInterval = null;
      }
    },
    refreshPage: function () {
      var self = this;
      self.stopRefresh();
      self.timeoutInterval = setInterval(function () {
        self.refreshCameras();
      }, 6000);
      self.$el.on('click', '#storeCamerasTab .cameraImage', function (e) {
        var cameraImageElement = self.$(e.target).closest('.cameraImage');
        self.storePopup.open(parseInt(cameraImageElement.attr('data-image-index')));
      });
      self.$el.on('click', '#newStoreCamerasTab .cameraImage', function (e) {
        var cameraImageElement = self.$(e.target).closest('.cameraImage');
        self.newStorePopup.open(parseInt(cameraImageElement.attr('data-image-index')));
      });
      self.$el.on('click', '#dcCamerasTab .cameraImage', function (e) {
        var cameraImageElement = self.$(e.target).closest('.cameraImage');
        self.dcPopup.open(parseInt(cameraImageElement.attr('data-image-index')));
      });
      var newStorePhotosToPopup = [];

      for (var i = 0; i < self.$root.taskMasterData.cameras.newStore.length; i++) {
        newStorePhotosToPopup.push({
          url: 'https://zoneminder.bedroomsandmore.com/zm/cgi-bin/nph-zms?mode=jpeg&monitor=' + self.$root.taskMasterData.cameras.newStore[i] + '&scale=200&user=appAccess&pass=L79zNpgxzIIkoWp',
          caption: 'Flagship Camera ' + (i + 1)
        });
      }

      if (self.newStorePopup) {
        self.newStorePopup.destroy();
      }

      self.newStorePopup = self.$app.photoBrowser.create({
        photos: newStorePhotosToPopup,
        theme: 'dark',
        toolbar: false,
        type: 'standalone',
        renderNavbar: function () {
          var navBarHTML = '';
          navBarHTML += '<div class="navbar">' + '<div class="navbar-bg"></div>' + '<div class="navbar-inner">' + '<div class="left">' + '<a href="#" class="link icon-only popup-close md-only"><i class="icon icon-back"></i></a>' + '<a href="#" class="link popup-close if-not-md">Close</a>' + '</div>' + '<div class="title sliding securityTitle">Security</div>' + '<div class="right">' + '</div>' + '</div>' + '</div>';
          return navBarHTML;
        },
        on: {
          open: function (photoBrowser) {// TODO: broken fix later...
            //photoBrowser.$el.find('.securityTitle').html((photoBrowser.swiper.activeIndex+1)+' of '+photoBrowser.swiper.virtual.slides.length);
          },
          close: function () {
            setTimeout(function () {
              window.stop();
            }, 0);
          },
          slideChange: function () {// TODO: broken fix later...
            //var photoBrowser= this;
            //photoBrowser.$el.find('.securityTitle').html((photoBrowser.swiper.activeIndex+1)+' of '+photoBrowser.swiper.virtual.slides.length);
          },
          slideChangeTransitionEnd: function () {
            var photoBrowser = this; // This helps STOP MotionJPEG images that are not currently active!

            photoBrowser.$el.find('.photo-browser-slide').each(function (index, element) {
              var thisSlide = self.$(element);

              if (!thisSlide.hasClass('swiper-slide-active')) {
                var previousSlideImg = thisSlide.find('img');

                if (previousSlideImg.hasClass('swiper-lazy-loaded')) {
                  thisSlide.prepend('<div class="swiper-lazy-preloader"></div>');
                  var previousSlideImgSrc = previousSlideImg.attr('src');

                  if (previousSlideImgSrc !== '') {
                    previousSlideImg.removeClass('swiper-lazy-loaded'); //.removeClass('swiper-lazy-loading');

                    previousSlideImg.removeAttr('src').attr('data-src', previousSlideImgSrc);
                  }
                }
              }
            });
          }
        }
      });
      var currentStorePhotosToPopup = [];

      for (var i = 0; i < self.$root.taskMasterData.cameras.store.length; i++) {
        currentStorePhotosToPopup.push({
          url: 'https://zoneminder.bedroomsandmore.com/zm/cgi-bin/nph-zms?mode=jpeg&monitor=' + self.$root.taskMasterData.cameras.store[i] + '&scale=200&user=appAccess&pass=L79zNpgxzIIkoWp',
          //html: '<iframe src="https://zoneminder.bedroomsandmore.com/zm/cgi-bin/nph-zms?mode=jpeg&monitor='+self.$root.taskMasterData.cameras.store[i]+'&scale=200&user=appAccess&pass=L79zNpgxzIIkoWp" frameborder="0" allowfullscreen></iframe>',
          caption: 'Clearance Outlet Camera ' + (i + 1)
        });
      }

      if (self.storePopup) {
        self.storePopup.destroy();
      }

      self.storePopup = self.$app.photoBrowser.create({
        photos: currentStorePhotosToPopup,
        theme: 'dark',
        toolbar: false,
        type: 'standalone',
        renderNavbar: function () {
          var navBarHTML = '';
          navBarHTML += '<div class="navbar">' + '<div class="navbar-bg"></div>' + '<div class="navbar-inner">' + '<div class="left">' + '<a href="#" class="link icon-only popup-close md-only"><i class="icon icon-back"></i></a>' + '<a href="#" class="link popup-close if-not-md">Close</a>' + '</div>' + '<div class="title sliding securityTitle">Security</div>' + '<div class="right">' + '</div>' + '</div>' + '</div>';
          return navBarHTML;
        },
        on: {
          open: function (photoBrowser) {// TODO: broken fix later...
            //photoBrowser.$el.find('.securityTitle').html((photoBrowser.swiper.activeIndex+1)+' of '+photoBrowser.swiper.virtual.slides.length);
          },
          close: function () {
            setTimeout(function () {
              window.stop();
            }, 0);
          },
          slideChange: function () {// TODO: broken fix later...
            //var photoBrowser= this;
            //photoBrowser.$el.find('.securityTitle').html((photoBrowser.swiper.activeIndex+1)+' of '+photoBrowser.swiper.virtual.slides.length);
          },
          slideChangeTransitionEnd: function () {
            var photoBrowser = this; // This helps STOP MotionJPEG images that are not currently active!

            photoBrowser.$el.find('.photo-browser-slide').each(function (index, element) {
              var thisSlide = self.$(element);

              if (!thisSlide.hasClass('swiper-slide-active')) {
                var previousSlideImg = thisSlide.find('img');

                if (previousSlideImg.hasClass('swiper-lazy-loaded')) {
                  thisSlide.prepend('<div class="swiper-lazy-preloader"></div>');
                  var previousSlideImgSrc = previousSlideImg.attr('src');

                  if (previousSlideImgSrc !== '') {
                    previousSlideImg.removeClass('swiper-lazy-loaded'); //.removeClass('swiper-lazy-loading');

                    previousSlideImg.removeAttr('src').attr('data-src', previousSlideImgSrc);
                  }
                }
              }
            });
          }
        }
      });
      var dcPhotosToPopup = [];

      for (var i = 0; i < self.$root.taskMasterData.cameras.dc.length; i++) {
        dcPhotosToPopup.push({
          url: 'https://zoneminder.bedroomsandmore.com/zm/cgi-bin/nph-zms?mode=jpeg&monitor=' + self.$root.taskMasterData.cameras.dc[i] + '&scale=200&user=appAccess&pass=L79zNpgxzIIkoWp',
          caption: 'D.C. Camera ' + (i + 1)
        });
      }

      if (self.dcPopup) {
        self.dcPopup.destroy();
      }

      self.dcPopup = self.$app.photoBrowser.create({
        photos: dcPhotosToPopup,
        theme: 'dark',
        toolbar: false,
        type: 'standalone',
        renderNavbar: function () {
          var navBarHTML = '';
          navBarHTML += '<div class="navbar">' + '<div class="navbar-bg"></div>' + '<div class="navbar-inner">' + '<div class="left">' + '<a href="#" class="link icon-only popup-close md-only"><i class="icon icon-back"></i></a>' + '<a href="#" class="link popup-close if-not-md">Close</a>' + '</div>' + '<div class="title sliding securityTitle">Security</div>' + '<div class="right">' + '</div>' + '</div>' + '</div>';
          return navBarHTML;
        },
        on: {
          open: function (photoBrowser) {// TODO: broken fix later...
            //photoBrowser.$el.find('.securityTitle').html((photoBrowser.swiper.activeIndex+1)+' of '+photoBrowser.swiper.virtual.slides.length);
          },
          close: function () {
            setTimeout(function () {
              window.stop();
            }, 0);
          },
          slideChange: function () {// TODO: broken fix later...
            //var photoBrowser= this;
            //photoBrowser.$el.find('.securityTitle').html((photoBrowser.swiper.activeIndex+1)+' of '+photoBrowser.swiper.virtual.slides.length);
          },
          slideChangeTransitionEnd: function () {
            var photoBrowser = this; // This helps STOP MotionJPEG images that are not currently active!

            photoBrowser.$el.find('.photo-browser-slide').each(function (index, element) {
              var thisSlide = self.$(element);

              if (!thisSlide.hasClass('swiper-slide-active')) {
                var previousSlideImg = thisSlide.find('img');

                if (previousSlideImg.hasClass('swiper-lazy-loaded')) {
                  thisSlide.prepend('<div class="swiper-lazy-preloader"></div>');
                  var previousSlideImgSrc = previousSlideImg.attr('src');

                  if (previousSlideImgSrc !== '') {
                    previousSlideImg.removeClass('swiper-lazy-loaded'); //.removeClass('swiper-lazy-loading');

                    previousSlideImg.removeAttr('src').attr('data-src', previousSlideImgSrc);
                  }
                }
              }
            });
          }
        }
      });
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.refreshPage();
    },
    pageBeforeOut: function () {
      var self = this;
      self.stopRefresh();
    }
  },
  id: 'd48d191d49',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page theme-dark ios-translucent-bars" data-name="securityPage">\n		<div class="navbar ios-translucent-bars theme-dark">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">Security</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					';
      r += Template7Helpers.js_if.call(ctx_1, "(window.app.f7.data.user.id == 16) || (window.app.f7.data.user.id == 4)", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="#" class="link icon-only" @click="restartZoneMinderServer">\n						<i class="icon f7-icons">arrow_clockwise_circle</i>\n					</a>\n					<label class="toggle color-green showExtraInfo">\n						<input type="checkbox"';
          r += Template7Helpers.if.call(ctx_2, ctx_2.showExtraInfo, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' checked';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '>\n						<span class="toggle-icon"></span>\n					</label>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n			</div>\n		</div>\n		<div class="toolbar tabbar tabbar-labels toolbar-bottom">\n			<div class="toolbar-inner">\n			';
      r += Template7Helpers.if.call(ctx_1, ctx_1.storeViewAllowed, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<a href="#newStoreCamerasTab" class="tab-link tab-link-active">\n				<i class="icon icon-fill f7-icons if-not-md">building_2_fill</i>\n				<i class="icon material-icons md-only">business</i>\n				<span class="tabbar-label">Flagship</span>\n			</a>\n			<a href="#storeCamerasTab" class="tab-link">\n					<i class="icon icon-fill f7-icons if-not-md">building_2_fill</i>\n					<i class="icon material-icons md-only">business</i>\n					<span class="tabbar-label">Clearance</span>\n				</a>\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			';
      r += Template7Helpers.if.call(ctx_1, ctx_1.dcViewAllowed, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<a href="#dcCamerasTab" class="tab-link';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.storeViewAllowed, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' tab-link-active';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '">\n					<i class="icon icon-fill f7-icons if-not-md">building_2_fill</i>\n					<i class="icon material-icons md-only">business</i>\n					<span class="tabbar-label">Dist. Center</span>\n				</a>\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n	    </div>\n		<div class="tabs">\n			';
      r += Template7Helpers.if.call(ctx_1, ctx_1.storeViewAllowed, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div id="newStoreCamerasTab" class="page-content tab securityTab tab-active" style="background: #000; text-align: center;">\n				<div class="row panZoomCameras">\n					';
          r += Template7Helpers.each.call(ctx_2, ctx_2.$root.taskMasterData.cameras.newStore, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<div class="col-100 small-50 large-33 xlarge-25 cameraImageContainer">\n						';
              r += Template7Helpers.if.call(ctx_3, root.showExtraInfo, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						<div class="extraInfoButtons">&nbsp;</div>\n						';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n						';
              r += Template7Helpers.if.call(ctx_3, root.useLazyLoading, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						<img class="cameraImage lazy lazy-fade-in" width="100%" height="*" data-src="https://zoneminder.bedroomsandmore.com/zm/cgi-bin/nph-zms?mode=single&monitor=';
                  r += c(ctx_4, ctx_4);
                  r += '&scale=200&user=appAccess&pass=L79zNpgxzIIkoWp" data-image-index="';
                  r += c(data_4 && data_4.index, ctx_4);
                  r += '" draggable="false">\n						';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						<img class="cameraImage" width="100%" height="*" src="https://zoneminder.bedroomsandmore.com/zm/cgi-bin/nph-zms?mode=single&monitor=';
                  r += c(ctx_4, ctx_4);
                  r += '&scale=200&user=appAccess&pass=L79zNpgxzIIkoWp" data-image-index="';
                  r += c(data_4 && data_4.index, ctx_4);
                  r += '" draggable="false">\n						';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n					</div>\n					';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				</div>\n			</div>\n			<div id="storeCamerasTab" class="page-content tab securityTab" style="background: #000; text-align: center;">\n				<div class="row panZoomCameras">\n					';
          r += Template7Helpers.each.call(ctx_2, ctx_2.$root.taskMasterData.cameras.store, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<div class="col-100 small-50 large-33 xlarge-25 cameraImageContainer">\n						';
              r += Template7Helpers.if.call(ctx_3, root.showExtraInfo, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						<div class="extraInfoButtons">&nbsp;</div>\n						';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n						';
              r += Template7Helpers.if.call(ctx_3, root.useLazyLoading, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						<img class="cameraImage lazy lazy-fade-in" width="100%" height="*" data-src="https://zoneminder.bedroomsandmore.com/zm/cgi-bin/nph-zms?mode=single&monitor=';
                  r += c(ctx_4, ctx_4);
                  r += '&scale=200&user=appAccess&pass=L79zNpgxzIIkoWp" data-image-index="';
                  r += c(data_4 && data_4.index, ctx_4);
                  r += '" draggable="false">\n						';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						<img class="cameraImage" width="100%" height="*" src="https://zoneminder.bedroomsandmore.com/zm/cgi-bin/nph-zms?mode=single&monitor=';
                  r += c(ctx_4, ctx_4);
                  r += '&scale=200&user=appAccess&pass=L79zNpgxzIIkoWp" data-image-index="';
                  r += c(data_4 && data_4.index, ctx_4);
                  r += '" draggable="false">\n						';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n					</div>\n					';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				</div>\n			</div>\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			';
      r += Template7Helpers.if.call(ctx_1, ctx_1.dcViewAllowed, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div id="dcCamerasTab" class="page-content tab securityTab';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.storeViewAllowed, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' tab-active';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" style="background: #000; text-align: center;">\n				<div class="row panZoomCameras">\n					';
          r += Template7Helpers.each.call(ctx_2, ctx_2.$root.taskMasterData.cameras.dc, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<div class="col-100 small-50 large-33 xlarge-25 cameraImageContainer">\n						';
              r += Template7Helpers.if.call(ctx_3, root.showExtraInfo, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						<div class="extraInfoButtons">&nbsp;</div>\n						';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n						';
              r += Template7Helpers.if.call(ctx_3, root.useLazyLoading, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						<img class="cameraImage lazy lazy-fade-in" width="100%" height="*" data-src="https://zoneminder.bedroomsandmore.com/zm/cgi-bin/nph-zms?mode=single&monitor=';
                  r += c(ctx_4, ctx_4);
                  r += '&scale=200&user=appAccess&pass=L79zNpgxzIIkoWp" data-image-index="';
                  r += c(data_4 && data_4.index, ctx_4);
                  r += '" draggable="false">\n						';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						<img class="cameraImage" width="100%" height="*" src="https://zoneminder.bedroomsandmore.com/zm/cgi-bin/nph-zms?mode=single&monitor=';
                  r += c(ctx_4, ctx_4);
                  r += '&scale=200&user=appAccess&pass=L79zNpgxzIIkoWp" data-image-index="';
                  r += c(data_4 && data_4.index, ctx_4);
                  r += '" draggable="false">\n						';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n					</div>\n					';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				</div>\n			</div>\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n	    </div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.md .toggle.showExtraInfo {
		padding-right: 3em;
		
	}

	.aurora .toggle.showExtraInfo {
		margin-left: 1em;

	}

	.cameraImageContainer {
		max-width: 100vmin;
		margin: 0px auto;
		position: relative;
	}
	
	.cameraImageContainer .extraInfoButtons {
		position: absolute;
		bottom: 1em;
		left: 1em;
	}

`,
  styleScoped: false
};
    