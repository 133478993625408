
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this; // TODO: check if admin and if they can choose more...
    // TODO: permissions and what they see...

    if (self.$root.user.department == 'Dist. Center') {
      self.currentTitle = 'D.C. Sync Drive';
      self.currentReference = 'dc';
    } else if (self.$root.user.department == 'Creative Team' || self.$root.user.department == 'IT') {
      self.currentTitle = 'Creative Team Folder';
      self.currentReference = 'creativeteam';
    } else {
      self.currentTitle = 'Sales Sync Drive';
      self.currentReference = 'sales';
    }
  },
  data: function () {
    return {
      currentTitle: 'Sales Sync Drive',
      currentReference: 'sales',
      allowedFiles: [{
        name: 'Sales Sync Drive',
        reference: 'sales'
      }, {
        name: 'D.C. Sync Drive',
        reference: 'dc'
      }, {
        name: 'Creative Team Folder',
        reference: 'creativeteam'
      }]
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;
    },
    changeURLLink: function (e) {
      var self = this;
      var thisLink = self.$(e.target).closest('a');
      var newReference = thisLink.attr('data-reference');

      if (newReference !== self.currentReference) {
        self.currentTitle = thisLink.text();
        self.$el.find('.filesiFrame').attr('src', 'about:blank');
        /*
        var newNavBarTitle= thisLink.text();
        
        self.$('.navbar-files-title').text(newNavBarTitle);
        self.$app.navbar.size(self.$el);
        */

        self.changeURL(newReference);
      }
    },
    changeURL: function (referenceName) {
      var self = this; // var newURL= 'https://taskmaster.bedroomsandmore.com/droppy/'+referenceName+'/';

      self.$setState({
        currentReference: referenceName
      });
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this; //self.filesiFrame= self.$el.find('.filesiFrame');
      //self.filesiFrame.attr('src','https://taskmaster.bedroomsandmore.com/droppy/sales/');
    }
  },
  id: 'f942e2e8f5',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="email">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title navbar-files-title">';
      r += c(ctx_1.currentTitle, ctx_1);
      r += ' (View Only)</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					';
      r += Template7Helpers.js_if.call(ctx_1, "this.allowedFiles.length > 1", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="#" class="link icon-only popover-open" data-popover=".files-menu">\n						<i class="icon f7-icons if-not-md">folder</i>\n						<i class="icon material-icons md-only">folder_open</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n			</div>\n		</div>\n		<div class="page-content">\n			<iframe class="filesiFrame" src="https://taskmaster.bedroomsandmore.com/droppy/';
      r += c(ctx_1.currentReference, ctx_1);
      r += '/"></iframe>\n		</div>\n		<div class="popover files-menu">\n			<div class="popover-angle"></div>\n			<div class="popover-inner">\n				<div class="list">\n					<ul>\n						';
      r += Template7Helpers.each.call(ctx_1, ctx_1.allowedFiles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n						<li><a href="#" class="list-button item-link popover-close" @click="changeURLLink" data-reference="';
          r += c(ctx_2.reference, ctx_2);
          r += '">';
          r += c(ctx_2.name, ctx_2);
          r += '</a></li>\n						';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					</ul>\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.loadingMessage {
		position: absolute;
		top: 12vh;
		left: 0px;
		right: 0px;
		z-index: 10;
		text-align: center;
	}
	
	.filesiFrame {
		border: 0px;
		height: 99%;
		width: 100%;
	}
	
`,
  styleScoped: false
};
    