
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.invoiceNumber = self.$route.params.invoiceNumber;
    self.canEditCommission = self.$root.user.isAdmin; // TODO: only certain admins?
  },
  data: function () {
    return {
      loading: true,
      invoiceNumber: '',
      invoiceCommissionObject: {},
      canEditCommission: false,
      editingCommission: false
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'invoiceCommission' && data.invoiceNumber == self.invoiceNumber) {
        self.invoiceCommissionObject = data.invoiceCommission; // console.log('got here!!!!!!',self.invoiceCommissionObject);

        self.loading = false;
        self.$setState();
      }
    },
    editCommission: function (e) {
      var self = this;
      self.$setState({
        editingCommission: true
      });
    },
    saveCommission: function (e) {
      var self = this;
    }
  },
  on: {
    popupOpen: function (e, popup) {
      var self = this;
    }
  },
  id: 'de3f56ec5d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="popup popupInvoiceCommission">\n		<div class="view">\n			<div class="page" data-name="popupInvoiceCommission">\n				<div class="navbar">\n					<div class="navbar-bg"></div>\n					<div class="navbar-inner">\n						<div class="left">\n							<a href="#" class="link icon-only popup-close md-only" data-popup=".popupInvoiceCommission"><i class="icon icon-back"></i></a>\n							<a href="#" class="link popup-close if-not-md" data-popup=".popupInvoiceCommission">Close</a>\n						</div>\n						<div class="title sliding">';
      r += c(ctx_1.invoiceNumber, ctx_1);
      r += ' Commission</div>\n						<div class="right">\n							';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							<a href="/bugreport/" class="link icon-only">\n								<i class="icon material-icons">bug_report</i>\n							</a>\n							';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n							';
      r += Template7Helpers.if.call(ctx_1, ctx_1.editingCommission, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							<a href="#" class="button button-big button-raised button-fill standOutButton" @click="saveCommission">Save</a>\n							';
          r += Template7Helpers.if.call(ctx_2, ctx_2.canEditCommission, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n							';
          r += Template7Helpers.if.call(ctx_2, ctx_2.canEditCommission, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							<a href="#" class="link" @click="editCommission">Edit</a>\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n							';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n						</div>\n					</div>\n				</div>\n				<div class="page-content">\n					';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<div class="block-title">';
          r += c(ctx_2.invoiceNumber, ctx_2);
          r += ' PayOn';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.invoiceCommissionObject.invoicePayOnObject.hasBeenReviewed, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<br><span class="highlightRed">(*** Has not been reviewed yet)</span>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\n					<div class="block block-strong">\n						<p>\n							';
          r += Template7Helpers.with.call(ctx_2, ctx_2.invoiceCommissionObject.invoicePayOnObject, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							Money Received: ';
              r += Template7Helpers.js.call(ctx_3, "this.invoiceMoneyReceived.formatMoney()", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '<br>\n							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(-) Tax: ';
              r += Template7Helpers.js.call(ctx_3, "this.invoiceTax.formatMoney()", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '<br>\n							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(-) Deliveries: ';
              r += Template7Helpers.js.call(ctx_3, "this.invoiceDelivery.formatMoney()", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '<br>\n							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(-) Adjustments: ';
              r += Template7Helpers.js.call(ctx_3, "(this.invoiceAdjustments*2.5).formatMoney()", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' (';
              r += Template7Helpers.js.call(ctx_3, "(this.invoiceAdjustments).formatMoney()", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' * 2.5)<br>\n							-----<br>\n							<span style="font-size: 1.25em;">PayOn: ';
              r += Template7Helpers.js.call(ctx_3, "this.invoicePayOn.formatMoney()", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</span><br>\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n							';
          r += Template7Helpers.with.call(ctx_2, ctx_2.invoiceCommissionObject, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							-----<br>\n							&nbsp;&nbsp;&nbsp;|<br>';
              r += Template7Helpers.if.call(ctx_3, root.editingCommission, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<!--TODO! give field for overridding Handled Percent-->';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n							OTD/Handled Product Percent: ';
              r += Template7Helpers.js_if.call(ctx_3, "this.handledPercent<100", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span class="highlightRed">[';
                  r += c(ctx_4.handledPercent, ctx_4);
                  r += '%*]</span>';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += c(ctx_4.handledPercent, ctx_4);
                  r += '%';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '<br>\n							&nbsp;&nbsp;&nbsp;|<br>\n							<span class="boldLarge">Handled PayOn: ';
              r += Template7Helpers.js.call(ctx_3, "this.handledPayOn.formatMoney()", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' <span class="extraInfoSmall">(';
              r += c(ctx_3.handledPercent, ctx_3);
              r += '% of ';
              r += Template7Helpers.js.call(ctx_3, "this.invoicePayOnObject.invoicePayOn.formatMoney()", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ')</span></span>\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						</p>\n					</div>\n					';
          r += Template7Helpers.if.call(ctx_2, ctx_2.editingCommission, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<div class="block">\n						TODO!\n						<p>1) Commission Type: Follow Rules for the date:, Manually enter amounts, no commission.</p>\n						<p>2) Pay Period date range for "rules" (ie: treat this as if it was in a different month, maybe paid)</p>\n						<p>3) Notes Box.</p>\n						// Need to somehow relate/tag linked invoice(s) for notes on the reason why something...\n						// Need to list EVENT dates with commission expected for each pay-period...\n					</div>\n					';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n					<div class="block-title">Commission Breakdown<br><span class="extraInfoSmall">(as of ';
          r += Template7Helpers.js.call(ctx_2, "window.moment(this.invoiceCommissionObject.reportRunDateTime).format('L LT')", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ')</span></div>\n					<div class="block block-strong">\n						<div class="data-table">\n							<table>\n								<thead>\n									<tr>\n										<th class="label-cell">Name</th>\n										<th class="numeric-cell">Comm. %</th>\n										<th class="numeric-cell">Comm. $</th>\n									</tr>\n								</thead>\n								<tbody>\n									';
          r += Template7Helpers.if.call(ctx_2, ctx_2.editingCommission, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n									<!--TODO: add House and then loop through ACTIVE salespeople-->\n									<!-- The fields here should be read only UNLESS picked "Manually enter" from above-->\n									';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceCommissionObject.commissionEmployeeObjects.length, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n									';
              r += Template7Helpers.if.call(ctx_3, ctx_3.invoiceCommissionObject.commissionEmployeeObjects.length, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n									';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.invoiceCommissionObject.commissionEmployeeObjects, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n									<tr>\n										<td class="label-cell">(';
                      r += c(ctx_5.commissionSplitPercent, ctx_5);
                      r += '%) ';
                      r += c(ctx_5.employeeName, ctx_5);
                      r += '</td>\n										<td class="numeric-cell">';
                      r += c(ctx_5.commissionPercent, ctx_5);
                      r += '%</td>\n										<td class="numeric-cell">';
                      r += Template7Helpers.js.call(ctx_5, "this.commissionDollars.formatMoney()", {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += Template7Helpers.js_if.call(ctx_5, "this.handledPercent<100", {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '<br><span class="extraInfoSmall">Fully Closed: ';
                          r += Template7Helpers.js.call(ctx_6, "this.potentialCommissionDollars.formatMoney()", {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '</span>';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</td>\n									</tr>\n									';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n									';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n									';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								</tbody>\n							</table>\n						</div>\n					</div>\n					<div class="block">\n						';
          r += Template7Helpers.js_if.call(ctx_2, "this.invoiceCommissionObject.handledPercent<100", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n						<p><span class="highlightRed">[* Commission will be paid in full when invoice is 100% completed]</span></p>\n						';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						';
          r += Template7Helpers.if.call(ctx_2, ctx_2.invoiceCommissionObject.handledPercentNotes, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n						<p><span class="highlightRed">';
              r += c(ctx_3.invoiceCommissionObject.handledPercentNotes, ctx_3);
              r += '</span></p>\n						';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n					</div>\n					';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<div class="block block-strong" style="text-align: center;">\n						<p>Loading...</p>\n						<div class="preloader"></div>\n					</div>\n					';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	span.highlightRed {
		color: #f00;
		font-weight: bold;
	}

	span.boldLarge {
		font-size: 1.25em;
		font-weight: bold;
	}

	span.extraInfoSmall {
		opacity: 0.5;
		font-size: 0.75em;
		font-weight: normal;
		font-style: italic;
	}
`,
  styleScoped: false
};
    