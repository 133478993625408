
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.searchType = self.$route.params.searchType;
  },
  data: function () {
    return {
      searchType: 'invoices'
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;
    },
    changeSearchType: function (e) {
      var self = this;
      var thisButton = self.$(e.target);
      self.$setState({
        searchType: thisButton.attr('data-searchType')
      }, function () {
        self.focusOnFirstInput();
      });
    },
    focusOnFirstInput: function () {
      var self = this;
      var tempTimeout = setTimeout(function () {
        if (self.searchType == 'invoices') {
          self.$el.find('.lastName').focus();
        } else if (self.searchType == 'inventory') {
          self.$el.find('.inventoryName').focus();
        } else {
          self.$el.find('.customerLastName').focus();
        }
      }, 100);
    },
    lastNameKeyPress: function (e) {
      var self = this;
      var theInputField = self.$(e.target);

      if (theInputField.val().length == 0) {
        var isNumber = /^[0-9]$/i.test(e.key);

        if (isNumber) {
          self.$el.find('.invoiceNumber').val(e.key).focus();
          var tempTimeout = setTimeout(function () {
            theInputField.val('');
          }, 0);
        }
      }
    },
    submitSearchForm: function (e) {
      var self = this; // self.$app.dialog.alert('Submitted!','Submitted!');

      if (!self.$app.data.taskMasterData.searchResultRequest) {
        self.$app.data.taskMasterData.searchResultRequest = {};
      }

      var searchData = self.$app.form.convertToData(self.$('form.searchForm')); // TODO: find out if we have done this search before... (And re-use data?)
      // TODO: should come up with a unique reference to match the results...

      var rightNowDate = new Date();
      self.$app.data.taskMasterData.searchResultRequest[self.searchType] = {
        type: self.searchType,
        uniqueId: rightNowDate.getTime(),
        searchData: searchData,
        haveResults: false,
        date: rightNowDate.toISOString()
      };
      var searchPopupView = self.$('.searchView')[0].f7View;
      self.$('input.input-focused').blur(); // Make sure we close the keyboard...

      searchPopupView.router.navigate('/taskmaster/search/' + self.searchType + '/results/');
      e.preventDefault();
      return false;
    }
  },
  on: {
    popupOpen: function (e, popup) {
      var self = this;
    },
    popupOpened: function (e, popup) {
      var self = this;
      self.focusOnFirstInput();
    }
  },
  id: '81f8fdf89d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="popup taskmasterSearchPopup">\n		<div class="view view-init searchView" data-name="searchView">\n			<div class="page" data-name="taskmasterSearchPopup"> <!-- TODO: should this be a popup instead of a whole page? -->\n				<div class="navbar">\n					<div class="navbar-bg"></div>\n					<div class="navbar-inner sliding">\n						<div class="left">\n							<a href="#" class="link icon-only popup-close md-only" data-popup=".taskmasterSearchPopup"><i class="icon icon-back"></i></a>\n							<a href="#" class="link popup-close if-not-md" data-popup=".taskmasterSearchPopup">Close</a>\n						</div>\n						<div class="title">TaskMaster Search</div>\n						<div class="right">\n							';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							<a href="/bugreport/" class="link icon-only">\n								<i class="icon material-icons">bug_report</i>\n							</a>\n							';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n						</div>\n					</div>\n				</div>\n				<div class="page-content">\n					<div class="block segmented-buttons-wrapper" style="margin: 10px 0px;">\n						<p class="segmented segmented-strong">\n							<button class="button no-ellipsis';
      r += Template7Helpers.js_if.call(ctx_1, "this.searchType == 'invoices'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' button-active';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" data-searchType="invoices" @click="changeSearchType">Invoices</button>\n							<button class="button no-ellipsis';
      r += Template7Helpers.js_if.call(ctx_1, "this.searchType == 'inventory'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' button-active';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" data-searchType="inventory" @click="changeSearchType">Inventory</button>\n							<button class="button no-ellipsis';
      r += Template7Helpers.js_if.call(ctx_1, "this.searchType == 'customers'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' button-active';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" data-searchType="customers" @click="changeSearchType">Customers</button>\n							<span class="segmented-highlight"></span>\n						</p>\n					</div>\n					<div class="block block-strong no-margin-top no-padding-top no-hairlines-md">\n						<form name="searchForm" class="searchForm list no-hairlines-md no-margin" action="" @submit="submitSearchForm">\n							<input type="hidden" name="searchType" value="';
      r += c(ctx_1.searchType, ctx_1);
      r += '">\n							<ul>\n								';
      r += Template7Helpers.js_if.call(ctx_1, "this.searchType == 'invoices'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-input-wrap">\n											<input name="lastName" class="lastName" type="text" value="" placeholder="Last Name" @keypress="lastNameKeyPress" />\n										</div>\n									</div>\n								</li>\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-input-wrap">\n											<input name="firstName" type="text" value="" placeholder="First Name" />\n										</div>\n									</div>\n								</li>\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-input-wrap">\n											<input name="phone" type="text" value="" placeholder="Phone" />\n										</div>\n									</div>\n								</li>\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-input-wrap">\n											<input name="invoiceNumber" class="invoiceNumber" type="text" value="" placeholder="Invoice #" />\n										</div>\n									</div>\n								</li>\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-input-wrap">\n											<input name="date" type="text" value="" placeholder="Date" />\n										</div>\n									</div>\n								</li>\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-input-wrap">\n											<input name="anyValue" type="text" value="" placeholder="Any Value" />\n										</div>\n									</div>\n								</li>\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-input-wrap">\n											<input name="insideSalesLines" type="text" value="" placeholder="Inside Saleslines" />\n										</div>\n									</div>\n								</li>\n								';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n								';
      r += Template7Helpers.js_if.call(ctx_1, "this.searchType == 'inventory'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-input-wrap">\n											<input name="inventoryName" class="inventoryName" type="text" value="" placeholder="Name" />\n										</div>\n									</div>\n								</li>\n								';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n								';
      r += Template7Helpers.js_if.call(ctx_1, "this.searchType == 'customers'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-input-wrap">\n											<input name="customerLastName" class="customerLastName" type="text" value="" placeholder="Last Name" />\n										</div>\n									</div>\n								</li>\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-input-wrap">\n											<input name="customerFirstName" type="text" value="" placeholder="First Name" />\n										</div>\n									</div>\n								</li>\n								';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n								<li class="item-content">\n									<input type="submit" class="button button-raised button-fill button-large" value="Search ';
      r += c(ctx_1.searchType, ctx_1);
      r += '">\n								</li>\n							</ul>\n						</form>\n					</div>\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.button.no-ellipsis {
		text-overflow: unset;
	}
`,
  styleScoped: false
};
    