
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.phoneInCall = self.$app.data.phoneInCall;
  },
  data: function () {
    return {
      phoneInCall: false,
      sortOptions: [{
        name: 'Available',
        value: 'available'
      }, {
        name: 'Department',
        value: 'department'
      }, {
        name: 'First Name',
        value: 'firstname'
      }, {
        name: 'Last Name',
        value: 'lastname'
      }],
      currentSort: 'available',
      employees: []
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
    self.$app.on('phoneVariablesChanged', self.phoneVariablesChanged);
    self.$el.on('click', 'a.clickableLink', self.clickedItem);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
    self.$app.off('phoneVariablesChanged', self.phoneVariablesChanged);
    self.$el.off('click', 'a.clickableLink', self.clickedItem);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'employees') {
        self.refreshEmployees();
      } else if (data.type == 'data' && data.data == 'departments') {
        self.refreshDepartments();
      }
    },
    phoneVariablesChanged: function (data) {
      var self = this;

      if (self.$root.PHONE.sessionIsSpeakerphone) {
        self.$el.find('.speakerButton').addClass('button-fill').addClass('elevation-10');
      } else {
        self.$el.find('.speakerButton').removeClass('button-fill').removeClass('elevation-10');
      }

      if (self.$root.PHONE.sessionIsMuted) {
        self.$el.find('.muteButton').addClass('button-fill').addClass('elevation-10');
      } else {
        self.$el.find('.muteButton').removeClass('button-fill').removeClass('elevation-10');
      }

      if (self.$root.PHONE.sessionOnHold) {
        self.$el.find('.holdButton').addClass('button-fill').addClass('elevation-10');
      } else {
        self.$el.find('.holdButton').removeClass('button-fill').removeClass('elevation-10');
      }
    },
    refreshPage: function () {
      var self = this;
      self.refreshDepartments();
      self.refreshEmployees();
    },
    phoneMuteToggle: function () {
      var self = this;

      if (self.$root.PHONE.sessionIsMuted) {
        self.$root.PHONE.unMute();
      } else {
        self.$root.PHONE.mute();
      }
    },
    phoneHoldToggle: function () {
      var self = this;

      if (self.$root.PHONE.sessionOnHold) {
        self.$root.PHONE.unHold();
      } else {
        self.$root.PHONE.hold();
      }
    },
    phoneSpeakerToggle: function () {
      var self = this;

      if (self.$root.PHONE.sessionIsSpeakerphone) {
        self.$root.PHONE.speakerPhoneOff();
      } else {
        self.$root.PHONE.speakerPhoneOn();
      }
    },
    phoneHangup: function () {
      var self = this;
      self.$root.PHONE.hangup();
    },
    refreshDepartments: function () {
      var self = this;
      var departmentHTML = '';

      for (var i = 0; i < self.$root.taskMasterData.phonebook.contacts.departments.length; i++) {
        var currentDepartment = self.$root.taskMasterData.phonebook.contacts.departments[i];
        departmentHTML += '<li>' + '<a href="#" class="item-link clickableLink item-content" data-department-index="' + i + '">' + '<div class="item-inner">' + '<div class="item-title">' + currentDepartment.name + '</div>' + '<div class="item-after">' + currentDepartment.extension + '</div>' + '</div>' + '</a>' + '</li>';
      }

      self.$el.find('.departmentList').html(departmentHTML);
    },
    refreshEmployees: function () {
      var self = this;

      if (self.$root.taskMasterData.employees) {
        self.employees = self.$root.taskMasterData.employees.slice();
        self.sortEmployees(self.currentSort, false);

        for (var i = 0; i < self.employees.length; i++) {
          self.employees[i].employeeNameDisplayTitle = self.employees[i].firstname + ' ' + self.employees[i].lastname;
          self.employees[i].showClockedInTime = self.employees[i].department == self.$root.user.department || self.$root.user.isAdmin;

          if (self.employees[i].showClockedInTime) {
            if (self.employees[i].department == 'Exec') {
              self.employees[i].showClockedInTime = false;
            }
          }

          self.employees[i].showClockedInTime = false; // TODO: remove this when ready...

          if (self.currentSort == 'lastname') {
            self.employees[i].employeeNameDisplayTitle = '';

            if (self.employees[i].lastname !== '') {
              self.employees[i].employeeNameDisplayTitle = self.employees[i].lastname + ', ';
            }

            self.employees[i].employeeNameDisplayTitle += self.employees[i].firstname;
          }
        } // console.log('employees',self.employees);


        self.$setState();
        /* // Not needed, fixed in Framework7...
        // Fix tooltips...
        self.$el.find('.tooltip-init').each(function(e) {
        	var thisToolTipEl= self.$(this);
        	var thisToolTip= self.$app.tooltip.get(this);
        	thisToolTip.setText(thisToolTipEl.attr('data-tooltip'));
        });
        */
      }
    },
    sortEmployees: function (sortOption, reloadThePage) {
      var self = this;
      reloadThePage = typeof reloadThePage == 'boolean' ? reloadThePage : true;

      if (sortOption == 'available') {
        self.employees.sort(function (a, b) {
          var textA = a.firstname.toUpperCase();
          var textB = b.firstname.toUpperCase();

          if (a.isClockedIn == b.isClockedIn) {
            // If the same then sort by name...
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          } else if (a.isClockedIn) {
            return -1;
          } else if (b.isClockedIn) {
            return 1;
          }

          return 0;
        });
      } else if (sortOption == 'lastname') {
        self.employees.sort(function (a, b) {
          var textA = a.lastname.toUpperCase();
          var textB = b.lastname.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (sortOption == 'department') {
        self.employees.sort(function (a, b) {
          var textA = a.department.toUpperCase();
          var textB = b.department.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else {
        // Default to first name...
        self.employees.sort(function (a, b) {
          var textA = a.firstname.toUpperCase();
          var textB = b.firstname.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      }

      self.currentSort = sortOption;

      if (reloadThePage) {
        self.refreshEmployees();
      }
    },
    changedSort: function (sortBy) {
      var self = this;
      self.currentSort = sortBy;
      self.refreshEmployees();
    },
    clickedItem: function (e) {
      var self = this;
      var theATag = self.$(e.target).closest('a.clickableLink');
      var departmentIndex = parseInt(theATag.attr('data-department-index') !== '' ? theATag.attr('data-department-index') : '-1');
      var employeeIndex = parseInt(theATag.attr('data-employee-index') !== '' ? theATag.attr('data-employee-index') : '-1');
      var employeeId = parseInt(theATag.attr('data-employeeId') !== '' ? theATag.attr('data-employeeId') : '-1');

      if (self.phoneInCall) {
        if (departmentIndex > -1) {
          var departmentObject = self.$root.taskMasterData.phonebook.contacts.departments[departmentIndex];
          self.$app.dialog.confirm(departmentObject.name + '<br>' + departmentObject.extension, 'Transfer call?', function () {
            self.$app.data.PHONE.transfer(departmentObject.extension, departmentObject.name);
          });
        } else if (employeeIndex > -1) {
          self.$app.dialog.confirm(self.employees[employeeIndex].firstname + ' ' + self.employees[employeeIndex].lastname + '<br>' + self.employees[employeeIndex].extension, 'Transfer call?', function () {
            self.$app.data.PHONE.transfer(self.employees[employeeIndex].extension, self.employees[employeeIndex].firstname + ' ' + self.employees[employeeIndex].lastname);
          });
        } else {//error? or ask what to do? or do nothing?
        }
      } else {
        if (employeeId > 0) {
          self.$router.navigate('/employees/' + employeeId + '/');
        }
      }
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.currentSort = 'available';
      self.refreshPage();
    }
  },
  id: 'f4d514b5b7',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page';
      r += Template7Helpers.if.call(ctx_1, ctx_1.phoneInCall, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' page-with-subnavbar';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" data-name="employeePage">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">Employees</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<a href="#" class="link popover-open" data-popover=".sort-menu">\n						<span>Sort: </span>\n						<i class="icon f7-icons if-not-md">sort_down_circle</i>\n						<i class="icon material-icons md-only">sort</i>\n					</a>\n				</div>\n				';
      r += Template7Helpers.if.call(ctx_1, ctx_1.phoneInCall, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="subnavbar">\n					<div class="subnavbar-inner incallSubnavbar">\n						<div class="segmented">\n							<a href="#" class="link button muteButton';
          r += Template7Helpers.js_if.call(ctx_2, "this.$root.PHONE.sessionIsMuted", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' button-fill';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="phoneMuteToggle">Mute</a>\n							<a href="#" class="link button holdButton';
          r += Template7Helpers.js_if.call(ctx_2, "this.$root.PHONE.sessionOnHold", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' button-fill';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="phoneHoldToggle">Hold</a>\n							<a href="#" class="link button speakerButton';
          r += Template7Helpers.js_if.call(ctx_2, "this.$root.PHONE.sessionIsSpeakerphone", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' button-fill elevation-10';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.$app.device.desktop, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="phoneSpeakerToggle">Speaker</a>\n							<a href="#" class="link button" @click="phoneHangup">End</a>\n						</div>\n					</div>\n				</div>\n			    ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n			    ';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.$root.turnOffLargeTitles, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<div class="title-large">\n					<div class="title-large-text">Employee Roster</div>\n				</div>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="page-content">\n			<div class="list accordion-list">\n				<ul>\n					<li class="accordion-item">\n						<a href="#" class="item-content clickableLink item-link">\n							<div class="item-inner">\n								<div class="item-title">Departments</div>\n							</div>\n						</a>\n						<div class="accordion-item-content">\n							<div class="block">\n								<div class="list inset">\n									<ul class="departmentList"></ul>\n								</div>\n							</div>\n						</div>\n					</li>\n				</ul>\n			</div>\n			<div class="list media-list employeeRosterDiv">\n				<ul>\n					';
      r += Template7Helpers.each.call(ctx_1, ctx_1.employees, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<li>\n						<a href="#" class="item-link clickableLink item-content';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.isClockedIn, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' clockedOut';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" data-employeeId="';
          r += c(ctx_2.id, ctx_2);
          r += '" data-employee-index="';
          r += c(data_2 && data_2.index, ctx_2);
          r += '" draggable="false">\n							<div class="item-inner">\n								<div class="item-title-row">\n									<div class="item-title">';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isClockedIn, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.status, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span class="badge color-red badge-empty">&nbsp;</span> ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span class="badge color-green badge-empty">&nbsp;</span> ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += c(ctx_2.employeeNameDisplayTitle, ctx_2);
          r += Template7Helpers.if.call(ctx_2, ctx_2.isClockedIn, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.clockedInCurrentlyOffSite, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <span class="text-monospace-border-block">Remote</span>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\n									<div class="item-after">';
          r += Template7Helpers.if.call(ctx_2, ctx_2.upList, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<i class="icon f7-icons tooltip-init';
              r += Template7Helpers.js_if.call(ctx_3, "this.upListObject.statusId !== 1", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' color-red';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '" data-tooltip="';
              r += Template7Helpers.js_if.call(ctx_3, "this.upListObject.statusId !== 1", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += c(ctx_4.upListObject._statusName, ctx_4);
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += c(ctx_4.upListObject._locationName, ctx_4);
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '">arrow_up_circle_fill';
              r += Template7Helpers.js_if.call(ctx_3, "this.upListObject.statusId == 1", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span class="badge color-teal">';
                  r += c(ctx_4.upListObject.order, ctx_4);
                  r += '</span>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</i>&nbsp;&nbsp;&nbsp;';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += c(ctx_2.department, ctx_2);
          r += '</div>\n								</div>\n								';
          r += Template7Helpers.if.call(ctx_2, ctx_2.showClockedInTime, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="item-text" style="padding-left: 1.5em; font-style: italic; color: #ccc;">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.isClockedIn, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'In Since ';
                  r += Template7Helpers.js.call(ctx_4, "moment(new Date(this.clockedInDatetime)).format('h:mm A')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				            </div>\n						</a>\n					</li>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</ul>\n			</div>\n		</div>\n		<div class="popover sort-menu">\n			<div class="popover-angle"></div>\n			<div class="popover-inner">\n				<div class="list">\n					<ul>\n						';
      r += Template7Helpers.each.call(ctx_1, ctx_1.sortOptions, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n						<li><a href="#" class="list-button item-link popover-close" @click="changedSort(\'';
          r += c(ctx_2.value, ctx_2);
          r += '\')">';
          r += c(ctx_2.name, ctx_2);
          r += '</a></li>\n						';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					</ul>\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.employeeRosterDiv .clockedOut .item-title {
		color: #666;
		font-style: italic;
		
	}
	
	.employeeRosterDiv .clockedOut {
		background: #eee;
		
	}
	
	.navbar .incallSubnavbar a.link.button {
		text-align: center;
		display: block !important;
	}
	
	.navbar .incallSubnavbar a.button.button-fill {
		background: #fff !important;
		color: #000 !important;
		text-decoration: underline !important;
		border-radius: 2em !important;
	}
	
`,
  styleScoped: false
};
    