
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this; //self.themeOptionNames= Object.keys(self.$root.user.settings.theme);

    self.themeOptionNames = [{
      name: 'App Main Color',
      code: 'mobileColorMain'
    }, {
      name: 'App Secondary Color',
      code: 'mobileColorSecondary'
    }, {
      name: 'App Font Color',
      code: 'mobileColorFont'
    }];
    self.themeOptionPickers = [];
  },
  data: function () {
    return {};
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
    self.uppy.close();

    for (var i = 0; i < self.themeOptionPickers.length; i++) {
      self.themeOptionPickers[i].destroy();
    }
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;
    },
    askForPhoto: function () {
      var self = this;
    },
    updateAppTheme: function () {
      var self = this;
      self.$root.mdWebSocket.sendOverWebSocket({
        action: 'saveMobileTheme',
        mainColor: self.$root.user.settings.theme.mobileColorMain,
        secondaryColor: self.$root.user.settings.theme.mobileColorSecondary,
        fontColor: self.$root.user.settings.theme.mobileColorFont
      });
      self.$root.setAppTheme({
        main: self.$root.user.settings.theme.mobileColorMain,
        secondary: self.$root.user.settings.theme.mobileColorSecondary,
        font: self.$root.user.settings.theme.mobileColorFont
      });
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;

      for (var i = 0; i < self.themeOptionNames.length; i++) {
        var colorOptionCode = self.themeOptionNames[i].code;
        self.themeOptionPickers.push(self.$app.colorPicker.create({
          colorOptionCode: self.themeOptionNames[i].code,
          colorOptionName: self.themeOptionNames[i].name,
          inputEl: '.colorPicker' + colorOptionCode,
          targetEl: '.colorPicker' + colorOptionCode + 'Icon',
          targetElSetBackgroundColor: true,
          modules: ['palette'],
          openIn: 'sheet',
          //openIn: 'auto',
          //openInPhone: 'sheet',
          toolbarSheet: !self.$app.data.isIOS9,
          renderToolbar: function (colorPicker) {
            return '<div class="toolbar toolbar-top no-shadow">' + '<div class="toolbar-inner">' + '<div class="left">' + colorPicker.params.colorOptionName + '</div>' + '<div class="right">' + '<a class="link sheet-close popover-close" data-sheet=".color-picker-sheet-modal" data-popover=".color-picker-popover">Done</a>' + '</div>' + '</div>' + '</div>';
          },
          palette: [['#FFFFFF', '#FFCDD2', '#EF9A9A', '#E57373', '#EF5350', '#F44336', '#E53935', '#D32F2F', '#C62828', '#B71C1C'], ['#DADADA', '#fecff2', '#feb7ec', '#fe9fe6', '#fe87e0', '#fd6fd9', '#fd57d3', '#fd3fcd', '#fc27c6', '#fc0fc0'], ['#C4C4C4', '#E1BEE7', '#CE93D8', '#BA68C8', '#AB47BC', '#9C27B0', '#8E24AA', '#7B1FA2', '#6A1B9A', '#4A148C'], ['#B6B6B6', '#C5CAE9', '#9FA8DA', '#7986CB', '#5C6BC0', '#3F51B5', '#3949AB', '#303F9F', '#283593', '#1A237E'], ['#919191', '#B3E5FC', '#81D4FA', '#4FC3F7', '#29B6F6', '#03A9F4', '#039BE5', '#0288D1', '#0277BD', '#01579B'], ['#6D6D6D', '#B2DFDB', '#80CBC4', '#4DB6AC', '#26A69A', '#009688', '#00897B', '#00796B', '#00695C', '#004D40'], ['#484848', '#DCEDC8', '#C5E1A5', '#AED581', '#9CCC65', '#8BC34A', '#7CB342', '#689F38', '#558B2F', '#33691E'], ['#242424', '#FFF9C4', '#FFF59D', '#FFF176', '#FFEE58', '#FFEB3B', '#FDD835', '#FBC02D', '#F9A825', '#F57F17'], ['#000000', '#FFE0B2', '#FFCC80', '#FFB74D', '#FFA726', '#FF9800', '#FB8C00', '#F57C00', '#EF6C00', '#E65100']],
          value: {
            hex: self.$root.user.settings.theme[colorOptionCode]
          },
          formatValue: function (value) {
            return this.$inputEl.attr('placeHolder');
          },
          on: {
            change: function (picker, value) {
              var thisCode = picker.$inputEl.attr('name');
              self.$root.user.settings.theme[thisCode] = value.hex; //console.log('picker',picker);
              //console.log('value',value);

              self.updateAppTheme();
            }
          }
        }));
      }

      self.uppy = Uppy({
        debug: false,
        autoProceed: true,
        allowMultipleUploads: false,
        restrictions: {
          maxFileSize: 10000000,
          maxNumberOfFiles: 1,
          minNumberOfFiles: 1,
          allowedFileTypes: ['image/*']
        },
        meta: {
          action: 'saveProfileImage',
          version: 2,
          sessionid: self.$root.mdWebSocket.sessionId
        }
      }).use(UppyDashboard, {
        trigger: '.profileImageAskForPhoto',
        inline: false,
        target: '#app',
        closeAfterFinish: true,
        disableThumbnailGenerator: true,
        showSelectedFiles: false,
        closeModalOnClickOutside: true,
        onRequestCloseModal: function () {
          self.uppy.getPlugin('Dashboard').closeModal();
          self.$('.uppy-DashboardContent-back').trigger('click');
          self.uppy.reset();
        }
      }).use(UppyXHRUpload, {
        endpoint: 'https://taskmaster.bedroomsandmore.com/4DACTION/api/saveProfileImage',
        formData: true,
        fieldName: 'files[]'
      });

      if (self.$app.device.desktop) {
        // If Desktop...
        self.uppy.use(UppyWebcam, {
          target: UppyDashboard,
          modes: ['picture']
        });
      }

      self.uppy.on('complete', result => {
        // console.log('successful files:', result.successful);
        // console.log('failed files:', result.failed);
        if (result.successful.length > 0) {
          self.$root.user.imageType = result.successful[0].response.body.imageType;
          self.$root.user.imageUpdatedDate = result.successful[0].response.body.imageUpdatedDate;
          self.$root.user.imageUpdatedTime = result.successful[0].response.body.imageUpdatedTime;
          self.$root.user.imageUpdatedDateTime = result.successful[0].response.body.imageUpdatedDateTime; // self.$root.noCacheStrings.profileImage= Math.random();
          //self.$root.user.imageUpdatedDateTime= Math.random(); // TODO... use the actual date time updated...

          self.$root.updateProfileImage(self.$root.getImageURL(self.$root.user.id, self.$root.user.imageType, self.$root.user.imageUpdatedDateTime));

          if (app.f7.data.localStorageData) {
            if (app.f7.data.localStorageData.saveLogin) {
              self.$app.data.localStorageData.user = self.$app.data.user;
              self.$app.methods.setItemLocalStorage('localStorageData', self.$app.data.localStorageData);
            }
          }
        } else {// TODO: alert error...
        }

        self.uppy.reset();
      });
    }
  },
  id: '51b834a670',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="blankPage">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">Settings</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text">Settings</div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="page-content">\n			<div class="block-title">Profile</div>\n			<div class="block block-strong employeeBlock no-margin">\n				<div class="profileImageAskForPhoto" style="float: left;">\n					<a class="none">\n						<div class="image-crop-circle profile-image" style="height: 90px; width: 90px; border: 2px solid #fff; background: #fff url(\'';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.user.id, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.js.call(ctx_2, "this.$root.getImageURL(this.$root.user.id,this.$root.user.imageType,this.$root.user.imageUpdatedDateTime)", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += 'static/images/user.png';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\') top center; background-size: cover;"></div>\n					</a>\n				</div>\n				<div style="margin-left: 120px; margin-top: 1em;">';
      r += c(ctx_1.$root.user.fullname, ctx_1);
      r += '<br>';
      r += c(ctx_1.$root.user.department, ctx_1);
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.user.settings.hasPhone, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<br>Ext: ';
          r += c(ctx_2.$root.user.settings.phoneLogin.extension, ctx_2);
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n			</div>\n			<div class="block-title">Theme</div>\n			<div class="list no-hairlines-md">\n				<ul>\n					';
      r += Template7Helpers.each.call(ctx_1, ctx_1.themeOptionNames, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<li>\n						<div class="item-content item-input">\n							<div class="item-media">\n								<i class="icon demo-list-icon colorPicker';
          r += c(ctx_2.code, ctx_2);
          r += 'Icon"></i>\n							</div>\n							<div class="item-inner">\n								<div class="item-input-wrap">\n									<input type="text" placeholder="';
          r += c(ctx_2.name, ctx_2);
          r += '" readonly="readonly" class="colorPicker';
          r += c(ctx_2.code, ctx_2);
          r += '" name="';
          r += c(ctx_2.code, ctx_2);
          r += '" />\n								</div>\n							</div>\n						</div>\n					</li>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</ul>\n			</div>\n			<div class="block-title">Notifications</div>\n			<div class="block block-strong">\n				<p>TODO</p>\n			</div>\n			<div class="block-title">My Devices</div>\n			<div class="block block-strong">\n				<p>TODO</p>\n			</div>\n			';
      r += Template7Helpers.if.call(ctx_1, false, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <!-- $root.phoneIsActive -->\n			<div class="block-title">Phone</div>\n			<div class="list">\n				<ul>\n					<li>\n						<a class="item-link smart-select smart-select-init">\n							<select name="phoneAudioInput">\n								';
          r += Template7Helpers.each.call(ctx_2, ctx_2.$root.PHONE.audioInputDevices, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n								<option value="';
              r += c(ctx_3.deviceId, ctx_3);
              r += '"';
              r += Template7Helpers.unless.call(ctx_3, data_3 && data_3.index, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' selected';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '>';
              r += c(ctx_3.label, ctx_3);
              r += '</option>\n								';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n							</select>\n							<div class="item-content">\n								<div class="item-inner">\n									<div class="item-title">Audio Input</div>\n								</div>\n							</div>\n						</a>\n					</li>\n					<li>\n						<a class="item-link smart-select smart-select-init">\n							<select name="phoneAudioOutput">\n								';
          r += Template7Helpers.each.call(ctx_2, ctx_2.$root.PHONE.audioOutputDevices, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n								<option value="';
              r += c(ctx_3.deviceId, ctx_3);
              r += '"';
              r += Template7Helpers.unless.call(ctx_3, data_3 && data_3.index, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' selected';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '>';
              r += c(ctx_3.label, ctx_3);
              r += '</option>\n								';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n							</select>\n							<div class="item-content">\n								<div class="item-inner">\n									<div class="item-title">Audio Output</div>\n								</div>\n							</div>\n						</a>\n					</li>\n				</ul>\n			</div>\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			<div class="DashboardContainer"></div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.ios .demo-list-icon {
		width: 29px;
		height: 29px;
		border-radius: 6px;
		margin: 0px auto;
		border: 2px solid #000;
		box-sizing: border-box;
	}
	
	.md .demo-list-icon {
		width: 24px;
		height: 24px;
		border-radius: 4px;
		margin: 0px auto;
		border: 2px solid #000;
	}
	
	.aurora .demo-list-icon {
		width: 18px;
		height: 18px;
		border-radius: 4px;
		margin: 0px auto;
		border: 2px solid #000;
	}
`,
  styleScoped: false
};
    