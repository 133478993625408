
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {
      currentDateTime: new Date(),
      checkboxOptions: [{
        name: 'Urgent',
        slug: 'urgent',
        color: 'red',
        checked: false
      }, {
        name: 'Telephoned',
        slug: 'telephoned',
        color: 'blue',
        checked: false
      }, {
        name: 'Will Call Again',
        slug: 'willCallAgain',
        color: 'purple',
        checked: false
      }, {
        name: 'Returned Your Call',
        slug: 'returnedYourCall',
        color: 'orange',
        checked: false
      }, {
        name: 'Please Callback',
        slug: 'pleaseCallback',
        color: 'teal',
        checked: false
      }, {
        name: 'Came in to See You',
        slug: 'cameInToSeeYou',
        color: 'black',
        checked: false
      }, {
        name: 'Wants to See You',
        slug: 'wantsToSeeYou',
        color: 'yellow',
        checked: false
      }, {
        name: 'No Action Needed, Just FYI',
        slug: 'noActionNeeded',
        color: 'pink',
        checked: false
      }]
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'employees') {
        self.$setState();
      }
    },
    clickedChip: function (e) {
      var self = this;
      var thisElement = self.$(e.target).closest('.chip');
      var inputElement = thisElement.find('input');
      var color = thisElement.attr('data-color');

      if (inputElement.val() == '') {
        thisElement.addClass('color-' + color);
        thisElement.find('.chip-media').removeClass('bg-color-gray');
        thisElement.find('.chip-media').addClass('bg-color-' + color);
        thisElement.find('.icon.if-not-md').text('checkmark_alt_circle');
        thisElement.find('.icon.md-only').text('check_box');
        inputElement.val('true');
      } else {
        thisElement.removeClass('color-' + color);
        thisElement.find('.chip-media').removeClass('bg-color-' + color);
        thisElement.find('.chip-media').addClass('bg-color-gray');
        thisElement.find('.icon.if-not-md').text('circle');
        thisElement.find('.icon.md-only').text('check_box_outline_blank');
        inputElement.val('');
      } // console.log('thisElement',thisElement);

    },
    saveMessage: function () {
      var self = this;
      var formData = self.$app.form.convertToData('#leaveMessageForm');
      formData.checkboxes = [];

      for (var i = 0; i < self.checkboxOptions.length; i++) {
        formData.checkboxes.push({
          name: self.checkboxOptions[i].name,
          slug: self.checkboxOptions[i].slug,
          value: formData[self.checkboxOptions[i].slug] == 'true'
        });
      } // console.log('formData',formData);


      if (formData.forWho == '0') {
        self.$el.find('#forWho').css('color', 'red');
      } else {
        self.$el.find('#forWho').removeAttr('style'); // options[sel.selectedIndex].text

        var selectBoxElement = self.$el.find('#forWho');
        var forEmployeeName = selectBoxElement[0].options[selectBoxElement[0].selectedIndex].text;
        formData.action = 'leaveMessage';
        self.$root.mdWebSocket.sendOverWebSocket(formData); //self.$el.find('#leaveMessageForm')[0].reset();
        //self.$setState();
        //self.$router.refreshPage();

        self.$router.back();
        app.f7.toast.create({
          icon: app.f7.theme === 'ios' ? '<i class="f7-icons">checkmark</i>' : '<i class="material-icons">check</i>',
          text: 'Message Left & Emailed to ' + forEmployeeName,
          position: 'center',
          closeTimeout: 5000,
          destroyOnClose: true
        }).open();
        /*
        var feedbackreceivedNotification= self.$app.notification.create({
        	icon: '<i class="icon f7-icons">info_fill</i>',
        	title: "Success",
        	titleRightText: 'now',
        	text: 'Your "While You Were Away" message has been left.',
        	closeOnClick: true,
        	closeTimeout: 5000,
        	on: {
        		close: function () {
        			// TODO: destroy this?
        		},
        	},
        }).open();
        */
      }
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.$el.find('form, input').each(function () {
        self.$(this).attr('autocomplete', 'off');
      });
    }
  },
  id: '4e7a464576',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="blankPage">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">Leave "Away" Message</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n			</div>\n		</div>\n		<div class="page-content">\n			<form id="leaveMessageForm">\n				<div class="list no-margin-top">\n					<ul>\n						<li>\n							<div class="item-content item-input">\n								<div class="item-inner">\n									<div class="item-input-wrap">\n										<input type="datetime-local" name="dateTime" value="';
      r += Template7Helpers.js.call(ctx_1, "this.currentDateTime.toDatetimeLocal().substr(0,16)", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n									</div>\n								</div>\n							</div>\n						</li>\n						<li>\n							<div class="item-content item-input">\n								<div class="item-inner">\n									<div class="item-input-wrap">\n										<select id="forWho" name="forWho">\n											<option value="0">For Who? (Please Select Employee)</option>\n											';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.taskMasterData.employees, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n											<option value="';
          r += c(ctx_2.id, ctx_2);
          r += '">';
          r += c(ctx_2.name, ctx_2);
          r += '</option>\n											';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n										</select>\n									</div>\n								</div>\n							</div>\n						</li>\n						<li>\n							<div class="item-content item-input">\n								<div class="item-inner">\n									<div class="item-input-wrap">\n										<input type="text" name="whoCalled" placeholder="Who Called?">\n									</div>\n								</div>\n							</div>\n						</li>\n						<li>\n							<div class="item-content item-input">\n								<div class="item-inner">\n									<div class="item-input-wrap">\n										<input type="text" name="phoneOrEmail" placeholder="Phone or Email?">\n									</div>\n								</div>\n							</div>\n						</li>\n						<li class="item-content">\n							<div class="item-inner item-cell">\n								<div class="item-row">\n									<div class="item-cell text-align-center">\n										';
      r += Template7Helpers.each.call(ctx_1, ctx_1.checkboxOptions, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n										<div class="chip clickChip" @click="clickedChip" data-color="';
          r += c(ctx_2.color, ctx_2);
          r += '">\n											<input type="hidden" name="';
          r += c(ctx_2.slug, ctx_2);
          r += '" value="';
          r += Template7Helpers.if.call(ctx_2, ctx_2.checked, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'true';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '">\n											<div class="chip-media bg-color-gray">\n												<i class="icon f7-icons if-not-md">circle</i>\n												<i class="icon material-icons md-only">check_box_outline_blank</i>\n											</div>\n											<div class="chip-label">';
          r += c(ctx_2.name, ctx_2);
          r += '</div>\n										</div>\n										';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n									</div>\n								</div>\n							</div>\n						</li>\n						<li class="item-content item-input">\n							<div class="item-inner">\n								<div class="item-title item-label">Message</div>\n								<div class="item-input-wrap">\n									<textarea name="message" placeholder="Write the message here..."></textarea>\n								</div>\n							</div>\n						</li>\n						<li class="item-content">\n							<div class="item-inner item-cell">\n								<div class="item-row">\n									<div class="item-cell text-align-center">\n										<a href="#" class="button button-large button-raised button-fill" @click="saveMessage">Save Message</span></a>\n									</div>\n								</div>\n							</div>\n						</li>\n					</ul>\n				</div>\n			</form>\n			<!-- TODO: messages I\'VE LEFT history here... or both? -->\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	
`,
  styleScoped: false
};
    