
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.tableNumber = parseInt(self.$route.params.tableNumber);
    self.recordId = parseInt(self.$route.params.recordId);
    var indexOfTableElement = self.$app.data.taskMasterData.taskMasterTables.map(function (elem) {
      return elem.number;
    }).indexOf(self.tableNumber);
    self.tableDetails = self.$app.data.taskMasterData.taskMasterTables[indexOfTableElement];
    self.fieldNames = self.tableDetails.fieldTitles;
    self.templateArray = self.fieldNames.map(function (elem) {
      return {
        type: 0,
        name: elem,
        value: '--------',
        valueAttributeSafe: '',
        changed: false
      };
    });
    self.templateArray.shift(); // To remove ID field...
  },
  data: function () {
    var self = this;
    return {
      tableNumber: 0,
      recordId: 0,
      loaded: false,
      tableDetails: {},
      fieldTypes: {},
      recordData: {},
      recordDataOriginal: {},
      fieldNames: [],
      templateArray: [],
      changesMade: {
        old: {},
        new: {},
        types: {}
      }
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'taskMasterTableInput') {
        // Save the data here...
        self.fieldTypes = data.fieldTypes;
        self.recordData = data.taskMasterTableInput; // self.fieldNames= Object.keys(self.fieldTypes);

        self.templateArray = [];

        for (var i = 0; i < self.fieldNames.length; i++) {
          if (self.fieldNames[i] !== 'ID') {
            // SKIP IDs...
            var tempValue = self.recordData[self.fieldNames[i]];
            var tempType = self.fieldTypes[self.fieldNames[i]];

            if (tempType == 4) {
              // DATE
              tempValue = tempValue.substring(0, 10);

              if (tempValue == '0000-00-00') {
                tempValue = '';
              }
            } else if (tempType == 11) {
              // TIME
              var tempSeconds = Math.floor(tempValue / 1000);
              var tempHours = Math.floor(tempSeconds / 3600);
              tempSeconds = tempSeconds / 3600 - tempHours;
              var tempMinutes = Math.floor(tempSeconds * 60);
              tempSeconds = tempSeconds * 60 - tempMinutes;
              tempSeconds = Math.floor(tempSeconds * 60);

              if (tempHours < 10) {
                tempHours = '0' + tempHours;
              }

              if (tempMinutes < 10) {
                tempMinutes = '0' + tempMinutes;
              }

              if (tempSeconds < 10) {
                tempSeconds = '0' + tempSeconds;
              }

              tempValue = tempHours + ':' + tempMinutes + ':' + tempSeconds;

              if (tempValue == '00:00:00') {
                tempValue = '';
              }
            } else if (tempType == 38) {
              // JSON Object
              tempValue = JSON.stringify(tempValue, null, '   ');
            }

            self.templateArray.push({
              name: self.fieldNames[i],
              type: tempType,
              value: tempValue,
              valueAttributeSafe: (tempValue + '').replace(/"/g, '&quot;'),
              changed: false
            });
          }
        }

        self.loaded = true;
        self.$setState({}, function () {
          self.$('textarea.resizable').each(function (e) {
            var thisItem = self.$(this);
            self.$app.input.resizeTextarea(thisItem);
          });
          /*
          self.$('textarea,input').each(function(e) {
          	var thisItem= self.$(this);
          	self.$app.input.checkEmptyState(thisItem);
          });
          */

          self.recordDataOriginal = self.getOnScreenDataObject();
        });
      } else if (data.type == 'event' && data.event == 'taskMasterTableInputSaved') {
        self.$app.preloader.hide();

        if (data.ok) {
          self.$app.toast.create({
            icon: self.$app.theme === 'ios' ? '<i class="icon f7-icons">checkmark</i>' : '<i class="icon material-icons">check</i>',
            text: 'Record Saved',
            position: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
          }).open();
          self.$router.back();
        } else {
          self.$app.dialog.alert(data.error, 'Error');
        }
      }
    },
    saveRecord: function () {
      var self = this;
      var onScreenData = self.getOnScreenDataObject(); //self.changesMade= (JSON.stringify(onScreenData) !== JSON.stringify(self.recordDataOriginal));

      self.changesMade = self.getChangesMade();
      console.log('self.changesMade', self.changesMade);

      if (Object.keys(self.changesMade.old).length > 0) {
        // TODO: if we saved a new record, then change the "output" records next?
        self.$root.mdWebSocket.sendOverWebSocket({
          action: 'saveRecordData',
          tableNumber: self.tableNumber,
          tableDetails: self.tableDetails,
          recordId: self.recordId,
          recordData: self.changesMade.new
        }); // TODO: progress dialog... and then...

        self.$app.preloader.show();
      } else {
        // Just leave...
        app.f7.toast.create({
          icon: app.f7.theme === 'ios' ? '<i class="icon f7-icons">checkmark</i>' : '<i class="icon material-icons">check</i>',
          text: 'Record Saved',
          position: 'center',
          closeTimeout: 2000,
          destroyOnClose: true
        }).open();
        self.$router.back();
      }
    },
    cancelGoBack: function () {
      var self = this; //self.changesMade= !(self.$app.methods.object_isEquivalent(self.$app.form.convertToData(self.$('form#taskMasterTableInputForm')),self.recordDataOriginal));
      //self.changesMade= (JSON.stringify(self.$app.form.convertToData(self.$('form#taskMasterTableInputForm'))) !== JSON.stringify(self.recordDataOriginal));

      self.changesMade = self.getChangesMade(); // console.log('self.changesMade',self.changesMade);

      if (Object.keys(self.changesMade.old).length > 0) {
        self.$app.dialog.confirm('You have made changes on this page, are you sure you want to leave without saving?', 'Unsaved Changes', function () {
          self.$router.back();
        });
      } else {
        self.$router.back();
      }
    },
    getChangesMade: function () {
      var self = this;
      var onScreenData = self.getOnScreenDataObject();
      var changesObject = {
        old: {},
        new: {},
        types: {}
      };
      var fieldNames = Object.keys(self.recordDataOriginal);

      for (var i = 0; i < fieldNames.length; i++) {
        var changeMade = false;
        changeMade = onScreenData[fieldNames[i]] !== self.recordDataOriginal[fieldNames[i]];

        if (changeMade) {
          changesObject.new[fieldNames[i]] = onScreenData[fieldNames[i]];
          changesObject.old[fieldNames[i]] = self.recordDataOriginal[fieldNames[i]];
          changesObject.types[fieldNames[i]] = self.fieldTypes[fieldNames[i]];
        }
      }

      return changesObject;
    },
    getOnScreenDataObject: function () {
      var self = this;
      var onScreenData = {};
      onScreenData = self.$app.form.convertToData(self.$('form#taskMasterTableInputForm')); // TODO: convert on screen booleans...

      var fieldNames = Object.keys(onScreenData);

      for (var i = 0; i < fieldNames.length; i++) {
        if (self.fieldTypes[fieldNames[i]] == 6) {
          onScreenData[fieldNames[i]] = onScreenData[fieldNames[i]].length > 0;
        }
      }

      console.log('onScreenData', onScreenData);
      return onScreenData;
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
    }
  },
  id: 'c0cec096b0',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="taskMasterTablesInput">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link" @click="cancelGoBack">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.recordId, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '#';
          r += c(ctx_2.recordId, ctx_2);
          r += ' - ';
          r += c(ctx_2.tableDetails.name, ctx_2);
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += 'New Record';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<!-- TODO: if editing... but if not then have an "EDIT" button there?  or assume read write always? -->\n					<a href="#" class="link" @click="saveRecord">Save</a>\n				</div>\n			</div>\n		</div>\n		<div class="page-content">\n			<form class="list no-margin-top" id="taskMasterTableInputForm">\n				<ul>\n					';
      r += Template7Helpers.each.call(ctx_1, ctx_1.templateArray, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n						';
          r += Template7Helpers.js_if.call(ctx_2, "this.type == 11", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<!-- Time -->\n						<li class="item-content item-input">\n							<div class="item-inner">\n								<div class="item-title item-label">';
              r += c(ctx_3.name, ctx_3);
              r += '</div>\n								<div class="item-input-wrap">\n									<input type="time" name="';
              r += c(ctx_3.name, ctx_3);
              r += '" placeholder="';
              r += c(ctx_3.name, ctx_3);
              r += '" step="1" value="';
              r += c(ctx_3.valueAttributeSafe, ctx_3);
              r += '">\n								</div>\n							</div>\n						</li>\n						';
              r += Template7Helpers.js_if.call(ctx_3, "this.type == 4", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.js_if.call(ctx_4, "(this.type == 8) || (this.type == 9) || (this.type == 25)", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += Template7Helpers.js_if.call(ctx_5, "this.type == 6", {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += Template7Helpers.js_if.call(ctx_6, "(this.type == 2) || (this.type == 0) || (this.type == 38)", {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n						';
              r += Template7Helpers.js_if.call(ctx_3, "this.type == 4", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<!-- Date -->\n						<li class="item-content item-input">\n							<div class="item-inner">\n								<div class="item-title item-label">';
                  r += c(ctx_4.name, ctx_4);
                  r += '</div>\n								<div class="item-input-wrap">\n									<input type="date" name="';
                  r += c(ctx_4.name, ctx_4);
                  r += '" placeholder="';
                  r += c(ctx_4.name, ctx_4);
                  r += '" value="';
                  r += c(ctx_4.valueAttributeSafe, ctx_4);
                  r += '">\n								</div>\n							</div>\n						</li>\n						';
                  r += Template7Helpers.js_if.call(ctx_4, "(this.type == 8) || (this.type == 9) || (this.type == 25)", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += Template7Helpers.js_if.call(ctx_5, "this.type == 6", {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += Template7Helpers.js_if.call(ctx_6, "(this.type == 2) || (this.type == 0) || (this.type == 38)", {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						';
                  r += Template7Helpers.js_if.call(ctx_4, "(this.type == 8) || (this.type == 9) || (this.type == 25)", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<!-- Integer -->\n						<li class="item-content item-input">\n							<div class="item-inner">\n								<div class="item-title item-label">';
                      r += c(ctx_5.name, ctx_5);
                      r += '</div>\n								<div class="item-input-wrap">\n									<input type="number" name="';
                      r += c(ctx_5.name, ctx_5);
                      r += '" placeholder="';
                      r += c(ctx_5.name, ctx_5);
                      r += '" pattern="\d*" value="';
                      r += c(ctx_5.valueAttributeSafe, ctx_5);
                      r += '">\n								</div>\n							</div>\n						</li>\n						';
                      r += Template7Helpers.js_if.call(ctx_5, "this.type == 6", {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += Template7Helpers.js_if.call(ctx_6, "(this.type == 2) || (this.type == 0) || (this.type == 38)", {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n						';
                      r += Template7Helpers.js_if.call(ctx_5, "this.type == 6", {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '<!-- Boolean -->\n						<li class="item-content">\n							<div class="item-inner">\n								<div class="item-title">';
                          r += c(ctx_6.name, ctx_6);
                          r += '</div>\n								<div class="item-after">\n									<label class="toggle">\n										<input type="checkbox" name="';
                          r += c(ctx_6.name, ctx_6);
                          r += '"';
                          r += Template7Helpers.if.call(ctx_6, ctx_6.value, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += ' checked';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '>\n										<span class="toggle-icon"></span>\n									</label>\n								</div>\n							</div>\n						</li>\n						';
                          r += Template7Helpers.js_if.call(ctx_6, "(this.type == 2) || (this.type == 0) || (this.type == 38)", {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n						';
                          r += Template7Helpers.js_if.call(ctx_6, "(this.type == 2) || (this.type == 0) || (this.type == 38)", {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '<!-- Text / Alpha / Object -->\n						<li class="item-content item-input">\n							<div class="item-inner">\n								<div class="item-title item-label">';
                              r += c(ctx_7.name, ctx_7);
                              r += '</div>\n								<div class="item-input-wrap';
                              r += Template7Helpers.unless.call(ctx_7, root.loaded, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += ' skeleton-text skeleton-effect-fade';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                              });
                              r += '">\n									<textarea class="resizable" style="misn-height: 124px;" name="';
                              r += c(ctx_7.name, ctx_7);
                              r += '" placeholder="';
                              r += c(ctx_7.name, ctx_7);
                              r += '">';
                              r += c(ctx_7.value, ctx_7);
                              r += '</textarea>\n								</div>\n							</div>\n						</li>\n						';
                              return r;
                            },
                            inverse: function (ctx_7, data_7) {
                              var r = '';
                              r += '<!-- Other -->\n						<li class="item-content item-input">\n							<div class="item-inner">\n								<div class="item-title item-label">';
                              r += c(ctx_7.name, ctx_7);
                              r += ' - ';
                              r += c(ctx_7.type, ctx_7);
                              r += '</div>\n								<div class="item-input-wrap">\n									<input type="text" name="';
                              r += c(ctx_7.name, ctx_7);
                              r += '" placeholder="';
                              r += c(ctx_7.name, ctx_7);
                              r += '" value="';
                              r += c(ctx_7.valueAttributeSafe, ctx_7);
                              r += '">\n								</div>\n							</div>\n						</li>\n						';
                              return r;
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '\n						';
                          return r;
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '\n						';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n						';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n						';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</ul>\n			</form>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `

`,
  styleScoped: false
};
    