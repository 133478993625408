
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.employeeId = parseInt(self.$route.params.employeeId);
    self.employeeObject = self.$app.data.taskMasterData.employees.filter(function (currentElement) {
      return currentElement.id == self.employeeId;
    })[0];
    self.employeeName = self.employeeObject.name;

    if (self.employeeObject.phone == '' && self.employeeObject.extension == 0) {
      self.ableToCall = false;
    }

    console.log('self.employeeObject', self.employeeObject);
  },
  data: function () {
    return {
      employeeId: 0,
      employeeName: '',
      employeeObject: {},
      ableToCall: true
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'employees') {
        self.$setState();
      }
    },
    callEmployee: function () {
      var self = this;

      if (self.$app.data.phoneIsActive) {
        var employeeButtons = [];

        if (self.$app.data.phoneIsActive) {
          if (self.employeeObject.isClockedIn && self.employeeObject.extension > 0) {
            employeeButtons.push({
              text: 'Call Extension: ' + self.employeeObject.extension,
              color: 'blue',
              onClick: function () {
                self.callEmployeeExtension();
              }
            });
          }

          if (self.employeeObject.extension > 0) {
            employeeButtons.push({
              text: 'Leave Voicemail',
              color: 'orange',
              onClick: function () {
                self.callEmployeeVoicemail();
              }
            });
          }
        }

        if (self.employeeObject.phone !== '') {
          employeeButtons.push({
            text: 'Call Cell: ' + self.$app.methods.formatPhoneNumber(self.employeeObject.phone),
            color: 'gray',
            onClick: function () {
              self.callEmployeeCellPhone();
            }
          });
        }

        employeeButtons.push({
          text: 'Cancel',
          color: 'red'
        });
        var dialogText = '';
        self.$app.dialog.create({
          title: 'Call ' + self.employeeName,
          text: dialogText,
          buttons: employeeButtons,
          verticalButtons: true,
          destroyOnClose: true,
          closeByBackdropClick: true
        }).open();
      }
    },
    callEmployeeExtension: function () {
      var self = this;

      if (self.$root.phoneIsActive) {
        self.$app.methods.callNumber(self.employeeObject.extension, self.employeeObject.name);
      } else {
        var toastLargeMessage = self.$app.toast.create({
          text: self.employeeObject.name + '<br>(206) 633-4498 x ' + self.employeeObject.extension,
          cssClass: 'large',
          position: 'center',
          closeTimeout: 10000
        }).open();
      }
    },
    callEmployeeVoicemail: function () {
      var self = this;

      if (self.$root.phoneIsActive) {
        self.$app.methods.callNumber('*96' + self.employeeObject.extension, self.employeeObject.name + ' Voicemail');
      }
    },
    callEmployeeCellPhone: function () {
      var self = this;
      var messageToConfirm = 'Are you sure you want to call them when they are off work?<br><br><strong>' + self.employeeObject.name + '</strong><br>' + self.$app.methods.formatPhoneNumber(self.employeeObject.phone);

      if (self.employeeObject.isClockedIn) {
        messageToConfirm = 'Are you sure you want to call their cell phone number?<br><br><strong>' + self.employeeObject.name + '</strong><br>' + self.$app.methods.formatPhoneNumber(self.employeeObject.phone);
      }

      self.$app.methods.callNumber(self.employeeObject.phone, self.employeeObject.name, messageToConfirm);
    },
    emailEmployee: function () {
      var self = this;
      var urlForEmail = 'mailto:' + self.employeeObject.email + '?subject=Message From ' + self.$app.data.user.name + '&body=';

      if (self.$app.device.cordova) {
        window.open(urlForEmail, '_system');
      } else {
        window.location.href = urlForEmail;
      }
    },
    messageEmployee: function () {
      var self = this;
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
    }
  },
  id: '68ff50723b',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="employeesDetailPage">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">&nbsp;</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n			</div>\n		</div>\n		<div class="page-content">\n			<div class="block text-align-center">\n				<div class="image-crop-circle" style="height: 100px; width: 100px; margin: 0px auto; border: 2px solid #fff; background: #fff url(\'https://taskmaster.bedroomsandmore.com/4DACTION/getImage/Employees/';
      r += c(ctx_1.employeeObject.id, ctx_1);
      r += '.';
      r += c(ctx_1.employeeObject.imageType, ctx_1);
      r += '?updated=';
      r += c(ctx_1.employeeObject.imageUpdatedDateTime, ctx_1);
      r += '\') top center; background-size: cover;"></div>\n				<h2 class="no-margin">';
      r += c(ctx_1.employeeObject.firstname, ctx_1);
      r += ' ';
      r += c(ctx_1.employeeObject.lastname, ctx_1);
      r += '</h2>\n				<p class="no-margin" style="color: #999;">';
      r += c(ctx_1.employeeObject.title, ctx_1);
      r += '</p>\n			</div>\n			<div class="block text-align-center">\n				<div class="row">\n					<div class="col"><a href="#" class="link iconButton disabled" @click="messageEmployee"><i class="icon f7-icons if-not-md">chat_bubble_2</i><i class="icon material-icons md-only">message</i><br>message</a></div>\n					<div class="col"><a href="#" class="link iconButton';
      r += Template7Helpers.js_if.call(ctx_1, "(!this.$root.phoneIsActive) || (!this.ableToCall)", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" @click="callEmployee"><i class="icon f7-icons if-not-md">phone_fill</i><i class="icon material-icons md-only">phone</i><br>call</a></div>\n					<div class="col"><a href="#" class="link iconButton';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.employeeObject.email, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" @click="emailEmployee"><i class="icon f7-icons if-not-md">envelope</i><i class="icon material-icons md-only">email</i><br>email</a></div>\n				</div>\n			</div>\n			<div class="block block-strong no-margin">\n				<p><span style="color: #999;">Today\'s shift: </span>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.employeeObject.todayShiftText, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += c(ctx_2.employeeObject.todayShiftText, ctx_2);
          r += ', ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += Template7Helpers.js_if.call(ctx_1, "((this.employeeObject.clockedOutTime) && (this.employeeObject.clockedOutTime > 0))", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'Left at ';
          r += Template7Helpers.js.call(ctx_2, "moment(new Date(this.employeeObject.clockedOutDatetime)).format('h:mma')", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.js_if.call(ctx_2, "((this.employeeObject.clockedInTime) && (this.employeeObject.clockedInTime > 0))", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.js_if.call(ctx_2, "((this.employeeObject.clockedInTime) && (this.employeeObject.clockedInTime > 0))", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'Clocked In at ';
              r += Template7Helpers.js.call(ctx_3, "moment(new Date(this.employeeObject.clockedInDatetime)).format('h:mma')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</p>\n				<p>';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.employeeObject.isClockedIn, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<span style="color: #999;">Next In:</span> ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += Template7Helpers.if.call(ctx_1, ctx_1.employeeObject.nextShiftBadge.color, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<span class="badge color-';
          r += c(ctx_2.employeeObject.nextShiftBadge.color, ctx_2);
          r += ' badge-empty">&nbsp;</span>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += c(ctx_1.employeeObject.nextShiftText, ctx_1);
      r += '</p>\n			</div>\n			';
      r += Template7Helpers.if.call(ctx_1, ctx_1.employeeObject.email, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="list">\n				<ul>\n					<li>\n						<a href="#" class="item-link item-content" @click="emailEmployee">\n							<div class="item-inner">\n								<div class="item-title">\n									<div class="item-header">email</div>\n									<span style="color: #2196f3">';
          r += c(ctx_2.employeeObject.email, ctx_2);
          r += '</span>\n								</div>\n							</div>\n						</a>\n					</li>\n				</ul>\n			</div>\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			';
      r += Template7Helpers.if.call(ctx_1, ctx_1.employeeObject.extension, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="list">\n				<ul>\n					<li>\n						<a href="#" class="item-link item-content" @click="callEmployeeExtension">\n							<div class="item-inner">\n								<div class="item-title">\n									<div class="item-header">extension</div>\n									<span style="color: #2196f3">206.633.4498 x ';
          r += c(ctx_2.employeeObject.extension, ctx_2);
          r += '</span>\n								</div>\n							</div>\n						</a>\n					</li>\n					';
          r += Template7Helpers.if.call(ctx_2, ctx_2.$root.phoneIsActive, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<li>\n						<a href="#" class="item-link item-content" @click="callEmployeeVoicemail">\n							<div class="item-inner">\n								<div class="item-title">\n									<span style="color: #ff9500">Leave Voicemail</span>\n								</div>\n							</div>\n						</a>\n					</li>\n					';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				</ul>\n			</div>\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			';
      r += Template7Helpers.if.call(ctx_1, ctx_1.employeeObject.phone, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="list">\n				<ul>\n					<li>\n						<a href="#" class="item-link item-content" @click="callEmployeeCellPhone">\n							<div class="item-inner">\n								<div class="item-title">\n									<div class="item-header">mobile</div>\n									<span style="color: #2196f3">';
          r += Template7Helpers.js.call(ctx_2, "this.$app.methods.formatPhoneNumber(this.employeeObject.phone)", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span>\n								</div>\n							</div>\n						</a>\n					</li>\n				</ul>\n			</div>\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.link.iconButton {
		display: inline;

	}

	.link.iconButton i {
		background: var(--f7-theme-color);
		color: #fff;
		border-radius: 18px;
		padding: 0.25em;

	}

`,
  styleScoped: false
};
    