
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {};
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;
    },
    saveRequest: function () {
      var self = this;
      var appRequestMessage = self.$el.find('#appRequestMessage').val(); // console.log('appRequestMessage',appRequestMessage);

      if (appRequestMessage !== '') {
        self.$root.mdWebSocket.sendOverWebSocket({
          action: 'sendFeedback',
          feedback: appRequestMessage,
          page: '/appRequests/'
        });
        var feedbackreceivedNotification = self.$app.notification.create({
          icon: '<i class="icon f7-icons">info_circle_fill</i>',
          title: "Request Received",
          titleRightText: 'now',
          text: 'Thank you for the message! I will review this. - Matt',
          closeOnClick: true,
          closeTimeout: 5000,
          on: {
            close: function () {// TODO: destroy this?
            }
          }
        }).open();
        self.$app.data.mainView.router.back();
      }
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
    },
    pageAfterIn: function (e, page) {
      var self = this;
      self.$el.find('#appRequestMessage').focus();
    }
  },
  id: '5fe437ca5e',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="blankPage">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">App Requests</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text">App Requests</div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="page-content">\n			<div class="block halfMarginTopBottom">\n				<p>Your request will be emailed to Matt Davis. Any type of request can be put in here.</p>\n			</div>\n			<form class="list no-margin-top">\n				<ul>\n					<li class="item-content item-input">\n						<div class="item-inner">\n							<div class="item-input-wrap">\n								<textarea id="appRequestMessage" name="appRequestMessage" placeholder="Type or speak your app request here..." style="height: 100px;"></textarea>\n							</div>\n						</div>\n					</li>\n					<li class="item-content">\n						<div class="item-inner item-cell">\n							<div class="item-row">\n								<div class="item-cell text-align-center">\n									<a href="#" class="button centeredLimitedButton button-large button-raised button-fill" @click="saveRequest">Send Request</span></a>\n								</div>\n							</div>\n						</div>\n					</li>\n				</ul>\n			</form>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.block.halfMarginTopBottom {
		margin-top: calc(var(--f7-block-margin-vertical)/2);
		margin-bottom: calc(var(--f7-block-margin-vertical)/2);
	}
`,
  styleScoped: false
};
    