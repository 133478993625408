
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;

    if (self.$root.user.id) {
      if (self.$root.isIOS9) {
        self.imageType = 'jpg';
      } else {
        self.imageType = self.$root.user.imageType;
      }
    }

    self.appUpdateAvailable = self.$app.data.taskMasterData && self.$app.data.taskMasterData.deviceRecord && self.$app.data.taskMasterData.deviceRecord.updateObject && self.$app.data.taskMasterData.deviceRecord.updateObject.updateAvailable;
  },
  data: function () {
    return {
      imageType: 'jpg',
      appList: [{
        name: 'TaskMaster',
        f7Icon: 'money_dollar',
        materialIcon: 'attach_money',
        link: '/taskmaster/'
      }, {
        name: 'Clock In/Out',
        f7Icon: 'alarm',
        materialIcon: 'alarm',
        link: '/timeclock/',
        customClass: 'clockInOutApp',
        prepend: '<i class="f7-icons clockedInCircle">circle_fill</i>'
      }, {
        name: 'Calendar',
        f7Icon: 'calendar',
        link: '/calendar/'
      }, {
        name: 'Memos',
        f7Icon: 'info_round',
        materialIcon: 'info',
        link: '/memos/'
      }, {
        name: 'Employees',
        f7Icon: 'persons_round',
        materialIcon: 'people',
        link: '/employees/'
      }, {
        name: 'Work Schedule',
        f7Icon: 'today',
        link: '/workSchedule/'
      }, {
        name: 'Reports',
        f7Icon: 'pie_chart',
        materialIcon: 'pie_chart',
        link: '/reports/'
      }, {
        name: 'TMScan',
        f7Icon: 'barcode',
        link: '/tmscan/'
      }, {
        name: 'Security',
        f7Icon: 'eye',
        materialIcon: 'remove_red_eye',
        link: '/security/'
      }, {
        name: 'H.R.',
        f7Icon: 'drawers',
        materialIcon: 'library_books',
        link: '/hr/'
      },
      /*{
      	name: 'Sales Payon',
      	f7Icon: 'money_dollar_round',
      	materialIcon: 'local_atm',
      	link: '/salesPayon/'
      },
      {
      	name: 'Devices',
      	f7Icon: 'desktop',
      	materialIcon: 'devices_other',
      	link: '/devices/'
      },
      {
      	name: 'Nextcloud',
      	f7Icon: 'cloud_fill',
      	materialIcon: 'cloud',
      	link: '/nextcloud/'
      },*/
      {
        name: 'Leave Message',
        //f7Icon: 'layers',
        materialIcon: 'history',
        link: '/leaveMessage/'
      }, {
        name: 'App Requests',
        f7Icon: 'plus_bubble',
        link: '/appRequests/'
      }
      /*
      {
      name: 'Alerts',
      f7Icon: 'alert',
      materialIcon: 'error',
      link: '/alerts/'
      }*/
      ],
      windowResizeTimer: null,
      imBusyToggle: null,
      imBusyReason: '',
      appUpdateAvailable: false
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
    self.$app.on('loggedIn', self.loggedInEvent);
    self.$app.on('appUpdate', self.appUpdateEvent);
    self.$(window).on('resize', self.windowResized);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
    self.$app.off('loggedIn', self.loggedInEvent);
    self.$app.off('appUpdate', self.appUpdateEvent);
    self.$(window).off('resize', self.windowResized);
  },
  methods: {
    appUpdateEvent: function () {
      var self = this;
      self.appUpdateAvailable = self.$app.data.taskMasterData && self.$app.data.taskMasterData.deviceRecord && self.$app.data.taskMasterData.deviceRecord.updateObject && self.$app.data.taskMasterData.deviceRecord.updateObject.updateAvailable;
      self.$setState();
    },
    loggedInEvent: function () {
      var self = this; // console.log('BEFORE self.imageType',self.imageType);

      if (self.$root.user.id) {
        if (self.$root.isIOS9) {
          self.imageType = 'jpg';
        } else {
          self.imageType = self.$root.user.imageType;
        }
      } // console.log('self.appList',self.appList);
      // console.log('AFTER self.imageType',self.imageType);


      self.$setState(); // This should be okay...
    },
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'conversations') {
        self.refreshBadges();
      } else if (data.type == 'pushNotification' && (data.additionalData.pushType.toLowerCase() == 'message' || data.additionalData.pushType.toLowerCase() == 'sticker')) {
        if (self.$root.taskMasterData.hasOwnProperty('unreadMessages')) {
          self.$root.taskMasterData.unreadMessages += 1;
        } else {
          self.$root.taskMasterData.unreadMessages = 1;
        }

        self.refreshBadges();
      } else if (data.type == 'data' && data.data == 'employees') {
        self.redrawAppIcons(); //self.fixClockInOutApp();

        self.updateUpList();
      }
    },
    navigate: function (pageUrl) {
      var self = this;
      self.$root.mainView.router.navigate(pageUrl); // , { transition: 'f7-circle' }); // TESTING :p
    },
    fixClockInOutApp: function () {
      var self = this; // <i class="f7-icons clockedInCircle" style="border: 1px solid #fff; border-radius: 1em; position: absolute; top: 1em; right: 1em; font-size: 10px;">circle_fill</i>

      if (self.$root.user.isClockedIn) {
        self.$el.find('.clockedInCircle').css('color', 'green');
        self.$el.find('.clockInOutApp span').text('Clock OUT');
      } else {
        self.$el.find('.clockedInCircle').css('color', '#ED4337');
        self.$el.find('.clockInOutApp span').text('Clock IN');
      }
    },
    refreshBadges: function () {
      var self = this; // PHONE
      // TODO
      // EMPLOYEE MESSAGING

      if (self.$root.taskMasterData.hasOwnProperty('unreadMessages')) {
        if (self.$root.taskMasterData.unreadMessages > 0) {
          self.$el.find('.messagesBadge').html(self.$root.taskMasterData.unreadMessages).show();
        } else {
          self.$el.find('.messagesBadge').html('0').hide();
        }
      } // EMAIL
      // TODO

    },
    windowResized: function () {
      var self = this;

      if (self.windowResizeTimer !== null) {
        clearTimeout(self.windowResizeTimer);
        self.windowResizeTimer = null;
      }

      self.windowResizeTimer = setTimeout(function () {
        self.redrawAppIcons();
      }, 250);
    },
    redrawAppIcons: function () {
      var self = this;
      var windowWidth = self.$('.app-swiper').width(); //self.$(window).width();

      var swiperWidth = windowWidth;
      var swiperHeight = self.$('.app-swiper').height() - 170;
      var minAppWidth = 120;
      var minAppHeight = 95;

      if (swiperHeight <= 400) {
        minAppHeight = 85;
      }

      if (swiperWidth <= 320) {
        //(self.$app.device.cordova) {
        minAppWidth = 90;
        minAppHeight = 85;
      }

      var numberOfColumns = Math.floor(swiperWidth / minAppWidth);

      if (numberOfColumns == 0) {
        numberOfColumns = 1;
      }

      var numberOfRows = Math.floor(swiperHeight / minAppHeight);

      if (numberOfRows == 0) {
        numberOfRows = 1;
      }

      var appsPerPage = numberOfColumns * numberOfRows;
      var canSeePayOn = false;

      if (self.$root.user && self.$root.user.department && self.$root.user.department == 'Sales') {
        canSeePayOn = true;
      }

      if (!canSeePayOn) {
        if (self.$root.user && self.$root.user.isAdmin) {
          canSeePayOn = true;
        }
      }

      console.log('canSeePayOn', canSeePayOn);
      var apprequestsindex = self.appList.map(function (elem) {
        return elem.name;
      }).indexOf('App Requests');
      var salespayonindex = self.appList.map(function (elem) {
        return elem.name;
      }).indexOf('Sales Payon');

      if (canSeePayOn) {
        if (salespayonindex < 0) {
          if (apprequestsindex > -1) {
            self.appList.splice(apprequestsindex, 0, {
              name: 'Sales Payon',
              f7Icon: 'money_dollar_round',
              materialIcon: 'local_atm',
              link: '/reports/sales/salesPayOn/' //'/salesPayon/'

            });
          } else {
            self.appList.push({
              name: 'Sales Payon',
              f7Icon: 'money_dollar_round',
              materialIcon: 'local_atm',
              link: '/reports/sales/salesPayOn/' //'/salesPayon/'

            });
          }
        }
      } else {
        if (salespayonindex > -1) {
          self.appList.splice(salespayonindex, 1);
        }
      } //console.log('Swiper',swiperWidth,swiperHeight);
      //console.log('appsPerPage',appsPerPage,'numberOfColumns',numberOfColumns,'numberOfRows',numberOfRows);
      // console.log('flex',self);

      /*
      // OLD HTML...
      	<div class="swiper-pagination"></div>
      <div class="swiper-wrapper">
      	<div class="swiper-slide">
      		{{#each appList}}
      			{{#js_if "(this.@index > 0) && (this.@index % 9) == 0"}}</div><div class="swiper-slide">{{/js_if}}
      			<a class="profileAppIcon" @click="navigate('{{link}}')"><i class="icon f7-icons{{#if materialIcon}} if-not-md{{/if}}">{{f7Icon}}</i>{{#if materialIcon}}<i class="icon material-icons md-only">{{materialIcon}}</i>{{/if}}<span>{{name}}</span></a>
      		{{/each}}
      	</div>
      </div>
      */


      var appSwiperHTML = '<div class="swiper-pagination"></div>' + '<div class="swiper-wrapper">' + '<div class="swiper-slide">'; // console.log('i',(Math.ceil(self.appList.length/appsPerPage)*appsPerPage));

      for (var i = 0; i < Math.ceil(self.appList.length / appsPerPage) * appsPerPage; i++) {
        if (i < self.appList.length) {
          if (i > 0 && i % appsPerPage == 0) {
            appSwiperHTML += '</div><div class="swiper-slide">';
          }

          var iconHTML = '';

          if (self.$app.theme.ios || !self.appList[i].materialIcon) {
            iconHTML = '<i class="icon f7-icons">' + self.appList[i].f7Icon + '</i>';
          } else if (self.appList[i].materialIcon) {
            iconHTML = '<i class="icon material-icons">' + self.appList[i].materialIcon + '</i>';
          }

          appSwiperHTML += '<a class="profileAppIcon' + (self.appList[i].customClass ? ' ' + self.appList[i].customClass : '') + '" data-link="' + self.appList[i].link + '">' + (self.appList[i].prepend ? self.appList[i].prepend : '') + iconHTML + '<span>' + self.appList[i].name + '</span></a>';
        } else {
          appSwiperHTML += '<span class="emptyAppIcon"><i class="icon f7-icons">circle</i><span>Empty</span></span>';
        }
      }

      appSwiperHTML += '</div>' + '</div>';
      self.$el.find('.app-swiper').html(appSwiperHTML);
      self.launchscreenSwiper = self.$app.swiper.get('.app-swiper'); // TODO: is there a better way to "update" without destroying and creating the swiper each time...?

      if (self.launchscreenSwiper) {
        if (self.launchscreenSwiper.destroy) {
          self.launchscreenSwiper.destroy();
        }
      }

      self.launchscreenSwiper = self.$app.swiper.create('.app-swiper', {
        speed: 400,
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination'
        }
      });
      self.$('.profileAppIcon, .emptyAppIcon').css('flex-basis', 100 / (numberOfColumns + 1) + '%');
      self.fixClockInOutApp();
    },
    clickedStatusButton: function () {
      var self = this;
      var currentText = self.$el.find('.upListStatusButton').text();

      if (currentText == 'Join Up List') {
        var locationsToAsk = self.$root.taskMasterData.locations.filter(function (currentElement) {
          return currentElement.hasUpList;
        }); //console.log('locationsToAsk');
        //console.log(locationsToAsk);

        var buttons = [];

        for (var i = 0; i < locationsToAsk.length; i++) {
          buttons.push({
            text: locationsToAsk[i].name,
            locationData: locationsToAsk[i],
            onClick: function () {
              var thisButton = this;
              self.$root.mdWebSocket.sendOverWebSocket({
                action: 'joinUpList',
                employeeId: self.$root.user.id,
                employeeName: self.$root.user.name,
                locationId: thisButton.locationData.id,
                locationName: thisButton.locationData.name
              });
            }
          });
        }

        buttons.push({
          text: 'Cancel',
          color: 'red'
        });
        var locationsDialog = self.$app.dialog.create({
          title: 'Join Up List',
          buttons: buttons,
          verticalButtons: true,
          destroyOnClose: true,
          closeByBackdropClick: false
        }).open();
      } else {
        self.navigate('/upList/');
      }
    },
    updateUpList: function () {
      var self = this;
      var textOnFooter = ''; // TODO: put this elsewhere...?

      self.imBusyToggle = self.$app.toggle.get('.imBusyToggle'); //console.log('self.imBusyToggle: ',self.imBusyToggle);
      // TODO: change to filter.......

      if (self.$root.taskMasterData.employees) {
        for (var i = 0; i < self.$root.taskMasterData.employees.length; i++) {
          if (self.$root.taskMasterData.employees[i].upList == true || self.$root.taskMasterData.employees[i].upListObject) {
            var currentUpListObject = self.$root.taskMasterData.employees[i].upListObject; //console.log('Found me:',currentUpListObject);

            if (currentUpListObject.employeeId == self.$root.user.id) {
              // Found me!
              if (currentUpListObject.statusId > 1) {
                // Busy!
                textOnFooter = currentUpListObject._statusName;
                self.imBusyReason = currentUpListObject._statusName; // console.log('Here 1');

                if (self.imBusyToggle) {
                  if (!self.imBusyToggle.checked) {
                    self.imBusyToggle.toggle();
                  }
                }
              } else {
                // Available!
                if (currentUpListObject.order > 1) {
                  textOnFooter = 'UP: In ' + currentUpListObject.order;
                } else {
                  textOnFooter = 'ON DOOR';
                } // console.log('Here 2');


                if (self.imBusyToggle) {
                  if (self.imBusyToggle.checked) {
                    self.imBusyToggle.toggle();
                  }
                }
              }
            }
          }
        }
      } //console.log('textOnFooter',textOnFooter);


      if (textOnFooter == '') {
        textOnFooter = 'Join Up List'; // upListLink

        if (self.$root.user.department == 'Sales') {
          self.$el.find('.upListLink').text('See Up List');
        } else {
          self.$el.find('.upListLink').text('Sales Up List');
        }

        self.$el.find('.imBusyToggle').hide();
      } else {
        self.$el.find('.upListLink').text('Up List');
        self.$el.find('.imBusyToggle').show();
      }

      if (!self.$root.user.isClockedIn) {
        self.$el.find('.upListStatusButton').hide();
      } else {
        self.$el.find('.upListStatusButton').show();
      }

      self.$el.find('.toolbarWhiteHighlight').html(textOnFooter);
    },
    checkedBusyToggle: function () {
      var self = this;
      self.imBusyToggle = self.$app.toggle.get('.imBusyToggle'); // console.log('self.imBusyToggle',self.imBusyToggle);

      if (self.imBusyToggle.checked) {
        if (self.$app.data.upListToast) {
          self.$app.toast.close('.changedOrderUpListToast');
        }

        if (self.imBusyReason == '') {
          self.changeStatus('Busy with Customer', 2); // console.log('Trying to open busy Dialog!');

          self.busyWithCustomerDialog.open();
        }
      } else {
        self.changeStatus('', 0);
        /*
        self.setToAvailableToast= self.$app.toast.create({
        	icon: self.$app.theme === 'ios' ? '<i class="f7-icons">check</i>' : '<i class="material-icons">check</i>',
        	text: 'Status set to available',
        	position: 'center',
        	closeTimeout: 3000,
        	cssClass: 'setToAvailableToast',
        	destroyOnClose: true
        }).open();
        */
      }
    },
    changeStatus: function (statusName, statusId) {
      var self = this;
      self.imBusyReason = statusName;
      var objectToSend = {
        employeeId: self.$root.user.id,
        employeeName: self.$root.user.name,
        imBusyReason: statusName,
        statusId: statusId,
        isBusy: statusName !== '',
        event: 'upListStatusChange'
      };
      self.$root.mdWebSocket.sendOverWebSocket(objectToSend);
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.redrawAppIcons();
      self.refreshBadges();
      self.updateUpList();
      self.$el.on('click', 'a.profileAppIcon', function (e) {
        var thisATag = self.$(e.target).closest('a.profileAppIcon');
        var thisATagLink = thisATag.attr('data-link');
        self.navigate(thisATagLink);
      });
      self.busyWithCustomerDialog = self.$app.dialog.create({
        title: 'Unavailable',
        text: 'Busy with Customer<br>&nbsp;',
        content: '<div class="progressbar"><span></span></div>',
        buttons: [{
          text: 'Accept'
        }, {
          text: 'Change Reason',
          onClick: function () {
            // New dialog here...
            // self.$app.dialog.alert('Busy, Lunch, Do Not Disturb, Customer, etc...','Statuses');
            var buttons = [];

            for (var i = 1; i < self.$root.taskMasterData.employeeStatuses.length; i++) {
              // Skip Available...
              buttons.push({
                text: self.$root.taskMasterData.employeeStatuses[i].name,
                status: self.$root.taskMasterData.employeeStatuses[i],
                onClick: function () {
                  var thisButton = this;
                  self.changeStatus(thisButton.status.name, thisButton.status.id);
                }
              });
            }

            buttons.push({
              text: 'Cancel',
              color: 'red',
              onClick: function () {
                self.imBusyToggle.toggle();
              }
            });
            self.$app.dialog.create({
              title: 'Busy',
              buttons: buttons,
              verticalButtons: true,
              destroyOnClose: true
            }).open();
          }
        }, {
          text: 'Cancel',
          color: 'red',
          onClick: function () {
            self.imBusyToggle.toggle();
          }
        }],
        verticalButtons: true,
        on: {
          opened: function () {
            self.busyWithCustomerDialog.setProgress(100, 7000);
            self.busyWithCustomerTimeout = setTimeout(function () {
              if (self.busyWithCustomerDialog) {
                self.busyWithCustomerDialog.close();
              }
            }, 6500);
          },
          close: function () {
            self.busyWithCustomerDialog.setProgress(0);

            if (self.busyWithCustomerTimeout) {
              clearTimeout(self.busyWithCustomerTimeout);
              self.busyWithCustomerTimeout = null;
            }
          }
        }
      });
    },
    pageAfterIn: function (e, page) {
      var self = this; // self.windowResized();

      self.updateUpList();
    }
  },
  id: '3318c016af',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page launchscreen no-swipeback" data-name="launchscreen">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<!-- TODO: Alerts/New Memos Here maybe? -->\n				<div class="title">My B&amp;M</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<a href="#" class="link icon-only popover-open" data-popover=".settings-menu">\n						<i class="icon f7-icons if-not-md">gear_alt_fill';
      r += Template7Helpers.if.call(ctx_1, ctx_1.appUpdateAvailable, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<span class="badge color-red">1</span>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</i>\n						<i class="icon material-icons md-only">settings';
      r += Template7Helpers.if.call(ctx_1, ctx_1.appUpdateAvailable, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<span class="badge color-red">1</span>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</i>\n					</a>\n				</div>\n			</div>\n		</div>\n		<div class="toolbar toolbar-bottom">\n			<div class="toolbar-inner">\n			    <a class="link upListLink" href="/uplist/">Up List</a>\n		        <a href="#" class="link upListStatusButton" @click="clickedStatusButton" style="display: none;"><span class="toolbarWhiteHighlight">Join Up List</span></a>\n	        	<label class="toggle imBusyToggle toggle-init color-red toggle-available" @toggle:change="checkedBusyToggle" style="display: none;">\n					<input class="imBusyToggleCheckbox" type="checkbox">\n					<span class="toggle-icon"></span>\n				</label>\n			</div>\n		</div>\n		<div class="page-content" style="background-color: #fff;">\n			<div class="launchscreenTop">\n				<div class="block employeeBlock no-margin">\n					<div style="float: left;">\n						<a href="/settings/">\n							<div class="image-crop-circle profile-image" style="height: 90px; width: 90px; border: 2px solid #fff; background: #fff url(\'';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.user.id, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.js.call(ctx_2, "this.$root.getImageURL(this.$root.user.id,this.$root.user.imageType,this.$root.user.imageUpdatedDateTime)", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += 'static/images/user.png';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\') top center; background-size: cover;"></div>\n						</a>\n					</div>\n					<div style="margin-left: 120px; margin-top: 1em; color: #fff;">';
      r += c(ctx_1.$root.user.fullname, ctx_1);
      r += '<br>';
      r += c(ctx_1.$root.user.department, ctx_1);
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.user.settings.hasPhone, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<br>(206) 633-4498 x ';
          r += c(ctx_2.$root.user.settings.phoneLogin.extension, ctx_2);
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n				</div>\n				<div class="block communicationBar" style="clear: left;">\n					<div class="row">\n						';
      r += Template7Helpers.if.call(ctx_1, true, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="col"><a href="/phone/" class="link icon-only"><i class="icon f7-icons if-not-md">phone</i><i class="icon material-icons md-only">phone</i></a></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n						<div class="col"><a href="/customerTexts/" class="link icon-only"><i class="icon f7-icons if-not-md">chat_bubble_2<span class="badge color-red elevation-1 messagesBadge" style="display: none;"></i><i class="icon material-icons md-only">message<span class="badge color-red elevation-1 messagesBadge" style="display: none;">0</span></i></a></div>\n						<div class="col"><a href="/email/" class="link icon-only';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.user.settings.emailSetup, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '"><i class="icon f7-icons if-not-md">envelope</i><i class="icon material-icons md-only">email</i></a></div>\n						<div class="col"><a href="/taskmaster/search/" class="link icon-only"><i class="icon f7-icons if-not-md">search</i><i class="icon material-icons md-only">search</i></a></div>\n						';
      r += Template7Helpers.if.call(ctx_1, true, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="col"><a href="#" class="link icon-only" @click="$root.scanBarcode"><i class="icon f7-icons">qrcode</i></a></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					</div>\n			    </div>\n		    </div>\n			<div class="swiper-container swiper-init app-swiper">\n			</div>\n		</div>\n		<div class="popover settings-menu">\n			<div class="popover-angle"></div>\n			<div class="popover-inner">\n				<div class="list">\n					<ul>\n						<li><a href="/settings/" class="list-button item-link popover-close">Settings</a></li>\n						<li><a href="#" class="list-button item-link popover-close" @click="$root.updateApp()">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.appUpdateAvailable, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<span class="badge color-red">1</span> <span style="font-weight: bold;">Update</span>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += 'Update';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</a></li>\n						<li><a href="/about/" class="list-button item-link popover-close">About v';
      r += c(ctx_1.$app.version, ctx_1);
      r += '</a></li>\n						<li><a href="#" class="list-button item-link popover-close" style="color: #f00;" @click="$root.logout()">Logout</a></li>\n					</ul>\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.launchscreenTop {
		position: absolute;
		z-index: 5;
		right: 0px;
		left: 0px;
		
	}
	
	.employeeBlock {
		padding-top: var(--f7-block-padding-vertical);
		padding-bottom: var(--f7-block-padding-vertical);
		height: 125px;
		
	}
	
	.communicationBar {
		text-align: center;
		padding: 5px 0px;
		margin: 0px;
		
	}
	.communicationBar a {
		/* TODO: give these some sort of bevel / button look */
		box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
		padding: 0.25em 0.75em;
		border-radius: 5px;
		
	}
	
	.app-swiper {
		width: 100%;
		height: 100%;
		padding-top: 170px;
		box-sizing: border-box;
		
	}
	
	.app-swiper .swiper-slide {
		background: #fff;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: stretch;
		
	}

	.profileAppIcon, .emptyAppIcon {
		position: relative;
		flex: 1 1 25%;
		text-align: center;
		box-sizing: border-box;
		border-radius: 1.5em;
		margin: 0.5em;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		
	}
	
	.clockedInCircle {
		border: 1px solid #fff;
		border-radius: 1em;
		position: absolute;
		top: 1em;
		right: 1em;
		font-size: 10px;
		
	}
	
	.profileAppIcon {
		background: #fcc;
		
	}
	
	.toolbarWhiteHighlight {
		display: inline-block;
		padding: 0px 0.5em;
		margin: 0px 0.5em;
		background: #fff;
		color: #000;
		font-weight: bold;
		height: 1.5em;
		line-height: 1.5em;
		
	}
	
	.md .toggle.imBusyToggle {
		padding-right: 4em;
		
	}
	
`,
  styleScoped: false
};
    