
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    var rightNow = new Date();

    if (self.$route.params.memoId == 'new') {
      // FIGURE THIS OUT...
      self.memoObject = {
        id: 0,
        isDeleted: false,
        categoryId: 0,
        title: '',
        text: '',
        html: '',
        dateIsCurrentThroughValue: rightNow.toISOString().substr(0, 10),
        dateCreated: '0000-00-00',
        _employeeName: '',
        _categoryName: ''
      };
      self.memoCategoryObject = {
        id: 0,
        color: '#fff'
      };
      self.editingMemo = true;
    } else {
      var memoId = parseInt(self.$route.params.memoId);
      self.memoIndex = self.$root.taskMasterData.memos.map(function (e) {
        return e.id;
      }).indexOf(memoId);
      self.memoObject = self.$root.taskMasterData.memos[self.memoIndex]; // TODO: is this okay?

      self.memoObject.html = self.memoObject.text.replace(/(\r\n|\n|\r)/g, '<br>');
      self.memoCategoryIndex = self.$root.taskMasterData.memoCategories.map(function (e) {
        return e.id;
      }).indexOf(self.memoObject.categoryId);
      self.memoCategoryObject = self.$root.taskMasterData.memoCategories[self.memoCategoryIndex];
      self.memoObject.dateIsCurrentThroughValue = self.memoObject.dateIsCurrentThrough.substr(0, 10);
      self.memoObject.isDeleted = false;
      console.log(self.memoObject);
      self.canEditMemo = self.$root.user.isAdmin;

      if (!self.canEditMemo) {
        self.canEditMemo = self.memoObject.employeeId == self.$root.user.id;
      }
    } // console.log('self.memoObject',self.memoObject);

  },
  data: function () {
    return {
      editingMemo: false,
      canEditMemo: false,
      memoIndex: -1,
      memoObject: {},
      memoCategoryIndex: -1,
      memoCategoryObject: {},
      todayInputString: ''
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this; // TODO: if not editing a memo, update it...!
    },
    changedMemoCategory: function () {
      var self = this;
      var color = '#fff';
      var categoryIdPicked = parseInt(self.$el.find('#categoryId').val());

      if (categoryIdPicked > 0) {
        self.memoCategoryIndex = self.$root.taskMasterData.memoCategories.map(function (e) {
          return e.id;
        }).indexOf(categoryIdPicked);
        self.memoCategoryObject = self.$root.taskMasterData.memoCategories[self.memoCategoryIndex];
      } else {
        self.memoCategoryIndex = -1;
        self.memoCategoryObject = {
          id: 0,
          color: '#fff'
        };
      }

      self.$el.find('.categoryPickedColor').css('background-color', self.memoCategoryObject.color);
    },
    editMemo: function () {
      var self = this;
      self.$setState({
        editingMemo: true
      });
    },
    deleteMemo: function () {
      var self = this;
      self.$app.dialog.confirm('Are you sure you want to delete this memo?', 'Delete Memo', function () {
        self.$el.find('#isDeleted').val('true');
        self.saveMemo();
      });
    },
    saveMemo: function () {
      var self = this;
      var formData = self.$app.form.convertToData(self.$el.find('.newMemoForm'));
      var errorMessage = '';

      if (formData.title == '') {
        errorMessage = 'Title is required.';
      }

      if (formData.categoryId == '0') {
        errorMessage = 'You must pick a category.';
      }

      if (formData.text == '') {
        errorMessage = 'The memo cannot be blank.';
      } // Done...


      if (errorMessage == '') {
        formData.dateIsCurrentThrough = moment(new Date(formData.dateIsCurrentThrough + ' 00:00:00')).format('L'); // TODO: wheel?

        formData.action = 'saveMemo';
        formData.isDeleted = formData.isDeleted == 'true';
        self.$root.mdWebSocket.sendOverWebSocket(formData); // IF OKAY...

        self.$app.popup.close('.memosDetailPopup');
      } else {
        self.$app.dialog.alert(errorMessage, 'Error');
      }
    }
  },
  on: {
    popupOpen: function (e, popup) {
      var self = this;
      /*
      self.$root.mdWebSocket.sendOverWebSocket({
      	action: 'readMemo',
      	memoId: self.memoObject.id
      });
      */

      self.$('.memoId' + self.memoObject.id).removeClass('isUnread').addClass('isRead');
      self.$el.find('form, input').each(function () {
        self.$(this).attr('autocomplete', 'off');
      });
    }
  },
  id: '5b2d7943cf',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="popup memosDetailPopup">\n		<div class="view">\n			<div class="page" data-name="memosDetailPopup">\n				<div class="navbar">\n					<div class="navbar-bg"></div>\n					<div class="navbar-inner">\n						<div class="left">\n							';
      r += Template7Helpers.if.call(ctx_1, ctx_1.editingMemo, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							<a href="#" class="link icon-only popup-close md-only" data-popup=".memosDetailPopup"><i class="icon material-icons">cancel</i></a>\n							<a href="#" class="link popup-close if-not-md" data-popup=".memosDetailPopup">Cancel</a>\n							';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n							<a href="#" class="link icon-only popup-close md-only" data-popup=".memosDetailPopup"><i class="icon icon-back"></i></a>\n							<a href="#" class="link popup-close if-not-md" data-popup=".memosDetailPopup">Close</a>\n							';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n						</div>\n						<div class="title">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.editingMemo, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'Edit ';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.memoObject.id, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'New ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += 'Manager Memo</div>\n						<div class="right">\n							';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							<a href="/bugreport/" class="link icon-only">\n								<i class="icon material-icons">bug_report</i>\n							</a>\n							';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n							';
      r += Template7Helpers.if.call(ctx_1, ctx_1.editingMemo, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							<a href="#" class="link" @click="saveMemo">Save</a>\n							';
          r += Template7Helpers.if.call(ctx_2, ctx_2.canEditMemo, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n							';
          r += Template7Helpers.if.call(ctx_2, ctx_2.canEditMemo, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							<a href="#" class="link" @click="editMemo">Edit</a>\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n							';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n						</div>\n					</div>\n				</div>\n				<div class="page-content">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.editingMemo, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					\n					<form class="newMemoForm" name="newMemoForm" onsubmit="return false;">\n						<!--<div class="block-title">New Memo</div>-->\n						<input type="hidden" id="memoId" name="memoId" value="';
          r += c(ctx_2.memoObject.id, ctx_2);
          r += '">\n						<input type="hidden" id="isDeleted" name="isDeleted" value="';
          r += c(ctx_2.memoObject.isDeleted, ctx_2);
          r += '">\n						<div class="list no-hairlines-md no-margin-top">\n							<ul>\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-title item-label">Title</div>\n										<div class="item-input-wrap">\n											<input name="title" style="font-weight: bold;" type="text" value="';
          r += c(ctx_2.memoObject.title, ctx_2);
          r += '" placeholder="Enter a title..." />\n										</div>\n									</div>\n								</li>\n								<li class="item-content item-input">\n									<div class="event-color categoryPickedColor" style="background-color: ';
          r += c(ctx_2.memoCategoryObject.color, ctx_2);
          r += '"></div>\n									<div class="item-inner">\n										<div class="item-title item-label">Category</div>\n										<div class="item-input-wrap input-dropdown-wrap">\n											<select id="categoryId" name="categoryId" placeholder="Please choose..." @change="changedMemoCategory">\n												';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.memoObject.categoryId, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n												<option value="0">Please choose...</option>\n												';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n												';
          r += Template7Helpers.each.call(ctx_2, ctx_2.$root.taskMasterData.memoCategories, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n												<option value="';
              r += c(ctx_3.id, ctx_3);
              r += '"';
              r += Template7Helpers.js_if.call(ctx_3, "this.id == this.@root.memoObject.categoryId", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' selected="selected"';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '>';
              r += c(ctx_3.name, ctx_3);
              r += '</option>\n												';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n											</select>\n								        </div>\n									</div>\n								</li>\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-title item-label">Memo</div>\n										<div class="item-input-wrap"><textarea name="text" style="min-height: 100px;" class="resizable" placeholder="Enter the message...">';
          r += c(ctx_2.memoObject.text, ctx_2);
          r += '</textarea></div>\n									</div>\n								</li>\n							</ul>\n						</div>\n						<div class="block-title">Additional Options</div>\n						<div class="list no-hairlines-md">\n							<ul>\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-title item-label">Highlight in "Current Memos" through</div>\n										<div class="item-input-wrap"><input name="dateIsCurrentThrough" type="date" value="';
          r += c(ctx_2.memoObject.dateIsCurrentThroughValue, ctx_2);
          r += '" placeholder="';
          r += c(ctx_2.memoObject.dateIsCurrentThroughValue, ctx_2);
          r += '"></div>\n									</div>\n								</li>\n								<!-- TODO: requirements about it here? -->\n							</ul>\n						</div>\n						<!--\n						<div class="block">\n							<button class="button button-big button-fill" @click="saveMemo">Save';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.memoObject.id, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'new ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' memo</button>\n						</div>\n						-->\n						';
          r += Template7Helpers.if.call(ctx_2, ctx_2.memoObject.id, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n						<div class="block block-strong">\n							<p><a href="#" class="link color-red" @click="deleteMemo">Delete Memo</a></p>\n						</div>\n						';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n					</form>\n					';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<div class="block block-strong no-margin-top">\n						<h3>';
          r += c(ctx_2.memoObject.title, ctx_2);
          r += '</h3>\n						<p>';
          r += c(ctx_2.memoObject.html, ctx_2);
          r += '</p>\n						<p style="color: #999; font-style: italic;">Posted by ';
          r += c(ctx_2.memoObject._employeeName, ctx_2);
          r += ' on ';
          r += Template7Helpers.js.call(ctx_2, "moment(new Date(this.memoObject.dateCreated)).format('L')", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p>\n					</div>\n					<div class="block block-strong">\n						<div style="text-align: right;">\n							<div class="chip" style="color: #fff; background: ';
          r += c(ctx_2.memoCategoryObject.color, ctx_2);
          r += ';">\n								<div class="chip-label">';
          r += c(ctx_2.memoObject._categoryName, ctx_2);
          r += '</div>\n							</div>\n						</div>\n					</div>\n					\n					<!-- TODO: action buttons here -->\n					\n					<!-- TODO:  HISTORY as well.... -->\n					';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	
`,
  styleScoped: false
};
    