
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {};
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
    }
  },
  id: 'd7e68a806e',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="tmAdminPage">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">TM Admin</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n			</div>\n		</div>\n		<div class="page-content">\n			<iframe src="https://taskmaster.bedroomsandmore.com/4DACTION/web_mobile?mobile=true&random=';
      r += Template7Helpers.js.call(ctx_1, "Math.random()", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" style="height: 100%; width: 100%;"></iframe>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	
`,
  styleScoped: false
};
    