
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {
      currentMemos: [],
      memoCategories: []
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'memoCategories') {
        self.refreshList();
      }
    },
    refreshList: function () {
      var self = this;

      if (!self.$root.taskMasterData.memoCategories) {
        self.$root.taskMasterData.memoCategories = [];
      }

      if (!self.$root.taskMasterData.memos) {
        self.$root.taskMasterData.memos = [];
      } // TODO: Do I need to copy them or is it okay to just reference the global arrays?


      self.memoCategories = self.$root.taskMasterData.memoCategories.slice();

      for (var i = 0; i < self.memoCategories.length; i++) {
        self.memoCategories[i].memos = self.$root.taskMasterData.memos.filter(function (elem) {
          return elem.categoryId == self.memoCategories[i].id;
        });
      }

      var rightNow = new Date();
      rightNow.setHours(0, 0, 0, 0);
      self.currentMemos = self.$root.taskMasterData.memos.filter(function (elem) {
        var thisDateIsCurrentThrough = new Date(elem.dateIsCurrentThrough);
        thisDateIsCurrentThrough.setHours(23, 59, 59, 999); //console.log('thisDateIsCurrentThrough,rightNow',thisDateIsCurrentThrough,rightNow);

        return thisDateIsCurrentThrough >= rightNow;
      }); //console.log('self.$root.taskMasterData.memos',self.$root.taskMasterData.memos);
      //console.log('self.currentMemos',self.currentMemos);

      for (var i = 0; i < self.currentMemos.length; i++) {
        self.currentMemos[i].color = '#000';
        self.currentMemos[i].hasBeenUpdated = false;
        self.currentMemos[i].postedText = 'Posted by ' + self.currentMemos[i]._employeeName + ' on ' + moment(new Date(self.currentMemos[i].dateCreated)).format('L');

        if (moment(new Date(self.currentMemos[i].dateCreated)).format('L') !== moment(new Date(self.currentMemos[i].dateModified)).format('L')) {
          self.currentMemos[i].postedText = 'Updated by ' + self.currentMemos[i]._employeeNameModified + ' on ' + moment(new Date(self.currentMemos[i].dateModified)).format('L');
          self.currentMemos[i].hasBeenUpdated = true;
        }

        var currentMemoCategoryIndex = self.memoCategories.map(function (e) {
          return e.id;
        }).indexOf(self.currentMemos[i].categoryId);

        if (currentMemoCategoryIndex > -1) {
          self.currentMemos[i].color = self.memoCategories[currentMemoCategoryIndex].color;
        }
      }

      self.$update();
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.refreshList();
    }
  },
  id: 'fe0d38dc3c',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="memosPage">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">Manager Memos</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.user.isAdmin, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<a href="/memos/new/" class="link icon-only"><i class="icon f7-icons if-not-md">plus</i><i class="icon material-icons md-only">add</i></a>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text">Manager Memos</div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="page-content">\n			<div class="block-title">Current Memos:</div>\n			<div class="list media-list inset">\n				<ul>\n					';
      r += Template7Helpers.each.call(ctx_1, ctx_1.currentMemos, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<li>\n						<a href="/memos/';
          r += c(ctx_2.id, ctx_2);
          r += '/" class="item-link item-content memoLink';
          r += Template7Helpers.if.call(ctx_2, ctx_2.myFeedback.ID, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' isRead';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' isUnread';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' memoId';
          r += c(ctx_2.id, ctx_2);
          r += '" data-index="';
          r += c(data_2 && data_2.index, ctx_2);
          r += '" style="border-left: 5px solid ';
          r += c(ctx_2.color, ctx_2);
          r += ';">\n							<div class="item-inner">\n								<div class="item-title-row">\n									<div class="item-title">';
          r += c(ctx_2.title, ctx_2);
          r += '</div>\n								</div>\n								<div class="item-text" style="font-style: italic;">';
          r += c(ctx_2.postedText, ctx_2);
          r += '</div>\n							</div>\n						</a>\n					</li>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</ul>\n			</div>\n			<div class="block-title">All Memos:</div>\n			<div class="list accordion-list allMemosList">\n				<ul>\n					';
      r += Template7Helpers.each.call(ctx_1, ctx_1.memoCategories, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<li class="accordion-item">\n						<a href="" class="item-link item-content" style="border-left: 10px solid ';
          r += c(ctx_2.color, ctx_2);
          r += ';">\n							<div class="item-inner">\n								<div class="item-title">';
          r += c(ctx_2.name, ctx_2);
          r += '</div>\n							</div>\n						</a>\n						<div class="accordion-item-content">\n							';
          r += Template7Helpers.if.call(ctx_2, ctx_2.memos.length, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							<div class="list inset">\n								<ul>\n									';
              r += Template7Helpers.each.call(ctx_3, ctx_3.memos, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n									<li>\n										<a class="item-link item-content memoLink';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.myFeedback.ID, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' isRead';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += ' isUnread';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '" href="/memos/';
                  r += c(ctx_4.id, ctx_4);
                  r += '/">\n											<div class="item-inner">\n												<div class="item-title">';
                  r += c(ctx_4.title, ctx_4);
                  r += '</div>\n												';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.myFeedback.ID, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div class="item-after"><span class="readSpan"><i class="icon f7-icons">eye_fill</i></span></div>';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n											</div>\n										</a>\n									</li>\n									';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n								</ul>\n							</div>\n							';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n							<div class="block">&nbsp;</div>\n							';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						</div>\n					</li>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</ul>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.ios .media-list .item-link.isRead .item-title, .ios li.media-item .item-link.isRead .item-title, .md .list .item-link.isRead .item-title, .ios .links-list a.isRead, .md .links-list a.isRead {
		color: #757575;
	}
	.media-list .item-link.isRead, .ios .links-list a.isRead, .md .links-list a.isRead {
		opacity: 0.7 !important;
	}

	.readSpan {
		font-family: monospace;
		font-style: italic;
		opacity: 0.4;
	}
`,
  styleScoped: false
};
    