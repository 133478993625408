
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {
      name: 'Up List Settings',
      variables: {},
      settingsObject: {},
      brokenDownSettings: []
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'settingsObject') {
        self.settingsObject = data.settingsObject;
        self.brokenDownSettings = self.makeBrokenDownSettings();
        self.refreshPage(); //console.log('tempArray2',JSON.stringify(tempArray));
      }
    },
    refreshPage: function () {
      var self = this;
      self.$setState();
    },
    makeBrokenDownSettings: function () {
      var self = this;
      var newArray = [];
      self.makeBrokenDownSettingsVariable(newArray, 0, self.settingsObject, self.name, 'object'); //console.log('self.brokenDownSettings',newArray);
      //console.log('self.settingsObject',self.settingsObject);

      return newArray;
    },
    makeBrokenDownSettingsVariable: function (arrayPointer, level, variableToBreakDown, nameOfVariable, typeOfVariable) {
      var self = this;
      nameOfVariable = typeof nameOfVariable == 'undefined' ? 'unknown' : nameOfVariable;
      typeOfVariable = typeof typeOfVariable == 'undefined' ? typeof variableToBreakDown : typeOfVariable;

      if (typeOfVariable == 'object') {
        if (Array.isArray(variableToBreakDown)) {
          typeOfVariable = 'array';
        }
      } //console.log('HERE', JSON.stringify(arrayPointer));


      if (typeOfVariable == 'object') {
        if (nameOfVariable !== '') {
          arrayPointer.push({
            type: 'divider',
            title: nameOfVariable,
            level: level
          });
        } // Loop properties...


        for (var property1 in variableToBreakDown) {
          self.makeBrokenDownSettingsVariable(arrayPointer, level + 1, variableToBreakDown[property1], property1);
        }
      } else if (typeOfVariable == 'array') {
        if (nameOfVariable !== '') {
          arrayPointer.push({
            type: 'divider',
            title: nameOfVariable,
            level: level
          });
        }

        console.log('array', variableToBreakDown);

        for (var property1 in variableToBreakDown) {
          var nameFound = variableToBreakDown[property1].name;

          if (!nameFound) {
            variableToBreakDown[property1].description;
          }

          if (!nameFound) {
            nameFound = '';
          }

          self.makeBrokenDownSettingsVariable(arrayPointer, level + 1, variableToBreakDown[property1], nameFound);
        }
      } else if (typeOfVariable == 'boolean') {
        arrayPointer.push({
          type: 'boolean',
          title: nameOfVariable,
          value: variableToBreakDown,
          level: level
        });
      } else if (typeOfVariable == 'string') {
        arrayPointer.push({
          type: 'string',
          title: nameOfVariable,
          value: variableToBreakDown,
          level: level
        });
      }
    }
  },
  on: {
    popupOpen: function (e, popup) {
      var self = this;
    }
  },
  id: 'a87da804a4',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="popup popupSettingsObject">\n		<div class="view">\n			<div class="page" data-name="settingsObjectPopup">\n				<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n					<div class="navbar-bg"></div>\n					<div class="navbar-inner">\n						<div class="left">\n							<a href="#" class="link icon-only popup-close md-only" data-popup=".popupSettingsObject"><i class="icon icon-back"></i></a>\n							<a href="#" class="link popup-close if-not-md" data-popup=".popupSettingsObject">Close</a>\n						</div>\n						<div class="title sliding">';
      r += c(ctx_1.name, ctx_1);
      r += '</div>\n						<div class="right">\n							';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							<a href="/bugreport/" class="link icon-only">\n								<i class="icon material-icons">bug_report</i>\n							</a>\n							';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n							<a href="#" class="link">\n								<span>Save</span>\n							</a>\n						</div>\n					</div>\n				</div>\n				<div class="page-content">\n					<form class="list" id="my-form">\n						<ul>\n							';
      r += Template7Helpers.each.call(ctx_1, ctx_1.brokenDownSettings, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							';
          r += Template7Helpers.js_if.call(ctx_2, "this.type == 'divider'", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							<li class="item-divider"';
              r += Template7Helpers.if.call(ctx_3, ctx_3.level, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'style="padding-left: ';
                  r += Template7Helpers.js.call(ctx_4, "1+this.level", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += 'em;"';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '>';
              r += c(ctx_3.title, ctx_3);
              r += '</li>\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n							';
          r += Template7Helpers.js_if.call(ctx_2, "this.type == 'boolean'", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							<li>\n								<div class="item-content">\n									<div class="item-inner">\n										<div class="item-title"';
              r += Template7Helpers.if.call(ctx_3, ctx_3.level, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'style="padding-left: ';
                  r += Template7Helpers.js.call(ctx_4, "1+this.level", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += 'em;"';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '>';
              r += c(ctx_3.title, ctx_3);
              r += '</div>\n										<div class="item-after">\n											<label class="toggle toggle-init">\n												<input type="checkbox" name="toggle" value="yes"';
              r += Template7Helpers.if.call(ctx_3, ctx_3.value, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' checked';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '><i class="toggle-icon"></i>\n											</label>\n										</div>\n									</div>\n								</div>\n							</li>\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n							';
          r += Template7Helpers.js_if.call(ctx_2, "this.type == 'string'", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							<li>\n								<div class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-title item-label"';
              r += Template7Helpers.if.call(ctx_3, ctx_3.level, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'style="padding-left: ';
                  r += Template7Helpers.js.call(ctx_4, "1+this.level", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += 'em;"';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '>';
              r += c(ctx_3.title, ctx_3);
              r += '</div>\n										<div class="item-input-wrap"';
              r += Template7Helpers.if.call(ctx_3, ctx_3.level, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'style="padding-left: ';
                  r += Template7Helpers.js.call(ctx_4, "1+this.level", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += 'em;"';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '>\n											<input type="text" name="';
              r += c(ctx_3.title, ctx_3);
              r += '" placeholder="';
              r += c(ctx_3.title, ctx_3);
              r += '" value="';
              r += c(ctx_3.value, ctx_3);
              r += '">\n										</div>\n									</div>\n								</div>\n							</li>\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n							';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n						</ul>\n					</form>\n					';
      r += Template7Helpers.js.call(ctx_1, "JSON.stringify(this.brokenDownSettings)", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	
`,
  styleScoped: false
};
    