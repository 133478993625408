
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.reportCategory = self.$route.params.reportsCategory;
    self.reportName = decodeURIComponent(self.$route.params.reportName);
    self.topOfPageTitle = self.reportName;
    var todaysDate = new Date();
    /*
    var currentMonthNumber= (todaysDate.getMonth()+1)+'';
    if (currentMonthNumber.length == 1) { currentMonthNumber= '0'+currentMonthNumber; }
    var currentDayNumber= todaysDate.getDate();
    if (currentDayNumber.length == 1) { currentDayNumber= '0'+currentDayNumber; }
    	var firstDate= currentMonthNumber+'/' + currentDayNumber + '/' + todaysDate.getFullYear();
    var secondDate= firstDate; //currentMonthNumber+'/' + currentDayNumber + '/' + todaysDate.getFullYear();
    
    self.reportData.variables.date= firstDate;
    self.reportData.variables.firstDate= firstDate;
    self.reportData.variables.secondDate= secondDate;
    */

    self.$app.data.pageStatusData = {
      reportCategory: self.reportCategory,
      reportName: self.reportName,
      reportVariables: self.reportData.variables,
      runReport: self.runReport
    }; // console.log('DONE WITH BEFORE_CREATE',self.reportData.variables);
  },
  data: function () {
    return {
      sharingIsLive: true,
      loading: true,
      loadingMessage: 'Loading...',
      waitForReport: true,
      runReport: true,
      reportName: '',
      topOfPageTitle: '',
      reportData: {
        options: {},
        variables: {}
      },
      reportsCategory: '',
      reportCharts: [],
      reportChartRanOnce: false
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'reportData') {
        if (data.reportData.name == self.reportName) {
          if (data.reportData.title && data.reportData.title !== '') {
            self.topOfPageTitle = data.reportData.title;
          }

          if (data.justStarted == true) {
            // || (!self.reportData)) {
            self.reportData = data.reportData; // self.drawReport();
          } else {
            if (self.reportData.type == 'chart') {
              self.reportData.chartData = self.reportData.chartData.concat(data.reportData.chartData);
            } else if (self.reportData.type == 'table') {
              self.reportData.tableData = self.reportData.tableData.concat(data.reportData.tableData);
            } else {// error?
            }
          }

          if (data.done) {
            //console.log('self.reportData',self.reportData);
            self.drawReport();
          }
        }
      } else if (data.type == 'data' && data.data == 'reportDataLoadingProgress') {
        if (self.loading == true || self.waitForReport == true) {
          // TODO: cross reference with report name...
          self.$setState({
            loadingMessage: data.loadingMessage
          });
        }
      }
    },
    locationPicked: function (e) {
      var self = this;
      var ss = self.$app.smartSelect.get(e.target);
      var valueOfSS = ss.getValue();
      self.reportData.variables.locationId = valueOfSS;

      if (self.reportData.type == 'pdf') {
        // Refresh URL
        self.$el.find('#pdfObject').attr('src', 'https://taskmaster.bedroomsandmore.com/4DACTION/web_pdf' + self.getPDFURL()); //} else if (self.reportData.type == 'chart') {
        // Reload page...
      } else {
        self.$setState({
          waitForReport: true
        }, function () {
          self.$app.data.pageStatusData = {
            reportCategory: self.reportCategory,
            reportName: self.reportName,
            reportVariables: self.reportData.variables,
            runReport: true
          };
          console.log('sendStatus: called from locationPicked');
          self.$root.sendStatus();
        });
      }
    },
    toggleChanged: function (e) {
      var self = this;
      var $eTarget = self.$(e.target); // console.log('e.target',$eTarget.attr('data-name'));

      var toggle = self.$app.toggle.get(e.target);
      self.$app.preloader.show();
      self.reportData.variables[$eTarget.attr('data-name')] = toggle.checked;
      var reportVariables = self.reportData.variables;
      self.$app.data.pageStatusData = {
        reportCategory: self.reportCategory,
        reportName: self.reportName,
        reportVariables: reportVariables,
        runReport: true
      };
      console.log('sendStatus: called from toggleChanged');
      self.$root.sendStatus();
    },
    pickerOptionChanged: function (e) {
      var self = this;
      var ss = self.$app.smartSelect.get(e.target);
      var valueOfSS = ss.getValue(); // TODO: get name of the picker...?

      console.log('ss', valueOfSS, ss);
      var variableNameToUse = ss.$el.attr('data-optionsVariableName');
      var useData = 'value';

      if (ss.$el.attr('data-optionsVariableUseData') !== '') {
        useData = ss.$el.attr('data-optionsVariableUseData');
      }

      var variableHasBeenChanged = false;

      if (useData == 'name') {
        var displayElement = self.$(ss.params.valueEl);
        var textToUse = displayElement.text();

        if (valueOfSS == '0' || valueOfSS == '') {
          // IS THIS OKAY?
          textToUse = ''; // IS THIS OKAY?
        }

        if (self.reportData.variables[variableNameToUse] !== textToUse) {
          self.reportData.variables[variableNameToUse] = textToUse;
          variableHasBeenChanged = true;
        }
      } else {
        if (self.reportData.variables[variableNameToUse] !== valueOfSS) {
          // TODO: how to check with arrays?
          self.reportData.variables[variableNameToUse] = valueOfSS;
          variableHasBeenChanged = true;
        }
      }

      if (variableHasBeenChanged) {
        if (self.reportData.type == 'pdf') {
          // Refresh PDF URL
          self.$el.find('#pdfObject').attr('src', 'https://taskmaster.bedroomsandmore.com/4DACTION/web_pdf' + self.getPDFURL());
        } else {
          self.$app.preloader.show();
          var reportVariables = self.reportData.variables;
          self.$app.data.pageStatusData = {
            reportCategory: self.reportCategory,
            reportName: self.reportName,
            reportVariables: reportVariables,
            runReport: true
          }; // TODO: do nothing cause it will auto run this soon...
          // self.$root.sendStatus();
        }
      } else {
        self.$app.data.pageStatusData = {
          runReport: false
        };
      }
    },
    datePicked: function (cal, values) {
      var self = this;
      console.log('datePicked', values, cal);
      var reloadData = false;

      if (self.reportData.userOptions.length > 0) {
        // NEW WAY
        var firstDate = values[0].getMonth() + 1 + '/' + values[0].getDate() + '/' + values[0].getFullYear();
        var secondDate = firstDate;

        if (values.length > 1) {
          secondDate = values[1].getMonth() + 1 + '/' + values[1].getDate() + '/' + values[1].getFullYear();
        }

        if (cal.reportVariableIsRange) {
          if (values.length == 2) {
            reloadData = true;
            self.reportData.variables[cal.reportVariableName] = [firstDate, secondDate];
          }
        } else {
          if (values.length == 1) {
            reloadData = true;
            self.reportData.variables[cal.reportVariableName] = firstDate;
          }
        }

        var reportVariables = self.reportData.variables; // console.log('*****cal',cal);
      } else {
        // OLD WAY
        if (self.reportData.options.datePickerIsRange && values.length == 2) {
          // We have the data...
          reloadData = true;
          var firstDate = values[0].getMonth() + 1 + '/' + values[0].getDate() + '/' + values[0].getFullYear();
          var secondDate = values[1].getMonth() + 1 + '/' + values[1].getDate() + '/' + values[1].getFullYear();
        } else if (!self.reportData.options.datePickerIsRange && values.length == 1) {
          reloadData = true;
          var firstDate = values[0].getMonth() + 1 + '/' + values[0].getDate() + '/' + values[0].getFullYear();
          var secondDate = firstDate;
        }

        var reportVariables = self.reportData.variables;
        reportVariables.firstDate = firstDate;
        reportVariables.secondDate = secondDate;
      }

      if (self.reportData.type == 'pdf') {
        if (reloadData) {
          // console.log('GOT HERE!', self.reportData);
          self.reportData.variables.date = firstDate;
          self.reportData.variables.firstDate = firstDate;
          self.reportData.variables.secondDate = secondDate; // console.log('URL','https://taskmaster.bedroomsandmore.com/4DACTION/web_pdf'+self.getPDFURL());

          self.$el.find('#pdfObject').attr('src', 'https://taskmaster.bedroomsandmore.com/4DACTION/web_pdf' + self.getPDFURL());
          cal.close();
        }
      } else {
        if (reloadData) {
          cal.close();
          self.$setState({
            waitForReport: true
          }, function () {
            self.$app.data.pageStatusData = {
              reportCategory: self.reportCategory,
              reportName: self.reportName,
              reportVariables: reportVariables,
              runReport: true
            };
            console.log('sendStatus: called from datePicked');
            self.$root.sendStatus();
          });
        }
      }
      /*
      } else if (self.reportData.type == 'chart') {
      	if (!self.reportChartRanOnce) {
      		self.reportChartRanOnce= true;
      		
      	} else {
      							
      		if (reloadData) {
      			cal.close();
      			
      			self.$app.preloader.show();
      			
      			var reportVariables= self.reportData.variables;
      			reportVariables.firstDate= firstDate;
      			reportVariables.secondDate= secondDate;
      			
      			self.$app.data.pageStatusData= {
      				reportCategory: self.reportCategory,
      				reportName: self.reportName,
      				reportVariables: reportVariables,
      				runReport: true
      			};
      			
      			self.$root.sendStatus();
      		}
      	}
      } else {
      	if (reloadData) {
      		cal.close();
      			var reportVariables= self.reportData.variables;
      		reportVariables.firstDate= firstDate;
      		reportVariables.secondDate= secondDate;
      		
      		self.$setState({
      			waitForReport: true
      			},function() {
      			self.$app.data.pageStatusData= {
      				reportCategory: self.reportCategory,
      				reportName: self.reportName,
      				reportVariables: reportVariables,
      				runReport: true
      			};
      			
      			self.$root.sendStatus();
      		});
      		}
      	}
      */

    },
    drawReport: function () {
      var self = this; // console.log('self.reportData',self.reportData);
      // TODO: virtual list TABLE...
      // self.loading= false;

      if (self.reportData.reportName && self.reportData.reportName !== '') {
        self.reportName = self.reportData.reportName;
      }

      self.$setState({
        loading: false,
        waitForReport: false
      }, function () {
        // TODO: will need to loop through all userOptions to enable date pickers
        for (var i = 0; i < self.reportData.userOptions.length; i++) {
          if (self.reportData.userOptions[i].type == 'date') {
            if (self.reportData.userOptions[i].range) {
              var convertedStartDate = moment(self.reportData.userOptions[i].value[0], 'MM-DD-YYYY').toDate();
              var convertedEndDate = moment(self.reportData.userOptions[i].value[1], 'MM-DD-YYYY').toDate();
            } else {
              var convertedDate = moment(self.reportData.userOptions[i].value, 'MM-DD-YYYY').toDate();
            }

            console.log('*** Current Value', 'calendar' + self.reportData.userOptions[i].name, self['calendar' + self.reportData.userOptions[i].name]);
            console.log('self', self);

            if (!self['calendar' + self.reportData.userOptions[i].name]) {
              var calendarSetupObject = {
                inputEl: '#reportDatePicker' + self.reportData.userOptions[i].name,
                dateFormat: 'M dd yyyy',
                rangePicker: self.reportData.userOptions[i].range,
                value: self.reportData.userOptions[i].range ? [convertedStartDate, convertedEndDate] : [convertedDate]
              };

              if (!self.reportData.userOptions[i].hasOwnProperty('maxDate')) {
                calendarSetupObject.maxDate = new Date();
              } else if (self.reportData.userOptions[i].maxDate) {// HAS A VALUE!
                // TODO! SET IT!
              }

              self['calendar' + self.reportData.userOptions[i].name] = self.$app.calendar.create(calendarSetupObject);
              self['calendar' + self.reportData.userOptions[i].name].reportVariableName = self.reportData.userOptions[i].name;
              self['calendar' + self.reportData.userOptions[i].name].reportVariableIsRange = self.reportData.userOptions[i].range ? true : false;
              self['calendar' + self.reportData.userOptions[i].name].on('change', self.datePicked);
            } else {// set the date on the existing variable object...
            }
          }
        }

        if (self.reportData.options.datePicker) {
          // IF Choosing a Calendar Range is allowed!
          // TODO: grab current values of dates if given...
          if (self.reportData.options.datePickerIsRange) {
            var convertedStartDate = moment(self.reportData.variables.firstDate, 'MM-DD-YYYY').toDate();
            var convertedEndDate = moment(self.reportData.variables.secondDate, 'MM-DD-YYYY').toDate();
          } else {
            var convertedDate = moment(self.reportData.variables.date, 'MM-DD-YYYY').toDate();
          }

          console.log(convertedStartDate, convertedEndDate);

          if (!self.calendarRange) {
            self.calendarRange = self.$app.calendar.create({
              inputEl: '#reportDatePicker',
              dateFormat: 'M dd yyyy',
              maxDate: new Date(),
              rangePicker: self.reportData.options.datePickerIsRange,
              value: self.reportData.options.datePickerIsRange ? [convertedStartDate, convertedEndDate] : [convertedDate]
              /*
              on: {
              	change: self.datePicked
              }
              */

            });
            self.calendarRange.on('change', self.datePicked);
          } else {// set the date!
          }
        }

        if (!self.reportCharts) {
          self.reportCharts = [];
        } else {
          for (var i = 0; i < self.reportCharts.length; i++) {
            self.reportCharts[i].destroy();
          }
        }

        if (self.reportData.type == 'multiple') {
          self.$el.find('#dataBlock').html('');

          for (var i = 0; i < self.reportData.multipleObjects.length; i++) {
            var blocksHTML = '<div class="dataBlockObject' + i + '"></div>';

            if (self.reportData.multipleObjects[i].type == 'chart') {
              blocksHTML = '<div class="card chartReportTypeCard">' + '<div class="card-content">' + '<div class="block">' + '<div class="dataBlockObject' + i + '"></div>' + '</div>' + '</div>' + '</div>';
            } // console.log('blocksHTML',blocksHTML);


            self.$el.find('#dataBlock').append(blocksHTML);
            self.drawReportBlock(self.reportData.multipleObjects[i], '.dataBlockObject' + i);
          } // console.log(self.reportCharts);

        } else {
          self.drawReportBlock(self.reportData, '#dataBlock');
        }

        self.$el.find('.range-slider').each(function (index, element) {
          var thisElement = self.$(element);

          if (!thisElement.hasClass('range-slider-init')) {
            self.$app.range.create({
              el: element
            });
          }
        });
        self.$el.find('.tooltip-init-after-refresh').each(function (index, element) {
          var thisElement = self.$(element);
          self.$app.tooltip.create({
            targetEl: element,
            text: thisElement.attr('data-tooltip')
          });
        });
        self.$app.preloader.hide();
      }); // Refresh the view...
      //self.$el.find('.loadingBlock').remove();
    },
    drawReportBlock: function (reportDataBlock, elementSelector) {
      var self = this;
      var uniqueStringForThis = ((+new Date()).toString(36) + Math.random()).replace(/\./g, '');
      var destinationElement = '';
      var htmlToUse = ''; // console.log('ThisBlock',reportDataBlock);

      var lowercaseType = reportDataBlock.type.toLowerCase();
      var wrapInAnAccordion = false;

      if (lowercaseType.indexOf('accordion') == 0) {
        if (lowercaseType !== 'accordion') {
          // There is MORE to it... wrap inside an accordion...
          lowercaseType = lowercaseType.substring(9);
          wrapInAnAccordion = true;
        }
      }

      if (wrapInAnAccordion) {}

      if (reportDataBlock.type == 'chart') {
        if (false) {//((self.reportChart) && (self.reportChart.chart.type == reportDataBlock.chartType)) { // Already defined

          /*
          // TODO: this doesn't change the type of an already built chart...
          self.reportChart.chart.type= reportDataBlock.chartType;
          
          // console.log(self.reportChart);
          var chartLabel= 'Sales';
          if (reportDataBlock.chartLabel) {
          	chartLabel= reportDataBlock.chartLabel;
          	console.log('HERE!');
          }
          self.reportChart.data= {
          	labels: reportDataBlock.chartLabels,
          	datasets: [{
          		label: chartLabel,
          		data: reportDataBlock.chartData,
          		backgroundColor: reportDataBlock.chartColors
          	}]
          };
          
          self.reportChart.options.title.text= reportDataBlock.chartTotalText;
          
          self.reportChart.update();
          */
        } else {
          var canvasElementHTML = '<div class="canvasWrapper"' + (reportDataBlock.chartHeight ? ' style="max-height: ' + reportDataBlock.chartHeight + ';"' : '') + '>' + '<canvas class="' + uniqueStringForThis + '_canvas" width="400" height="400"></canvas>' + '</div>';
          self.$el.find(elementSelector).html(canvasElementHTML);
          var chartOptions = {
            responsive: true,
            maintainAspectRatio: !(reportDataBlock.chartHeight && reportDataBlock.chartHeight !== ''),
            title: {
              display: true,
              text: reportDataBlock.chartTotalText
            }
          };

          if (reportDataBlock.legend) {
            chartOptions.legend = reportDataBlock.legend;
          }

          if (reportDataBlock.scales) {
            chartOptions.scales = reportDataBlock.scales;
          } else {
            if (reportDataBlock.chartType == 'bar') {
              chartOptions.scales = {
                xAxes: [{
                  stacked: true,
                  ticks: {
                    autoSkip: false
                  }
                }],
                yAxes: [{
                  stacked: true,
                  ticks: {
                    beginAtZero: true
                  }
                }]
              };
            }
          }

          var ctx = self.$el.find('.' + uniqueStringForThis + '_canvas')[0];

          if (reportDataBlock.chartDatasets) {
            // TODO: AND it's not blank...
            self.reportCharts.push(new Chart(ctx, {
              type: reportDataBlock.chartType,
              data: {
                labels: reportDataBlock.chartLabels,
                datasets: reportDataBlock.chartDatasets
              },
              options: chartOptions
            }));
          } else {
            var chartLabel = 'Sales';

            if (reportDataBlock.chartLabel) {
              chartLabel = reportDataBlock.chartLabel;
            }

            self.reportCharts.push(new Chart(ctx, {
              type: reportDataBlock.chartType,
              data: {
                labels: reportDataBlock.chartLabels,
                //labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
                datasets: [{
                  label: chartLabel,
                  data: reportDataBlock.chartData,
                  // minBarLength: 2, // TODO: this is interesting...
                  backgroundColor: reportDataBlock.chartColors
                }]
              },
              options: chartOptions
            }));
          }
        }
      } else if (reportDataBlock.type == 'accordionlist') {
        // TODO: break into just a LIST when ready...
        var listToLoop = [];

        if (!reportDataBlock.listTitle || reportDataBlock.listTitle == '') {
          reportDataBlock.listTitle = 'title';
        }

        if (!reportDataBlock.listAfter || reportDataBlock.listAfter == '') {
          reportDataBlock.listAfter = 'after';
        }

        if (!reportDataBlock.listText || reportDataBlock.listText == '') {
          reportDataBlock.listText = 'text';
        }

        if (reportDataBlock.list && reportDataBlock.list.length > 0) {
          listToLoop = reportDataBlock.list;
        }

        var htmlBlockHTML = '<div class="list accordion-list accordion-list-report-page">' + '<ul>' + '<li class="accordion-item">' + '<a href="" class="item-link item-content">' + '<div class="item-inner">' + '<div class="item-title">' + reportDataBlock.accordionTitle + '</div>' + '</div>' + '</a>' + '<div class="accordion-item-content">' + '<div class="list media-list">' + '<ul>';

        for (var j = 0; j < listToLoop.length; j++) {
          var currentListObject = listToLoop[j];

          if (reportDataBlock.listURL && reportDataBlock.listURL !== '') {
            var currentObjectLink = reportDataBlock.listURL; // TODO: loop through to overwrite variables...

            var availableVarNames = Object.keys(currentListObject);

            for (var a = 0; a < availableVarNames.length; a++) {
              currentObjectLink = currentObjectLink.replace('[[' + availableVarNames[a] + ']]', currentListObject[availableVarNames[a]]);
            }

            htmlBlockHTML += '<li>' + '<a href="' + currentObjectLink + '" class="item-link item-content" draggable="false">';
          } else {
            htmlBlockHTML += '<li class="item-content">';
          }

          htmlBlockHTML += '<div class="item-inner">' + '<div class="item-title-row">';

          if (listToLoop[j][reportDataBlock.listTitle]) {
            htmlBlockHTML += '<div class="item-title">' + listToLoop[j][reportDataBlock.listTitle] + '</div>';
          }

          if (listToLoop[j][reportDataBlock.listAfter]) {
            htmlBlockHTML += '<div class="item-after">' + listToLoop[j][reportDataBlock.listAfter] + '</div>';
          }

          htmlBlockHTML += '</div>';

          if (listToLoop[j][reportDataBlock.listText]) {
            htmlBlockHTML += '<div class="item-text">' + listToLoop[j][reportDataBlock.listText] + '</div>';
          }

          htmlBlockHTML += '</div>';

          if (reportDataBlock.listURL && reportDataBlock.listURL !== '') {
            htmlBlockHTML += '</a>' + '</li>';
          } else {
            htmlBlockHTML += '</li>';
          }
        }

        htmlBlockHTML += '</ul>' + '</div>' + '</div>' + '</li>' + '</ul>' + '</div>';
        self.$el.find(elementSelector).html(htmlBlockHTML);
      } else if (reportDataBlock.type == 'table') {
        self.$el.find('#dataBlock').addClass('no-margin'); //.addClass('no-padding');

        if (true) {
          // IF not desktop...? (TODO!)
          self.$el.find('#dataBlock').addClass('virtual-list');
          var pageContentWidth = self.$el.width();
          var rowHeight = self.$app.theme === 'ios' ? 44 : self.$app.theme === 'md' ? 48 : 32;

          if (!reportDataBlock.columnWidths) {
            reportDataBlock.columnWidths = new Array(reportDataBlock.tableLabels.length);
            reportDataBlock.columnWidths.fill(0, 0);
          } else {
            if (reportDataBlock.columnWidths.length < reportDataBlock.tableLabels.length) {
              for (var i = 0; i < reportDataBlock.tableLabels.length - reportDataBlock.columnWidths.length; i++) {
                reportDataBlock.columnWidths.push(0);
              }
            }
          }

          var totalWidth = 0;

          for (var i = 0; i < reportDataBlock.columnWidths.length; i++) {
            if (reportDataBlock.columnWidths[i] == 0) {
              var thisColumnWidth = 0;

              for (var j = 0; j < reportDataBlock.tableData.length; j++) {
                var thisWidth = self.$root.getTextWidth(reportDataBlock.tableData[j][reportDataBlock.tableLabels[i]]);

                if (thisWidth > thisColumnWidth) {
                  thisColumnWidth = thisWidth;
                }
              }

              if (thisColumnWidth < 25) {
                thisColumnWidth = 25;
              }

              reportDataBlock.columnWidths[i] = thisColumnWidth;
            } else if (reportDataBlock.columnWidths[i] < 0) {
              reportDataBlock.columnWidths[i] = 0;
            }

            totalWidth += reportDataBlock.columnWidths[i];
          }

          var tableHeaderHTML = '<div class="virtualTableHeader" style="line-height: ' + (rowHeight - 4) + 'px">' + '<div class="virtualTableRow" style="width: ' + totalWidth + 'px; height: ' + rowHeight + 'px">';

          for (var i = 0; i < reportDataBlock.tableLabels.length; i++) {
            tableHeaderHTML += '<div class="virtualTableCell" style="width: ' + reportDataBlock.columnWidths[i] + 'px; height: ' + rowHeight + 'px">' + reportDataBlock.tableLabels[i] + '</div>';
          }

          tableHeaderHTML += '</div>' + '</div>'; // console.log('tableHeader',tableHeaderHTML);

          self.$el.find('.page-content').prepend(tableHeaderHTML);
          self.$el.find('#dataBlock').css('padding-top', rowHeight + 'px'); // TODO: need any kind of delay?

          self.$el.find('.page-content').on('scroll', function (e) {
            self.$el.find('.virtualTableHeader').css('left', -1 * self.$el.find('.page-content').scrollLeft() + 'px');
          }); // console.log('reportDataBlock.columnWidths',reportDataBlock.columnWidths);

          self.virtualList = self.$app.virtualList.create({
            el: '.virtual-list',
            createUl: false,
            items: reportDataBlock.tableData,
            searchByItem: function (query, item, index) {
              // TODO: do the "search by word" logic I did in TaskMaster
              // TODO: Get property names of item NOT reportDataBlock.tableLabels
              for (var i = 0; i < reportDataBlock.tableLabels.length; i++) {
                if ((item[reportDataBlock.tableLabels[i]] + '').toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') {
                  return true;
                }
              }

              return false;
            },
            //itemTemplate: '<li><a href="#">{{Model}}</a></li>'
            renderItem: function (item, index) {
              var thisItemHTML = '<div class="virtualTableRow' + (index % 2 == 1 ? ' evenRow' : '') + '" style="width: ' + totalWidth + 'px; height: ' + rowHeight + 'px">';

              for (var i = 0; i < reportDataBlock.tableLabels.length; i++) {
                thisItemHTML += '<div class="virtualTableCell" style="width: ' + reportDataBlock.columnWidths[i] + 'px; height: ' + rowHeight + 'px">' + item[reportDataBlock.tableLabels[i]] + '</div>';
              }

              thisItemHTML += '</div>';
              return thisItemHTML.trim();
            },
            height: rowHeight
          });
          self.searchbar = self.$app.searchbar.create({
            el: '.searchbar',
            searchContainer: '.virtual-list',
            on: {
              enable: function () {
                console.log('Searchbar opened');
              }
            }
          }); //self.virtualList.tempDomElement= document.createElement('table');
          //console.log(self.virtualList);
        } else {
            /*
            var pageContentHeight= (self.$el.height()-parseFloat(self.$el.find('.page-content').css('padding-top')));
            
            console.log('pageContentHeight',pageContentHeight);
            console.log('navBar',parseFloat(self.$el.find('.page-content').css('padding-top')));
            
            
            var columnsArray= reportDataBlock.tableLabels.map(function(name) {
            	return {
            		title: name,
            		field: name
            	}
            });
            
            self.renderedTable= new Tabulator('#dataBlock', {
            	height: pageContentHeight-10, //'100%',
            	movableColumns: true,
            	selectable: 1,
            	data: reportDataBlock.tableData, //assign data to table
            	layout:'fitDataFill', //fit columns to width of table (optional)
            	columns:columnsArray,
            	rowClick:function(e, row){ //trigger an alert message when the row is clicked
            		// alert("Row " + JSON.stringify(row.getData()) + " Clicked!!!!");
            	},
            	footerElement: '<p> </p>'//'<button>Custom Button</button>'
            });
            */
          }
      } else if (reportDataBlock.type == 'html') {
        var htmlBlockHTML = '<div class="card htmlReportTypeCard">' + '<div class="card-content">' + '<div class="block">' + reportDataBlock.html + '</div>' + '</div>' + '</div>';
        self.$el.find(elementSelector).html(htmlBlockHTML);
      } else if (reportDataBlock.type == 'pdf') {
        self.$el.find('#dataBlock').addClass('no-margin').addClass('no-padding'); // self.$el.find('.datePickerDiv').show(); // TODO: should be it's own thing...

        var pdfReportURL = self.getPDFURL();
        var pdfHTML = '<div class="content-block" id="datePageContent">' + '<iframe id="pdfObject" width="100%" height="100%" src="https://taskmaster.bedroomsandmore.com/4DACTION/web_pdf' + pdfReportURL + '" allow="fullscreen" style="height: 100%; width:100%; min-height: 850px;" frameborder="0">Loading...</iframe>' + '</div>';
        self.$el.find('#dataBlock canvas').remove();
        self.$el.find('#dataBlock').append(pdfHTML);
      } else if (reportDataBlock.type == 'card') {// TODO: need to figure out the rules for this one!
      } else if (reportDataBlock.type == 'custom') {
        if (reportDataBlock.name == 'Compare Current Sales') {
          var topOptionsHTML = '';
          var dataBlockHTML = '';
          topOptionsHTML += '<div class="block">' + '<p class="segmented">' + '<button class="button button-round button-outline compareCurrentSalesViewButton' + (reportDataBlock.currentView == 'MTD' ? ' button-active' : '') + '">MTD</button>' + '<button class="button button-round button-outline compareCurrentSalesViewButton' + (reportDataBlock.currentView == 'YTD' ? ' button-active' : '') + '">YTD</button>' + '</p>' + '</div>';

          for (var i = 0; i < reportDataBlock.locations.length; i++) {
            dataBlockHTML += '<div class="card">' + '<div class="card-header">' + reportDataBlock.locations[i].locationName + '</div>' + '<div class="card-content card-content-padding">'; // TODO: make a list here?

            for (var j = 0; j < reportDataBlock.locations[i][reportDataBlock.currentView + '_Dollars'].length; j++) {
              var upDownText = '';

              if (j == 0) {
                dataBlockHTML += reportDataBlock.locations[i][reportDataBlock.currentView + '_Text'][j] + ' ';
                dataBlockHTML += '<strong>'; //upDownText= (reportDataBlock.locations[i][reportDataBlock.currentView+'_YearOverYearPercent'][j] >= 0) ? ' <span style="color: #006400;">UP</span>': ' <span style="color: #f00;">DOWN</span>';
                //upDownText= (reportDataBlock.locations[i][reportDataBlock.currentView+'_YearOverYearPercent'][j] >= 0) ? ' <div class="chip color-green"><div class="chip-label">UP</div></div>': ' <div class="chip color-red"><div class="chip-label">DOWN</div></div>';
              } else {
                dataBlockHTML += '<span style="color: #999;">';
                dataBlockHTML += reportDataBlock.locations[i][reportDataBlock.currentView + '_Text'][j] + ' ';
              }

              dataBlockHTML += reportDataBlock.locations[i][reportDataBlock.currentView + '_Dollars'][j].formatMoney() + ' - ';

              if (j == 0) {
                if (reportDataBlock.locations[i][reportDataBlock.currentView + '_YearOverYearPercent'][j] >= 0) {
                  dataBlockHTML += '<div class="chip color-green"><div class="chip-label" style="font-size: 1.25em;">';
                } else {
                  dataBlockHTML += '<div class="chip color-red"><div class="chip-label" style="font-size: 1.25em;">';
                }
              }

              dataBlockHTML += reportDataBlock.locations[i][reportDataBlock.currentView + '_YearOverYearPercent'][j] + '%';

              if (j == 0) {
                dataBlockHTML += '</div></div></strong>';
              } else {
                dataBlockHTML += '</span>';
              }

              dataBlockHTML += '<br>';
            }

            dataBlockHTML += '</div>' + '<div class="card-footer">' + reportDataBlock.currentView + ' average over ' + reportDataBlock.locations[i][reportDataBlock.currentView + '_Dollars'].length + ' years: ' + reportDataBlock.locations[i][reportDataBlock.currentView + '_YearOverYearAveragePercent'] + '%</div>' + '</div>';
          }

          self.$el.find('#topOptionsBlock').html(topOptionsHTML);
          self.$el.find('#dataBlock').html(dataBlockHTML);
          self.$el.on('click', '.compareCurrentSalesViewButton', function (e) {
            var thisButton = self.$(this);

            if (!thisButton.hasClass('button-active')) {
              reportDataBlock.currentView = thisButton.text();
              reportDataBlock.reportName = 'Compare Current Sales ' + reportDataBlock.currentView;
              self.drawReport();
              thisButton.parent().find('.button-active').removeClass('button-active');
              thisButton.addClass('button-active');
            }
          });
        } else {// error...
        }
      } else {// Unsupported report type...  TODO: show error...
        }
    },
    getPDFURL: function () {
      var self = this;
      var pdfReportURL = '';
      var continueWithPDF = false;

      if (self.reportData.url && self.reportData.url !== '') {
        continueWithPDF = true;
        pdfReportURL = self.reportData.url;
        console.log('STARTING URL:', pdfReportURL);
        var variableKeys = Object.keys(self.reportData.variables);
        console.log('variableKeys:', variableKeys, self.reportData.variables);

        for (var i = 0; i < variableKeys.length; i++) {
          // TODO! make a required object from the server later...

          /*
          if ((variableKeys[i].required) && (self.reportData.variables[variableKeys[i]] == '')) {
          	continueWithPDF= false;
          	break;
          }
          */
          if (continueWithPDF) {
            pdfReportURL = pdfReportURL.replace('{{' + variableKeys[i] + '}}', self.reportData.variables[variableKeys[i]]);
          }
        }
      }

      if (continueWithPDF) {
        pdfReportURL = pdfReportURL.replace('{{sessionId}}', self.$app.data.mdWebSocket.sessionId);
      } else {
        pdfReportURL = '';
      }

      console.log('pdfURL', pdfReportURL);
      return pdfReportURL;
    },
    shareReport: function () {
      var self = this;
      self.shareActions.open();
    },
    goToInvoice: function (e) {
      var self = this;
      var thisATag = self.$(e.target).closest('a'); // self.$app.data.invoiceBackHref= self.$router.url; // This is not needed here... There is an if statement now on the invoice screen that will handle it.
      //console.log('self.$app.data.invoiceBackHref',self.$app.data.invoiceBackHref);

      self.$router.navigate('/taskmaster/invoices/' + thisATag.attr('data-invoiceNumber') + '/');
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.shareActions = self.$app.actions.create({
        buttons: [// First group
        [{
          text: 'Print',
          bold: true,
          onClick: function () {
            if (self.$app.device.desktop) {
              window.print();
            } else if (window.cordova && window.cordova.plugins && window.cordova.plugins.printer) {
              window.cordova.plugins.printer.print();
            } else {
              self.$app.dialog.alert('Printing is not supported yet.', 'Not Supported');
            }
          }
        }
        /*,
        {
        text: 'Share Report',
        label: true
        },
        {
        text: 'Email',
        bold: true
        }*/
        ], // Second group
        [{
          text: 'Cancel',
          color: 'red'
        }]]
      });
    }
  },
  id: '350cede91b',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="blankPage">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">';
      r += c(ctx_1.topOfPageTitle, ctx_1);
      r += '</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					';
          r += Template7Helpers.if.call(ctx_2, ctx_2.sharingIsLive, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<a class="link icon-only" @click="shareReport">\n						<i class="icon f7-icons if-not-md">square_arrow_up</i>\n						<i class="icon material-icons md-only">share</i>\n					</a>\n					';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n					';
          r += Template7Helpers.if.call(ctx_2, ctx_2.reportData.options.search, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<a class="link icon-only searchbar-enable" data-searchbar=".searchbar-report">\n						<i class="icon f7-icons if-not-md">search</i>\n						<i class="icon material-icons md-only">search</i>\n					</a>\n					';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n				<form class="searchbar searchbar-expandable searchbar-report">\n					<div class="searchbar-inner">\n						<div class="searchbar-input-wrap">\n							<input type="search" placeholder="Search"/>\n							<i class="searchbar-icon"></i>\n							<span class="input-clear-button"></span>\n						</div>\n						<span class="searchbar-disable-button if-not-aurora">Cancel</span>\n					</div>\n				</form>\n			</div>\n		</div>\n		<div class="page-content';
      r += Template7Helpers.if.call(ctx_1, ctx_1.waitForReport, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="block block-strong loadingBlock">\n				<p><div class="preloader"></div></p>\n				<p>';
          r += c(ctx_2.loadingMessage, ctx_2);
          r += '</p>\n			</div>\n			';
          r += Template7Helpers.if.call(ctx_2, ctx_2.icon, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.placeholder, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.range, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.icon, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.value, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.multiple, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.multiple, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.selected, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.icon, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.multiple, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.reportData.options.datePicker, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.reportData.options.datePickerIsRange, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.reportData.options.brandPicker, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.reportData.options.brandPickerMultiple, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.reportData.options.categoryPicker, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.reportData.options.categoryPickerMultiple, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.reportData.options.locationPicker, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.reportData.options.locationPickerMultiple, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.if.call(ctx_3, ctx_3.retailInvoicePrefix, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.reportData.options.disclaimerAbove, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.waitForReport, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.reportData.options.disclaimerBelow, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div id="topOptionsBlock">\n				';
          r += Template7Helpers.each.call(ctx_2, ctx_2.reportData.userOptions, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				';
              r += Template7Helpers.js_if.call(ctx_3, "this.type == 'date'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n				<div class="list no-hairlines-md no-margin no-padding datePickerDiv">\n					<ul>\n						<li>\n							<div class="item-content item-input">\n								<div class="item-media">\n									';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.icon, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n									';
                      r += c(ctx_5.icon, ctx_5);
                      r += '\n									';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n									<i class="icon f7-icons">calendar</i>\n									';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n								</div>\n								<div class="item-inner">\n									<div class="item-input-wrap">\n										<input type="text" name="';
                  r += c(ctx_4.name, ctx_4);
                  r += '" placeholder="';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.placeholder, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += c(ctx_5.placeholder, ctx_5);
                      r += Template7Helpers.if.call(ctx_5, ctx_5.range, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += 'Select date';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.range, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += ' range';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '" readonly="readonly" id="reportDatePicker';
                  r += c(ctx_4.name, ctx_4);
                  r += '">\n									</div>\n								</div>\n							</div>\n						</li>\n					</ul>\n				</div>\n				';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n				';
              r += Template7Helpers.js_if.call(ctx_3, "((this.type == 'boolean') || (this.type == 'toggle'))", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n				<div class="list no-hairlines-md no-margin no-padding togglePickerDiv">\n					<ul>\n						<li>\n							<div class="item-content">\n								';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.icon, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n								<div class="item-media">';
                      r += c(ctx_5.icon, ctx_5);
                      r += '</div>\n								';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n								<div class="item-inner">\n									<div class="item-title item-title-';
                  r += c(ctx_4.name, ctx_4);
                  r += '">';
                  r += c(ctx_4.title, ctx_4);
                  r += '</div>\n									<div class="item-after item-after-';
                  r += c(ctx_4.name, ctx_4);
                  r += '">\n										<label class="toggle toggle-init color-blue" data-name="';
                  r += c(ctx_4.name, ctx_4);
                  r += '" @toggle:change="toggleChanged">\n											<input type="checkbox"';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.value, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' checked';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' />\n											<span class="toggle-icon"></span>\n										</label>\n									</div>\n								</div>\n							</div>\n						</li>\n					</ul>\n				</div>\n				';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n				';
              r += Template7Helpers.js_if.call(ctx_3, "this.type == 'choicelist'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n				<div class="list no-hairlines-md no-margin no-padding choicelistPickerDiv">\n					<ul>\n						<li>\n							<a class="item-link smart-select choicelistSmartSelect smart-select-init" data-optionsVariableUseData="value" data-optionsVariableName="';
                  r += c(ctx_4.name, ctx_4);
                  r += '"';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.multiple, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' data-value-el=".item-after-';
                      r += c(ctx_5.name, ctx_5);
                      r += '"';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += ' data-value-el=".item-title-';
                      r += c(ctx_5.name, ctx_5);
                      r += '"';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' data-open-in="popup"';
                  r += Template7Helpers.unless.call(ctx_4, ctx_4.multiple, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' data-close-on-select="true"';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' @smartselect:close="pickerOptionChanged">\n								<select name="';
                  r += c(ctx_4.name, ctx_4);
                  r += '"';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.multiple, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' multiple';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '>\n									';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.choices, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n									<option value="';
                      r += c(ctx_5.value, ctx_5);
                      r += '"';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.selected, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += ' selected';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '>';
                      r += c(ctx_5.name, ctx_5);
                      r += '</option>\n									';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n								</select>\n								<div class="item-content">\n									<div class="item-media">\n										';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.icon, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n										';
                      r += c(ctx_5.icon, ctx_5);
                      r += '\n										';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n										<i class="icon f7-icons">smallcircle_fill_circle</i>\n										';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n									</div>\n									<div class="item-inner">\n										<div class="item-title item-title-';
                  r += c(ctx_4.name, ctx_4);
                  r += '">';
                  r += c(ctx_4.title, ctx_4);
                  r += '</div>\n										';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.multiple, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n										<div class="item-after item-after-';
                      r += c(ctx_5.name, ctx_5);
                      r += '"></div>\n										';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n									</div>\n								</div>\n							</a>\n						</li>\n					</ul>\n				</div>\n				';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				';
          r += Template7Helpers.if.call(ctx_2, ctx_2.reportData.options.datePicker, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<div class="list no-hairlines-md no-margin no-padding datePickerDiv">\n					<ul>\n						<li>\n							<div class="item-content item-input">\n								<div class="item-media">\n									<i class="icon f7-icons">calendar</i>\n								</div>\n								<div class="item-inner">\n									<div class="item-input-wrap">\n										<input type="text" placeholder="Select date';
              r += Template7Helpers.if.call(ctx_3, ctx_3.reportData.options.datePickerIsRange, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' range';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '" readonly="readonly" id="reportDatePicker">\n									</div>\n								</div>\n							</div>\n						</li>\n					</ul>\n				</div>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				';
          r += Template7Helpers.if.call(ctx_2, ctx_2.reportData.options.brandPicker, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<div class="list no-hairlines-md no-margin no-padding brandPickerDiv">\n					<ul>\n						<li>\n							<a class="item-link smart-select brandSmartSelect smart-select-init" data-optionsVariableUseData="name" data-optionsVariableName="brand" data-value-el=".item-titleBrand" data-open-in="popup"';
              r += Template7Helpers.unless.call(ctx_3, ctx_3.reportData.options.brandPickerMultiple, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' data-close-on-select="true"';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' @smartselect:close="pickerOptionChanged">\n								<select name="brand"';
              r += Template7Helpers.if.call(ctx_3, ctx_3.reportData.options.brandPickerMultiple, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' multiple';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '>\n									';
              r += Template7Helpers.each.call(ctx_3, ctx_3.reportData.options.brandsArray, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n									<option value="';
                  r += c(ctx_4.id, ctx_4);
                  r += '"';
                  r += Template7Helpers.unless.call(ctx_4, data_4 && data_4.index, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' selected';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '>';
                  r += c(ctx_4.name, ctx_4);
                  r += '</option>\n									';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n								</select>\n								<div class="item-content">\n									<div class="item-media">\n										<i class="icon f7-icons">smallcircle_fill_circle</i>\n									</div>\n									<div class="item-inner">\n										<div class="item-title item-titleBrand"></div>\n									</div>\n								</div>\n							</a>\n						</li>\n					</ul>\n				</div>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				';
          r += Template7Helpers.if.call(ctx_2, ctx_2.reportData.options.categoryPicker, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<div class="list no-hairlines-md no-margin no-padding categoryPickerDiv">\n					<ul>\n						<li>\n							<a class="item-link smart-select categorySmartSelect smart-select-init" data-optionsVariableUseData="name" data-optionsVariableName="category" data-value-el=".item-titleCategory" data-open-in="popup"';
              r += Template7Helpers.unless.call(ctx_3, ctx_3.reportData.options.categoryPickerMultiple, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' data-close-on-select="true"';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' @smartselect:close="pickerOptionChanged">\n								<select name="category"';
              r += Template7Helpers.if.call(ctx_3, ctx_3.reportData.options.categoryPickerMultiple, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' multiple';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '>\n									';
              r += Template7Helpers.each.call(ctx_3, ctx_3.reportData.options.categoriesArray, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n									<option value="';
                  r += c(ctx_4.id, ctx_4);
                  r += '"';
                  r += Template7Helpers.unless.call(ctx_4, data_4 && data_4.index, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' selected';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '>';
                  r += c(ctx_4.name, ctx_4);
                  r += '</option>\n									';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n								</select>\n								<div class="item-content">\n									<div class="item-media">\n										<i class="icon f7-icons">rectangle_grid_2x2_fill</i>\n									</div>\n									<div class="item-inner">\n										<div class="item-title item-titleCategory"></div>\n									</div>\n								</div>\n							</a>\n						</li>\n					</ul>\n				</div>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				';
          r += Template7Helpers.if.call(ctx_2, ctx_2.reportData.options.locationPicker, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<div class="list no-hairlines-md no-margin no-padding locationPickerDiv">\n					<ul>\n						<li>\n							<a class="item-link smart-select smart-select-init" data-value-el=".item-title" data-open-in="popup"';
              r += Template7Helpers.unless.call(ctx_3, ctx_3.reportData.options.locationPickerMultiple, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' data-close-on-select="true"';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' @smartselect:close="locationPicked">\n								<select name="location"';
              r += Template7Helpers.if.call(ctx_3, ctx_3.reportData.options.locationPickerMultiple, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' multiple';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '>\n									';
              r += Template7Helpers.each.call(ctx_3, ctx_3.$root.taskMasterData.locations, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n									';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.retailInvoicePrefix, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n									<option value="';
                      r += c(ctx_5.id, ctx_5);
                      r += '"';
                      r += Template7Helpers.unless.call(ctx_5, data_5 && data_5.index, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += ' selected';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '>';
                      r += c(ctx_5.name, ctx_5);
                      r += '</option>\n									';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n									';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n								</select>\n								<div class="item-content">\n									<div class="item-media">\n										<i class="icon f7-icons">building_2_fill</i>\n									</div>\n									<div class="item-inner">\n										<div class="item-title"></div>\n									</div>\n								</div>\n							</a>\n						</li>\n					</ul>\n				</div>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				';
          r += Template7Helpers.if.call(ctx_2, ctx_2.reportData.options.disclaimerAbove, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<div class="block" style="text-align: center;">';
              r += c(ctx_3.reportData.options.disclaimerAbove, ctx_3);
              r += '</div>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n			</div>\n			';
          r += Template7Helpers.if.call(ctx_2, ctx_2.waitForReport, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n			<div class="block block-strong loadingBlock">\n				<p><div class="preloader"></div></p>\n				<p>';
              r += c(ctx_3.loadingMessage, ctx_3);
              r += '</p>\n			</div>\n			';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n			<div id="dataBlock"></div>\n			';
          r += Template7Helpers.if.call(ctx_2, ctx_2.reportData.options.disclaimerBelow, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n			<div class="block" style="text-align: center;">';
              r += c(ctx_3.reportData.options.disclaimerBelow, ctx_3);
              r += '</div>\n			';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n			';
          r += Template7Helpers.js_if.call(ctx_2, "this.reportData.name == 'November Companion Sales Contest'", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n			<div class="block-title">Invoices Involved:</div>\n			<div class="list media-list invoicesInvolvedList">\n				<ul>\n					';
              r += Template7Helpers.each.call(ctx_3, ctx_3.reportData.invoiceByInvoiceForContest, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n					<li>\n						<a href="#" class="item-link item-content" data-invoiceNumber="';
                  r += c(ctx_4.invoiceNumber, ctx_4);
                  r += '" @click="goToInvoice">\n							<div class="item-inner">\n								<div class="item-title-row">\n									<div class="item-title">';
                  r += c(ctx_4.invoiceNumber, ctx_4);
                  r += '</div>\n									<div class="item-after">';
                  r += c(ctx_4.invoiceEmployeeName, ctx_4);
                  r += ' ';
                  r += Template7Helpers.js.call(ctx_4, "this.totalContestDollars.formatMoney()", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</div>\n								</div>\n								<div class="item-subtitle"><span class="lighter">';
                  r += Template7Helpers.js.call(ctx_4, "moment(this.invoiceDateTime).format('l[,] LT')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</span>\n								';
                  r += Template7Helpers.js_if.call(ctx_4, "(this.bigTicketItemAmount > 0) && (this.totalContestDollars > 0)", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n								<br>BIG ITEM: ';
                      r += c(ctx_5.bigTicketItemDescription, ctx_5);
                      r += '\n								';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n								</div>\n								';
                  r += Template7Helpers.js_if.call(ctx_4, "(this.bigTicketItemAmount > 0) && (this.totalContestDollars > 0)", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n								<div class="item-text"><table cellpadding="0" cellspacing="2" style="width: min-content; margin-right: auto;"><tr><td>';
                      r += Template7Helpers.js.call(ctx_5, "this.totalItemsDollars.formatMoney()", {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</td><td>-</td><td>';
                      r += Template7Helpers.js.call(ctx_5, "this.bigTicketItemAmount.formatMoney()", {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</td><td>-</td><td>';
                      r += Template7Helpers.js.call(ctx_5, "this.adjustmentAmount.formatMoney()", {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</td><td>=</td><td>';
                      r += Template7Helpers.js.call(ctx_5, "this.totalContestDollars.formatMoney()", {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</td></tr><tr><td><sup>Items</sup></td><td>&nbsp;</td><td><sup>Big Item</sup></td><td>&nbsp;</td><td><sup>Adj</sup></td><td>&nbsp;</td><td>&nbsp;</td></tr></table></div>\n								';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n							</div>\n						</a>\n					</li>\n					';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n				</ul>\n			</div>\n			';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n			';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.loadingBlock {
		text-align: center;
	}

	.htmlReportTypeCard .card-content {
		padding-top: 1em;
		padding-bottom: 1em;
	}

	.virtualTableHeader {
		position: fixed;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		z-index: 1;
		background: #ccc;
	}
	
	.virtualTableHeader .virtualTableRow .virtualTableCell {
		text-align: center;
		font-weight: bold;
	}
	
	.virtualTableRow {
		position: relative;
	    box-sizing: border-box;
	}
	
	.virtualTableRow.evenRow {
		background: #eee;
	}
	
	.virtualTableRow .virtualTableCell {
		display: inline-block;
	    position: relative;
	    box-sizing: border-box;
	    padding: 4px;
	    border-right: 1px solid #aaa;
	    vertical-align: middle;
	    /*white-space: nowrap;*/
	    overflow: hidden;
	    text-overflow: ellipsis;
	}

	.invoicesInvolvedList .item-text table td {
		text-align: center;
	}

	.invoicesInvolvedList sup {
		font-style: italic;
		text-transform: uppercase;
		opacity: 0.5;
		white-space: nowrap;
	}

	.list.invoicesInvolvedList .item-subtitle {
		white-space: normal;
	}

	.list.invoicesInvolvedList .item-text {
		max-height: 50px;
	}

	.list.invoicesInvolvedList span.lighter {
		color: #999;
	}

	.list.accordion-list-report-page .item-title {
		width: 100%;
	}

	.chip.chip-badge {
		height: var(--f7-badge-size);
		line-height: unset;
		padding-left: unset;
		padding-right: unset;
		padding: var(--f7-badge-padding);
	}

	.chip.chip-badge .chip-media {
		width: unset;
		margin-left: unset;
	}

	.chip.chip-badge .chip-media + .chip-label {
		margin-left: unset;
	}
`,
  styleScoped: false
};
    