
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.conversationId = parseInt(self.$route.params.id);
    self.conversationName = decodeURIComponent(self.$route.params.name);
    self.$app.data.pageStatusData = {
      test: 'test'
    };
  },
  data: function () {
    return {
      messages: null,
      messageBar: null,
      typingTimeout: null,
      currentlyTypingEmployeeId: 0,
      conversationId: 0,
      conversationName: '',
      conversationObject: {}
    };
  },
  mounted: function () {
    var self = this;
    self.messages = self.$app.messages.create({
      el: '.messages',
      firstMessageRule: function (message, previousMessage, nextMessage) {
        if (message.isTitle) return false;
        if (!previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name) return true;
        return false;
      },
      lastMessageRule: function (message, previousMessage, nextMessage) {
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
        return false;
      },
      tailMessageRule: function (message, previousMessage, nextMessage) {
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
        return false;
      }
    });
    self.messageBar = self.$app.messagebar.create({
      el: '.messagebar'
    });
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
    self.messages.destroy();
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'pushNotification' && (data.additionalData.pushType.toLowerCase() == 'message' || data.additionalData.pushType.toLowerCase() == 'sticker')) {
        // Is it this conversation????
        var conversationId = data.additionalData.conversationId;

        if (conversationId == self.conversationId) {
          var actualMessage = data.additionalData.actualMessage;
          var messageToAdd = {}; // console.log('data.additionalData.fromEmployeeId',data.additionalData.fromEmployeeId);
          // console.log('self.$root.user.id',self.$root.user.id);

          if (data.additionalData.fromEmployeeId == self.$root.user.id) {
            messageToAdd.type = 'sent';
          } else {
            messageToAdd.type = 'received';
            messageToAdd.avatar = self.$root.getImageURL(data.additionalData.fromEmployeeId, 'jpg', 'TODO');
            self.markConversationAsRead(true);
          }

          if (data.additionalData.pushType.toLowerCase() == 'sticker') {
            messageToAdd.imageSrc = actualMessage; // STICKERS MOVED...

            messageToAdd.imageSrc = messageToAdd.imageSrc.replace("/img/", "/static/");
          } else {
            messageToAdd.text = actualMessage;
          }

          self.messages.addMessage(messageToAdd);
        }
      } else if (data.type == 'data' && data.event == 'typing' && data.conversationId == self.conversationId && data.employeeId !== self.$root.user.id) {
        self.messages.showTyping({
          header: data.employeeName + ' is typing',
          avatar: self.$root.getImageURL(data.employeeId, 'jpg', 'TODO')
        });
        self.currentlyTypingEmployeeId = data.employeeId;
      } else if (data.type == 'data' && data.event == 'doneTyping' && data.conversationId == self.conversationId && data.employeeId !== self.$root.user.id) {
        if (data.employeeId == self.currentlyTypingEmployeeId) {
          self.messages.hideTyping();
          self.currentlyTypingEmployeeId = 0;
        }
      }
    },
    sendMessage: function (e) {
      var self = this;
      var messageToAdd = self.messageBar.getValue();
      self.$root.mdWebSocket.sendOverWebSocket({
        action: 'sendMessage',
        conversationId: self.conversationId,
        message: messageToAdd
      });
      var text = messageToAdd.replace(/\n/g, '<br>').trim();
      if (!text.length) return;
      self.messageBar.clear();
      self.messageBar.focus(); // THE SERVER WILL ADD IT, DO NOT ADD IT HERE :)

      /*
      self.messages.addMessage({
      	text: text
      });
      */

      var indexOfConversation = self.$root.taskMasterData.conversations.map(function (e) {
        return e.id;
      }).indexOf(self.conversationId);

      if (false) {
        // TODO: (indexOfConversation > -1) {
        self.$root.taskMasterData.conversations[indexOfConversation].recentMessages.unshift({
          id: -1,
          // Hasn't referenced the DB yet...
          message: messageToAdd,
          messageType: '',
          sender: self.$root.user.id
        });

        if (indexOfConversation > 0) {
          self.$root.array_move(self.$root.taskMasterData.conversations, indexOfConversation, 0);
        }
      } else {
        var tempTimeout = setTimeout(function () {
          self.$root.mdWebSocket.sendOverWebSocket({
            action: 'getConversations'
          });
        }, 500);
      }
    },
    refreshConversation: function (e) {
      var self = this;
      var conversationIndex = self.$root.taskMasterData.conversations.map(function (e) {
        return e.id;
      }).indexOf(self.conversationId);

      if (conversationIndex > -1) {
        self.conversationObject = self.$root.taskMasterData.conversations[conversationIndex];
      }

      if (self.conversationObject !== {}) {
        var messagesArray = [];

        for (var i = 0; i < self.conversationObject.recentMessages.length; i++) {
          var currentMessageObject = {};

          if (self.conversationObject.recentMessages[i].sender == self.$root.user.id) {
            currentMessageObject.type == 'sent';
          } else {
            currentMessageObject.type = 'received';
            currentMessageObject.avatar = self.$root.getImageURL(self.conversationObject.recentMessages[i].sender, 'jpg', 'TODO');
          }

          if (self.conversationObject.recentMessages[i].messageType.toLowerCase() == 'sticker') {
            currentMessageObject.imageSrc = self.conversationObject.recentMessages[i].message; // STICKERS MOVED...

            currentMessageObject.imageSrc = currentMessageObject.imageSrc.replace("/img/", "/static/");
          } else {
            currentMessageObject.text = self.conversationObject.recentMessages[i].message;
          }

          messagesArray.push(currentMessageObject);
        }

        if (messagesArray.length > 0) {
          messagesArray.reverse();

          if (messagesArray.length < self.conversationObject.totalMessages) {
            messagesArray.unshift({
              text: '<a href="#" class="button button-large">Load Previous Messages...</a>',
              isTitle: true
            }); //self.$el.find('.messages-content').prepend('<a href="#" class="button button-large">Load Previous Messages...</a>');
          }

          self.messages.addMessages(messagesArray, 'append', 'false');
          var tempTimeout = setTimeout(function () {
            console.log('about to scroll!'); //self.messages.scroll(500, 999999);

            self.messages.scroll(0, 999999);
          }, 200);
        }
      }
    },
    markConversationAsRead: function (tellServer) {
      var self = this;
      tellServer = typeof tellServer !== 'undefined' ? tellServer : false;

      if (tellServer) {
        var tempTimeout = setTimeout(function () {
          self.$root.mdWebSocket.sendOverWebSocket({
            action: 'status',
            page: self.$root.mainView.router.url
          });
        }, 1000);
      } // TODO... is this working okay??


      if (self.conversationObject.newMessageStatusBool) {
        self.conversationObject.newMessageStatusBool = false;
        var indexOfConversation = self.$root.taskMasterData.conversations.map(function (e) {
          return e.id;
        }).indexOf(self.conversationId);

        if (indexOfConversation > -1) {
          //console.log('HERE');
          //console.log(self.$root.taskMasterData.conversations[indexOfConversation].newMessageStatusBool);
          self.$root.taskMasterData.conversations[indexOfConversation].newMessageStatusBool = false; //console.log(self.$root.taskMasterData.conversations[indexOfConversation].newMessageStatusBool);

          self.$app.emit('webSocketMessage', {
            type: 'data',
            data: 'conversations'
          });
        }
      }
    },
    userIsTyping: function () {
      var self = this;

      if (self.$root.mdWebSocket.ws) {
        if (self.$root.mdWebSocket.ws.readyState == self.$root.mdWebSocket.ws.OPEN) {
          if (self.typingTimeout !== null) {
            clearTimeout(self.typingTimeout);
            self.typingTimeout = null;
          } else {
            //console.log('I AM TYPING!!',self.$root.mainView.router.url);
            // Is null so send typing!
            self.$root.mdWebSocket.sendOverWebSocket({
              event: 'typing',
              page: self.$root.mainView.router.url
            });
          } // TODO: set a timeout for when they STOP typing...


          self.typingTimeout = setTimeout(function () {
            self.$root.mdWebSocket.sendOverWebSocket({
              event: 'doneTyping',
              page: self.$root.mainView.router.url
            });
            self.typingTimeout = null;
          }, 1000);
        }
      }
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.refreshConversation();
      self.markConversationAsRead(); // TODO: necessary?  I think I can use the page data with the status update...
    },
    pageAfterIn: function (e, page) {
      var self = this;

      if (self.$app.device.desktop) {
        self.messageBar.focus();
        self.messageBar.$el.find('textarea').on('keydown', function (e) {
          if (e.which == 13) {
            e.preventDefault();
            self.messageBar.$el.find('a.send-link').trigger('click');
            return false;
          }
        });
      }
    }
  },
  id: '98e555ab18',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page conversationPage" data-name="conversationPage">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">';
      r += c(ctx_1.conversationName, ctx_1);
      r += '</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<a href="#" class="link icon-only">\n						<i class="icon f7-icons if-not-md">info_circle</i>\n						<i class="icon material-icons md-only">info</i>\n					</a>\n				</div>\n			</div>\n		</div>\n		<div class="toolbar toolbar-bottom messagebar">\n			<div class="toolbar-inner">\n				<div class="messagebar-area">\n					<textarea class="resizable" placeholder="Message" @keyup="userIsTyping"></textarea>\n				</div><a class="link send-link" href="#" style="background: #fff;" @click="sendMessage">Send</a>\n			</div>\n		</div>\n		<div class="page-content messages-content">\n			<div class="messages">\n				\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.conversationMediaLists {
		margin-top: 0px;
		
	}
	
	.page.conversationPage .page-content.messages-content .messages {
		background-image: url('static/images/swirl_pattern_50.png');
		
	}
	
	.page.conversationPage .page-content.messages-content .message-image {
		background: #fff;
		
	}
	
`,
  styleScoped: false
};
    