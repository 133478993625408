
import ReconnectingWebSocket from '../lib/reconnecting-websocket/js/reconnecting-websocket-iife.js';

var TaskMaster= function(hostFullURL,wsHostFullURL) {
	var thisTMClass= this;
	thisTMClass.host= hostFullURL;
	thisTMClass.deviceId= -1;
	thisTMClass.sessionId= '';
	thisTMClass.sessionIdExpires= new Date();
	thisTMClass.wsHost= wsHostFullURL;
	thisTMClass.ws= null;
	thisTMClass.wsIsOnline= false;
	thisTMClass.timeoutVariable= null;
	
	thisTMClass.login= function(requestObject) {
		
		var dataObject= {};
		var onSuccess= function() {};
		var onError= function() {};
		if (requestObject.hasOwnProperty('data')) {
			dataObject= requestObject.data;
		}
		
		if (requestObject.hasOwnProperty('username')) {
			dataObject.username= requestObject.username;
		}
		
		if (requestObject.hasOwnProperty('password')) {
			dataObject.password= requestObject.password;
		}
		
		if (requestObject.hasOwnProperty('device')) {
			dataObject.device= requestObject.device;
		}
		
		if (requestObject.hasOwnProperty('deviceType')) {
			dataObject.deviceType= requestObject.deviceType;
		}
		
		if (requestObject.hasOwnProperty('deviceF7')) {
			dataObject.deviceF7= requestObject.deviceF7;
		}
		
		if (requestObject.hasOwnProperty('buildInfo')) {
			dataObject.buildInfo= requestObject.buildInfo;
		}
		
		if (requestObject.hasOwnProperty('deviceNavigator')) {
			dataObject.deviceNavigator= requestObject.deviceNavigator;
		}
		
		if (requestObject.hasOwnProperty('saveLogin')) {
			dataObject.saveLogin= requestObject.saveLogin;
		}
		
		if (requestObject.hasOwnProperty('onSuccess')) {
			onSuccess= requestObject.onSuccess;
		}
		
		if (requestObject.hasOwnProperty('onError')) {
			onError= requestObject.onError;
		}
		
		thisTMClass.ajaxToServer({
			method: 'POST',
            showSpinner: true,
            url: '/4DACTION/mobile_auth',
            data: dataObject,
            timeout: 8000,
            success: function(data) {
	            if (data.success) {
		            thisTMClass.deviceId= data.deviceId;
		            thisTMClass.sessionId= data.sessionid;
		            thisTMClass.sessionIdExpires= new Date(data.sessionEndDateTime);
		            onSuccess(data);
		            
		        } else {
			        if ((!data.error) || (data.error == '')) {
				        data.error= 'Login failed.'
			        }
			        onError(data);
		        }
	        },
	        error: function(data) {
		        if ((!data.error) || (data.error == '')) {
			        data.error= 'Login failed.'
		        }
		        onError(data);
	        }
		});
	};
	
	thisTMClass.logout= function() {
		thisTMClass.sessionId= '';
		
		thisTMClass.closeWebSocket();
		
		// TODO: thisTMClass.ajaxToServer logout...
	};
	
	thisTMClass.ajaxToServer= function(requestObject) {
		requestObject= ((typeof(requestObject) == 'object') ? requestObject: {});
		
		var requestURL= '';
		var timeout= 10000;
		var dataObject= {};
		var showAjaxIndicator= true; // Default
		var cache= false;
		var beforeSendFunction= function() {};
		var completeFunction= function() {};
		var successFunction= function() {};
		// TODO: timeout function?
		var errorFunction= function(data) { console.log('Error',data); };
		var requestMethod= 'GET';
		
		// TODO: how to use... thisTMClass.sessionIdExpires
		
		if (requestObject.hasOwnProperty('url')) { // REQUIRED
			requestURL= requestObject.url;
			
			if (requestObject.hasOwnProperty('data')) {
				dataObject= requestObject.data;
			}
			
			if (requestObject.hasOwnProperty('showSpinner')) {
				showAjaxIndicator= requestObject.showSpinner;
			}
			
			if (requestObject.hasOwnProperty('timeout')) {
				timeout= requestObject.timeout;
			}
			
			if (requestObject.hasOwnProperty('cache')) {
				cache= requestObject.cache;
			}
			
			if (!cache) { // Force it...
				dataObject.noCache= Math.floor(Math.random() * 999999999);
				
			}
			
			// TODO: clean up showAjaxIndicator...
			if (requestObject.hasOwnProperty('beforeSend')) {
				if (showAjaxIndicator) {
					beforeSendFunction= function(xhr) {
						window.app.f7.preloader.show();
						return (requestObject.beforeSend(xhr));
					}
				} else {
					beforeSendFunction= requestObject.beforeSend;
				}
			} else {
				if (showAjaxIndicator) {
					beforeSendFunction= function(xhr) {
						window.app.f7.preloader.show();
						return true;
					}
				}
			}
			
			// TODO: clean up showAjaxIndicator...
			if (requestObject.hasOwnProperty('complete')) {
				if (showAjaxIndicator) {
					completeFunction= function(xhr,status) {
						requestObject.complete(xhr,status);
						window.app.f7.preloader.hide();
					}
				} else {
					completeFunction= requestObject.complete;
				}
			} else {
				if (showAjaxIndicator) {
					completeFunction= function(xhr,status) {
						window.app.f7.preloader.hide();
					}
				}
			}
			
			if (requestObject.hasOwnProperty('success')) {
				successFunction= requestObject.success;
			}
			
			if (requestObject.hasOwnProperty('error')) {
				errorFunction= requestObject.error;
			}
			
			if (requestObject.hasOwnProperty('method')) {
				requestMethod= requestObject.method;
			}
			
			var serverURL= this.host;
			if (requestURL.indexOf("http") > -1) {
				serverURL= requestURL;
			} else {
				serverURL+= requestURL;
			}
			
			dataObject.sessionid = thisTMClass.sessionId; // necessary? Yes!
			
			var tempCallBack= function(data) {
				/*
				if (data.hasOwnProperty('invalidsession')) {
					thisTMClass.isOnline= false; // TODO....
					
					var originalAjaxCallForInvalidSession= {
						url: serverURL,
						method: requestMethod,
						data: requestMethod == 'GET' ? dataObject : JSON.stringify(dataObject),
						dataType: 'json',
						beforeSend: beforeSendFunction,
						complete: completeFunction,
						success: successFunction,
						error: errorFunction
					};
					
					// Attempt to autoSignIn...
					//window.app.f7.methods.autoSignIn('/',originalAjaxCallForInvalidSession); // Don't go anywhere...?
					window.app.f7.methods.autoSignIn(''); // Don't go anywhere...?
				} else {
					successFunction(data);
				}
				*/
				successFunction(data);
			};
			
			window.app.f7.request({
				url: serverURL,
				method: requestMethod,
				data: requestMethod == 'GET' ? dataObject : JSON.stringify(dataObject),
				dataType: 'json',
				beforeSend: beforeSendFunction,
				complete: completeFunction,
				success: tempCallBack,
				error: errorFunction,
				timeout: timeout,
				xhrFields: {
					withCredentials: true
				}
			});
			
		} else {
			errorFunction({
				ok: false,
				error: 'URL required for ajaxToServer Calls'
			})
		}
	}

	thisTMClass.sendOverWebSocket= function(dataObject) {
		if (thisTMClass.ws) {
			if (thisTMClass.ws.readyState == thisTMClass.ws.OPEN) {
				thisTMClass.ws.send(JSON.stringify(dataObject));
				
				return true;
			} else {
				// TODO: fallback...? Send a POST...?
				
			}
		}
		
		return false;
	};

	thisTMClass.connectWebSocket= function(paramsObject) {
		paramsObject= ((typeof(paramsObject) == 'undefined') ? {}: paramsObject);
		
		if (!paramsObject.hasOwnProperty('onMessage')) {
			paramsObject.onMessage= function() {};
		}
		if (!paramsObject.hasOwnProperty('onOpen')) {
			paramsObject.onOpen= function() {};
		}
		if (!paramsObject.hasOwnProperty('onClose')) {
			paramsObject.onClose= function() {};
		}
		if (!paramsObject.hasOwnProperty('onTimeout')) {
			paramsObject.onTimeout= function() {};
		}
		if (!paramsObject.hasOwnProperty('timeout')) {
			paramsObject.timeout= 2000;
		}
		
		if (thisTMClass.ws !== null) {
			thisTMClass.ws.close();
		}
		
		if (thisTMClass.sessionId !== '') {
			if (ReconnectingWebSocket) {
				thisTMClass.ws= new ReconnectingWebSocket(thisTMClass.wsHost,['json',thisTMClass.sessionId]);
				
			} else {
				thisTMClass.ws= new WebSocket(thisTMClass.wsHost,['json',thisTMClass.sessionId]);
				
			}
			
			thisTMClass.ws.onopen= function(e) {
				thisTMClass.wsIsOnline= true;
				
				if (thisTMClass.timeoutVariable !== null) {
					clearTimeout(thisTMClass.timeoutVariable);
					thisTMClass.timeoutVariable= null;
				}
				
				paramsObject.onOpen(e);
			};
			thisTMClass.ws.onmessage= paramsObject.onMessage;
			thisTMClass.ws.onclose= function(e) {
				
				if (thisTMClass.wsIsOnline) {
					thisTMClass.wsIsOnline= false;
					paramsObject.onClose(e);
					
				} else {
					// Never connected, run the timeout function...
					if (thisTMClass.timeoutVariable !== null) {
						clearTimeout(thisTMClass.timeoutVariable);
						thisTMClass.timeoutVariable= null;
					}
					
					paramsObject.onTimeout(e);
					
				}
			};
			
			if (thisTMClass.timeoutVariable !== null) {
				clearTimeout(thisTMClass.timeoutVariable);
				thisTMClass.timeoutVariable= null;
			}
			
			thisTMClass.timeoutVariable= setTimeout(function() {
				paramsObject.onTimeout();
			}, paramsObject.timeout);
			
			return true;
			
		} else {
			// ERROR!  Do Nothing!
			console.log('ERROR: no sessionId for WebSocket');

		}
		
		return false;
	};

	thisTMClass.closeWebSocket= function(onCloseFunction) {
		onCloseFunction= ((typeof(onCloseFunction) == 'function') ? onCloseFunction: null);
		
		if (thisTMClass.ws !== null) {
			if (onCloseFunction !== null) { // override close function...
				thisTMClass.ws.onclose= onCloseFunction;
			}

			//console.log('thisTMClass.closeWebSocket, app is in background: ',window.app.f7.data.appIsInBackground);
			//console.log('BEFORE calling... thisTMClass.ws.close()');

			thisTMClass.ws.close();

			//console.log('Called thisTMClass.ws.close()');
		}
	};
	
};

export default TaskMaster;
