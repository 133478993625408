
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {
      loading: true,
      haveDeletedARow: false
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'buckets') {
        self.loading = false;
        self.refreshPage();
      } else if (data.type == 'event' && data.event == 'batchCreated') {// Auto navigate to the newly created batch?
      }
    },
    deleteBucketSwipe: function (e) {
      var self = this;
      var thisLI = self.$(e.target).closest('.swipeout');
      var bucketId = parseInt(thisLI.attr('data-bucketId'));
      self.haveDeletedARow = true;
      self.$root.mdWebSocket.sendOverWebSocket({
        action: 'deleteBucket',
        id: bucketId
      }); // console.log('bucketId',bucketId);
    },
    refreshPage: function () {
      var self = this; // TODO: hide or show past batches...?

      if (!self.haveDeletedARow) {
        self.$update();
      } else {
        var tempTimeout = setTimeout(function () {
          self.$router.refreshPage();
        }, 250);
      }
    },
    startNewBatch: function () {
      var self = this;
      var d = new Date();
      var weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";
      var n = weekday[d.getDay()];
      var defaultName = n + ' Bucket';
      var count = 0;

      for (var i = 0; i < self.$root.buckets.length; ++i) {
        if (self.$root.buckets[i].name.indexOf(defaultName) == 0) count++;
      }

      if (count > 0) {
        defaultName = defaultName + ' ' + (count + 1);
      }

      var batchNamePrompt = self.$app.dialog.prompt('', 'New Batch Bucket', function (newName) {
        self.$root.mdWebSocket.sendOverWebSocket({
          action: 'createBucket',
          name: newName
        }); // TODO: send it over anyway? May need to for offline reasons...
        //self.$router.navigate('/batch/0/'+encodeURIComponent(newName)+'/');
      }, function () {}, defaultName);
      batchNamePrompt.$el.find('input')[0].select();
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
    },
    pageBeforeIn: function (e, page) {
      var self = this;
    }
  },
  id: '0a2ee6c2cd',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page no-swipeback pageBlank" data-name="blank">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title sliding">Batch Buckets</div>\n				<div class="right">\n					<a href="#" @click="startNewBatch" class="link icon-only">\n						<i class="icon f7-icons">plus</i>\n					</a>\n				</div>\n			</div>\n		</div>\n		<div class="page-content">\n			<div class="list no-margin-top">\n				<ul>\n					';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.taskMasterData.buckets.length == 0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<li class="item-content">\n						<div class="block">\n							';
          r += Template7Helpers.if.call(ctx_2, ctx_2.loading, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							Loading...\n							';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n							No open buckets, please create a bucket to start scanning.\n							';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						</div>\n					</li>\n					';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<li class="item-divider">Open Buckets</li>\n					';
          r += Template7Helpers.each.call(ctx_2, ctx_2.$root.taskMasterData.buckets, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<li class="swipeout" @swipeout:deleted="deleteBucketSwipe" data-bucketId="';
              r += c(ctx_3.ID, ctx_3);
              r += '">\n						<div class="swipeout-content">\n							<a href="/tmscan/buckets/';
              r += c(ctx_3.ID, ctx_3);
              r += '/';
              r += Template7Helpers.js.call(ctx_3, "encodeURIComponent(this.name)", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '/" class="item-content item-link">\n								<div class="item-inner">\n									<div class="item-title">\n										';
              r += c(ctx_3.name, ctx_3);
              r += '\n										<div class="item-footer">';
              r += Template7Helpers.js.call(ctx_3, "moment(this.dateTimeStarted).format('M/D/YY, h:mma')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' &rArr; ';
              r += Template7Helpers.js.call(ctx_3, "moment(this.dateTimeUpdated).format('M/D/YY, h:mma')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div>\n									</div>\n									<div class="item-after">';
              r += c(ctx_3._numberOfScans, ctx_3);
              r += '</div>\n								</div>\n							</a>\n						</div>\n						<div class="swipeout-actions-right">\n							<!-- Add this button and item will be deleted automatically -->\n							<a href="#" data-confirm="Are you sure you want to delete this bucket?" class="swipeout-delete">Delete</a>\n						</div>\n					</li> <!-- TODO: show the date! -->\n					';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n					<!-- LOOP BATCHES HERE -->\n					';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</ul>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `

`,
  styleScoped: false
};
    