
// import JsSIP from 'jssip'; // TODO: doesn't work with uglifyJS
//import JsSIP from '../lib/jssip/js/jssip-3.3.5.min.js';
import JsSIP from '../lib/jssip/js/jssip-3.2.16.min.js';
//import adapter from 'webrtc-adapter'; // doesn't work with uglifyJS
// import adapter from '../lib/webrtc-adapter/js/adapter-7.1.1.js';
//import * as SIP from 'sip.js'

//var JsSIP= {};
//JsSIP.debug.enable('JsSIP:*');
JsSIP.debug.disable('JsSIP:*');

var useJsSIP= true;

var f7Phone= function(host) {
	var thisPhone= this;
	
	thisPhone.host= host;
	thisPhone.wsURL= 'wss://'+thisPhone.host+':5040/ws';
	thisPhone.controller= null;
	thisPhone.socket= null;

	thisPhone.onUnRegisterEvent= null;
	
	thisPhone.session= null;
	thisPhone.sessionPeerName= '';
	thisPhone.sessionPeerNumber= '';
	thisPhone.sessionIsMuted= false;
	thisPhone.sessionOnHold= false;
	thisPhone.sessionIsIncoming= false;
	thisPhone.sessionStartTime= null;
	thisPhone.sessionDTMF= null;
	thisPhone.sessionIsSpeakerphone= false;
	
	thisPhone.remoteAudio= null;
	thisPhone.beepSound= null;
	thisPhone.ringingSound= null;
	
	thisPhone.outgoingProgressTimeout= null;
	thisPhone.lastEvent= '';
	
	thisPhone.inputSinkId= 'default';
	thisPhone.outputSinkId= 'default';
	thisPhone.audioDevices= [];
	thisPhone.audioInputDevices= [];
	thisPhone.audioOutputDevices= [];
	
	thisPhone.setup= function(onSuccess,onFailed) {
		var tempOnSuccess= ((typeof(onSuccess) == 'function')?onSuccess:function() {});
		onFailed= ((typeof(onFailed) == 'function')?onFailed:function() {});
		
		onSuccess= function() {
			
			thisPhone.audioDevices= [];
			thisPhone.audioOutputDevices= [];
			/*
			navigator.mediaDevices.enumerateDevices().then(function(devices) {
				thisPhone.audioDevices= devices;
				
				thisPhone.audioOutputDevices= thisPhone.audioDevices.filter(function(device) {
					return (device.kind === 'audiooutput');
				});
				
				thisPhone.audioInputDevices= thisPhone.audioDevices.filter(function(device) {
					return (device.kind === 'audioinput');
				});
				
				//thisPhone.audioOutputDevicesIds= thisPhone.audioOutputDevices.map(function(device) {
				//	return (device.deviceId);
				//});
				
				tempOnSuccess();
				
			});
			*/
			tempOnSuccess();
		}
		
		var loadAdapterJs= false;
		
		var thereIsAWait= false;
		if (app.f7.device.cordova) {
			if (app.f7.device.ios) {
				cordova.plugins.iosrtc.registerGlobals();
				
				thereIsAWait= true;
				var tempTimeoutHere= setTimeout(function() {
					var script= document.createElement("script");
				    script.type= "text/javascript";
				    //script.src= "static/lib/webrtc-adapter/js/adapter-7.1.1.js";
				    script.src= "static/lib/webrtc-adapter/js/adapter.js";
				    script.async= false;
				    document.getElementsByTagName("head")[0].appendChild(script);
				}, 0);
				
			}
			
			var tempTimeoutHere= setTimeout(function() {
				var waitForSuccess= false;
				
				// Android only here...
				// TODO: ask this on first CALL and OR on the first RECEIVED call?
				// Maybe do not allow received calls if this is not given permission
				if ((app.f7.device.android) && (window.plugins) && (window.plugins.k) && (window.plugins.k.webrtc)) {
					waitForSuccess= true;
					
					window.plugins.k.webrtc.permission.request(function(data) {
						console.log('success we have permission for phone!'); console.log(data);
						onSuccess();
						
					},function(data) {
						console.log('fail we do not have permission for phone...'); console.log(data);
						onFailed();
						
						// TODO...
						//app.data.user.settings.hasPhone= false;
						
					});
					
				}
				
				if (!waitForSuccess) {
					onSuccess();
				}
			}, ((thereIsAWait)?1000:0));
			
			
		} else {
			onSuccess();
			
		}
		
	};
	
	thisPhone.register= function(username,password,extension,onFinished) {
		thisPhone.remoteAudio= document.createElement('audio');
		if (thisPhone.remoteAudio.setSinkId) {
			//thisPhone.remoteAudio.setSinkId(thisPhone.outputSinkId);
		}
		
		//if (app.f7.data.user.settings.hasPhone) {
			if (useJsSIP) {
				
				if (!thisPhone.socket) {
					thisPhone.socket= new JsSIP.WebSocketInterface(thisPhone.wsURL);
				}

				if (!thisPhone.controller) {
					var configuration= {
						sockets  : [ thisPhone.socket ],
						uri      : 'sip:'+username+'@'+thisPhone.host,
						password : password,
						//register_expires: 30, // 30 seconds...
						contact_uri: 'sip:'+extension+'@'+thisPhone.host+';transport=ws',
						session_timers: false,
						use_preloaded_route: true
					};
					
					thisPhone.controller= new JsSIP.UA(configuration);
				}

				thisPhone.controller.start();
				
				thisPhone.controller.on('disconnected',function(data) {

					console.log('Phone Controller DISCONNECTING!');

					app.f7.data.phoneInCall= false;
					thisPhone.controller= null;
					thisPhone.session= null;

					console.log('Phone Controller NULL!');
					if (thisPhone.onUnRegisterEvent) {
						var tempFunction= thisPhone.onUnRegisterEvent; // Copy the function?
						tempFunction();
						thisPhone.onUnRegisterEvent= null;
					}
				});

				// EVENTS...
				thisPhone.controller.on('newRTCSession',function(data) {
					var session= data.session;

					thisPhone.session= session;
					app.f7.data.phoneInCall= true;
					
					if (session.direction === 'incoming') {
						console.log('PHONE - incoming call');
						
						console.log('session',session);
						
						session.on('accepted',function(e) {
							console.log('PHONE - accepted');
							// the call has answered
							
							thisPhone.updateGUI('accepted');
							
							console.log('HERE','e',e);
							
						});
						
						session.on('confirmed',function(e) {
							console.log('PHONE - confirmed');
							
							thisPhone.updateGUI('confirmed');
							
							var localStream= session.connection.getLocalStreams()[0];
							thisPhone.sessionDTMF= session.connection.createDTMFSender(localStream.getAudioTracks()[0]);
							// this handler will be called for incoming calls too
							
							// console.log('e',e);
							
						});
						
						session.on('failed',function(e) {
							console.log('PHONE - failed');
							
							thisPhone.hangup();
							
						});
						
						session.on('peerconnection', function() {
							console.log('PHONE - peerconnection');
							
							thisPhone.updateGUI('peerconnection');
							
							if (session.connection) {
								session.connection.addEventListener('addstream', function(e) {
									thisPhone.remoteAudio.srcObject= e.stream;
									thisPhone.remoteAudio.play();
									
									thisPhone.setAudioRoute();
									
								});
							}
						});
						
						
						var answerAfterHeader= data.request.headers['Answer-After'];
				
						//console.log('answerAfterHeader');
						console.log('answerAfterHeader',answerAfterHeader);
						
						if ((answerAfterHeader) && (answerAfterHeader.length > 0) && (answerAfterHeader[0].raw == '0')) {
							
							console.log('AUTO answering.');
							
							thisPhone.openInCallPopup();
							
							var tempTimeout=setTimeout(function() {
								thisPhone.answer();
							}, 0);
							
						} else {
							// thisPhone.alertIncomingCall();
							
						}
						
						// TODO: check if "auto-answer" is on and then auto answer....
						
						
					} else {
						console.log('PHONE - outgoing call');
						// Outgoing....
						
						if (session.connection) {
							session.connection.addEventListener('addstream', function(e) {
								thisPhone.remoteAudio.srcObject= e.stream;
								thisPhone.remoteAudio.play();
								
								thisPhone.setAudioRoute();
								
							});
						}
						
						session.on('progress',function() {
							console.log('PHONE - progress');
							
							if (thisPhone.outgoingProgressTimeout !== null) {
								clearTimeout(thisPhone.outgoingProgressTimeout);
								thisPhone.outgoingProgressTimeout= null;
							}
							
							thisPhone.updateGUI('progress');
							
							thisPhone.stopAllSounds();
							
							thisPhone.ringingSound= new Audio();
							thisPhone.ringingSound.loop= true;
							thisPhone.ringingSound.src= 'static/media/phone-ringing.wav';
							if (thisPhone.ringingSound.setSinkId) {
								//thisPhone.ringingSound.setSinkId(thisPhone.outputSinkId);
							}
							thisPhone.ringingSound.play();
							
							thisPhone.setAudioRoute();
						
						});
						
						session.on('confirmed',function() {
							console.log('PHONE - confirmed');
							
							if (thisPhone.outgoingProgressTimeout !== null) {
								clearTimeout(thisPhone.outgoingProgressTimeout);
								thisPhone.outgoingProgressTimeout= null;
							}
							
							thisPhone.updateGUI('confirmed');
							
							thisPhone.stopAllSounds();
							
							//the call has connected, and audio is playing
							var localStream= session.connection.getLocalStreams()[0];
							thisPhone.sessionDTMF= session.connection.createDTMFSender(localStream.getAudioTracks()[0]);
							
						});
						
						session.on('failed',function(data) {
							console.log('PHONE - failed');
							
							thisPhone.hangup();
						});
						
					}
					
					session.on('ended',function() {
						console.log('PHONE - ended');
						
						app.f7.popup.close('.popupInCall');
						
						thisPhone.hangup();
					});
					
				});
				
			} else {
				/*
				thisPhone.controller= new SIP.UA({
					uri: 'sip:'+username+'@'+thisPhone.host,
					transportOptions: {
						wsServers: [thisPhone.wsURL]
					},
					authorizationUser: username,
					password: password
				});
				*/
			}
			
			onFinished();
		//}
	
	};

	thisPhone.unRegister= function() {
		thisPhone.hangup();
		thisPhone.controller.stop();
	};

	thisPhone.disconnect= function() {
		thisPhone.onUnRegisterEvent= function() {
			console.log('Phone SOCKET DISCONNECTING!');
		
			thisPhone.socket.disconnect();
	
			thisPhone.socket= null;
	
			console.log('Phone SOCKET NULL!');
		};

		thisPhone.unRegister();
	};
	
	thisPhone.call= function(numberToDial,nameOfPerson) {
		nameOfPerson= (typeof(nameOfPerson) == 'string') ? nameOfPerson: '';
		thisPhone.sessionPeerNumber= numberToDial;
		thisPhone.sessionPeerName= nameOfPerson;
		
		thisPhone.outgoingProgressTimeout= setTimeout(function() {
			thisPhone.hangup();
			// TODO: alert fail error...
			app.f7.toast.create({
				icon: app.f7.theme === 'ios' ? '<i class="icon f7-icons">exclamationmark_triangle</i>' : '<i class="icon material-icons">error</i>',
				text: 'Call Failed',
				position: 'center',
				closeTimeout: 4000,
				destroyOnClose: true
			}).open();
			
			// TODO: put the number back into the dial pad if necessary...?
			
		}, 5000); // 5 second timeout connecting...
		
		thisPhone.openInCallPopup();
		
		if (true) {
			thisPhone.beepSound= new Audio();
			thisPhone.beepSound.loop= true;
			thisPhone.beepSound.src= 'static/media/phone-beep.wav';
			if (thisPhone.beepSound.setSinkId) {
				//thisPhone.beepSound.setSinkId(thisPhone.outputSinkId);
			}
			thisPhone.beepSound.play();
		}
		
		thisPhone.setAudioRoute();
		
		app.f7.data.PHONE.controller.call('sip:'+numberToDial+'@'+thisPhone.host,{
			mediaConstraints: {
				audio: true, // only audio calls
				video: false
			}/*, // TODO: this can only work when on wifi, this takes to long to find candidates on LTE...
			'pcConfig': {
				'iceServers': [
				  //{ 'urls': ['stun:null'] }
				  { 'urls': ['stun:stun.l.google.com:19302'] }
				  //{ 'urls': ['stun:phone.bedroomsandmore.com:3478'] }
				  //{ 'urls': ['stun:phone.bedroomsandmore.com:3478','stun:stun.l.google.com:19302'] }
				],
				'rtcpMuxPolicy': 'require'
			}*/
		});
	};
	
	thisPhone.answer= function() {
		
		thisPhone.openInCallPopup();
		
		app.f7.data.PHONE.session.answer({
			'mediaConstraints' : { 'audio': true, 'video': false },
			'pcConfig': {
				'iceServers': [
				  //{ 'urls': ['stun:null'] }
				  { 'urls': ['stun:stun.l.google.com:19302'] }
				  //{ 'urls': ['stun:phone.bedroomsandmore.com:3478'] }
				  //{ 'urls': ['stun:phone.bedroomsandmore.com:3478','stun:stun.l.google.com:19302'] }
				],
				'rtcpMuxPolicy': 'require'
			}
		});
		
	};
	
	thisPhone.transfer= function(numberToTransferTo,nameToTransferTo) {
		nameToTransferTo= (typeof(nameToTransferTo) == 'string') ? nameToTransferTo: '';
		
		if (nameToTransferTo == '') {
			nameToTransferTo= numberToTransferTo;
		}
		
		// TODO: this doesn't work...
		thisPhone.session.refer('sip:'+numberToTransferTo+'@phone.bedroomsandmore.com');
		
		// TODO: if successful...
		thisPhone.hangup();
		
		var tempTimeout= setTimeout(function() {
			app.f7.toast.create({
				icon: app.f7.theme === 'ios' ? '<i class="f7-icons">arrow_turn_up_right</i>' : '<i class="material-icons">phone_forwarded</i>',
				text: 'Call transferred to '+nameToTransferTo,
				position: 'center',
				closeTimeout: 4000,
				destroyOnClose: true
			}).open();
		}, 0);
	};
	
	thisPhone.hangup= function() {
		console.log('PHONE - hangup');
		
		if (window.navigator.proximity) {
			console.log('DISABLED PROXIMITY SENSOR');
			window.navigator.proximity.disableProximityScreenOff();
			navigator.proximity.disableSensor();
		}

		thisPhone.resetLocalVariables();
		
		if (thisPhone.outgoingProgressTimeout !== null) {
			clearTimeout(thisPhone.outgoingProgressTimeout);
			thisPhone.outgoingProgressTimeout= null;
		}
		
		thisPhone.stopAllSounds();
		
		app.f7.popup.close('.popupInCall');
		
		var tempTimeout= setTimeout(function() {
			// Just so it fails gracefully...
			if (thisPhone.session) {
				thisPhone.session.terminate();
			}
		}, 0);
		
		var tempTimeout= setTimeout(function() {
			thisPhone.session= null;
			app.f7.data.phoneInCall= false;

			thisPhone.updateGUI('ended');
		}, 150);
	};
	
	thisPhone.resetLocalVariables= function() {
		thisPhone.sessionPeerNumber= '';
		thisPhone.sessionPeerName= '';
		
		thisPhone.sessionIsMuted= false;
		thisPhone.sessionOnHold= false;
		thisPhone.sessionIsIncoming= false;
		thisPhone.sessionStartTime= null;
		thisPhone.sessionDTMF= null;
		thisPhone.sessionIsSpeakerphone= false;
		
	};
	
	thisPhone.setAudioRoute= function() {
		if (app.f7.device.cordova) {
			if (thisPhone.sessionIsSpeakerphone) {
				if (false) {//(app.f7.device.ios) {
					cordova.plugins.audioroute.overrideOutput('builtin-speaker',function() {
						console.log('SUCCESS changed to speaker');
					},function() {
						console.log('FAILED changed to speaker');
					});
				} else {
					if (window.AudioToggle) {
						AudioToggle.setAudioMode(AudioToggle.SPEAKER);
					} else {
						// error?
					}
				}
			} else {
				if (false) {//(app.f7.device.ios) {
					cordova.plugins.audioroute.overrideOutput('builtin-receiver',function() {
						console.log('SUCCESS changed to earpiece');
					},function() {
						console.log('FAILED changed to earpiece');
					});
				} else {
					if (window.AudioToggle) {
						AudioToggle.setAudioMode(AudioToggle.EARPIECE);
					} else {
						// error?
					}
				}
			}
		}	
	};
	
	thisPhone.mute= function() {
		var tempTimeout= setTimeout(function() {
			thisPhone.session.mute({audio: true});
			
			thisPhone.sessionIsMuted= true;
			
			thisPhone.updateGUI();
		}, 0);

	};
	
	thisPhone.unMute= function() {
		var tempTimeout= setTimeout(function() {
			thisPhone.session.unmute({audio: true});
			
			thisPhone.sessionIsMuted= false;
			
			thisPhone.updateGUI();
		}, 0);

	};
	
	thisPhone.hold= function() {
		var tempTimeout= setTimeout(function() {
			thisPhone.session.hold();
			
			thisPhone.sessionOnHold= true;
			
			thisPhone.updateGUI();
		}, 0);
		
	};
	
	thisPhone.unHold= function() {
		var tempTimeout= setTimeout(function() {
			thisPhone.session.unhold();
			
			thisPhone.sessionOnHold= false;
			
			thisPhone.updateGUI();
		}, 0);
		
	};
	
	thisPhone.speakerPhoneOn= function() {
		
		thisPhone.sessionIsSpeakerphone= true;
		
		thisPhone.setAudioRoute();
		
		thisPhone.updateGUI();
		
	};
	
	thisPhone.speakerPhoneOff= function() {

		thisPhone.sessionIsSpeakerphone= false;
		
		thisPhone.setAudioRoute();
		
		thisPhone.updateGUI();
	};
	
	thisPhone.openInCallPopup= function() {
		if (app.f7.data.mainView.router.url !== '/phoneInCall/') {
			console.log('Opening In Call Popup!');
			var tempTimeout= setTimeout(function() {
				app.f7.data.mainView.router.navigate('/phoneInCall/',{animate: false});
			}, 0);
		} else {
			// Refresh the popup data...
			// thisPhone.updateGUI();
		}
	};
	
	thisPhone.updateGUI= function(currentEvent) {
		currentEvent= ((typeof(currentEvent) == 'undefined') ? thisPhone.lastEvent: currentEvent);
		
		window.app.f7.methods.emitEventToApp('phoneVariablesChanged', {
			event: currentEvent
		});
		
	};
	
	thisPhone.stopAllSounds= function() {
		//var tempTimeout= setTimeout(function() {
			if (thisPhone.beepSound !== null) {
				//if (!thisPhone.beepSound.paused) {
					thisPhone.beepSound.pause();
					thisPhone.beepSound= null;
				//}
			}
			
			if (thisPhone.ringingSound !== null) {
				//if (!thisPhone.ringingSound.paused) {
					thisPhone.ringingSound.pause();
					thisPhone.ringingSound= null;
				//}
			}	
		//},0);
	};
};

export default f7Phone;
