
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {
      loading: true
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'customerTextsList') {
        self.$setState({
          loading: false
        });
      }
    },
    createNewConversation: function () {
      var self = this;
      self.askPrompt('Please enter the 10 digit number of the person you are texting.', function (value) {
        self.$router.navigate('/customerTexts/' + value + '/%20/');
      });
    },
    askPrompt: function (message, onSuccess) {
      var self = this;
      var askForNumberPrompt = self.$app.dialog.prompt(message, '10 Digit Number', function (value) {
        value = value.replace(/\D/g, '');

        if (value.length == 10) {
          onSuccess(value);
        } else {
          var tempTimeout = setTimeout(function () {
            self.askPrompt('MUST be exactly 10 digits.', onSuccess);
          }, 0);
        }
      });
      askForNumberPrompt.$el.find('input').attr('type', 'number');
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
    }
  },
  id: '7b8189f79b',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="blankPage">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">Customer Texts</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<a href="#" class="link icon-only" @click="createNewConversation">\n						<i class="icon f7-icons if-not-md">square_pencil</i>\n						<i class="icon material-icons md-only">create</i>\n					</a>\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text">Customer Texts</div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="page-content">\n			';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="block block-strong">\n				<p>Loading...</p>\n			</div>\n			';
          r += Template7Helpers.if.call(ctx_2, ctx_2.externalNumberCallerId, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.externalNumberCallerId, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.externalNumberCallerId, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="list media-list no-margin-top">\n				<ul>\n					';
          r += Template7Helpers.each.call(ctx_2, ctx_2.$root.taskMasterData.customerTextsList, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<li>\n						<a href="/customerTexts/';
              r += c(ctx_3.externalNumber, ctx_3);
              r += '/';
              r += Template7Helpers.if.call(ctx_3, ctx_3.externalNumberCallerId, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.js.call(ctx_4, "encodeURIComponent(this.externalNumberCallerId)", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += c(ctx_4.externalNumber, ctx_4);
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '/" class="item-link item-content">\n							<div class="item-inner">\n								<div class="item-title-row">\n									<div class="item-title">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.externalNumberCallerId, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += c(ctx_4.externalNumberCallerId, ctx_4);
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += c(ctx_4.externalNumber, ctx_4);
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div>\n									<div class="item-after">';
              r += c(ctx_3.relativeDateTime, ctx_3);
              r += '</div>\n								</div>\n								';
              r += Template7Helpers.if.call(ctx_3, ctx_3.externalNumberCallerId, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="item-subtitle">';
                  r += c(ctx_4.externalNumber, ctx_4);
                  r += '</div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n								<div class="item-text">Last Message: ';
              r += c(ctx_3.lastMessageSummary, ctx_3);
              r += '</div>\n							</div>\n						</a>\n					</li>\n					';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '		\n				</ul>\n			</div>\n			';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	
`,
  styleScoped: false
};
    