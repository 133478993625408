
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.invoiceNumber = self.$route.params.invoiceNumber;
  },
  data: function () {
    return {
      invoiceNumber: '',
      invoiceHistory: [],
      showOpenedOnly: true,
      showOpenedOnlyToggle: null
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'invoiceHistory') {
        self.invoiceHistory = data.invoiceHistory;
        self.tagShowHideRows();
        self.$setState();
      }
    },
    checkedShowOpenedOnly: function () {
      var self = this;
      self.showOpenedOnly = self.showOpenedOnlyToggle.checked;
      self.tagShowHideRows();
      self.$setState();
    },
    tagShowHideRows: function () {
      var self = this;

      for (var i = 0; i < self.invoiceHistory.length; i++) {
        var showRow = true;

        if (self.invoiceHistory[i].whatChanges == 'OPENED INVOICE' && !self.showOpenedOnly) {
          showRow = false;
        }

        self.invoiceHistory[i].showRow = showRow;
      }
    }
  },
  on: {
    popupOpen: function (e, popup) {
      var self = this;
      self.showOpenedOnlyToggle = self.$app.toggle.create({
        el: '.showOpenedOnly',
        on: {
          change: self.checkedShowOpenedOnly
        }
      });
    }
  },
  id: 'a0a46c031f',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="popup popupInvoiceHistory">\n		<div class="view">\n			<div class="page" data-name="pageInvoiceHistory">\n				<div class="navbar">\n					<div class="navbar-bg"></div>\n					<div class="navbar-inner">\n						<div class="left">\n							<a href="#" class="link icon-only popup-close md-only" data-popup=".popupInvoiceHistory"><i class="icon icon-back"></i></a>\n							<a href="#" class="link popup-close if-not-md" data-popup=".popupInvoiceHistory">Close</a>\n						</div>\n						<div class="title sliding">';
      r += c(ctx_1.invoiceNumber, ctx_1);
      r += ' History</div>\n						<div class="right">\n							';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							<a href="/bugreport/" class="link icon-only">\n								<i class="icon material-icons">bug_report</i>\n							</a>\n							';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n							<label class="toggle color-green showOpenedOnly">\n								<input type="checkbox" checked>\n								<span class="toggle-icon"></span>\n							</label>\n						</div>\n					</div>\n				</div>\n				<div class="page-content">\n					';
      r += Template7Helpers.js_if.call(ctx_1, "this.invoiceHistory.length > 0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<div class="list media-list no-margin-top">\n						<ul><!-- TODO!! -->\n							';
          r += Template7Helpers.each.call(ctx_2, ctx_2.invoiceHistory, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							';
              r += Template7Helpers.if.call(ctx_3, ctx_3.showRow, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n							<li>\n								<a href="#" class="item-link item-content">\n									<!--<div class="item-media">\n										';
                  r += c(data_4 && data_4.index, ctx_4);
                  r += '\n									</div>-->\n									<div class="item-inner">\n										<div class="item-title-row">\n											<div class="item-title">';
                  r += c(ctx_4.who, ctx_4);
                  r += '</div>\n											<div class="item-after" style="color: #333;">';
                  r += Template7Helpers.js.call(ctx_4, "moment(new Date(this.dateTimeCreated)).format('M/DD/YY, LT')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</div>\n										</div>\n										<div class="item-subtitle">';
                  r += c(ctx_4.eventName, ctx_4);
                  r += '</div>\n										<div class="item-text">';
                  r += c(ctx_4.whatChanges, ctx_4);
                  r += '</div>\n									</div>\n								</a>\n							</li>\n							';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						</ul>\n					</div>\n					';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<div class="block block-strong" style="text-align: center;">\n						<p>Loading <div class="preloader"></div></p>\n					</div>\n					';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.md .toggle.showOpenedOnly {
		padding-right: 3em;
		
	}
`,
  styleScoped: false
};
    