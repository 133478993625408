
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {
      pageWithProblems: ''
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;
    },
    sendFeedback: function () {
      var self = this;
      var message = self.$el.find('.feedbackTextArea').val();

      if (message !== '') {
        self.$root.mdWebSocket.sendOverWebSocket({
          action: 'sendFeedback',
          feedback: message,
          page: self.pageWithProblems
        });
        var feedbackreceivedNotification = self.$app.notification.create({
          icon: '<i class="icon f7-icons">info_circle_fill</i>',
          title: "Feedback Received",
          titleRightText: 'now',
          text: 'Thank you for the message!  I will review this and will work on getting it fixed.  - Matt',
          closeOnClick: true,
          closeTimeout: 5000,
          on: {
            close: function () {// TODO: destroy this?
            }
          }
        }).open();
        self.$app.popup.close('.popupBugReport');
      }
    }
  },
  on: {
    popupOpen: function (e, popup) {
      var self = this;
      var historyLength = self.$root.mainView.router.history.length;
      self.pageWithProblems = self.$root.mainView.router.history[historyLength - 2];
      self.$el.find('.pageNameVisited').html('Page: ' + self.pageWithProblems);
    },
    popupOpened: function () {
      var self = this;
      self.$el.find('.feedbackTextArea').focus();
    }
  },
  id: '5b93651a4d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="popup popup-tablet-fullscreen popupBugReport">\n		<div class="view">\n			<div class="page" data-name="bugreport">\n				<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n					<div class="navbar-bg"></div>\n					<div class="navbar-inner sliding">\n						<div class="left">\n							<a href="#" class="link popup-close if-not-md" data-popup=".popupBugReport">Close</a>\n							<a href="#" class="link icon-only popup-close md-only" data-popup=".popupBugReport"><i class="icon icon-back"></i></a>\n						</div>\n						<!-- TODO: Alerts/New Memos Here maybe? -->\n						<div class="title">Feedback/Bugs</div>\n						<div class="right md-only">\n							<a href="#" class="link" @click="sendFeedback">Send</a>\n						</div>\n					</div>\n				</div>\n				<div class="page-content">\n					<div class="block">\n						<p>Every suggestion or bug report will be reviewed by Matt.<br><br>Thank you for helping build this!</p>\n					</div>\n					<div class="list inline-labels no-hairlines-md">\n					  <ul>\n						<li class="item-content">\n							<div class="item-inner">\n								<div class="item-title pageNameVisited">Page: </div>\n							</div>\n				        </li>\n						<li class="item-content item-input">\n				          <div class="item-inner">\n				            <div class="item-input-wrap">\n				              <textarea class="feedbackTextArea" placeholder="Enter your feedback here."></textarea>\n				            </div>\n				          </div>\n				        </li>\n				        <li class="if-not-md"><a href="#" class="list-button item-link" @click="sendFeedback">Send</a></li>\n				      </ul>\n				    </div>\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
`,
  styleScoped: false
};
    