
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {};
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;
      var emailiFrame = self.$el.find('.emailiFrame');

      if (data.type == 'emailSSOHash' && emailiFrame.attr('src') == null) {
        //var urlToLoad= 'https://taskmaster.bedroomsandmore.com/mail/?/MobileVersion/sso&hash='+data.hash+'&Random='+Math.random()
        var urlToLoad = 'https://taskmaster.bedroomsandmore.com/mail/?sso&hash=' + data.hash + '&Random=' + Math.random(); //self.$el.find('.loadingMessage').text(urlToLoad);

        self.$el.find('.loadingMessage').remove();
        emailiFrame.attr('src', urlToLoad);
      } // window.open(urlToLoad,'_system');

    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
    }
  },
  id: '82aadaa09f',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="email">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">E-Mail</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n			</div>\n		</div>\n		<div class="page-content" style="-webkit-user-select: unset;">\n			<p class="loadingMessage">Loading WebMail...</p>\n			<iframe class="emailiFrame"></iframe>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.loadingMessage {
		position: absolute;
		top: 12vh;
		left: 0px;
		right: 0px;
		z-index: 10;
		text-align: center;
	}
	
	.emailiFrame {
		border: 0px;
		height: 99%;
		width: 100%;
	}
`,
  styleScoped: false
};
    