export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {};
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
    }
  },
  id: '64029df9f8',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page no-swipeback pageTransfers" data-name="transfers">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title sliding">Transfers</div>\n			</div>\n		</div>\n		<div class="page-content">\n			<div class="block">\n				<h1>Transfers Page!</h1>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `

`,
  styleScoped: false
};