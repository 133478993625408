
var appVersion= '1.8.9';

import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';
import Framework7Keypad from 'framework7-plugin-keypad/dist/framework7-keypad.min.js';

import 'framework7/css/framework7.bundle.css';
import 'framework7-plugin-keypad/dist/framework7-keypad.min.css';

import 'framework7-icons';

import '../css/fontDIN.css';
import '../css/icons.css';
import '../lib/piklor/css/piklor.css';

import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';
// import 'line-awesome';

require('@uppy/core/dist/style.css');
require('@uppy/dashboard/dist/style.css');
//require('@uppy/webcam/dist/style.css');

import '../css/app.css';

import f7Phone from './phone.js';

import cordovaApp from './cordova-app.js';
import TaskMaster from './taskmaster.js';

window.Uppy= require('@uppy/core');
window.UppyFileInput= require('@uppy/file-input');
window.UppyDashboard= require('@uppy/dashboard');
window.UppyWebcam= require('@uppy/webcam');
window.UppyURL= require('@uppy/url');
window.UppyXHRUpload = require('@uppy/xhr-upload');

window.isMainWindow= true;

if (Framework7.device.electron) {
	if (window.remote) {
		window.isMainWindow= (window.remote.getCurrentWindow().id == window.remote.getGlobal('mainWindow').id)
	}
}

window.justDoAppOnDesktop= true; // TODO: this could be a setting but this is for Taylor's App

console.log('**** isMainWindow',window.isMainWindow);

var MobileDetect= require('mobile-detect');

/*
import { Swappable,Plugins,Droppable,Draggable } from '@shopify/draggable';

window.Swappable= Swappable;
window.Plugins= Plugins;
window.Droppable= Droppable;
window.Draggable= Draggable;
*/

// Added iOS 10 as well...?
var mobileDetect= new MobileDetect(window.navigator.userAgent);
var isIOS9= ((mobileDetect.is('iOS')) && (mobileDetect.version('iOS')<10));

import {polyfill} from "mobile-drag-drop";
// optional import of scroll behaviour
import {scrollBehaviourDragImageTranslateOverride} from "mobile-drag-drop/scroll-behaviour";

polyfill({// options are optional ;)
	// use this to make use of the scroll behaviour
	dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
	dragStartConditionOverride: function(e) {
		var draggableAttrValue= $$(e.target).closest('[draggable]').attr('draggable');
		//console.log('got here dragStart!',draggableAttrValue,e);
		return ((draggableAttrValue) && (draggableAttrValue == 'true'));
	}
});

window.getPWADisplayMode= function() {
	var isStandalone= window.matchMedia('(display-mode: standalone)').matches;
	if (!isStandalone) {
		isStandalone= window.navigator.userAgent.toLowerCase().indexOf('nativefier') > -1;
	}
	if (!isStandalone) {
		isStandalone= window.navigator.userAgent.toLowerCase().indexOf('pake') > -1;
	}
	if (!isStandalone) {
		isStandalone= window.navigator.userAgent.toLowerCase().indexOf('electron') > -1;
	}
	if (document.referrer.startsWith('android-app://')) {
		return 'twa';
	} else if (navigator.standalone || isStandalone) {
		return 'standalone';
	}
	return 'browser';
};

window.addEventListener( 'dragenter', function(e) {
	e.preventDefault();
});

var supportsPassive = false;
try {
	var opts = Object.defineProperty({}, 'passive', {
		get: function() {
			supportsPassive = true;
		}
	});
	window.addEventListener("testPassive", null, opts);
	window.removeEventListener("testPassive", null, opts);
} catch (e) {}

if(supportsPassive) {
	window.addEventListener('touchmove', function () { }, {passive: false});
}
else {
	window.addEventListener( 'touchmove', function() { });
}

//window.moment= require('moment');
window.moment= require('moment-timezone');
moment.tz.setDefault("America/Los_Angeles");

window.Tabulator = require("tabulator-tables");
import "tabulator-tables/dist/css/tabulator.min.css";

window.Chart = require('chart.js');
//window.Chart= Chart;


//import moment from 'moment';
//window.moment= moment;

window.simpleDebounce = function(fn, delay) {
  var timer = null;
  return function () {
    var context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

String.prototype.capitalize= function() {
  return this.charAt(0).toUpperCase()+this.slice(1);
}

Date.prototype.getFirstDayOfWeek= function() {
	var date= this;
	
	return (new Date(date.setDate((date.getDate() - date.getDay()))));
};

Date.prototype.getLastDayOfWeek= function() {
	var date= this;
		
	return (new Date(date.setDate(date.getFirstDayOfWeek().getDate()+6)));
};

Date.prototype.toDatetimeLocal =
  function toDatetimeLocal() {
    var
      date = this,
      ten = function (i) {
        return (i < 10 ? '0' : '') + i;
      },
      YYYY = date.getFullYear(),
      MM = ten(date.getMonth() + 1),
      DD = ten(date.getDate()),
      HH = ten(date.getHours()),
      II = ten(date.getMinutes()),
      SS = ten(date.getSeconds())
    ;
    return YYYY + '-' + MM + '-' + DD + 'T' +
             HH + ':' + II + ':' + SS;
  };

Date.prototype.fromDatetimeLocal = (function (BST) {
  // BST should not be present as UTC time
  return new Date(BST).toISOString().slice(0, 16) === BST ?
    // if it is, it needs to be removed
    function () {
      return new Date(
        this.getTime() +
        (this.getTimezoneOffset() * 60000)
      ).toISOString();
    } :
    // otherwise can just be equivalent of toISOString
    Date.prototype.toISOString;
}('2006-06-06T06:06'));

Number.prototype.roundMoney= function() {
	var n= this;
	
	var f = Math.pow(10, 2);
	
	return Math.round(n * f) / f;
};

Number.prototype.formatMoney= function(c, d, t) {
	var n = this, 
	c = isNaN(c = Math.abs(c)) ? 2 : c, 
	d = d == undefined ? "." : d, 
	t = t == undefined ? "," : t, 
	s = n < 0 ? "-$" : "$", 
	i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))), 
	j = (j = i.length) > 3 ? j % 3 : 0;
	return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

import routes from './routes.js';
import methods from './methods.js';

Framework7.use(Framework7Keypad);

const uA= navigator.userAgent;
const vendor= navigator.vendor;

$$(document).on('page:beforein page:reinit page:afterin popup:open popup:opened', function(e) {
	var pageElement= null;
	pageElement= $$(e.target);
	
	/*
	if (e.type == 'page:beforein') {
		pageElement= $$(e.target);

	} else if (e.type == 'popup:open') {
		console.log('EVENT',e);
		//pageElement= $$(e.target);
		
	}
	*/

	app.methods.fixDraggableElements(pageElement);
	
});

var app= new Framework7({
	root: '#app', // App root element
	id: 'com.bedroomsandmore.portal', // App bundle ID
	version: appVersion,
	name: 'MyBnM', // App name
	theme: ((Framework7.device.desktop)?'aurora':'auto'), // Automatic theme detection
	iosTranslucentBars: false,
	touch: {
		fastClicks: true // For older devices... // TODO: not included anymore!  Find out about iOS 9...
		
	},
	calendar: {
		firstDay: 0
		
	},
	data: function () {
		return {
			isIOS9: isIOS9,
			isMainWindow: window.isMainWindow,
			userLoggedOut: false,
			showBugReportIcon: false,
			turnOffLargeTitles: false,
			isDesktopSafari: (/Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA)),
			monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August' , 'September' , 'October', 'November', 'December'],
			monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug' , 'Sep' , 'Oct', 'Nov', 'Dec'],
			dayNames: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
			dayNamesShort: ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'],
			disconnectedToastTimeout: null,
			disconnectedToast: null,
			hasKeyboardScanner: false,
			previousBarcodeEntryInputValue: '',
			buckets: [],
			hasCamera: true, // TODO!
			scanBeep: new Audio('static/media/sound_beep.wav'),
			errorBeep: new Audio('static/media/error_beep.wav'),
			noCacheStrings: { // TODO...
				profileImage: Math.random()
			},
			user: {
				id: 0,
				name: '',
				fullname: '',
				department: '',
				settings: {
					hasPhone: false,
					phoneLogin: {
						extension: 0
					}
				}
			},
			phoneIsActive: false,
			phoneExternalApp: '',
			phoneInCall: false,
			pageStatusData: {},
			taskMasterData: {
				buckets: []
			},
			localStorageData: {},
			jsQR: {
				video: null,
				beep: null,
				animationFrame: null,
				counter: 1
			},
			deviceId: 0,
			printTagPrintList: [], // TODO: move to localStorageData?
			//codeReader: new BrowserQRCodeReader(),
			//codeReaderDevices: [],
			//codeReaderDeviceSelected: '',
			activeCallsTimer: null,
			incomingCallNotification: null,
			invoiceBackHref: '/taskmaster/'
		};
	},
	methods: methods,
	routes: routes,
	input: {
		scrollIntoViewOnFocus: !!Framework7.device.cordova,
		scrollIntoViewCentered: !!Framework7.device.cordova
	},
	statusbar: {
		overlay: Framework7.device.cordova && Framework7.device.ios || 'auto',
		iosOverlaysWebView: true,
		androidOverlaysWebView: false
	},
	on: {
		init: function() {
			var f7= this;
			if (f7.device.cordova) {
				cordovaApp.init(f7);
			}

			if (Framework7.device.android) {
				// Handling BACK BUTTON...
				window.history.pushState({}, '');
			}

			document.addEventListener('backbutton', function (e) {
				if ($('.actions-modal.modal-in').length) {
					f7.actions.close('.actions-modal.modal-in');
					e.preventDefault();
					return false;
				}
				if ($('.dialog.modal-in').length) {
					f7.dialog.close('.dialog.modal-in');
					e.preventDefault();
					return false;
				}
				if ($('.sheet-modal.modal-in').length) {
					f7.sheet.close('.sheet-modal.modal-in');
					e.preventDefault();
					return false;
				}
				if ($('.popover.modal-in').length) {
					f7.popover.close('.popover.modal-in');
					e.preventDefault();
					return false;
				}
				if ($('.popup.modal-in').length) {
					if ($('.popup.modal-in>.view').length) {
						const currentView = f7.views.get('.popup.modal-in>.view');
						if (currentView && currentView.router && currentView.router.history.length > 1) {
							currentView.router.back();
							e.preventDefault();
							return false;
						}
					}
					f7.popup.close('.popup.modal-in');
					e.preventDefault();
					return false;
				}
	
				const currentView = f7.views.current;
	
				if (currentView && currentView.router && currentView.router.url == '/') {
					f7.dialog.confirm('Exit App?','Exit',function() {
						navigator.app.exitApp();
					});
	
				} else {
					if (currentView && currentView.router && currentView.router.history.length > 1) {
						currentView.router.back();
						e.preventDefault();
						return false;
					}
				}
	
				if ($('.panel.panel-in').length) {
					f7.panel.close('.panel.panel-in');
					e.preventDefault();
					return false;
				}
			}, false);


		}
	}
});

app.methods.resetLocalData(false);

var showLoginScreen= (app.data.isMainWindow); // TODO: find out if they are signed in already...

app.data.lockScreen= app.loginScreen.create({ el: '#my-login-screen' });

if (showLoginScreen) {
	app.data.lockScreen.open(false);
	
}

var urlToLoad= '/';

if ((app.device.electron) && (!window.justDoAppOnDesktop)) {
	urlToLoad= '/taskmaster/invoices/new/';
	if (!app.data.isMainWindow) {
		// TODO: if requested a specific invoice, put it here ie: "/taskmaster/invoices/S-0000/"
	}
}

var viewMainConfigObject= {
	url: urlToLoad
};

if (Framework7.device.android) {
	viewMainConfigObject.pushState= true;
	viewMainConfigObject.pushStateRoot= document.location.pathname.split('index.html')[0];
}

app.data.mainView= app.views.create('.view-main', viewMainConfigObject);

app.data.mdWebSocket= null;
app.data.PHONE= null;
app.data.md= mobileDetect;

if (app.data.isMainWindow) {
	// If not desktop?
	app.data.PHONE= new f7Phone('phone.bedroomsandmore.com');
}

var leftSidePanelOptions= {
	el: '.mainScreenLeftPanel'
}

// ONLY THE MAIN WINDOW SHOULD HAVE THE LEFT PANEL...
if ((app.data.isMainWindow) && (app.device.desktop) && (!window.justDoAppOnDesktop)) { //  && (app.device.desktop)) {
	// Resizable?
	leftSidePanelOptions.collapsedBreakpoint= 768;
	leftSidePanelOptions.visibleBreakpoint= 1024;
}

app.data.mainLeftSidePanel= app.panel.create(leftSidePanelOptions);

/*
if (app.device.desktop) {
	var leftSidePanel= app.panel.get('.mainScreenLeftPanels');

	if (leftSidePanel.params) {
		leftSidePanel.params.collapsedBreakpoint= 768;
		leftSidePanel.params.visibleBreakpoint= 768;
	}

	// $$('.mainScreenLeftPanels').attr('data-collapsed-breakpoint','768').attr('data-visible-breakpoint','768');
}
*/


if (!app.data.turnOffLargeTitles) {
	// TODO: set this based off of whether we are ios 9...
	if ((app.data.isIOS9) || (app.data.md.version('iOS')<11) || (screen.width <= 320)) { // added ios 10 too...
		app.data.turnOffLargeTitles= true;
	}
}

$$('.mainSearchInvoiceButton').on('click', function() {
	app.methods.searchInvoices();
	
});

$$('.mainLeftInvoiceButton').on('click', function() {

});

$$('.mainRightInvoiceButton').on('click', function() {
	
});

// Login Screen Demo
$$('#my-login-screen .login-button').on('click', function() {
	
	var password= $$('#passwordInput').val();
	
	// TODO: check saved sign in checkbox...
	
	if (window.loginScreenNumberPad) {
		password= window.loginScreenNumberPad.getValue();
		if (typeof(password) == 'undefined') {
			password= '';
			
		}
	}
	
	if (password == '') {
		if (window.loginScreenNumberPad) {	
			window.loginScreenNumberPad.open();
			
		} else {
			$$('#passwordInput').focus();
			
		}
		
	} else {
		$$('#my-login-screen form').trigger('submit');
		
	}
});

$$(document).on('loginscreen:opened', function(e) {
	$$('style.bnmthemeCSS').html('');
	
	app.methods.emitEventToApp('loggedOut');

	if (window.loginScreenNumberPad) {
		window.loginScreenNumberPad.open();
		
	} else {
		$$('#passwordInput').focus();
		
	}
	
	if (app.data.disconnectedToastTimeout !== null) {
		clearTimeout(app.data.disconnectedToastTimeout);
		app.data.disconnectedToastTimeout= null;
	}
	
	app.data.disconnectedToast.close();
	app.$('.toast.disconnectedToast').remove();
	
});

$$(document).on('fab:close', function(e) {
	// TODO: IF ACTIVE CALLS FAB...
	
	var tempTimeout= setTimeout(function() {
		var outsideActiveCalls= app.data.taskMasterData.activeCalls.filter(function(elem) {
			return elem.isOutside;
		});
		
		if (outsideActiveCalls.length == 0) {
			$$('.fab-activeCalls').hide();
			
		}
		
	}, 1000);
});

window.addEventListener('offline', function(e) {
	console.log('offline');
	
	app.methods.checkIfOnline();

	/*
	if (app.data.disconnectedToastTimeout !== null) {
		clearTimeout(app.data.disconnectedToastTimeout);
		app.data.disconnectedToastTimeout= null;
	}
	
	if (app.data.mdWebSocket.sessionId !== '') {
		if (!app.data.appIsInBackground) {

			app.data.disconnectedToastTimeout= setTimeout(function() {
				app.data.disconnectedToast.open();
			}, 2000); // 4 seconds...
			
		}
	}
	*/

});

window.addEventListener('online', function(e) {
	console.log('online');

	app.methods.checkIfOnline();
	/*
	if (app.data.disconnectedToastTimeout !== null) {
		clearTimeout(app.data.disconnectedToastTimeout);
		app.data.disconnectedToastTimeout= null;
	}
	
	app.data.disconnectedToast.close();
	app.$('.toast.disconnectedToast').remove();
	*/

});

$$('#my-login-screen form').on('submit', function(e) {
	e.preventDefault();
	
	var password= $$('#passwordInput').val();
	var saveLogin= false;
	
	if ($$('#saveSignIn').length > 0) {
		saveLogin= $$('#saveSignIn').prop('checked');
		
	}
	
	if (window.loginScreenNumberPad) {
		password= window.loginScreenNumberPad.getValue();
		window.loginScreenNumberPad.setValue('');
		
	}
	
	$$('#passwordInput').val('').blur();
	$$('#saveSignIn').prop('checked',false);
	
	app.data.mdWebSocket.login({
		data: {
			password: password,
			saveLogin: saveLogin,
			deviceId: app.data.deviceId,
			device: window.device,
			deviceF7: app.device,
			appVersionF7: app.version,
			buildInfo: window.BuildInfo,
			deviceType: (app.data.md.mobile() ? (app.data.md.tablet() ? 'Tablet' : 'Phone') : 'Computer'),
			deviceNavigator: app.methods.object_getJSONOnly(window.navigator)
		},
		onSuccess: function(data) {
			app.data.user= data.employee;
			app.data.deviceId= data.deviceId;
			app.methods.setItemLocalStorage('taskMasterDeviceId',app.data.deviceId);

			// console.log(data);
			
			app.data.localStorageData.saveLogin= saveLogin;
			
			if (saveLogin) {
				app.data.localStorageData.user= app.data.user;
				app.data.localStorageData.session= {
					id: app.data.mdWebSocket.sessionId,
					endDateTime: data.sessionEndDateTime
				};
				app.methods.setItemLocalStorage('localStorageData',app.data.localStorageData);
				//window.localStorage.setItem('localStorageData', JSON.stringify(app.data.localStorageData));
			}
			
			app.methods.afterLoggedIn();
			
		},
		onError: function(data) {
			app.dialog.alert(data.error,'Error',function() {
				var tempTimeout= setTimeout(function() {
					if (window.loginScreenNumberPad) {
						window.loginScreenNumberPad.setValue('');
						window.loginScreenNumberPad.open();
					} else {
						$$('#passwordInput').focus();
					}
				}, 250);
			});
		}
	});
	
});

var objectToBind= window;
var onLoadEventString= 'load';
if (Framework7.device.cordova) {
	onLoadEventString= 'deviceready';
	objectToBind= document;
} else {
	app.data.turnOffLargeTitles= false;
	
}

// Strictly private internal function to be used only in iosrtc code // TODO: necessary???
function extend(From, ImplFunc) {
  function F() {
    var args = Array.prototype.slice.call(arguments, 0);
    From.apply(this, args); // Apply From's instance members
    ImplFunc.apply(this, args); // Apply ImplFunc's instance members
  }
  // F.prototype = Object.create(From.prototype);
  F.prototype = Object.assign({}, From.prototype);
  F.prototype.constructor = F;
  return F;
}




var afterLocalStorage= function(hasLocalStorage) {
	hasLocalStorage= ((typeof(hasLocalStorage) == 'boolean')?hasLocalStorage:false);
	
	// console.log('HAS LOCAL STORAGE',hasLocalStorage,app.data.localStorageData);
	// console.log('app.data.localStorageData',app.data.localStorageData);
	
	if (!app.data.localStorageData) {
		app.data.localStorageData= {};
		
	}
	
	var userDataSet= false;

	// IF THE MAIN WINDOW...
	if (hasLocalStorage) {
		
		// TODO: check for phone login...
		// Check if this was a background wake up then login the phone right away if necessary...!
		//var tempTimeout= setTimeout(function() {
			// console.log(app.data.localStorageData);
// app.data.PHONE.register(app.data.localStorageData.phoneLogin.username,app.data.localStorageData.phoneLogin.password,app.data.localStorageData.phoneLogin.extension,function() { window.app.f7.data.phoneIsActive= true; });
		//}, 500);
		
		//app.data.localStorageData.password= password;
		
		if (app.data.localStorageData.saveLogin) {
			if (app.data.localStorageData.session) {
				var endDate= new Date(Date.parse(app.data.localStorageData.session.endDateTime));
				var rightNow= new Date();
				
				if (endDate > rightNow) {
					app.data.user= app.data.localStorageData.user;
					app.data.mdWebSocket.sessionId= app.data.localStorageData.session.id;
					app.data.mdWebSocket.sessionIdExpires= endDate;
					
					userDataSet= true;
				}
			}
		}
		// console.log('showLoginScreen',showLoginScreen);
	}

	if ((!userDataSet) && (!app.data.isMainWindow) && (window.remote)) { // AND IS NOT THE MAIN WINDOW...
		app.data.user= window.remote.getGlobal('user');
		userDataSet= true;
	}
	
	if (userDataSet) {
		app.methods.setAppTheme({
			main: app.data.user.settings.theme.mobileColorMain,
			secondary: app.data.user.settings.theme.mobileColorSecondary,
			font: app.data.user.settings.theme.mobileColorFont
		});
		
		showLoginScreen= false;
	}

	window.lastEventNameAndPage= '';
	
	// TODO: I need to check whether it was a popup or a page... Then act accordingly...
	// It should not refresh automatically when a popup closes... Only on certain popups...
	
	$$(document).on('page:beforein popup:open popup:closed', function(e) { // TODO: are these events okay?
		//var thisEventAndPage= (e.type+'-'+window.app.f7.data.mainView.router.url);
		var thisEventAndPage= (e.type+'-'+window.app.f7.views.current.router.url);

		// console.log('page:beforein popup:open popup:closed',app.views.current,thisEventAndPage,window.lastEventNameAndPage);

		if (thisEventAndPage !== window.lastEventNameAndPage) {
			// console.log('event',thisEventAndPage);
			// TODO: pass in this window Id as well!
			//console.log('AUTO_STATUS',e.type);
			app.methods.sendStatus();
			window.lastEventNameAndPage= thisEventAndPage;
		}
	});
	
	if (!app.device.desktop) {
		window.loginScreenNumberPad= app.keypad.create({
			inputEl: '#passwordInput',
			openIn: 'sheet',
			toolbar: false,
			dotButton: false,
			scrollToInput: false,
			formatValue: function (value) {
				value = value.toString();
				if (true) {
					var returnString= '';
					for (var i= 0; i< value.length; i++) {
						returnString+= '*';
					}
					return returnString;
				} else {
					// String.repeat does not work on android 5...
					return ('*'.repeat(value.length));
				}
			}
		});
		
	}
	
	// TODO: remove splashscreen altogether on child window...
	if (navigator.splashscreen) {
		navigator.splashscreen.hide(); // This okay for every platform?
	}

	if (showLoginScreen) {
		if (window.loginScreenNumberPad) {
			window.loginScreenNumberPad.open();
			
		} else {
			$$('#passwordInput').focus();
			
		}
		
	} else {
		app.data.lockScreen.close(false);
		app.methods.afterLoggedIn();
		
	}

	app.methods.fixDraggableElements();
};

$$(objectToBind).on(onLoadEventString,function(e) {
	
	if (app.data.isMainWindow) {
		app.data.mdWebSocket= new TaskMaster('https://taskmaster.bedroomsandmore.com','wss://taskmaster.bedroomsandmore.com/websocket/mybandm');	
	} else {
		
		if (window.ipc) {
			app.data.mdWebSocket= {
				sendOverWebSocket: function(objectToSend) {
					if (window.ipc.send) {
						window.ipc.send('websocketToServer',objectToSend);
					}
				},
				connectWebSocket: function() {
					// Do nothing...
				}
			};
		} else {
			// ERROR!

		}
	}

	if (window.ipc) {
		// Events that come FROM electron.
		// TODO: need to distinguish whether this is the MAIN window, or an invoice window...
		if (window.ipc.on) {
			window.ipc.on('logout',function(event,eventData) {
				if (app.data.isMainWindow) {
					app.methods.logout(true);
				} else {
					// close the window?
					
				}
			});
		}

		if (window.ipc.on) {
			window.ipc.on('searchInvoices',function(event,eventData) {
				// TODO: If it's not already set...
				app.data.mainView.router.navigate('/taskmaster/search/');
			});
		}

		if (app.data.isMainWindow) {
			if (window.ipc.on) {
				window.ipc.on('websocketToServer',function(event,objectToSend) {
					$app.data.mdWebSocket.sendToWebSocket(objectToSend);
				});
			}
		}
	}

	// TODO: find out if they clicked on a notification from the outside...
	// console.log(cordova.plugins.notification.local.launchDetails);

	if (!window.device) {
		window.device= {};
	}

	if (!window.BuildInfo) {
		window.BuildInfo= {
			version: app.version
		};
	}

	$$(document).on('input keypress','.barcodeEntryInput',function(e) {
		//console.log(e.type,e.keyCode,e.target.value);
		//console.log('app.data.previousBarcodeEntryInputValue',app.data.previousBarcodeEntryInputValue);
		
		if (e.target.value == '') {
			app.data.previousBarcodeEntryInputValue= '';

		} else if (e.keyCode == 13) {
			if (e.target.value !== '') {
				app.methods.barCodeEntered({
					text: e.target.value,
					scanned: false,
					event: e
				});
			}

		} else {
			var newValue= e.target.value;
			if ((newValue.length-app.data.previousBarcodeEntryInputValue.length) >= 3) { // Grew by 3 characters instantly!
				if (e.target.value !== '') {
					app.methods.barCodeEntered({
						text: newValue,
						scanned: true,
						event: e
					});
				}
			} else {
				app.data.previousBarcodeEntryInputValue= newValue;
			}
		}
	});

	if (app.device.electron) {
		$$('.saveSignInLI').hide();
		$$('.passwordLI').css('padding-bottom','1em');
	}

	app.data.device= window.device; // TODO: if Cordova?
	
	app.data.disconnectedToast= app.toast.create({
		position: 'bottom',
		text: 'Disconnected from the server...<br>You can continue to use the app but nothing new will be updated.<br>This will automatically reconnect when you have internet or the server is back online.',
		closeTimeout: 0,
		cssClass: 'disconnectedToast'
	});
	
	app.methods.getItemLocalStorage('taskMasterDeviceId',function(returnedValue) {
		app.data.deviceId= returnedValue;

	},function() {
		// If failed...
		app.data.deviceId= 0;
		
	});

	/*
	app.data.codeReader.getVideoInputDevices()
		.then((videoInputDevices) => {
			app.data.codeReaderDeviceSelected= videoInputDevices[0].deviceId;
			app.data.codeReaderDevices= videoInputDevices.slice(); // Copy the array...
		});
	*/
	
	// Check localStorage first before continuing...
	app.methods.getItemLocalStorage('localStorageData',function(returnedValue) {
		app.data.localStorageData= returnedValue;
		afterLocalStorage(true);
	},function() {
		// On Error...
		afterLocalStorage(false);
	})
	
});
