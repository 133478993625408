
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;

    if (self.$app.data.taskMasterData.messageFromHR) {
      self.messageFromHR = self.$app.data.taskMasterData.messageFromHR;
    }
  },
  data: function () {
    return {
      ptoGauge: null,
      messageFromHR: {}
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'currentPTO') {
        self.refreshPage();
      } else if (data.type == 'data' && data.data == 'messageFromHR') {
        self.messageFromHR = data.messageFromHR;

        if (!self.messageFromHR.messageHTML || self.messageFromHR.messageHTML == '') {
          if (self.messageFromHR.message) {
            self.messageFromHR.messageHTML = self.messageFromHR.message;
            self.messageFromHR.messageHTML = self.messageFromHR.messageHTML.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
            self.messageFromHR.messageHTML = self.messageFromHR.messageHTML.replace(/\r\n/g, '<br>');
            self.messageFromHR.messageHTML = self.messageFromHR.messageHTML.replace(/\n/g, '<br>');
            self.messageFromHR.messageHTML = self.messageFromHR.messageHTML.replace(/\r/g, '<br>');
            self.messageFromHR.messageHTML = self.$root.externalURLsToLinks(self.messageFromHR.messageHTML);
          }
        }

        self.refreshPage();
      }
    },
    refreshPage: function () {
      var self = this;
      self.$update(function () {
        if (self.ptoGauge !== null) {
          self.ptoGauge.destroy();
        }

        if (self.$root.taskMasterData.currentPTO) {
          self.ptoGauge = self.$app.gauge.create({
            el: '.pto-gauge',
            type: 'circle',
            value: 1,
            size: 200,
            borderColor: '#2196f3',
            borderWidth: 10,
            valueText: self.$root.taskMasterData.currentPTO.endingBalance,
            // TODO: get hours...
            valueFontSize: 41,
            valueTextColor: '#2196f3',
            labelText: 'PTO Hours',
            labelFontSize: 21
          });
        }
      });
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.refreshPage();
    }
  },
  id: 'f530fc42cb',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="hrPage">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">Human Resources</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text">Human Resources</div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="page-content">\n			<div class="block block-strong employeeInfoBlock no-margin-top">\n				<div class="row">\n				    <div class="col">\n					    <h3>';
      r += c(ctx_1.$root.user.fullname, ctx_1);
      r += '</h3>\n					    <p>Department: ';
      r += c(ctx_1.$root.user.department, ctx_1);
      r += '</p>\n				    </div>\n				    <div class="col text-align-center">\n			    		<div class="gauge pto-gauge"></div>\n			    		<p class="belowGaugeText"></p>\n				    </div>\n			    </div>\n			</div>\n			<div class="list links-list no-margin">\n				<ul>\n					<li><a href="/reports/hr/My%20Timecard%20PDFs/" class="item-link item-content"><p><i class="icon f7-icons">doc_text</i> My TimeCard PDFs</p></a></li>\n				</ul>\n			</div>\n			';
      r += Template7Helpers.with.call(ctx_1, ctx_1.messageFromHR, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			';
          r += Template7Helpers.if.call(ctx_2, ctx_2.message, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n			<div class="block block-strong">\n				';
              r += Template7Helpers.if.call(ctx_3, ctx_3.title, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="block-header">';
                  r += c(ctx_4.title, ctx_4);
                  r += '</div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n				<p>';
              r += c(ctx_3.messageHTML, ctx_3);
              r += '</p>\n			</div>\n			';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	
`,
  styleScoped: false
};
    