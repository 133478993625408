
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();
    var calendarNavBarTitle = self.$root.monthNames[month] + ', ' + year;
    self.$app.data.pageStatusData = {
      name: calendarNavBarTitle,
      month: month + 1,
      year: year
    };

    if (self.$root.taskMasterData.calendars) {
      if (self.$root.taskMasterData.calendars.length > 0) {
        self.calendars = self.$root.taskMasterData.calendars.map(function (currentElement) {
          return {
            id: currentElement.id,
            name: currentElement.name,
            color: currentElement.color,
            isSubscription: currentElement.isSubscription
          };
        });
      }
    }

    if (self.$root.taskMasterData.restrictedDatesCalendars) {
      if (self.$root.taskMasterData.restrictedDatesCalendars.length > 0) {
        self.restrictedDatesCalendars = self.$root.taskMasterData.restrictedDatesCalendars;
      }
    }

    self.today = new Date(year, month, day);
    self.datePicked = new Date(year, month, day);
  },
  data: function () {
    var self = this;
    return {
      restrictedDatesCalendars: [],
      calendars: [
        /*
        {
        name: 'My Calendar',
        color: '#2196f3'
        },
        {
        name: self.$root.user.department+' Department',
        color: '#4caf50'
        },
        {
        name: 'Meetings',
        color: '#e91e63'
        },
        {
        name: 'Time Away / Vacations',
        color: '#ff9800'
        }
        */
      ],
      calendar: null,
      eventItems: [],
      datePicked: null,
      today: null,
      events: []
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && (data.data == 'calendars' || data.data == 'calendarEvents')) {
        self.refreshPage();
      }
    },
    openCalendarPanel: function () {
      var self = this;
      self.calendarPanel.open();
    },
    refreshPage: function () {
      var self = this;
      self.events = []; // Clear them to start...

      var calendarIds = [];

      if (self.$root.taskMasterData.calendars) {
        if (self.$root.taskMasterData.calendars.length > 0) {
          self.calendars = self.$root.taskMasterData.calendars.map(function (currentElement) {
            return {
              id: currentElement.id,
              name: currentElement.name,
              color: currentElement.color,
              isSubscription: currentElement.isSubscription
            };
          });
          calendarIds = self.calendars.map(function (currentElement) {
            return currentElement.id;
          });
        }
      }

      if (self.$root.taskMasterData.restrictedDatesCalendars) {
        if (self.$root.taskMasterData.restrictedDatesCalendars.length > 0) {
          self.restrictedDatesCalendars = self.$root.taskMasterData.restrictedDatesCalendars;
        }
      }

      console.log('self.restrictedDatesCalendars', self.restrictedDatesCalendars);
      var calendarEventsToLoop = [];

      if (self.$root.taskMasterData.calendarEvents) {
        if (self.$root.taskMasterData.calendarEvents.length > 0) {
          calendarEventsToLoop = self.$root.taskMasterData.calendarEvents.filter(function (currentElement) {
            return !currentElement.isDeleted && calendarIds.indexOf(currentElement.calendarId) > -1;
          });
        }
      } // loop calendars


      for (var i = 0; i < calendarEventsToLoop.length; i++) {
        var currentEvent = calendarEventsToLoop[i];
        var currentCalendar = self.calendars.filter(function (currElem) {
          return currElem.id == currentEvent.calendarId;
        })[0];
        var eventStart = new Date(currentEvent.startDateTime);
        var eventEnd = new Date(currentEvent.endDateTime);
        var dateText = '';
        var daysBetween = self.$root.days_between(eventStart, eventEnd);
        daysBetween += 1;

        if (daysBetween > 1) {
          dateText = moment(eventStart).format("MMM Do") + ' - ' + moment(eventEnd).format("MMM Do");
        } else {
          dateText = ''; //moment(eventStart).format("MMM Do");
        }

        var startTimeText = moment(eventStart).format("LT");
        var endTimeText = moment(eventEnd).format("LT"); // console.log('CURRENTEVENT',currentEvent);

        if (currentEvent.allDay) {
          startTimeText = '';
          endTimeText = '';
        }

        var currentLoopDate = eventStart;
        currentLoopDate.setHours(0, 0, 0, 0);

        for (var d = 0; d < daysBetween; d++) {
          self.events.push({
            id: currentEvent.id,
            calendarId: currentEvent.calendarId,
            isPartOfASchedule: currentEvent.isPartOfASchedule,
            assignedEmployeeId: currentEvent.assignedEmployeeId,
            _assignedEmployeeName: currentEvent._assignedEmployeeName,
            notApprovedYet: currentEvent.isPartOfASchedule && currentEvent.isApproved == false,
            date: new Date(currentLoopDate.getTime()),
            title: currentEvent.title,
            color: currentCalendar.color,
            startTimeText: startTimeText,
            endTimeText: endTimeText,
            dateText: dateText
          });
          currentLoopDate.setDate(currentLoopDate.getDate() + 1);
        }
      }

      if (self.calendar !== null) {
        //console.log('Here!');
        self.calendar.params.events = self.events; // console.log('self.calendar.params.events',self.calendar.params.events);
        // calendar-months-wrapper
        //var calendarIsNotMoving= (self.$el.find('.calendar-months-wrapper').css('transform'));

        var tempTimeout = setTimeout(function () {
          var calendarElement = self.$el.find('.calendar-months-wrapper');
          var calendarWidth = calendarElement.width(); // TODO: if the matrix is not zero AND is not a division of calendarElement.width() then skip!

          var transformString = calendarElement.css('transform').replace('matrix(', '').replace(')', '');
          var continueWithUpdate = true;

          if (transformString !== 'none') {
            var transformSplit = transformString.split(', ').map(function (currElem) {
              return parseFloat(currElem);
            });

            if (transformSplit.length == 6) {
              if (transformSplit[4] !== 0) {
                continueWithUpdate = Math.abs(transformSplit[4]) % calendarWidth == 0; // console.log('continueWithUpdate',continueWithUpdate,transformSplit);
              }
            }
          }

          if (continueWithUpdate) {
            self.calendar.update();
          }
        }, 50);
        self.renderEvents(self.calendar);
      }
      /*
      	{
      		date: new Date(year, month, day),
      		hours: 12,
      		minutes: 30,
      		title: 'Meeting with Vladimir',
      		color: '#2196f3',
      	}
      */

    },
    renderEvents: function (calendar) {
      var self = this;
      var currentDate = calendar.value[0];
      var currentEvents = self.events.filter(function (event) {
        return event.date.getTime() >= currentDate.getTime() && event.date.getTime() < currentDate.getTime() + 24 * 60 * 60 * 1000;
      });
      const eventItems = [];

      if (currentEvents.length) {
        currentEvents.forEach(function (event) {
          var timeText = 'All Day';

          if (event.startTimeText !== '') {
            timeText = event.startTimeText;
          } // console.log('event',event);


          eventItems.push({
            id: event.id,
            calendarId: event.calendarId,
            title: (event.isPartOfASchedule ? event._assignedEmployeeName + ' OFF: ' : '') + event.title,
            notApprovedYet: event.notApprovedYet,
            dateText: event.dateText,
            time: timeText,
            color: event.color
          });
        });
      }

      self.$setState({
        eventItems: eventItems,
        datePicked: currentDate
      });
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.calendar = self.$app.calendar.create({
        containerEl: '#calendar',
        toolbar: false,
        value: [self.today],
        events: self.events,
        on: {
          init: function (calendar) {
            self.$('.navbar-calendar-title').text(self.$root.monthNames[calendar.currentMonth] + ', ' + calendar.currentYear);
            self.$app.navbar.size(self.$app.navbar.getElByPage(page.el));
            calendar.$el.addClass('no-safe-area-right');
            self.renderEvents(calendar);
          },
          monthYearChangeStart: function (calendar) {
            var calendarNavBarTitle = self.$root.monthNames[calendar.currentMonth] + ', ' + calendar.currentYear;
            self.$('.navbar-calendar-title').text(calendarNavBarTitle);
            self.$app.navbar.size(self.$app.navbar.getElByPage(page.el));
          },
          monthYearChangeEnd: function (calendar) {
            self.$app.data.pageStatusData = {
              // name: calendarNavBarTitle, // Not needed...
              month: calendar.currentMonth + 1,
              year: calendar.currentYear
            };
            self.$root.sendStatus();
          },
          change: function (calendar) {
            self.renderEvents(calendar);
          }
        }
      });
      self.calendarPanel = self.$app.panel.create({
        el: '.calendarPanel'
      });
      self.refreshPage();
    },
    pageBeforeRemove: function () {
      var self = this;
      self.calendar.destroy();
      self.calendarPanel.destroy();
    }
  },
  id: '2343ae5e77',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page calendarPage" data-name="calendarPage">\n		<div class="navbar no-shadow">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title navbar-calendar-title">Calendar</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<!--<a href="/calendar/0/event/0/" class="link icon-only">-->\n					<a href="#" class="link icon-only popover-open" data-popover=".calendarPopover">\n						<i class="icon f7-icons if-not-md">plus</i>\n						<i class="icon material-icons md-only">add</i>\n					</a>\n				</div>\n			</div>\n		</div>\n		<div class="toolbar toolbar-bottom">\n			<div class="toolbar-inner">\n				<span>&nbsp;</span>\n				<a class="link" href="#" @click="openCalendarPanel">Calendars</a>\n			</div>\n		</div>\n		<div class="panel panel-right panel-cover calendarPanel">\n			<div class="block-title">Calendars</div>\n			<div class="list no-margin no-hairlines no-safe-area-right">\n				<ul>\n					<!-- TODO: this should be accordian and then LIST stuff for the current month... -->\n					';
      r += Template7Helpers.each.call(ctx_1, ctx_1.calendars, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<li class="item-content">\n						<div class="event-color" style="background-color: ';
          r += c(ctx_2.color, ctx_2);
          r += ';"></div>\n						<div class="item-inner">\n							<div class="item-title">';
          r += c(ctx_2.name, ctx_2);
          r += '</div>\n							<!--<div class="item-after">test</div>-->\n						</div>\n					</li>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</ul>\n			</div>\n		</div>\n		<div class="page-content">\n			<div id="calendar" class="block block-strong no-padding no-margin no-hairline-top"></div>\n			<div id="calendar-events" class="list media-list no-margin no-hairlines no-safe-area-right">\n				<ul>\n					<li class="item-divider">';
      r += Template7Helpers.js.call(ctx_1, "moment(this.datePicked).format('MMMM Do YYYY')", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</li>\n				';
      r += Template7Helpers.each.call(ctx_1, ctx_1.eventItems, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<li>\n						<a href="/calendar/';
          r += c(ctx_2.calendarId, ctx_2);
          r += '/event/';
          r += c(ctx_2.id, ctx_2);
          r += '/" class="item-content item-link">\n							<div class="event-color';
          r += Template7Helpers.if.call(ctx_2, ctx_2.notApprovedYet, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' notApprovedYet';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" data-color="';
          r += c(ctx_2.color, ctx_2);
          r += '" style="background-color: ';
          r += c(ctx_2.color, ctx_2);
          r += '"></div>\n							<div class="item-inner">\n								<div class="item-title-row">\n									<div class="item-title">';
          r += c(ctx_2.title, ctx_2);
          r += Template7Helpers.if.call(ctx_2, ctx_2.notApprovedYet, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<span style="color: #f00; font-style: italic; opacity: 0.5;"> (Under Review)</span>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\n									<div class="item-after">';
          r += c(ctx_2.time, ctx_2);
          r += '</div>\n								</div>\n								<div class="item-text">';
          r += c(ctx_2.dateText, ctx_2);
          r += '</div>\n							</div>\n						</a>\n					</li>\n				';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<li class="item-content">\n						<div class="item-inner">\n							<div class="item-title text-color-gray">No events for this day</div>\n						</div>\n					</li>\n				';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</ul>\n			</div>\n		</div>\n		<div class="popover calendarPopover">\n			<div class="popover-angle"></div>\n			<div class="popover-inner">\n				<div class="list">\n					<ul>\n						';
      r += Template7Helpers.each.call(ctx_1, ctx_1.calendars, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n						';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.isSubscription, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n						<li>\n							<a href="/calendar/';
              r += c(ctx_3.id, ctx_3);
              r += '/event/0/" class="item-link item-content popover-close">\n								<div class="event-color" style="background-color: ';
              r += c(ctx_3.color, ctx_3);
              r += ';"></div>\n								<div class="item-inner">\n									<div class="item-title">';
              r += c(ctx_3.name, ctx_3);
              r += '</div>\n								</div>\n							</a>\n						</li>\n						';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					</ul>\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	#calendar, #calendar-events {
		height: 50%;
		box-sizing: border-box;
	}

	.calendar-day.calendar-day-background .calendar-day-number {
		color: #fff;
		background-color: #000;
	}
	
	#calendar .calendar {
		height: 100%;
	}
	
	#calendar-events ul {
		height: 100%;
		overflow: auto;
	}

	.notApprovedYet {
		background: repeating-linear-gradient(45deg,transparent,transparent 5px, 0px,#fff 10px);
	}
	
	/* Right Panel right border when it is visible by breakpoint */
	.panel-right.panel-visible-by-breakpoint:before {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 1px;
		background: rgba(0,0,0,0.1);
		content: '';
		z-index: 6000;
	}
	
	/* Hide navbar link which opens right panel when it is visible by breakpoint */
	.panel-right.panel-visible-by-breakpoint ~ .view .navbar .panel-open[data-panel="left"] {
		display: none;
	}
	
	/*
	Extra borders for main view and left panel for iOS theme when it behaves as panel (before breakpoint size)
	*/
	.ios .panel-right:not(.panel-visible-by-breakpoint).panel-active ~ .view-main:before,
	.ios .panel-right:not(.panel-visible-by-breakpoint).panel-closing ~ .view-main:before {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 1px;
		background: rgba(0,0,0,0.1);
		content: '';
		z-index: 6000;
	}
	
	@media (orientation: landscape) {
		#calendar {
			float: left;
		}
		
		#calendar, #calendar-events {
			height: 100%;
		}
		
		#calendar, #calendar-events {
			width: 50%;
		}
		
		#calendar-events {
			margin-left: 50% !important;
			border-left: 1px solid #eee;
		}
		
		.theme-dark #calendar-events {
			border-left-color: #282828;
		}
	}

	@media (min-width: 768px) {
		/*
		.calendarPage .navbar, .calendarPage .page-content {
			margin-left: 260px;
		}
		
		.calendarPage .toolbar {
			display: none;
		}
		
		.calendarPage .calendarPanel {
			display: block;
			transform: translate3d(0,0,0) !important;
		}
		*/
	}

`,
  styleScoped: false
};
    