
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    var pickedDate = new Date();

    if (self.$('#calendar')) {
      self.calendarInstance = self.$app.calendar.get(self.$('#calendar').find('.calendar.calendar-inline'));

      if (self.calendarInstance) {
        pickedDate = self.calendarInstance.value[0];
      }
    }

    self.calendarId = parseInt(self.$route.params.calendarId);
    self.eventId = parseInt(self.$route.params.eventId);

    if (self.$root.taskMasterData.calendars) {
      if (self.$root.taskMasterData.calendars.length > 0) {
        self.calendars = self.$root.taskMasterData.calendars.map(function (currentElement) {
          return {
            id: currentElement.id,
            name: currentElement.name,
            color: currentElement.color
          };
        });
      }
    }

    if (self.calendarId > 0) {
      var filteredCalendars = self.$app.data.taskMasterData.calendars.filter(function (currentElement) {
        return currentElement.id == self.calendarId;
      });

      if (filteredCalendars.length == 1) {
        self.calendar = filteredCalendars[0];
      }

      if (self.eventId > 0) {
        if (self.$app.data.taskMasterData.calendarEvents) {
          var filteredEvents = self.$app.data.taskMasterData.calendarEvents.filter(function (currentElement) {
            return currentElement.id == self.eventId;
          });

          if (filteredEvents.length == 1) {
            self.event = filteredEvents[0];
            self.event.notesHTML = self.$root.externalURLsToLinks(self.event.notes);
            self.event.notesHTML = self.event.notesHTML.replace(/\r\n/g, '<br>').trim(); // trim?

            self.event.notesHTML = self.event.notesHTML.replace(/\n/g, '<br>').trim(); // trim?

            self.event.notesHTML = self.event.notesHTML.replace(/\r/g, '<br>').trim(); // trim?

            self.canEditEvent = self.$root.user.isAdmin;

            if (!self.canEditEvent) {
              self.canEditEvent = self.event.createdEmployeeId == self.$root.user.id;
            } // console.log(self.event);

          }
        }
      }
    }

    if (self.eventId == 0) {
      self.editMode = true;
      self.event.startDate = pickedDate.toISOString();
      self.event.startDateTime = pickedDate.toISOString();
      self.event.endDate = pickedDate.toISOString();
      self.event.endDateTime = pickedDate.toISOString();
      self.event.allDay = true;
    }

    if (self.calendarId == 3) {
      // Time-Away - PTO Calendar
      self.event.isPartOfASchedule = true;
      self.event.allDay = true;

      if (!self.event.assignedEmployeeId) {
        self.event.assignedEmployeeId = 0;
        self.event._assignedEmployeeName = '';
      }

      if (self.event.assignedEmployeeId == 0) {
        // If not assigned...
        // if (!self.$app.data.user.isAdmin) {
        self.event.assignedEmployeeId = self.$app.data.user.id;
        self.event._assignedEmployeeName = self.$app.data.user.name; // }
      }

      if (self.event.title == '') {
        self.event.title = 'Vacation';
      }
    } // console.log('MOMENTJS',moment(self.event.startDateTime).format(moment.HTML5_FMT.DATETIME_LOCAL));


    self.event.startDateValue = self.event.startDateTime.substr(0, 10);

    if (self.event.allDay) {
      self.event.startDateTimeValue = self.event.startDateValue + 'T00:00';
    } else {
      self.event.startDateTimeValue = moment(self.event.startDateTime).format(moment.HTML5_FMT.DATETIME_LOCAL);
    }

    self.event.endDateValue = self.event.endDateTime.substr(0, 10);

    if (self.event.allDay) {
      self.event.endDateTimeValue = self.event.endDateValue + 'T00:00';
    } else {
      self.event.endDateTimeValue = moment(self.event.endDateTime).format(moment.HTML5_FMT.DATETIME_LOCAL);
    } // console.log('self.event',self.event);

  },
  data: function () {
    var date = new Date();
    return {
      calendarId: 0,
      eventId: 0,
      editMode: false,
      canEditEvent: false,
      calendar: {
        id: 0,
        name: '',
        color: '#fff'
      },
      event: {
        id: 0,
        isDeleted: false,
        title: '',
        notes: '',
        allDay: false,
        startDate: date.toISOString(),
        startDateTime: date.toISOString(),
        endDate: date.toISOString(),
        endDateTime: date.toISOString()
      },
      calendars: []
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'calendars') {
        self.$setState(); // Refresh the page...
      }
    },
    checkedApproved: function () {
      var self = this;
      var checkedApprovedCheckbox = self.$el.find('#isApproved');
      self.event.isApprovedChecked = checkedApprovedCheckbox[0].checked;
    },
    checkedAllDay: function () {
      var self = this;
      var allDayCheckbox = self.$el.find('#allDay');

      if (allDayCheckbox[0].checked) {
        // Get Start/End Days...
        var startDateTimeVal = self.$el.find('#startDateTime').val();
        self.$el.find('#startDate').val(startDateTimeVal.substr(0, 10));
        var endDateTimeVal = self.$el.find('#endDateTime').val();
        self.$el.find('#endDate').val(startDateTimeVal.substr(0, 10));
        self.$el.find('#startDateTime').hide();
        self.$el.find('#startDate').show();
        self.$el.find('#endDateTime').hide();
        self.$el.find('#endDate').show();
      } else {
        // TODO: get current time to add close to NOW time...
        var startDateVal = self.$el.find('#startDate').val();
        self.$el.find('#startDateTime').val(startDateVal + 'T00:00');
        var endDateVal = self.$el.find('#endDate').val();
        self.$el.find('#endDateTime').val(endDateVal + 'T00:00');
        self.$el.find('#startDate').hide();
        self.$el.find('#startDateTime').show();
        self.$el.find('#endDate').hide();
        self.$el.find('#endDateTime').show();
      }
    },
    editEvent: function () {
      var self = this;
      self.$setState({
        editMode: true
      });
    },
    saveEvent: function () {
      var self = this;
      var formDataObject = self.$app.form.convertToData('#eventForm');
      formDataObject.action = 'saveCalendarEvent';
      formDataObject.isDeleted = formDataObject.isDeleted == 'true'; // console.log('formDataObject',formDataObject);

      self.$root.mdWebSocket.sendOverWebSocket(formDataObject); // IF OKAY...

      self.$app.popup.close('.calendarNewEventPopup');
    },
    deleteEvent: function () {
      var self = this;
      self.$app.dialog.confirm('Are you sure you want to delete this event?', 'Delete Event', function () {
        self.$el.find('#isDeleted').val('true');
        self.saveEvent();
      });
    },
    changedAssignedEmployee: function (e) {
      var self = this; // var thisSelectBox= self.$el.find('#assignedEmployeeId').val();

      self.event.assignedEmployeeId = self.$el.find('#assignedEmployeeId').val();

      if (self.event.assignedEmployeeId > 0) {
        var pickedEmployeeObject = self.$app.data.taskMasterData.employees.filter(function (currElem) {
          return currElem.id == self.event.assignedEmployeeId;
        })[0];
        self.event._assignedEmployeeName = pickedEmployeeObject.name;
      } // Re-Render Template


      self.$setState();
    },
    changedCalendar: function (e) {
      var self = this;
      var color = '#fff';
      var calendarIdPicked = parseInt(self.$el.find('#calendarId').val());
      var oldCalendarId = self.calendarId;
      self.calendarId = calendarIdPicked;
      var filteredCalendars = self.calendars.filter(function (currentElement) {
        return currentElement.id == calendarIdPicked;
      });

      if (filteredCalendars.length == 1) {
        self.calendar = filteredCalendars[0];
        color = filteredCalendars[0].color;
      }

      if (self.calendarId == 3) {
        // Time-Away - PTO Calendar
        self.event.isPartOfASchedule = true;
        self.event.allDay = true;

        if (self.event.title == '') {
          self.event.title = 'Vacation';
        }

        if (!self.event.assignedEmployeeId || self.event.assignedEmployeeId == 0) {
          // If not assigned...
          if (!self.$app.data.user.isAdmin) {
            self.event.assignedEmployeeId = self.$app.data.user.id;
            self.event._assignedEmployeeName = self.$app.data.user.name;
          }
        }
      } else {
        if (oldCalendarId == 3) {
          self.event.isPartOfASchedule = false;
        }
      } //console.log('EVENT',self.event);
      // Re-Render Template


      self.$setState();
    }
  },
  on: {
    popupOpen: function (e, popup) {
      var self = this;
      self.$el.find('form, input').each(function () {
        self.$(this).attr('autocomplete', 'off');
      });
    }
  },
  id: '03739fe71d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="popup calendarNewEventPopup">\n		<div class="view">\n			<div class="page" data-name="calendarNewEventPopup">\n				<div class="navbar">\n					<div class="navbar-bg"></div>\n					<div class="navbar-inner">\n						<div class="left">\n							';
      r += Template7Helpers.if.call(ctx_1, ctx_1.editMode, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							<a href="#" class="link icon-only popup-close md-only" data-popup=".calendarNewEventPopup"><i class="icon material-icons">cancel</i></a>\n							<a href="#" class="link popup-close if-not-md" data-popup=".calendarNewEventPopup">Cancel</a>\n							';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n							<a href="#" class="link icon-only popup-close md-only" data-popup=".calendarNewEventPopup"><i class="icon icon-back"></i></a>\n							<a href="#" class="link popup-close if-not-md" data-popup=".calendarNewEventPopup">Close</a>\n							';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n						</div>\n						<div class="title sliding">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.eventId, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.if.call(ctx_2, ctx_2.editMode, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'Edit Event';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += 'Event Details';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += 'New Event';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n						<div class="right">\n							';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							<a href="/bugreport/" class="link icon-only">\n								<i class="icon material-icons">bug_report</i>\n							</a>\n							';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n							';
      r += Template7Helpers.if.call(ctx_1, ctx_1.editMode, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							<a href="#" class="link" @click="saveEvent">Save</a>\n							';
          r += Template7Helpers.if.call(ctx_2, ctx_2.canEditEvent, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n							';
          r += Template7Helpers.if.call(ctx_2, ctx_2.canEditEvent, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							<a href="#" class="link" @click="editEvent">Edit</a>\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n							';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n						</div>\n					</div>\n				</div>\n				<div class="page-content">\n					<form id="eventForm" onsubmit="return false;">\n						';
      r += Template7Helpers.if.call(ctx_1, ctx_1.editMode, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n						<input type="hidden" name="eventId" value="';
          r += c(ctx_2.eventId, ctx_2);
          r += '">\n						<input type="hidden" id="isDeleted" name="isDeleted" value="';
          r += c(ctx_2.event.isDeleted, ctx_2);
          r += '">\n						<div class="list no-hairlines-md">\n							<ul>\n								<li class="item-content item-input">\n									<div class="event-color calendarPickedColor" style="background-color: ';
          r += c(ctx_2.calendar.color, ctx_2);
          r += '"></div>\n									<div class="item-inner">\n										<div class="item-title item-label">Calendar</div>\n										<div class="item-input-wrap input-dropdown-wrap">\n											<select id="calendarId" name="calendarId" placeholder="Please choose..." @change="changedCalendar">\n												';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.calendarId, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n												<option value="0">Please choose...</option>\n												';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n												';
          r += Template7Helpers.each.call(ctx_2, ctx_2.calendars, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n												<option value="';
              r += c(ctx_3.id, ctx_3);
              r += '"';
              r += Template7Helpers.js_if.call(ctx_3, "this.id == this.@root.calendarId", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' selected="selected"';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '>';
              r += c(ctx_3.name, ctx_3);
              r += '</option>\n												';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n											</select>\n										</div>\n									</div>\n								</li>\n								';
          r += Template7Helpers.if.call(ctx_2, ctx_2.event.isPartOfASchedule, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n								';
              r += Template7Helpers.if.call(ctx_3, ctx_3.$root.user.isAdmin, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-title item-label">Assigned Employee</div>\n										<div class="item-input-wrap input-dropdown-wrap">\n											<select id="assignedEmployeeId" name="assignedEmployeeId" placeholder="Please choose..." @change="changedAssignedEmployee">\n												';
                  r += Template7Helpers.unless.call(ctx_4, ctx_4.event.assignedEmployeeId, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n												<option value="0">Please choose...</option>\n												';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n												';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.$root.taskMasterData.employees, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n												<option value="';
                      r += c(ctx_5.id, ctx_5);
                      r += '"';
                      r += Template7Helpers.js_if.call(ctx_5, "this.id == this.@root.event.assignedEmployeeId", {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += ' selected="selected"';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '>';
                      r += c(ctx_5.name, ctx_5);
                      r += '</option>\n												';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n											</select>\n										</div>\n									</div>\n								</li>\n								';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n								<input type="hidden" name="assignedEmployeeId" value="';
                  r += c(ctx_4.event.assignedEmployeeId, ctx_4);
                  r += '">\n								';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n								';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								<li class="item-content item-input">\n									';
          r += Template7Helpers.if.call(ctx_2, ctx_2.event.isPartOfASchedule, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n									<div class="item-media employeeNameItemMedia">';
              r += c(ctx_3.event._assignedEmployeeName, ctx_3);
              r += ' OFF:</div>\n									';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n									<div class="item-inner">\n										<div class="item-input-wrap">\n											<input style="font-weight: bold;" type="text" name="title" placeholder="';
          r += Template7Helpers.if.call(ctx_2, ctx_2.event.isPartOfASchedule, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'Reason';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += 'Title';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" value="';
          r += c(ctx_2.event.title, ctx_2);
          r += '">\n										</div>\n									</div>\n								</li>\n								';
          r += Template7Helpers.if.call(ctx_2, ctx_2.event.isPartOfASchedule, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n								';
              r += Template7Helpers.if.call(ctx_3, ctx_3.$root.user.isAdmin, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n								';
                  r += Template7Helpers.if.call(ctx_4, true, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n								<li class="item-content">\n									<div class="item-inner">\n										<div class="item-title-row">\n											<div class="item-title">Approved? <label class="toggle toggle-init toggle-approved toggle-active-state">\n													<input type="checkbox" id="isApproved" name="isApproved" value="yes" ';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.event.isApproved, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += 'checked';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += ' @click="checkedApproved"><i class="toggle-icon"></i>\n												</label></div>\n										</div>\n									</div>\n								</li>\n								';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.event.isApproved, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n								<input type="hidden" name="isApproved" value="';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.event.isApproved, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += 'yes';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '">\n								';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n								';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n								';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n							</ul>\n						</div>\n						\n						';
          r += Template7Helpers.if.call(ctx_2, ctx_2.event.isPartOfASchedule, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n						';
              r += Template7Helpers.if.call(ctx_3, ctx_3.event.isApproved, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						';
                  r += Template7Helpers.js_if.call(ctx_4, "(this.event.assignedEmployeeId !== this.event.approvedEmployeeId)", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n						<div class="block">\n							<div class="chip color-green">\n								<div class="chip-label" style="font-weight: bold;">Approved by: ';
                      r += c(ctx_5.event._approvedEmployeeName, ctx_5);
                      r += '</div>\n							</div>\n						</div>\n						';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n						';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						<div class="block">\n							<div class="chip color-red">\n								<div class="chip-label" style="font-weight: bold;">(Under Review, Not Approved Yet)</div>\n							</div>\n						</div>\n						';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n						';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n						<div class="list media-list">\n							<ul>\n								';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.event.isPartOfASchedule, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n								<li class="item-content">\n									<div class="item-inner">\n										<div class="item-title-row">\n											<div class="item-title">All-day</div>\n											<div class="item-after">\n												<label class="toggle toggle-init toggle-allDay">\n													<input type="checkbox" id="allDay" name="allDay" value="yes" @click="checkedAllDay"';
              r += Template7Helpers.if.call(ctx_3, ctx_3.event.allDay, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'checked="checked"';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '><i class="toggle-icon"></i>\n												</label>\n											</div>\n										</div>\n									</div>\n								</li>\n								';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-title item-label">Starts</div>\n										<div class="item-input-wrap">\n											<input';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.event.allDay, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' style="display: none;"';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' type="date" id="startDate" name="startDate" value="';
          r += c(ctx_2.event.startDateValue, ctx_2);
          r += '" placeholder="Please choose...">\n											<input';
          r += Template7Helpers.if.call(ctx_2, ctx_2.event.allDay, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' style="display: none;"';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' type="datetime-local" id="startDateTime" name="startDateTime" value="';
          r += c(ctx_2.event.startDateTimeValue, ctx_2);
          r += '" placeholder="Please choose...">\n										</div>\n									</div>\n								</li>\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-title item-label">Ends</div>\n										<div class="item-input-wrap">\n											<input';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.event.allDay, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' style="display: none;"';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' type="date" id="endDate" name="endDate" value="';
          r += c(ctx_2.event.endDateValue, ctx_2);
          r += '" placeholder="Please choose...">\n											<input';
          r += Template7Helpers.if.call(ctx_2, ctx_2.event.allDay, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' style="display: none;"';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' type="datetime-local" id="endDateTime" name="endDateTime" value="';
          r += c(ctx_2.event.endDateTimeValue, ctx_2);
          r += '" placeholder="Please choose...">\n										</div>\n									</div>\n								</li>\n							</ul>\n						</div>\n						\n						<div class="list">\n							<ul>\n								<li class="item-content item-input">\n									<div class="item-inner">\n										<div class="item-input-wrap">\n											<textarea name="notes" placeholder="Notes">';
          r += c(ctx_2.event.notes, ctx_2);
          r += '</textarea>\n										</div>\n									</div>\n								</li>\n							</ul>\n						</div>\n						';
          r += Template7Helpers.if.call(ctx_2, ctx_2.eventId, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n						<div class="block">\n							<p><a href="#" class="link color-red" @click="deleteEvent">Delete Event</a></p>\n						</div>\n						';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						';
          r += Template7Helpers.if.call(ctx_2, ctx_2.event.isPartOfASchedule, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.event.isPartOfASchedule, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.event.isApproved, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.event.allDay, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.event.notes, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n						<div class="list no-margin no-hairlines no-safe-area-left">\n							<ul>\n								';
          r += Template7Helpers.with.call(ctx_2, ctx_2.calendar, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n								<li class="item-content">\n									<div class="event-color" style="background-color: ';
              r += c(ctx_3.color, ctx_3);
              r += ';"></div>\n									<div class="item-inner">\n										<div class="item-title" style="color: ';
              r += c(ctx_3.color, ctx_3);
              r += ';">';
              r += c(ctx_3.name, ctx_3);
              r += '</div>\n										<!--<div class="item-after">test</div>-->\n									</div>\n								</li>\n								';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n							</ul>\n						</div>\n						<div class="block-title block-title-large">';
          r += Template7Helpers.if.call(ctx_2, ctx_2.event.isPartOfASchedule, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.event._assignedEmployeeName, ctx_3);
              r += ': ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += c(ctx_2.event.title, ctx_2);
          r += '</div>\n						';
          r += Template7Helpers.if.call(ctx_2, ctx_2.event.isPartOfASchedule, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n						';
              r += Template7Helpers.if.call(ctx_3, ctx_3.event.isApproved, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						';
                  r += Template7Helpers.js_if.call(ctx_4, "(this.event.assignedEmployeeId !== this.event.approvedEmployeeId)", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n						<div class="block">\n							<div class="chip color-green">\n								<div class="chip-label" style="font-weight: bold;">Approved by: ';
                      r += c(ctx_5.event._approvedEmployeeName, ctx_5);
                      r += '</div>\n							</div>\n						</div>\n						';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n						';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						<div class="block">\n							<div class="chip color-red">\n								<div class="chip-label" style="font-weight: bold;">(Under Review, Not Approved Yet)</div>\n							</div>\n						</div>\n						';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n						';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						<div class="block block-strong">\n							<i class="icon f7-icons" style="float: left; padding-right: 0.5em;">calendar</i>\n							';
          r += Template7Helpers.if.call(ctx_2, ctx_2.event.allDay, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n							';
              r += Template7Helpers.js_if.call(ctx_3, "this.event.endDate == this.event.startDate", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n							<p class="no-margin-top">';
                  r += Template7Helpers.js.call(ctx_4, "moment(this.event.startDate).format('MMM Do')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' (All-day)</p>\n							';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n							<p class="no-margin-top">';
                  r += Template7Helpers.js.call(ctx_4, "moment(this.event.startDate).format('MMM Do')+' - '+moment(this.event.endDate).format('MMM Do YYYY')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</p>\n							';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n							';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n							<p class="no-margin-top">Starts: ';
              r += Template7Helpers.js.call(ctx_3, "moment(this.event.startDateTime).format('MMM Do, LT')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</p>\n							<p>Ends: ';
              r += Template7Helpers.js.call(ctx_3, "moment(this.event.endDateTime).format('MMM Do, LT')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</p>\n							';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n							<!--<p>';
          r += Template7Helpers.js.call(ctx_2, "JSON.stringify(this.event)", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p>-->\n						</div>\n						';
          r += Template7Helpers.if.call(ctx_2, ctx_2.event.notes, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n						<div class="block block-strong">\n							<p>';
              r += c(ctx_3.event.notesHTML, ctx_3);
              r += '</p>\n						</div>\n						';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						\n						';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					</form>\n					<!--\n					<div class="block block-strong">\n						<p>EVENT CALENDAR (If 1 then hard code it...)</p>\n					</div>\n					<div class="block block-strong">\n						<p>TITLE</p>\n					</div>\n					<div class="block block-strong">\n						<p>Description</p>\n					</div>\n					<div class="block block-strong">\n						<p>Start Date / Time or All Day</p>\n					</div>\n					<div class="block block-strong">\n						<p>End Date / Time or All Day</p>\n					</div>\n					-->\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.list .item-media.employeeNameItemMedia {
		font-weight: bold;
		font-size: var(--f7-input-font-size);
		padding-top: calc(var(--f7-list-item-padding-vertical) + (var(--f7-list-item-padding-vertical)/2));
	}

	.list .item-media.employeeNameItemMedia + .item-inner {
		margin-left: 0.2em;
	}

	.notYetApprovedText {
		color: #f00;
		opacity: 0.5;
	}

	#eventForm .list input[type="datetime-local"] {
		max-width: 100vw;
	}

`,
  styleScoped: false
};
    