
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;

    if (self.$app.data.taskMasterData.taskMasterTables) {
      self.tableList = self.$app.data.taskMasterData.taskMasterTables;
      self.maketableListTemplate();
    }
  },
  data: function () {
    var self = this;
    return {
      showAllTables: self.$root.user.id == 16,
      tableList: [],
      tableListTemplate: [],
      showAllTablesToggle: null,
      tableListIndex: null,
      lastLetter: ''
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'taskMasterTables') {
        self.tableList = data.taskMasterTables;
        self.refreshList();
      }
    },
    checkedShowAllTables: function (e) {
      var self = this;
      self.showAllTables = self.showAllTablesToggle.checked;
      self.refreshList();
    },
    maketableListTemplate: function () {
      var self = this;
      self.tableListTemplate = [];
      var lastLetter = '';

      for (var i = 0; i < self.tableList.length; i++) {
        if (self.showAllTables || self.tableList[i].showForEveryone) {
          var thisCapitalLetter = self.tableList[i].name.charAt(0).toUpperCase();

          if (thisCapitalLetter !== lastLetter) {
            self.tableListTemplate.push({
              isGroupTitle: true,
              letter: thisCapitalLetter,
              endPrevious: self.tableListTemplate.length > 0
            });
            lastLetter = thisCapitalLetter;
          }

          self.tableListTemplate.push(self.tableList[i]);
        }
      }
    },
    refreshList: function () {
      var self = this;
      self.maketableListTemplate();
      self.$setState({}, function () {
        //console.log('self.tableListTemplate',self.tableListTemplate);
        //console.log('self.tableListIndex',self.tableListIndex);
        if (self.tableListIndex) {
          self.tableListIndex.destroy();
        }

        self.tableListIndex = self.$app.listIndex.create({
          // ".list-index" element
          el: '.list-index',
          // List el where to look indexes and scroll for
          listEl: '.tablesList',
          // Generate indexes automatically based on ".list-group-title" and ".item-divider"
          indexes: 'auto',
          // Scroll list on indexes click and touchmove
          scrollList: true,
          // Enable bubble label when swiping over indexes
          label: true
        });
        window.tableListIndex = self.tableListIndex;
      });
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.showAllTablesToggle = self.$app.toggle.create({
        el: '.showAllTables',
        on: {
          change: self.checkedShowAllTables
        }
      });
    }
  },
  id: '99f69196bc',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="taskMasterTables">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">TaskMaster Tables</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.user.isAdmin, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<label class="toggle color-green showAllTables">\n						<input type="checkbox"';
          r += Template7Helpers.if.call(ctx_2, ctx_2.showAllTables, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' checked';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '>\n						<span class="toggle-icon"></span>\n					</label>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text">Tables</div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="list-index"></div>\n		<div class="page-content">\n			';
      r += Template7Helpers.js_if.call(ctx_1, "this.tableListTemplate.length == 0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="block block-strong">\n				<p>Loading list...</p>\n			</div>\n			';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="list links-list no-margin-top tablesList">\n				';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.showAllTables, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<ul>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				';
          r += Template7Helpers.each.call(ctx_2, ctx_2.tableListTemplate, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				';
              r += Template7Helpers.if.call(ctx_3, ctx_3.isGroupTitle, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n				';
                  r += Template7Helpers.if.call(ctx_4, root.showAllTables, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n				';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.endPrevious, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n					</ul>\n				</div>\n				';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '\n				<div class="list-group">\n					<ul>\n						<li class="list-group-title">';
                      r += c(ctx_5.letter, ctx_5);
                      r += '</li>\n				';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n				';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						<li><a href="/taskmaster/tables/';
                  r += c(ctx_4.number, ctx_4);
                  r += '/">';
                  r += c(ctx_4.name, ctx_4);
                  r += '</a></li>\n				';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n					</ul>\n				';
          r += Template7Helpers.if.call(ctx_2, ctx_2.showAllTables, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				</div>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n			</div>\n			';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.md .toggle.showAllTables {
		padding-right: 3em;
		
	}
`,
  styleScoped: false
};
    