
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;

    if (!self.$app.data.taskMasterData.currentLocationId) {
      self.$app.data.taskMasterData.currentLocationId = 11;
    }

    self.currentLocation = self.$root.taskMasterData.locations.filter(function (elem) {
      return elem.id == self.$app.data.taskMasterData.currentLocationId;
    })[0];
    self.loadingInvoiceNumber = self.currentLocation.retailInvoicePrefix + '-';
  },
  data: function () {
    return {
      isLoading: true,
      loadingInvoiceNumber: 'X-',
      currentLocation: {},
      invoiceNumber: '',
      leftSideData: {},
      lastPrintedTitle: '',
      currentListPrintedTitle: '',
      todayTooltip: null,
      todayDollarsTooltipString: 'Loading...'
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
    self.$app.on('loggedIn', self.loggedInEvent);
    self.$app.on('changedOnScreenInvoiceDetails', self.changedOnScreenInvoiceDetails); // self.$app.on('panelOpen', self.panelOpened);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
    self.$app.off('loggedIn', self.loggedInEvent);
    self.$app.off('changedOnScreenInvoiceDetails', self.changedOnScreenInvoiceDetails); // self.$app.off('panelOpen', self.panelOpened);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data') {
        if (data.data == 'todaySales' || data.data == 'invoice' || data.data == 'invoiceLoadError' || data.data == 'leftSideData' || data.data == 'todaySales') {
          if (data.data == 'invoice') {
            self.invoiceNumber = data.invoice.invoicenumber;
          } else if (data.data == 'invoiceLoadError') {
            self.invoiceNumber = '';
          } else if (data.data == 'leftSideData') {
            self.leftSideData = data.leftSideData;
            var tempListKeyNames = self.leftSideData.listKeyNames;

            for (var i = 0; i < tempListKeyNames.length; i++) {
              var currentKeyName = tempListKeyNames[i];
              var prevTitle = '';

              for (var j = 0; j < self.leftSideData[currentKeyName].length; j++) {
                var thisMomentString = 'No Date';

                if (self.leftSideData[currentKeyName][j].date !== '0000-00-00T00:00:00.000Z') {
                  thisMomentString = moment(self.leftSideData[currentKeyName][j].date).format('ddd L');
                }

                self.leftSideData[currentKeyName][j].titleDivider = thisMomentString !== prevTitle ? thisMomentString : '';
                prevTitle = thisMomentString;
              }
            }
          } else if (data.data == 'todaySales') {
            self.todayDollarsTooltipString = '';

            if (self.$app.data.taskMasterData && self.$app.data.taskMasterData.todaySales && self.$app.data.taskMasterData.todaySales.locations) {
              var showWholesale = self.$app.data.user.id == 16 || self.$app.data.user.department == 'Exec';
              var totalDollars = self.$app.data.taskMasterData.todaySales.todaySalesRetailString;

              if (showWholesale) {
                totalDollars = self.$app.data.taskMasterData.todaySales.todaySalesString;
              }

              var localLocations = self.$app.data.taskMasterData.todaySales.locations.filter(function (currElem) {
                return showWholesale || currElem.isRetail;
              });
              self.todayDollarsTooltipString = '<div style="text-align: center; font-size: 0.8em;"><span style="font-weight: bold; font-size: 1.4em;">' + totalDollars + '</span><br>';
              self.todayDollarsTooltipString += '(' + localLocations.length + ' locations @ ' + self.$app.data.taskMasterData.todaySales.timeString + ')</div>';
              self.todayDollarsTooltipString += '<table>';

              for (var i = 0; i < localLocations.length; i++) {
                var currLoc = localLocations[i];
                self.todayDollarsTooltipString += '<tr><td><nobr>' + currLoc.name + ' (' + currLoc.prefix + '):</td><td> ' + currLoc.todaySalesString + '</nobr></td></tr>';
              }

              self.todayDollarsTooltipString += '</table>';
            }
            /*
            {{#each $root.taskMasterData.todaySales.locations}}
            				{{#js_if "(this.@root.showAllLocations) || (this.isRetail)"}}
            				<tr style="cursor: pointer;" @click="loadLatestInvoice" data-locationLatestInvoice="{{latestInvoice}}">
            					<td class="label-cell">{{name}}</td>
            					<td class="numeric-cell">{{todaySalesString}}</td>
            				</tr>
            				{{/js_if}}
            				{{/each}}
            				*/

          }

          self.isLoading = false;
          self.refreshThisPage();
        }
      }
    },
    panelOpened: function (e, panel) {
      var self = this;
      console.log('Panel Opened!!!', e, panel); // mainScreenLeftPanel
    },
    changedOnScreenInvoiceDetails: function (data) {
      var self = this; // console.log('ON SCREEN INVOICE CHANGED!',data);

      if (data.locationId) {
        self.$app.data.taskMasterData.currentLocationId = data.locationId;
        self.currentLocation = self.$root.taskMasterData.locations.filter(function (elem) {
          return elem.id == self.$app.data.taskMasterData.currentLocationId;
        })[0];
        self.invoiceNumber = '';
      } else {
        if (!self.$app.data.taskMasterData.currentLocationId) {
          self.$app.data.taskMasterData.currentLocationId = 11;
        }
      }

      if (data.invoiceNumber) {
        self.invoiceNumber = data.invoiceNumber;
      }

      self.refreshThisPage();
    },
    loggedInEvent: function () {
      var self = this;
      self.isLoading = false;
      self.refreshThisPage();
    },
    invoiceBack: function () {
      var self = this; // console.log('GOT HERE');

      self.requestInvoice(self.invoiceNumber, -1);
    },
    invoiceForward: function () {
      var self = this;
      self.requestInvoice(self.invoiceNumber, 1);
    },
    openNewWindow: function () {
      var self = this;

      if (window.ipc) {
        if (window.ipc.send) {
          window.ipc.send('newInvoiceWindow', '');
        }
      }
    },
    openDCReports: function () {
      var self = this;

      if (window.ipc) {
        if (window.ipc.send) {
          window.ipc.send('openDCReportsWindow', '');
        }
      }
    },
    openSalesReports: function () {
      var self = this;

      if (window.ipc) {
        if (window.ipc.send) {
          window.ipc.send('openSalesReportsWindow', '');
        }
      }
    },
    requestInvoice: function (invoiceNumber, offset) {
      var self = this; // console.log('invoiceNumber',invoiceNumber);

      if (invoiceNumber == '') {
        invoiceNumber = self.currentLocation.retailInvoicePrefix + '-';
      }

      var splitInvoiceNumberArray = invoiceNumber.split('-'); // console.log('INVOICE SPLIT',splitInvoiceNumberArray);

      var prefix = splitInvoiceNumberArray[0];

      if (splitInvoiceNumberArray.length == 1 || splitInvoiceNumberArray[1] == '' || splitInvoiceNumberArray[1] == 'LATEST') {
        self.loadingInvoiceNumber = prefix + '-LATEST';
        invoiceNumber = self.loadingInvoiceNumber;
        offset = -1; // SHOULD BE NEGATIVE 1!
      } else {
        var invoiceNumberNumsOnly = parseInt(splitInvoiceNumberArray[1]);
        self.loadingInvoiceNumber = prefix + '-' + (invoiceNumberNumsOnly + offset);
      } // console.log('self.loadingInvoiceNumber',self.loadingInvoiceNumber);


      self.isLoading = true;
      self.$setState({}, function () {
        // TODO: need a better if statement...
        // console.log('mainViewURL',self.$app.data.mainView.router.url,self.$app.data.mainView.router.url.indexOf('/taskmaster/invoices/'),'/taskmaster/invoices/'+self.loadingInvoiceNumber+'/');
        if (self.$app.data.mainView.router.url.indexOf('/taskmaster/invoices/') < 0) {
          // IF THE MAINVIEW IS NOT ON THE INVOICE SCREEN...!
          window.lastEventNameAndPage = ''; // This okay?  I think so... Forces it to load the page we are asking for...

          self.$app.data.mainView.router.navigate('/taskmaster/invoices/' + self.loadingInvoiceNumber + '/', {
            animate: false
          });
        } else {
          self.$root.sendStatus('/taskmaster/invoices/' + self.loadingInvoiceNumber + '/', {
            invoiceOffset: offset,
            invoiceNumber: invoiceNumber
          });
        }
      });
    },
    refreshThisPage: function () {
      var self = this; // console.log('REFRESHING LEFT SIDE!');

      self.$setState({}, function () {
        self.todayTooltip.setText(self.todayDollarsTooltipString);
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      self.todayTooltip = self.$app.tooltip.create({
        targetEl: '.todaySpanBox',
        text: 'Loading...',
        on: {
          show: function () {
            self.$root.mdWebSocket.sendOverWebSocket({
              action: 'todaySales'
            });
          }
        }
      });
    },
    pageBeforeIn: function (e, page) {
      var self = this; // TODO: is this the wrong event...?

      self.refreshThisPage();
    }
  },
  id: '75e8e12c05',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page no-navbar leftPanelPage">\n		<div class="above-page-content">\n			<div class="block">\n				<div class="row">\n					<div class="col-50">\n						<div class="row">\n							<div class="col-100">\n								<a href="/taskmaster/search/" class="button button-raised button-large no-padding button-fill color-white"><i class="icon f7-icons if-not-md">search</i><i class="icon material-icons md-only">search</i><span>Search</span></a>\n							</div>\n						</div>\n						<div class="row" style="--f7-grid-row-gap: 16px;">\n							<div class="col minimizeCol">\n								<button class="button button-raised no-padding button-fill color-white">Minimize</button>\n							</div>\n							<div class="col">\n								';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$app.device.desktop, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n								<a href="#" class="button button-raised panel-close no-padding button-fill color-white" @click="openNewWindow">New</a>\n								';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n								<a href="/taskmaster/invoices/new/" class="button button-raised panel-close no-padding button-fill color-white" data-view=".view-main" data-animate="false">New</a>\n								';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n							</div>\n						</div>\n					</div>\n					<div class="col-50" style="text-align: center;">\n						<div class="row">\n							<div class="col-50">\n								<button class="button button-raised no-padding button-fill color-white';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isLoading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" @click="invoiceBack"><i class="icon f7-icons">arrow_left</i></button>\n							</div>\n							<div class="col-50">\n								<button class="button button-raised no-padding button-fill color-white mainRightInvoiceButton';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.invoiceNumber, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' disabled';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isLoading, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" @click="invoiceForward"><i class="icon f7-icons">arrow_right</i></button>\n							</div>\n						</div>\n						<span class="invoiceNumberTitle">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isLoading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<span style="opacity: 0.5;">';
          r += c(ctx_2.loadingInvoiceNumber, ctx_2);
          r += '</span>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.invoiceNumber, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.if.call(ctx_2, ctx_2.invoiceNumber, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.invoiceNumber, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.currentLocation.retailInvoicePrefix, ctx_3);
              r += '-';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</span>\n						<div class="row">\n							<div class="col-100"><span class="todaySpanBox">Today <a href="/reports/" class="panel-close icon-only" data-view=".view-main" data-animate="false"><i class="icon f7-icons">arrow_right_circle</i></a></span></div>\n						</div>\n					</div>\n				</div>\n			</div>\n		</div>\n		<div class="page-content leftSidePageContent">\n			<div class="block no-margin extraButtons">\n				<div class="row">\n					<div class="col-100 medium-50">\n						<div class="block-title no-margin">Quick Actions</div>\n						<div class="row" style="--f7-grid-row-gap: 16px;">\n							<div class="col">\n								<button class="button button-raised no-padding button-fill color-white">Gift Cards</button>\n							</div>\n							<div class="col">\n								<button class="button button-raised no-padding button-fill color-white">Print Delivery</button>\n							</div>\n						</div>\n						<div class="row" style="--f7-grid-row-gap: 16px;">\n							<div class="col">\n								<button class="button button-raised no-padding button-fill color-white">Open Invoices</button>\n							</div>\n							<div class="col">\n								<button class="button button-raised no-padding button-fill color-white">Transfers</button>\n							</div>\n						</div>\n					</div>\n					<div class="col-100 medium-50">\n						<div class="block-title no-margin">Reports</div>\n						<div class="row" style="--f7-grid-row-gap: 16px;">\n							<div class="col">\n								';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$app.device.desktop, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n								<a href="#" class="button button-raised panel-close no-padding button-fill color-white" @click="openDCReports">DC Reports</a>\n								';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n								<a href="/reports/dc/" class="button button-raised panel-close no-padding button-fill color-white" data-view=".view-main" data-animate="false">DC Reports</a>\n								';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n							</div>\n							<div class="col">\n								';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$app.device.desktop, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n								<a href="#" class="button button-raised panel-close no-padding button-fill color-white" @click="openSalesReports">Sales Reports</a>\n								';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n								<a href="/reports/sales/" class="button button-raised panel-close no-padding button-fill color-white" data-view=".view-main" data-animate="false">Sales Reports</a>\n								';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n							</div>\n						</div>\n						<div class="row" style="--f7-grid-row-gap: 16px;">\n							<div class="col">\n								<button class="button button-raised no-padding button-fill color-white">Spotlight/In-Stock Items</button>\n							</div>\n						</div>\n					</div>\n				</div>\n			</div>\n			<div class="block blockLists">\n				<div class="row">\n					<div class="col-100 medium-50" style="min-width: 20px"><!-- resizable? -->\n						<div class="block-title no-margin">Deliveries</div>\n						<div class="list links-list deliverySalesList no-margin no-chevron">\n							<ul>\n								';
      r += Template7Helpers.each.call(ctx_1, ctx_1.leftSideData.deliverySales, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n								';
          r += Template7Helpers.if.call(ctx_2, ctx_2.titleDivider, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class="item-divider">';
              r += c(ctx_3.titleDivider, ctx_3);
              r += '</li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								<li class="invoiceLi"><a href="/taskmaster/invoices/';
          r += c(ctx_2.invoiceNumber, ctx_2);
          r += '/" class="panel-close';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isTentative, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' isTentative';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" data-view=".view-main" data-animate="false">';
          r += c(ctx_2.customerLastName, ctx_2);
          r += ', ';
          r += c(ctx_2.customerFirstName, ctx_2);
          r += '</a></li>\n								';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n							</ul>\n						</div>\n						<!--<span class="resize-handler"></span>-->\n					</div>\n					<div class="col-100 medium-50" style="min-width: 20px"><!-- resizable? -->\n						<div class="block-title no-margin">Pickups</div>\n						<div class="list links-list pickupSalesList no-margin no-chevron">\n							<ul>\n								';
      r += Template7Helpers.each.call(ctx_1, ctx_1.leftSideData.pickupSales, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n								';
          r += Template7Helpers.if.call(ctx_2, ctx_2.titleDivider, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class="item-divider">';
              r += c(ctx_3.titleDivider, ctx_3);
              r += '</li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								<li class="invoiceLi"><a href="/taskmaster/invoices/';
          r += c(ctx_2.invoiceNumber, ctx_2);
          r += '/" class="panel-close" data-view=".view-main" data-animate="false">';
          r += c(ctx_2.customerLastName, ctx_2);
          r += ', ';
          r += c(ctx_2.customerFirstName, ctx_2);
          r += '</a></li>\n								';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n							</ul>\n						</div>\n						<div class="block-title no-margin">Shipping</div>\n						<div class="list links-list shippingSalesList no-margin no-chevron">\n							<ul>\n								';
      r += Template7Helpers.each.call(ctx_1, ctx_1.leftSideData.shippingSales, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n								';
          r += Template7Helpers.if.call(ctx_2, ctx_2.titleDivider, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class="item-divider">';
              r += c(ctx_3.titleDivider, ctx_3);
              r += '</li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								<li class="invoiceLi"><a href="/taskmaster/invoices/';
          r += c(ctx_2.invoiceNumber, ctx_2);
          r += '/" class="panel-close" data-view=".view-main" data-animate="false">';
          r += c(ctx_2.customerLastName, ctx_2);
          r += ', ';
          r += c(ctx_2.customerFirstName, ctx_2);
          r += '</a></li>\n								';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n							</ul>\n						</div>\n						<div class="block-title no-margin">Service</div>\n						<div class="list links-list serviceSalesList no-margin no-chevron">\n							<ul>\n								';
      r += Template7Helpers.each.call(ctx_1, ctx_1.leftSideData.serviceSales, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n								';
          r += Template7Helpers.if.call(ctx_2, ctx_2.titleDivider, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class="item-divider">';
              r += c(ctx_3.titleDivider, ctx_3);
              r += '</li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								<li class="invoiceLi"><a href="/taskmaster/invoices/';
          r += c(ctx_2.invoiceNumber, ctx_2);
          r += '/" class="panel-close" data-view=".view-main" data-animate="false">';
          r += c(ctx_2.customerLastName, ctx_2);
          r += ', ';
          r += c(ctx_2.customerFirstName, ctx_2);
          r += '</a></li>\n								';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n							</ul>\n						</div>\n						<!--<span class="resize-handler"></span>-->\n					</div>\n				</div>\n			</div>\n			<!--\n			<div class="block-title">Left View Navigation</div>\n			<div class="list links-list">\n				<ul>\n					<li><a href="/left-page-1/">Left Page 1</a></li>\n					<li><a href="/left-page-2/">Left Page 2</a></li>\n				</ul>\n			</div>\n			<div class="block-title">Control Main View</div>\n			<div class="list links-list">\n				<ul>\n					<li>\n						<a href="/about/" data-view=".view-main" class="panel-close">About</a>\n					</li>\n					<li>\n						<a href="/form/" data-view=".view-main" class="panel-close">Form</a>\n					</li>\n					<li>\n						<a href="#" data-view=".view-main" class="back">Back in history</a>\n					</li>\n				</ul>\n			</div>\n			-->\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.todaySpanBox {
		display: inline-block;
		background: #fff;
		border: 2px solid #000;
		padding: 0.25em 1em;
	}

	.panel-left.panel-in-breakpoint.mainScreenLeftPanel .above-page-content {
		padding-top: 0px; /*calc(0px + 0px + 0px + 0px + 0px); */
    	padding-top: calc(var(--f7-page-navbar-offset, 0px) + var(--f7-page-toolbar-top-offset, 0px) + var(--f7-page-subnavbar-offset, 0px) + var(--f7-page-searchbar-offset, 0px) + var(--f7-page-content-extra-padding-top, 0px));
	}

	.panel-left.mainScreenLeftPanel .blockLists .block-title {
		margin-left: 0px;
	}

	.panel-left.mainScreenLeftPanel .block-title, .panel-left.mainScreenLeftPanel .invoiceNumberTitle {
		margin-top: 0px;
		color: var(--bnm-invoice-left-text-color);
	}

	.panel-left.mainScreenLeftPanel a.button-fill.color-white, .panel-left.mainScreenLeftPanel button.button-fill.color-white {
		color: #000;
	}

	.panel-left.mainScreenLeftPanel .blockLists .row .medium-50 {
		--f7-cols-per-row: 1;
	}
	
	.panel-left.panel-in-breakpoint.mainScreenLeftPanel .blockLists .row .medium-50 {
		--f7-cols-per-row: 2;
	}

	.page-content.leftSidePageContent {
		padding-top: 0px;
		padding-bottom: 150px;
	}
	
	.panel-left.panel-in-breakpoint.mainScreenLeftPanel .page-content.leftSidePageContent {
		overflow: hidden;
	}

	.mainScreenLeftPanel .button {
		text-overflow: clip;
	}

	.mainScreenLeftPanel .extraButtons .button {
		font-size: 0.8em;
	}

	.panel-left.panel-in-breakpoint.mainScreenLeftPanel .deliverySalesList,
		.panel-left.panel-in-breakpoint.mainScreenLeftPanel .pickupSalesList,
			.panel-left.panel-in-breakpoint.mainScreenLeftPanel .shippingSalesList,
				.panel-left.panel-in-breakpoint.mainScreenLeftPanel .serviceSalesList {
		overflow: hidden;
		overflow-y: scroll;
		background: #fff;
	}

	.panel-left.panel-in-breakpoint.mainScreenLeftPanel .deliverySalesList {
		height: calc(100vh - 280px);

	}

	.panel-left.panel-in-breakpoint.mainScreenLeftPanel .pickupSalesList,
		.panel-left.panel-in-breakpoint.mainScreenLeftPanel .shippingSalesList,
			.panel-left.panel-in-breakpoint.mainScreenLeftPanel .serviceSalesList {
		height: calc(((100vh - 280px) / 3.63)); /* TODO: this should be way better to match the delivery list height */
	}

	.deliverySalesList a.isTentative {
		background: #ff6;

	}

	.leftSidePageContent li.item-divider, .leftSidePageContent .links-list a {
		padding: 3px;
		height: auto;
	}

	.leftSidePageContent .links-list a { /* all of them */
		padding-left: 1em;
	}

	.leftSidePageContent li.item-divider {
		position: relative;
		position: -webkit-sticky;
		position: sticky;
		top: 0px;
		z-index: 20;
		font-size: smaller;
		background: #fff;
		color: #000;
		font-weight: bold;
		border-top: 1px solid #666;
		border-bottom: 1px solid #666;
	}


`,
  styleScoped: false
};
    