
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {
      numberOfUnreadMessages: 0,
      conversations: []
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'conversations') {
        self.refreshList();
      } else if (data.type == 'data' && data.event == 'typing' && data.employeeId !== self.$root.user.id) {
        self.$el.find('.conversation' + data.conversationId).html('<em>' + data.employeeName + ' is typing...</em>');
      } else if (data.type == 'data' && data.event == 'doneTyping' && data.employeeId !== self.$root.user.id) {
        var indexOfConversation = self.$root.taskMasterData.conversations.map(function (e) {
          return e.id;
        }).indexOf(data.conversationId);
        self.$el.find('.conversation' + data.conversationId).html(self.$root.taskMasterData.conversations[indexOfConversation].recentMessages[0].message);
      }
    },
    refreshList: function () {
      var self = this;
      var $$ = self.$;
      self.numberOfUnreadMessages = 0; // TODO! self.$root.taskMasterData.messages.unreadMessages;

      self.conversations = self.$root.taskMasterData.conversations.slice();
      var htmlData = '<div class="list media-list conversationMediaLists">' + '<ul>';

      for (var i = 0; i < self.conversations.length; i++) {
        var thisConversation = self.conversations[i];
        var thisRecentMessage = '&nbsp;';

        if (thisConversation.recentMessages.length > 0) {
          if (thisConversation.recentMessages[0].messageType.toLowerCase() == 'sticker') {
            thisRecentMessage = '&lt;sticker&gt;';
          } else {
            thisRecentMessage = thisConversation.recentMessages[0].message;
          }
        }

        var thisImageId = thisConversation.members.length > 0 ? thisConversation.members[0].id : 'BLANK';
        var thisConversationTitle = thisConversation.membersString; // thisImageId

        var thisConversationMedia = '<div class="image-crop-circle" style="height: 44px; width: 44px; background: #fff url(\'' + self.$root.getImageURL(thisImageId, 'jpg', 'TODO') + '\') top center; background-size: cover;"></div>';

        if (thisConversation.newMessageStatusBool) {
          thisConversationMedia = '<span class="badge color-blue badge-empty">&nbsp;</span>' + thisConversationMedia;
        } else {
          thisConversationMedia = '<span class="badge color-white badge-empty">&nbsp;</span>' + thisConversationMedia;
        }

        htmlData += '<li><a href="/messages/' + thisConversation.id + '/' + encodeURIComponent(thisConversation.membersString) + '/" class="item-link item-content"><div class="item-media">' + thisConversationMedia + '</div><div class="item-inner"><div class="item-title-row"><div class="item-title">' + thisConversationTitle + '</div><div class="item-after">' + thisConversation.relativeDateTime + '</div></div><div class="item-text conversation' + thisConversation.id + '">' + thisRecentMessage + '</div></div></a></li>';
      }

      htmlData += '</ul>' + '</div>';
      self.$el.find('.conversationLists').html(htmlData);
      var newPageTitle = 'Messages';

      if (self.numberOfUnreadMessages > 0) {
        newPageTitle += ' (' + self.numberOfUnreadMessages + ')';
      }

      $$('.conversationListTitle').html(newPageTitle);
      var currentNavbar = self.$app.navbar.getElByPage(self.$el);
      self.$app.navbar.size(currentNavbar);
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.refreshList();
    }
  },
  id: '76705f0522',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="blankPage">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title conversationListTitle">Messages</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<a href="#" class="link icon-only disabled">\n						<i class="icon f7-icons if-not-md">square_pencil</i>\n						<i class="icon material-icons md-only">create</i>\n					</a>\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text">Employee <span class="conversationListTitle">Messages</span></div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="page-content">\n			<div class="conversationLists"></div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.conversationMediaLists {
		margin-top: 0px;
		
	}
	
	.conversationMediaLists .item-link.item-content {
		padding-left: 8px;
		
	}
`,
  styleScoped: false
};
    