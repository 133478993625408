
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.tableNumber = parseInt(self.$route.params.tableNumber);
    var indexOfTableElement = self.$app.data.taskMasterData.taskMasterTables.map(function (elem) {
      return elem.number;
    }).indexOf(self.tableNumber);
    self.tableDetails = self.$app.data.taskMasterData.taskMasterTables[indexOfTableElement];
    self.$app.data.pageStatusData = {
      getRecords: true
    };
  },
  data: function () {
    var self = this;
    return {
      tableNumber: 0,
      tableDetails: {},
      recordsKeys: [],
      recordsList: [],
      loadedRecords: false
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'taskMasterTableRecords') {
        if (data.done == true) {
          self.loadedRecords = true;
        }

        self.recordsList = self.$app.data.taskMasterData.taskMasterTableRecords;
        self.buildTemplateVariables();
        self.$setState();
      }
    },
    buildTemplateVariables: function () {
      var self = this;

      if (self.recordsList.length > 0) {
        self.recordsKeys = Object.keys(self.recordsList[0]);
        self.recordsKeys = self.recordsKeys.filter(function (elem) {
          return elem.indexOf('f7') !== 0;
        }); // console.log(self.recordsKeys);

        for (var i = 0; i < self.recordsList.length; i++) {
          if (false) {} else {
            // DEFAULT IF NOT SPECIFICALLY CHOSEN... TODO: what if the table has less than 5 fields...
            self.recordsList[i].f7title = self.recordsList[i][self.recordsKeys[1]];
            self.recordsList[i].f7after = self.recordsList[i][self.recordsKeys[2]];
            self.recordsList[i].f7subtitle = self.recordsList[i][self.recordsKeys[3]];
            self.recordsList[i].f7text = '';

            for (var j = 4; j < self.recordsKeys.length; j++) {
              if (j > 4) {
                self.recordsList[i].f7text += ' ';
              }

              self.recordsList[i].f7text += self.recordsList[i][self.recordsKeys[j]];
            }
          }
        } // console.log(self.recordsList);

      }
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
    }
  },
  id: 'ab678cddd4',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="taskMasterTablesOutput">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">';
      r += c(ctx_1.tableDetails.name, ctx_1);
      r += ' Table</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n			</div>\n		</div>\n		<div class="page-content">\n			<div class="block block-strong no-margin">\n				<p class="row">\n					<a href="#" class="col button button-large button-raised button-fill"><i class="icon f7-icons">search</i><span>&nbsp;&nbsp;Search All Records</span></a>\n				</p>\n			</div>\n			<div class="list media-list no-margin-top recordsList">\n				<ul>\n					<li class="item-divider">';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.loadedRecords, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'Loading Records...';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.js.call(ctx_2, "this.recordsList.length", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' Recent Records';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</li>\n					';
      r += Template7Helpers.each.call(ctx_1, ctx_1.recordsList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<li>\n						<a href="/taskmaster/tables/';
          r += c(root.tableNumber, ctx_2);
          r += '/';
          r += c(ctx_2.ID, ctx_2);
          r += '/" class="item-link item-content">\n							<!--<div class="item-media">\n								';
          r += c(data_2 && data_2.index, ctx_2);
          r += '\n							</div>-->\n							<div class="item-inner">\n								<div class="item-title-row">\n									<div class="item-title">';
          r += c(ctx_2.f7title, ctx_2);
          r += '</div>\n									<div class="item-after">';
          r += c(ctx_2.f7after, ctx_2);
          r += '</div>\n								</div>\n								<div class="item-subtitle">';
          r += c(ctx_2.f7subtitle, ctx_2);
          r += '</div>\n								<div class="item-text">';
          r += c(ctx_2.f7text, ctx_2);
          r += '</div>\n							</div>\n						</a>\n					</li>					\n					\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</ul>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `

`,
  styleScoped: false
};
    