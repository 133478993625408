
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.pickDateCalendarModal = self.$app.calendar.create({
      scrollToInput: false,
      routableModals: false,
      openIn: 'customModal',
      header: true,
      footer: true
    }); //self.startDate= self.$app.methods.getFirstDayOfTheWeek(self.today);
    //self.endDate= self.$app.methods.getLastDayOfTheWeek(self.today);

    self.buildDatesArray();
    self.$app.data.pageStatusData = {
      startDate: self.startDate,
      endDate: self.endDate
    }; // console.log('Department',self.$app.data.user.department);

    self.calendarId = 1; // SALES... TODO: set this to the proper schedule...
    // TODO: do not hardcode this... use the database

    if (self.$app.data.user.department == 'Sales') {
      self.calendarId = 1;
    } else if (self.$app.data.user.department == 'Exec') {
      if (self.$app.data.user.id == 4) {
        // Jeff
        self.calendarId = 1; // SALES
      } else if (self.$app.data.user.id == 6) {
        // Wanda
        self.calendarId = 1; // SALES
      } else if (self.$app.data.user.id == 8) {
        // Drew
        self.calendarId = 1; // 49 = Creative Team
      } else if (self.$app.data.user.id == 1) {
        // Blake
        self.calendarId = 1; // D.C.
      } else if (self.$app.data.user.id == 5) {
        // Thane
        self.calendarId = 1; // SALES
      } else {
        self.calendarId = 50;
      }
    } else if (self.$app.data.user.department == 'Admin' || self.$app.data.user.department == 'Sanitation' || self.$app.data.user.department == 'IT') {
      self.calendarId = 48;
    } else if (self.$app.data.user.department == 'Dist. Center') {
      self.calendarId = 2;
    } else if (self.$app.data.user.department == 'Creative Team') {
      self.calendarId = 49;
    }

    if (self.$app.data.taskMasterData && self.$app.data.taskMasterData.schedulesCalendars) {
      var currentCalendarIndex = self.$app.data.taskMasterData.schedulesCalendars.map(function (elem) {
        return elem.id;
      }).indexOf(self.calendarId);

      if (currentCalendarIndex > -1) {
        var currentCalendarDepartmentNames = self.$app.data.taskMasterData.schedulesCalendars[currentCalendarIndex].departments.map(function (elem) {
          return elem.name;
        });
        self.employees = self.employees.filter(function (elem) {
          return currentCalendarDepartmentNames.indexOf(elem.department) > -1 || employeesIdsFoundInShifts.indexOf(elem.id) > -1;
        });
      }
    }

    if (self.$app.data.taskMasterData && self.$app.data.taskMasterData.schedules) {
      delete self.$app.data.taskMasterData.schedules;
    } // self.$app.data.user.department;


    self.screenIsLarge = self.$(window).width() >= 1024; // self.showAllDaysOfTheWeek= self.screenIsLarge;

    console.log('self.screenIsLarge', self.screenIsLarge, self.$(window).width());
    /*
    if (self.$app.data.user.department !== 'IT') {
    	if (self.$app.data.user.department == 'Sanitation') {
    		self.department= 'Admin';
    	} else {
    		self.department= self.$app.data.user.department;
    	}
    }
    */
    // console.log('self.currentScheduleReference',self.currentScheduleReference);
  },
  data: function () {
    var self = this;
    return {
      loading: true,
      editing: false,
      showAllDaysOfTheWeek: false,
      showAllDaysToggle: null,
      screenIsLarge: false,
      stickyNames: false,
      // TODO: get this working...
      scheduleReference: 'me',
      pageTitle: 'Schedule',
      pickDateCalendarModal: null,
      showAllEmployees: false,
      calendarId: -1,
      isStarted: false,
      isFinalized: false,
      headerTitle: 'Current Week',
      statusButtonText: 'Status: Not Started',
      outsideScheduleSave: false,
      pickWeekPopover: null,
      moveOrCopyPopover: null,
      moveOrCopyPopoverFromDetails: {},
      moveOrCopyPopoverToDetails: {},
      currentWeekNumber: -1,
      currentScheduleReference: '',
      currentWeekIncludesToday: false,
      today: moment().toDate(),
      startDate: moment().startOf('week').toDate(),
      endDate: moment().endOf('week').toDate(),
      turnOffOldCode: false,
      datesArray: [],
      displayDatesArray: [],
      // For filtering to current day...
      employeesHoursTotals: 0,
      year: '2019',
      employees: [],
      windowResizeTimer: null,
      isLoadingEditors: false
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived); //if (self.stickyNames) {

    self.$(window).on('resize', self.windowResized); //}
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived); //if (self.stickyNames) {

    self.$(window).off('resize', self.windowResized); //}
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'schedules' || data.type == 'data' && data.data == 'employees') {
        //console.log('data',data);
        //console.log('self.currentScheduleReference',self.currentScheduleReference,data.schedulesReferences);
        //if (data.schedulesReferences.indexOf(self.currentScheduleReference) > -1) {
        // The current week we are looking at was updated remotely!  Refresh the page!
        // Just got a new schedule for the current week...?
        // self.outsideScheduleSave= true;
        self.refreshPage(); //}
      }
    },
    editorChanged: function (e) {
      var self = this;

      if (!self.isLoadingEditors) {
        // If LOADING... then don't trigger a change...
        //var newText= e.detail;
        //var tempTest= self.$(e.target);
        var textEditorElement = self.$app.textEditor.get(e.target);
        var newText = textEditorElement.value; //e.detail;

        var dayIndexString = textEditorElement.$el.attr('data-dayIndex');
        var isEveryoneNotes = textEditorElement.$el.hasClass('text-editor-everyone-notes'); //console.log('Got here! EDITORCHANGED',newText);
        //console.log('Got here! isEveryoneNotes',isEveryoneNotes);
        //console.log('Got here! clickedDayIndexString',dayIndexString);

        if (self.sendChangeNotesDebounceTimeout) {
          clearTimeout(self.sendChangeNotesDebounceTimeout);
        }

        self.sendChangeNotesDebounceTimeout = setTimeout(function () {
          self.editorChangedSendToTaskMaster(dayIndexString, newText, isEveryoneNotes);
        }, 500);
      }
    },
    editorChangedSendToTaskMaster: function (dayIndex, notes, isEveryoneNotes) {
      var self = this;
      self.$root.mdWebSocket.sendOverWebSocket({
        action: 'scheduleAddChangeDayNotes',
        calendarId: self.calendarId,
        weekNumber: self.currentWeekNumber[1],
        weekNumberYear: self.currentWeekNumber[0],
        weekStartDate: self.startDate,
        weekEndDate: self.endDate,
        dayIndex: dayIndex,
        isEveryoneNotes: isEveryoneNotes,
        notes: notes
      });
    },
    getCurrentCalendarNotes: function () {
      var self = this;

      if (self.currentScheduleObject) {
        if (self.currentScheduleObject.notes) {
          if (self.currentScheduleObject.notes['' + self.calendarId]) {
            return self.currentScheduleObject.notes['' + self.calendarId];
          }
        }
      }

      return '';
    },
    buildDatesArray: function () {
      var self = this;
      var currentStatusObject;

      if (self.currentScheduleObject && self.currentScheduleObject.weeklySchedulesStatus && self.currentScheduleObject.weeklySchedulesStatus[self.calendarId]) {
        currentStatusObject = self.currentScheduleObject.weeklySchedulesStatus[self.calendarId];

        if (!currentStatusObject.objectData) {
          currentStatusObject.objectData = {};
        }

        if (!currentStatusObject.objectData.allSchedulesNotesArray) {
          currentStatusObject.objectData.allSchedulesNotesArray = ['', '', '', '', '', '', '']; // New empty text array...
        }

        if (!currentStatusObject.objectData.notesArray) {
          currentStatusObject.objectData.notesArray = ['', '', '', '', '', '', '']; // New empty text array...
        }
      } // console.log('buildDatesArray',self.startDate,currentStatusObject);


      self.currentWeekNumber = self.$app.methods.getWeekNumber(self.startDate);
      self.currentScheduleReference = 'week' + self.currentWeekNumber[1] + '_' + self.currentWeekNumber[0];
      self.datesArray = [{
        dayName: 'Sunday',
        date: moment(self.startDate),
        isToday: false,
        isInThePast: false,
        totalHours: 0
      }, {
        dayName: 'Monday',
        date: moment(self.startDate).add(1, 'days'),
        isToday: false,
        isInThePast: false,
        totalHours: 0
      }, {
        dayName: 'Tuesday',
        date: moment(self.startDate).add(2, 'days'),
        isToday: false,
        isInThePast: false,
        totalHours: 0
      }, {
        dayName: 'Wednesday',
        date: moment(self.startDate).add(3, 'days'),
        isToday: false,
        isInThePast: false,
        totalHours: 0
      }, {
        dayName: 'Thursday',
        date: moment(self.startDate).add(4, 'days'),
        isToday: false,
        isInThePast: false,
        totalHours: 0
      }, {
        dayName: 'Friday',
        date: moment(self.startDate).add(5, 'days'),
        isToday: false,
        isInThePast: false,
        totalHours: 0
      }, {
        dayName: 'Saturday',
        date: moment(self.startDate).add(6, 'days'),
        isToday: false,
        isInThePast: false,
        totalHours: 0
      }];
      var todayMoment = moment();

      for (var i = 0; i < self.datesArray.length; i++) {
        if (self.datesArray[i].date.isSame(todayMoment, 'day')) {
          self.datesArray[i].isToday = true;
          break;
        }

        if (self.datesArray[i].date.isBefore(todayMoment, 'day')) {
          self.datesArray[i].isInThePast = true;
        }
      }

      for (var i = 0; i < self.datesArray.length; i++) {
        //console.log('self.datesArray LOOP '+i+' BEFORE IF STATEMENT',currentStatusObject);
        if (currentStatusObject && currentStatusObject.objectData) {
          //console.log('self.datesArray LOOP '+i,currentStatusObject.objectData.notesArray[i]);
          self.datesArray[i].notes = currentStatusObject.objectData.notesArray[i];
          self.datesArray[i].everyoneNotes = ''; //currentStatusObject.objectData.allSchedulesNotesArray[i];
        }
      } // console.log('self.datesArray',self.datesArray);


      self.isStarted = false;
      self.isFinalized = false;
      self.currentScheduleObject = {};
      var currentStatusObject = {};

      if (self.$app.data.taskMasterData.schedules) {
        if (self.$app.data.taskMasterData.schedules[self.currentScheduleReference]) {
          self.currentScheduleObject = self.$app.data.taskMasterData.schedules[self.currentScheduleReference];

          if (self.currentScheduleObject.weeklySchedulesStatus[self.calendarId]) {
            currentStatusObject = self.currentScheduleObject.weeklySchedulesStatus[self.calendarId];

            if (currentStatusObject.isFinalized) {
              self.isFinalized = true;
            } else if (currentStatusObject.isStarted) {
              self.isStarted = true;
            }
          }
        }
      }
    },
    openCalendarModal: function (afterPickedDate) {
      // TODO: add options...
      var self = this;
      afterPickedDate = typeof afterPickedDate == 'function' ? afterPickedDate : function () {};
      self.pickDateCalendarModal.once('change', function (calendar, value) {
        afterPickedDate(calendar, value);
      });
      self.pickDateCalendarModal.open();
    },
    refreshPage: function (onSuccessFunction) {
      var self = this;
      onSuccessFunction = typeof onSuccessFunction == 'function' ? onSuccessFunction : function () {};
      self.buildDatesArray();
      console.log('self.currentScheduleReference', self.currentScheduleReference);

      if (self.$app.data.taskMasterData && self.$app.data.taskMasterData.employees) {
        if (self.$app.data.taskMasterData.schedules && self.$app.data.taskMasterData.schedules[self.currentScheduleReference]) {
          console.log('INSIDE if statement');
          self.screenIsLarge = self.$(window).width() >= 1024;

          if (self.screenIsLarge) {
            self.showAllDaysOfTheWeek = true;
          }

          self.buildDatesArray();

          if (!self.isFinalized) {
            if (self.$app.data.user.isAdmin) {
              self.showAllDaysOfTheWeek = true;
            }
          }

          var calendarName = '';

          if (self.$app.data.taskMasterData && self.$app.data.taskMasterData.schedulesCalendars) {
            var currentCalendarIndex = self.$app.data.taskMasterData.schedulesCalendars.map(function (elem) {
              return elem.id;
            }).indexOf(self.calendarId);

            if (currentCalendarIndex > -1) {
              calendarName = self.$app.data.taskMasterData.schedulesCalendars[currentCalendarIndex].name;
            }
          }

          self.pageTitle = calendarName.replace(' Schedule', '');

          if (false) {
            // TODO: utilize who made the change... if it's someone different check if finalized... // (self.outsideScheduleSave) {
            if (self.isFinalized) {
              self.editing = false;
            }

            self.outsideScheduleSave = false;
          }

          self.employees = self.$app.data.taskMasterData.employees.map(function (elem) {
            var newEmployeeObject = {
              id: elem.id,
              name: elem.name,
              department: elem.department,
              isClockedIn: elem.isClockedIn,
              clockedInDatetime: elem.clockedInDatetime,
              clockedOutDatetime: elem.clockedOutDatetime,
              nextShift: elem.nextShift,
              nextShiftText: elem.nextShiftText,
              nextShiftBadge: elem.nextShiftBadge
            };
            return newEmployeeObject;
          });
          var indexOfHouse = self.employees.map(function (elem) {
            return elem.name;
          }).indexOf('House');

          if (indexOfHouse > -1) {
            self.employees.splice(indexOfHouse, 1);
          } // TODO: Filter data as needed per which schedule we are looking at!


          if (!self.showAllEmployees) {
            // console.log('BEFORE',self.calendarId,self.employees);
            if (self.$app.data.taskMasterData.schedules && self.$app.data.taskMasterData.schedules[self.currentScheduleReference]) {
              var currentShiftsForThisCalendar = self.$app.data.taskMasterData.schedules[self.currentScheduleReference].shifts.filter(function (elem) {
                return elem.calendarId == self.calendarId;
              }); // console.log('currentShiftsForThisCalendar',currentShiftsForThisCalendar);

              var employeesIdsFoundInShifts = currentShiftsForThisCalendar.map(function (elem) {
                return elem.assignedEmployeeId;
              });

              if (self.calendarId == 1) {
                // Sales Schedule
                if (employeesIdsFoundInShifts.indexOf(8) < 0) {
                  employeesIdsFoundInShifts.unshift(8);
                }

                if (employeesIdsFoundInShifts.indexOf(1) < 0) {
                  employeesIdsFoundInShifts.unshift(1);
                }
              }

              if (self.$app.data.taskMasterData && self.$app.data.taskMasterData.schedulesCalendars) {
                var currentCalendarIndex = self.$app.data.taskMasterData.schedulesCalendars.map(function (elem) {
                  return elem.id;
                }).indexOf(self.calendarId);

                if (currentCalendarIndex > -1) {
                  var currentCalendarDepartmentNames = self.$app.data.taskMasterData.schedulesCalendars[currentCalendarIndex].departments.map(function (elem) {
                    return elem.name;
                  });
                  self.employees = self.employees.filter(function (elem) {
                    return currentCalendarDepartmentNames.indexOf(elem.department) > -1 || employeesIdsFoundInShifts.indexOf(elem.id) > -1;
                  });
                }
              }
            } // console.log('AFTER',self.calendarId,self.employees);

          } else {} // SORT BY THE PROPER DEPARTMENT HERE...
            // console.log('Here! 2');


          self.employeesHoursTotals = 0;

          for (var i = 0; i < self.employees.length; i++) {
            var currentEmployeeId = self.employees[i].id;
            var totalHours = 0;
            self.employees[i].isEven = i % 2 == 0; // TODO: set a variable here that says "This Employee is Not from this calendar..."
            // Used for when we "recruit" outside help for a schedule...

            var thisScheduleIsForThisEmployee = false;

            if (self.$app.data.taskMasterData && self.$app.data.taskMasterData.schedulesCalendars) {
              var currentCalendarIndex = self.$app.data.taskMasterData.schedulesCalendars.map(function (elem) {
                return elem.id;
              }).indexOf(self.calendarId);

              if (currentCalendarIndex > -1) {
                // TODO: use departmentId instead of name...
                var currentCalendarDepartmentNames = self.$app.data.taskMasterData.schedulesCalendars[currentCalendarIndex].departments.map(function (elem) {
                  return elem.name;
                });

                if (currentCalendarDepartmentNames.indexOf(self.employees[i].department) > -1) {
                  thisScheduleIsForThisEmployee = true;
                }
              }
            }
            /*
            var rightNowTemp= new Date();
            	if (self.employees[i].isClockedIn) {
            	self.employees[i].nextShiftText= '<span class="badge color-green badge-empty">&nbsp;</span> IN NOW';
            	} else {
            	if ((self.employees[i].nextShift) && (self.employees[i].nextShift.startDateTime)) {
            		if (true) {//(thisScheduleIsForThisEmployee) {
            			self.employees[i].nextShiftText= moment(self.employees[i].nextShift.startDateTime).calendar(null,
            				{
            					sameDay:'[Today]',
            					nextDay:'[Tomorrow,] h:mma',
            					nextWeek:'ddd, h:mma',
            					lastDay:'[Yesterday]',
            					lastWeek:'[Last] dddd',
            					sameElse:'L'
            				}
            			);
            		} else {
            			self.employees[i].nextShiftText= moment(self.employees[i].nextShift.startDateTime).calendar(null,
            				{
            					sameDay:'[Today]',
            					nextDay:'[Tomorrow]',
            					nextWeek:'ddd',
            					lastDay:'[Yesterday]',
            					lastWeek:'[Last] dddd',
            					sameElse:'L'
            				}
            			);
            		}
            	}
            }
            */
            // console.log('currentEmployeeId',currentEmployeeId);


            if (self.$app.data.taskMasterData.schedules) {
              if (self.$app.data.taskMasterData.schedules[self.currentScheduleReference]) {
                self.currentScheduleObject = self.$app.data.taskMasterData.schedules[self.currentScheduleReference];
                var thisEmployeeShifts = self.currentScheduleObject.shifts.filter(function (elem) {
                  return elem.assignedEmployeeId == currentEmployeeId;
                });

                for (var j = 0; j < self.datesArray.length; j++) {
                  var thisDayShifts = thisEmployeeShifts.filter(function (elem) {
                    var fromDate = moment(elem.startDate).toDate();
                    var toDate = moment(elem.endDate).toDate(); // Make sure the range is there!

                    fromDate.setHours(0, 0, 0, 0);
                    toDate.setHours(23, 59, 59, 999); //if (currentEmployeeId == 56) {
                    //console.log('self.dates['+dayReferenceNames[j]+'].valueOf()',self.dates[dayReferenceNames[j]].valueOf(),'toDate.getTime()',toDate.getTime(),'fromDate.getTime()',fromDate.getTime());
                    //}

                    return self.datesArray[j].date.valueOf() <= toDate.getTime() && self.datesArray[j].date.valueOf() >= fromDate.getTime();
                  });

                  if (thisDayShifts.length > 0) {
                    // TODO: loop shifts or expect only 1 per day?
                    var thisDayNameLowercase = self.datesArray[j].dayName.toLowerCase(); // Found a shift!
                    // console.log('thisDayShifts',thisDayShifts);
                    // TODO: test this out...! Is it okay to say ANY event?  We should be dealing with workShifts only though...
                    // Is the 0 okay here...? should I be looping multiple shifts in ONE day...?

                    if (thisDayShifts[0].isPartOfASchedule && thisDayShifts[0].calendarId !== 3) {
                      //((thisDayShifts[0].calendarid == self.calendarId) || (self.showAllEmployees)) { // ONLY... THIS CALENDAR ITEMS... // TODO: if they pick "show all..."
                      // var get start/end times...
                      var thisStartDate = moment(thisDayShifts[0].startDateTime);
                      var thisEndDate = moment(thisDayShifts[0].endDateTime);
                      /*
                      if (self.datesArray[j].isToday) {
                      	var rightNowTemp= moment(new Date());
                      	if ((!self.employees[i].isClockedIn) && (thisStartDate.isBefore(rightNowTemp)) && (thisEndDate.isAfter(rightNowTemp))) {
                      		// console.log('thisStartDate',thisStartDate.date,rightNowTemp.date);
                      		// TODO: check for if they clocked out...!
                      		// if (self.employees[i].clockedInDatetime == '')
                      			if ((moment(self.employees[i].clockedInDatetime).isBefore(rightNowTemp, 'day'))) {
                      			self.employees[i].nextShiftText= 'NOT IN YET';
                      			} else {
                      			self.employees[i].nextShiftText= '<span class="badge color-red badge-empty">&nbsp;</span> OUT UNTIL<br>'+self.employees[i].nextShiftText;
                      			}
                      	}
                      }
                      */

                      var assignmentText = thisDayShifts[0].eventAssigment;
                      var thisIsDraggable = true;
                      var shiftHoursText = '<nobr>' + thisStartDate.format('h:mma') + ' -</nobr> ' + thisEndDate.format('h:mma');
                      var shiftWorkHours = thisDayShifts[0].workHours;

                      if (thisDayShifts[0].calendarId !== self.calendarId) {
                        // FIND THE DEPARTMENT SCHEDULE...
                        var prefixText = '*';
                        var otherCalendarIndex = self.$app.data.taskMasterData.schedulesCalendars.map(function (elem) {
                          return elem.id;
                        }).indexOf(thisDayShifts[0].calendarId);

                        if (otherCalendarIndex > -1) {
                          prefixText = self.$app.data.taskMasterData.schedulesCalendars[otherCalendarIndex].name.replace(' Schedule', '');
                        }

                        if (!thisScheduleIsForThisEmployee) {
                          thisIsDraggable = false;

                          if (self.showAllDaysOfTheWeek) {
                            shiftHoursText = '';
                            assignmentText = '[' + prefixText + ']'; //+assignmentText;
                          } else {
                            shiftHoursText = '<span style="font-style: italic; opacity: 0.5;">[' + shiftHoursText + '</span>';

                            if (assignmentText !== '') {
                              assignmentText = prefixText + '/' + assignmentText + ']';
                            } else {
                              assignmentText = prefixText + ']';
                            }
                          }
                        } else {
                          assignmentText = '[' + prefixText + ']' + assignmentText;
                        } // shiftWorkHours= 0; // TODO: is this okay?

                      }

                      if (self.datesArray[j].isInThePast && self.isFinalized) {
                        thisIsDraggable = false;
                      }

                      self.employees[i][thisDayNameLowercase] = {
                        date: thisStartDate.toDate(),
                        dayNameLowercase: thisDayNameLowercase,
                        text: shiftHoursText,
                        assignment: assignmentText,
                        color: thisDayShifts[0].eventColor,
                        eventId: thisDayShifts[0].id,
                        eventObject: thisDayShifts[0],
                        draggable: thisIsDraggable,
                        workHours: shiftWorkHours,
                        isToday: self.datesArray[j].isToday,
                        employeeIndex: i
                      };
                    } else if (thisDayShifts[0].calendarId == 3) {
                      self.employees[i][thisDayNameLowercase] = {
                        date: moment(thisDayShifts[0].startDateTime).toDate(),
                        dayNameLowercase: thisDayNameLowercase,
                        text: 'OFF: ' + thisDayShifts[0].title + (!thisDayShifts[0].isApproved ? ' (*Not Approved Yet)' : ''),
                        assignment: '',
                        color: '',
                        eventId: thisDayShifts[0].id,
                        eventObject: thisDayShifts[0],
                        draggable: false,
                        workHours: 0,
                        isToday: self.datesArray[j].isToday,
                        employeeIndex: i
                      };
                    } else {}
                  } // console.log('thisDayShifts',thisDayShifts);

                }
              }
            }

            self.employees[i].days = [];

            for (var j = 0; j < self.datesArray.length; j++) {
              var thisDayNameLowercase = self.datesArray[j].dayName.toLowerCase();

              if (!self.employees[i][thisDayNameLowercase]) {
                self.employees[i][thisDayNameLowercase] = {
                  date: self.datesArray[j].date.toDate(),
                  dayNameLowercase: thisDayNameLowercase,
                  text: 'OFF',
                  assignment: '',
                  color: '',
                  eventId: -1,
                  eventObject: {},
                  draggable: !self.datesArray[j].isInThePast || !self.isFinalized,
                  workHours: 0,
                  isToday: self.datesArray[j].isToday,
                  employeeIndex: i
                };
              }

              self.employees[i].days.push(self.employees[i][thisDayNameLowercase]);
              delete self.employees[i][thisDayNameLowercase];
            }

            self.employees[i].totalHours = 0;

            for (var h = 0; h < self.employees[i].days.length; h++) {
              self.employees[i].totalHours += self.employees[i].days[h].workHours;
            }

            for (var j = 0; j < self.datesArray.length; j++) {
              self.datesArray[j].totalHours += self.employees[i].days[j].workHours;
            }

            self.employeesHoursTotals += self.employees[i].totalHours;
          }

          self.screenIsLarge = self.$(window).width() >= 1024;

          if (!self.editing) {// TODO: do not set it here... like this...
            // self.showAllDaysOfTheWeek= self.screenIsLarge;
          }

          if (!self.showAllDaysOfTheWeek && !self.editing && self.isFinalized) {
            self.displayDatesArray = self.datesArray.filter(function (currElement) {
              return currElement.isToday;
            });

            if (self.displayDatesArray.length == 0) {
              self.displayDatesArray = self.datesArray;
            } else {
              // Filter Employee Days too...
              for (var i = 0; i < self.employees.length; i++) {
                self.employees[i].days = self.employees[i].days.filter(function (currElement) {
                  return currElement.isToday;
                });
              }
            }
          } else {
            self.displayDatesArray = self.datesArray;
          }

          if (self.editing) {
            if (self.isFinalized) {
              self.statusButtonText = 'Status: Finalized';
            } else if (self.isStarted) {
              self.statusButtonText = 'Status: Draft';
            } else {
              self.statusButtonText = 'Status: Not Started';
            }
          } // console.log('Here! 3');


          self.$setState({
            loading: false
          }, function () {
            // TODO: this fixes some sort of refresh bug...
            self.$el.find('.card.data-table').attr('style', '');
            self.$el.find('.page-content').attr('style', '').show();
            onSuccessFunction();

            if (!self.editing) {
              if (!self.screenIsLarge) {
                self.showAllDaysToggle = self.$app.toggle.create({
                  el: '.showAllDates',
                  on: {
                    change: self.showAllDatesToggle
                  }
                });
              }
            } else {
              // WE ARE EDITING... NEED TO UPDATE THE EDITORS HERE...
              console.log('UPDATE EDITORS HERE...');
              var currentStatusObject;

              if (self.currentScheduleObject && self.currentScheduleObject.weeklySchedulesStatus && self.currentScheduleObject.weeklySchedulesStatus[self.calendarId]) {
                currentStatusObject = self.currentScheduleObject.weeklySchedulesStatus[self.calendarId];
                self.isLoadingEditors = true;
                self.$('.text-editor-notes').each(function (index, element) {
                  var valueToSet = currentStatusObject.objectData.notesArray[index];

                  if (valueToSet == '') {
                    element.f7TextEditor.setValue(valueToSet); // .clearValue();
                  } else {
                    element.f7TextEditor.setValue(valueToSet);
                  }
                });
                self.$('.text-editor-everyone-notes').each(function (index, element) {
                  var valueToSet = currentStatusObject.objectData.allSchedulesNotesArray[index];

                  if (valueToSet == '') {
                    element.f7TextEditor.setValue(valueToSet); // .clearValue();
                  } else {
                    element.f7TextEditor.setValue(valueToSet);
                  }
                });
                self.isLoadingEditors = false;
              }
            }
          });
        }
      }
    },
    buildShiftData: function () {
      var shiftObject = {};
      return shiftObject;
    },
    getHoursBetweenTime: function (startTime, endTime, getTimeDiff) {
      getTimeDiff = typeof getTimeDiff == 'boolean' ? getTimeDiff : false;
      var self = this;
      var start = startTime.split(":");
      var end = endTime.split(":");
      var startDate = new Date(0, 0, 0, start[0], start[1], start[2] ? start[2] : 0, 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], end[2] ? end[2] : 0, 0); //for calculation to work if endtime cross over next day. Eg. 11pm to 2am

      if (endDate.getTime() < startDate.getTime()) {
        endDate.setDate(endDate.getDate() + 1);
      }

      var diff = endDate.getTime() - startDate.getTime();
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);
      diff -= minutes * 1000 * 60;
      var seconds = Math.floor(diff / 1000); // console.log((hours < 9 ? "0" : "") + hours + ":" + (minutes < 9 ? "0" : "") + minutes + ":" + (seconds < 9 ? "0" : "") + seconds);

      if (getTimeDiff) {
        return (hours < 9 ? "0" : "") + hours + ":" + (minutes < 9 ? "0" : "") + minutes + ":" + (seconds < 9 ? "0" : "") + seconds;
      } else {
        return (hours + minutes / 60).toFixed(2);
      }
    },
    editSchedule: function () {
      var self = this;

      var tempFunction = function (componentReference) {
        componentReference.editing = true;
        componentReference.showAllEmployees = false;
        componentReference.showAllDaysOfTheWeek = true;
        componentReference.refreshPage();
      };

      if (self.isFinalized) {
        // IF AN ALREADY SUBMITTED WEEK...
        self.$app.dialog.confirm('You are asking to edit a week that has already been submitted & finalized.<br><br><strong>Please inform the affected employees.</strong>', 'Are you sure?', function () {
          tempFunction(self);
        });
      } else {
        tempFunction(self);
      }
    },
    saveSchedule: function () {
      var self = this;
      self.editing = false;
      self.showAllEmployees = false;
      self.showAllDaysOfTheWeek = false; // TODO: need anything?

      self.refreshPage();
    },
    submitFinal: function () {
      var self = this;

      if (self.isFinalized) {
        //self.$app.dialog.alert('Details here on when finalized, etc...','Finalized');
        // TODO: give finalized details here somewhere?
        self.saveSchedule();
      } else if (self.isStarted) {
        self.$app.dialog.confirm('Are you ready to finalize the current schedule for:<br><br>department [dept],<br>[week - week]', 'Finalize?', function () {
          // TODO: Need a wheel here?
          self.$root.mdWebSocket.sendOverWebSocket({
            action: 'scheduleSubmitFinal',
            calendarId: self.calendarId,
            weekNumber: self.currentWeekNumber[1],
            weekNumberYear: self.currentWeekNumber[0],
            weekStartDate: self.startDate,
            weekEndDate: self.endDate
          });
        });
      } else {// Do nothing...
      }
    },
    copyPreviousWeek: function () {
      var self = this;

      var continueWithCopyFunction = function (calendar, value) {
        // console.log('continueWithCopyFunction',value);
        if (value.length > 0) {
          var momentJSValue = moment(value[0]);
          self.$root.mdWebSocket.sendOverWebSocket({
            action: 'scheduleCopyPreviousWeek',
            calendarId: self.calendarId,
            weekNumberToCopy: momentJSValue.week(),
            // FROM
            weekNumberYearToCopy: momentJSValue.year(),
            // FROM
            weekStartDateToCopy: momentJSValue.startOf('week').toDate(),
            // FROM (This seems redundant to the weeknumber/year)
            weekEndDateToCopy: momentJSValue.endOf('week').toDate(),
            // FROM (This seems redundant to the weeknumber/year)
            weekNumber: self.currentWeekNumber[1],
            // TO
            weekNumberYear: self.currentWeekNumber[0],
            // TO
            weekStartDate: self.startDate,
            // TO (This seems redundant to the weeknumber/year)
            weekEndDate: self.endDate // TO (This seems redundant to the weeknumber/year)

          });
        }
      };

      var okayToMoveForward = function (calendar, value) {
        // console.log('value',value);
        if (value.length > 0) {
          var pickedWeekStart = moment(value[0]).startOf('week');
          var pickedWeekEnd = moment(value[0]).endOf('week');
          var textToPrepend = 'Picked:<br>';
          textToPrepend += '&nbsp;&nbsp;' + pickedWeekStart.format('l') + ' -> ' + pickedWeekEnd.format('l') + '<br>';
          textToPrepend += 'Copy To:<br>';
          textToPrepend += '&nbsp;&nbsp;' + moment(self.startDate).format('l') + ' -> ' + moment(self.endDate).format('l') + '<br><br>';

          if (self.isStarted) {
            self.$app.dialog.confirm(textToPrepend + 'It looks like you have already started this week, are you sure you want to overwrite it with a copy of last weeks shifts?', 'Overwrite Data?', function () {
              continueWithCopyFunction(calendar, value);
            });
          } else {
            self.$app.dialog.confirm(textToPrepend + 'This will copy all the shifts for this schedule from the previous week.', 'Copy Last Week?', function () {
              continueWithCopyFunction(calendar, value);
            });
          }
        }
      };

      if (self.isFinalized) {
        self.$app.dialog.alert('Can\'t.  Copying from last week ideally would happen when a schedule is not started but can only be used BEFORE the week has been finalized.', 'Can\'t Copy Last Week');
      } else {
        self.openCalendarModal(function (calendar, value) {
          // console.log('got here!',value, calendar);
          if (value.length > 0) {
            calendar.close();
            okayToMoveForward(calendar, value);
          }
        });
      }
    },
    toggleAllEmployees: function () {
      var self = this;
      self.showAllEmployees = !self.showAllEmployees;
      self.refreshPage();
    },
    showAllDatesToggle: function () {
      var self = this;
      self.showAllDaysOfTheWeek = self.showAllDaysToggle.checked;
      self.refreshPage();
    },
    windowResized: function () {
      var self = this;

      if (self.windowResizeTimer !== null) {
        clearTimeout(self.windowResizeTimer);
        self.windowResizeTimer = null;
      }

      self.windowResizeTimer = setTimeout(function () {
        if (self.stickyNames) {
          self.fixNameColumnHeight();
        }

        self.autoShowWeekVsDay();
      }, 250);
    },
    shareSchedule: function () {
      var self = this;
      self.$app.dialog.alert('Printing or Emailing is not ready yet....', 'Not Ready'); // TODO!
    },
    printSchedule: function () {
      var self = this;
      self.$app.dialog.alert('Printing is not ready yet...', 'Not ready');
    },
    emailSchedule: function () {
      var self = this;
      self.$app.dialog.alert('Emailing is not ready yet...', 'Not ready');
    },
    fixNameColumnHeight: function () {
      var self = this;
      self.$el.find('.nameColumn').each(function (e) {
        var thisItem = self.$(this);
        var newHeight = thisItem.closest('tr').height();
        thisItem.css('height', newHeight + 'px');
      }); // console.log('HERE!',self.$el.find('#thursday0').height(),self.$el.find('#thursday0').height());
    },
    pickedWeek: function (e) {
      var self = this;
      var thisATag = self.$(e.target).closest('a');
      var thisReference = thisATag.attr('data-week'); //self.startDate= self.$app.methods.getFirstDayOfTheWeek(self.today);
      //self.endDate= self.$app.methods.getLastDayOfTheWeek(self.today);

      self.startDate = moment(self.today).startOf('week').toDate();
      self.endDate = moment(self.today).endOf('week').toDate();

      var okayToMoveForward = function () {
        self.$app.data.pageStatusData = {
          startDate: self.startDate,
          endDate: self.endDate
        };
        console.log('okayToMoveForward', self.$app.data.pageStatusData);
        self.$root.sendStatus();
      };

      if (thisReference == 'currentWeek') {
        // ready to go!
        self.headerTitle = 'Current Week';
        okayToMoveForward();
      } else if (thisReference == 'nextWeek') {
        self.startDate = moment(self.startDate).add(7, 'days').toDate();
        self.endDate = moment(self.endDate).add(7, 'days').toDate();
        self.headerTitle = '';
        okayToMoveForward();
      } else if (thisReference == 'twoWeeks') {
        self.startDate = moment(self.startDate).add(14, 'days').toDate();
        self.endDate = moment(self.endDate).add(14, 'days').toDate();
        self.headerTitle = '';
        okayToMoveForward();
      } else {
        self.openCalendarModal(function (calendar, value) {
          // console.log('got here!',value, calendar);
          if (value.length > 0) {
            self.startDate = moment(value[0]).startOf('week').toDate();
            self.endDate = moment(value[0]).endOf('week').toDate();
            calendar.close(); // console.log('da');

            okayToMoveForward();
          }
        }); // self.$app.dialog.alert('Picking a Week is not ready yet...','Not Ready');
      } // self.currentScheduleReference= 'week'+((self.currentWeekNumber[1])+1)+'_'+self.currentWeekNumber[0]; // TODO: this should be smarter...

    },
    clickedTab: function (e) {
      var self = this;
      var thisATag = self.$(e.target).closest('a');
      var thisCalendarId = parseInt(thisATag.attr('data-calendarId'));
      var thisText = thisATag.text();
      self.calendarId = thisCalendarId;
      self.editing = false; // Reset this everytime...

      self.showAllEmployees = false; // Reset this everytime...

      self.showAllDaysOfTheWeek = self.screenIsLarge;
      self.refreshPage();
    },
    refreshEditShiftDialog: function () {
      var self = this;
    },
    autoShowWeekVsDay: function () {
      var self = this;
      self.screenIsLarge = self.$(window).width() >= 1024;

      if (self.showAllDaysOfTheWeek) {
        if (!self.screenIsLarge) {
          if (!self.editing) {
            self.showAllDaysOfTheWeek = false;
            self.refreshPage();
          }
        }
      } else {
        if (self.screenIsLarge) {
          if (!self.editing) {
            self.showAllDaysOfTheWeek = true;
            self.refreshPage();
          }
        }
      }
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.moveOrCopyPopover = self.$app.popover.create({
        content: '<div class="popover">' + '<div class="popover-angle"></div>' + '<div class="popover-inner">' + '<div class="list">' + '<ul>' + '<li class="item-divider dividerText"></li>' + '<li><a class="list-button item-link moveShiftLink popover-close" href="#" data-moveEvent="swap">Swap<span class="swapExtraText"></span></a></li>' + '<li><a class="list-button item-link moveShiftLink popover-close" href="#" data-moveEvent="copy">Copy<span class="copyExtraText"></span></a></li>' + '</ul>' + '</div>' + '</div>' + '</div>',
        on: {
          open: function () {// console.log('Popover opened');
          }
        }
      });
      self.editShiftDialog = self.$app.dialog.create({
        cssClass: 'editShiftDialog',
        title: 'Edit Shift',
        text: '<div class="segmented segmented-round">' + '<button class="button button-round button-outline tabButtons buttonWorking button-active">Working</button>' + '<button class="button button-round button-outline tabButtons buttonOff">OFF</button>' + '</div>',
        content: '<form class="editShiftForm" onsubmit="return false;"><div class="row showHideWorking">' + '<div class="col-33 inputLabel">Start:</div>' + '<div class="col-66">' + '<div class="dialog-input-field input">' + '<input type="text" name="startTime" class="dialog-input startTimeInput" placeholder="__:__ __">' + '</div>' + '</div>' + '</div>' + '<div class="row showHideWorking">' + '<div class="col-33 inputLabel">End:</div>' + '<div class="col-66">' + '<div class="dialog-input-field input">' + '<input type="text" name="endTime" class="dialog-input endTimeInput" placeholder="__:__ __">' + '</div>' + '</div>' + '</div>' + '<div class="row showHideWorking">' + '<div class="col-33 inputLabel">What:</div>' + '<div class="col-66">' + '<div class="dialog-input-field input">' + '<input type="text" name="assignment" class="dialog-input assignmentInput" value="West Building">' + '</div>' + '</div>' + '</div>' + '<div class="row showHideWorking">' + '<div class="col-33 inputLabel">Color:</div>' + '<div class="col-66">' + '<div class="dialog-input-field input">' + '<input type="text" name="color" class="dialog-input colorInput demo-color-picker-wheel-value" readonly="readonly" id="demo-color-picker-wheel">' + '</div>' + '</div>' + '</div>' + '</form>',
        buttons: [{
          text: 'Cancel',
          color: 'red'
        }, {
          text: 'Save',
          onClick: function (dialog, e) {
            var clickedIndex = parseInt(dialog.$el.attr('data-row-index'));
            var clickedDayIndex = dialog.$el.attr('data-dayIndex');
            var dataToSend = self.$app.form.convertToData('.editShiftForm');

            if (true) {
              // Normalize the time strings into military time for 4D...
              // console.log('dataToSend',JSON.stringify(dataToSend));
              var tempStartArray = self.$app.methods.parseTimeStringIntoPickerArray(dataToSend.startTime, true);
              var tempEndArray = self.$app.methods.parseTimeStringIntoPickerArray(dataToSend.endTime, true);
              dataToSend.startTime = tempStartArray[0] + ':' + tempStartArray[1];
              dataToSend.endTime = tempEndArray[0] + ':' + tempEndArray[1]; // console.log('dataToSendFIXED',JSON.stringify(dataToSend));
            }

            dataToSend.action = 'scheduleSaveShift';
            dataToSend.deleteShift = dialog.$el.find('.buttonOff').hasClass('button-active');
            dataToSend.calendarId = self.calendarId;
            dataToSend.date = self.datesArray[clickedDayIndex].date.toDate().toISOString();
            dataToSend.employeeId = self.employees[clickedIndex].id;
            dataToSend.employeeName = self.employees[clickedIndex].name;
            dataToSend.eventId = self.employees[clickedIndex].days[clickedDayIndex].eventId;
            dataToSend.weekNumber = self.currentWeekNumber[1];
            dataToSend.weekNumberYear = self.currentWeekNumber[0];
            dataToSend.weekStartDate = self.startDate;
            dataToSend.weekEndDate = self.endDate; // console.log('dataToSend',dataToSend);

            self.$root.mdWebSocket.sendOverWebSocket(dataToSend);
          }
        }],
        on: {
          open: function (dialog) {
            // TODO: on time change use this: self.getHoursBetweenTime(dialog.$el.find('.startTimeInput').val(),dialog.$el.find('.endTimeInput').val())
            var currentStartTime = dialog.$el.find('.startTimeInput').val();
            var currentEndTime = dialog.$el.find('.endTimeInput').val(); // console.log('currentStartTime',currentStartTime,'currentEndTime',currentEndTime);

            if (!dialog.startTimePicker) {
              var startTimePickerCreateObject = {
                inputEl: '.startTimeInput',
                openIn: 'popover',
                toolbar: false,
                rotateEffect: true,
                formatValue: function (values) {
                  return values[0] + ':' + values[1] + ' ' + values[2];
                },
                cols: [{
                  values: '12 1 2 3 4 5 6 7 8 9 10 11'.split(' ')
                }, {
                  values: '00 15 30 45'.split(' ')
                }, {
                  values: 'AM PM'.split(' ')
                }],
                on: {
                  open: function (picker) {
                    picker.app.$('#app').addClass('popoverMoveToTop');
                  },
                  close: function (picker) {
                    picker.app.$('#app').removeClass('popoverMoveToTop'); // This is when we should change the END time I think...

                    var dialogStartTimeValue = picker.value.slice(); // Default end time if not set...

                    var setNewStartValue = false;
                    var dialogEndTimeValue = ['6', '00', 'PM']; // Default end time if not set...

                    var setNewEndValue = false;

                    if (dialog.endTimePicker) {
                      if (dialog.endTimePicker.value) {
                        dialogEndTimeValue = dialog.endTimePicker.value.slice();

                        if (dialogEndTimeValue[2] == 'AM' && dialogStartTimeValue[2] == 'PM') {
                          dialogEndTimeValue[2] = 'PM';
                          setNewEndValue = true;
                        }

                        if (dialogEndTimeValue[2] == dialogStartTimeValue[2]) {
                          // We are in the same AM/PM block...
                          if (parseInt(dialogEndTimeValue[0]) == 12 || parseInt(dialogStartTimeValue[0]) !== 12 && parseInt(dialogEndTimeValue[0]) < parseInt(dialogStartTimeValue[0])) {
                            dialogEndTimeValue[0] = dialogStartTimeValue[0];
                            setNewEndValue = true;
                          }

                          if (dialogStartTimeValue[0] == dialogEndTimeValue[0] && parseInt(dialogEndTimeValue[1]) < parseInt(dialogStartTimeValue[1])) {
                            dialogEndTimeValue[1] = dialogStartTimeValue[1];
                            setNewEndValue = true;
                          }
                        }
                      }
                    }

                    if (setNewEndValue) {
                      console.log('STARTPICKER, setNewEndValue', dialog.endTimePicker.value, dialogEndTimeValue);
                      dialog.endTimePicker.setValue(dialogEndTimeValue);
                    }

                    if (setNewStartValue) {
                      console.log('STARTPICKER, setNewStartValue', values, dialogStartTimeValue);
                      picker.setValue(dialogStartTimeValue);
                    }
                  },
                  change: function (picker, values, displayValues) {
                    var dialogStartTimeValue = values.slice();
                    var setNewStartValue = false;
                    var dialogEndTimeValue = ['6', '00', 'PM']; // Default end time if not set...

                    var setNewEndValue = false;

                    if (dialog.endTimePicker) {
                      if (dialog.endTimePicker.value) {
                        dialogEndTimeValue = dialog.endTimePicker.value.slice();
                      }
                    }

                    if (dialogStartTimeValue[0] == '12' && dialogStartTimeValue[1] == '00' && dialogStartTimeValue[2] == 'AM') {
                      // If first one set to 12am...
                      if (dialogEndTimeValue[2] == 'PM') {
                        dialogStartTimeValue[2] = 'PM';
                      } else {
                        dialogStartTimeValue[0] = '8'; // 8am default start time.

                        if (parseInt(dialogEndTimeValue[0]) < parseInt(dialogStartTimeValue[0])) {
                          dialogStartTimeValue[0] = dialogEndTimeValue[0];
                        }
                      }

                      setNewStartValue = true;
                    }

                    if (setNewEndValue) {
                      console.log('STARTPICKER, setNewEndValue', dialog.endTimePicker.value, dialogEndTimeValue);
                      dialog.endTimePicker.setValue(dialogEndTimeValue);
                    }

                    if (setNewStartValue) {
                      console.log('STARTPICKER, setNewStartValue', values, dialogStartTimeValue);
                      picker.setValue(dialogStartTimeValue);
                    }
                  }
                }
              };

              if (currentStartTime == '') {
                startTimePickerCreateObject.value = ['8', '00', 'AM'];
              } else {
                startTimePickerCreateObject.value = self.$app.methods.parseTimeStringIntoPickerArray(currentStartTime);
              } // console.log('startTimePickerCreateObject.value',startTimePickerCreateObject.value);


              dialog.startTimePicker = self.$app.picker.create(startTimePickerCreateObject);
            }

            if (!dialog.endTimePicker) {
              var endTimePickerCreateObject = {
                inputEl: '.endTimeInput',
                openIn: 'popover',
                verticalPosition: 'bottom',
                toolbar: false,

                /*
                render: function () {
                var picker= this;
                var { rotateEffect, cssClass, toolbar } = picker.params;
                console.log('render this',this,arguments);
                return '<div class="popover picker-popover" data-vertical-position="bottom">'+
                '<div class="popover-inner">'+
                '<div class="picker'+((rotateEffect)?' picker-3d':'')+((cssClass !== '')?' '+cssClass:'')+'">'+
                ((toolbar)?picker.renderToolbar():'')+
                '<div class="picker-columns">'+
                picker.cols.map((col) => picker.renderColumn(col))+
                '<div class="picker-center-highlight"></div>'+
                '</div>'+
                '</div>'+
                '</div>'+
                '</div>';
                },*/
                rotateEffect: true,
                formatValue: function (values) {
                  return values[0] + ':' + values[1] + ' ' + values[2];
                },
                cols: [{
                  values: '12 1 2 3 4 5 6 7 8 9 10 11'.split(' ')
                }, {
                  values: '00 15 30 45'.split(' ')
                }, {
                  values: 'AM PM'.split(' ')
                }],
                on: {
                  open: function (picker) {
                    picker.app.$('#app').addClass('popoverMoveToTop');
                    picker.modal.params.verticalPosition = 'bottom'; //picker.modal.resize();

                    console.log('open picker.modal', picker.modal.params);
                  },
                  close: function (picker) {
                    picker.app.$('#app').removeClass('popoverMoveToTop');
                  },
                  change: function (picker, values, displayValues) {
                    var dialogStartTimeValue = ['8', '00', 'AM']; // Default end time if not set...

                    var setNewStartValue = false;
                    var dialogEndTimeValue = values.slice();
                    var setNewEndValue = false;

                    if (dialog.startTimePicker) {
                      if (dialog.startTimePicker.value) {
                        dialogStartTimeValue = dialog.startTimePicker.value.slice();

                        if (dialogStartTimeValue[2] == 'PM' && dialogEndTimeValue[2] == 'AM') {
                          dialogEndTimeValue[2] = 'PM';
                          setNewEndValue = true;
                        }

                        if (dialogStartTimeValue[2] == dialogEndTimeValue[2]) {
                          // We are in the same AM/PM block...
                          if (parseInt(dialogStartTimeValue[0]) !== 12 && (parseInt(dialogStartTimeValue[0]) > parseInt(dialogEndTimeValue[0]) || dialogEndTimeValue[0] == 12)) {
                            dialogEndTimeValue[0] = dialogStartTimeValue[0];
                            setNewEndValue = true;
                          }

                          if (dialogEndTimeValue[0] == dialogStartTimeValue[0] && parseInt(dialogStartTimeValue[1]) > parseInt(dialogEndTimeValue[1])) {
                            dialogEndTimeValue[1] = dialogStartTimeValue[1];
                            setNewEndValue = true;
                          }
                        }
                      }
                    }

                    if (setNewEndValue) {
                      console.log('ENDPICKER, setNewEndValue', values, dialogEndTimeValue);
                      picker.setValue(dialogEndTimeValue);
                    }

                    if (setNewStartValue) {
                      console.log('ENDPICKER, setNewStartValue', dialog.startTimePicker.value, dialogStartTimeValue);
                      dialog.startTimePicker.setValue(dialogStartTimeValue);
                    }
                  }
                }
              };

              if (currentEndTime == '') {
                endTimePickerCreateObject.value = ['6', '00', 'PM'];
              } else {
                endTimePickerCreateObject.value = self.$app.methods.parseTimeStringIntoPickerArray(currentEndTime);
              }

              console.log('endTimePickerCreateObject.value', endTimePickerCreateObject.value);
              dialog.endTimePicker = self.$app.picker.create(endTimePickerCreateObject);
            }

            console.log('dialog.startTimePicker', dialog.startTimePicker);
            console.log('dialog.endTimePicker', dialog.endTimePicker);

            if (!dialog.colorPickerWheel) {
              var colorToShow = dialog.$el.find('.colorInput').val(); // console.log('colorToShow',colorToShow);

              if (colorToShow == '') {
                colorToShow = '#ffffff';
              }

              dialog.colorPickerWheel = self.$app.colorPicker.create({
                inputEl: '#demo-color-picker-wheel',
                cssClass: 'smallerPicker',
                modules: ['palette'],
                openIn: 'popover',
                palette: [// #40d226 // OLD COLORS
                '#ffffff', '#25a70e', '#da0020', '#ae00bb', '#d68000' // TODO, add more colors to palette
                ],
                value: {
                  hex: colorToShow // TODO: need to set it to already saved value...!

                },
                on: {
                  opened: function () {
                    console.log('Color Picker opened');
                  },
                  change: function (colorPicker, value) {
                    dialog.$el.find('.demo-color-picker-wheel-value').css('background', value.hex);
                    colorPicker.close(); // console.log('Color Picker changed!',colorPicker, value);
                  }
                }
              });
            }
          },
          close: function (dialog) {
            if (dialog.startTimePicker) {
              dialog.startTimePicker.destroy();
              dialog.startTimePicker = null;
            }

            if (dialog.endTimePicker) {
              dialog.endTimePicker.destroy();
              dialog.endTimePicker = null;
            }

            if (dialog.colorPickerWheel) {
              dialog.colorPickerWheel.destroy();
              dialog.colorPickerWheel = null;
            }
          }
        },
        destroyOnClose: false
      }); // TODO: find out if they are admin or if the week has been "finalized."
      //var actualCurrentWeekSunday= self.$app.methods.getFirstDayOfTheWeek(self.today);
      //var actualCurrentWeekSaturday= self.$app.methods.getLastDayOfTheWeek(self.today);

      /*
      var weekPopoverElement= self.$('.popover.weeks-menu');
      weekPopoverElement.find('.currentWeek').html('Current Week');
      weekPopoverElement.find('.nextWeek').html(moment(actualCurrentWeekSunday).add(7,'days').format('M/D')+' – '+moment(actualCurrentWeekSaturday).add(7,'days').format('M/D'));
      weekPopoverElement.find('.twoWeeks').html(moment(actualCurrentWeekSunday).add(14,'days').format('M/D')+' – '+moment(actualCurrentWeekSaturday).add(14,'days').format('M/D'));
      */
      // weekPopoverElement.find('.pickWeek').html('Pick Week....');
      // console.log('self.moveOrCopyPopover.$el',self.moveOrCopyPopover.$el);

      self.editShiftDialog.$el.on('click', '.tabButtons', function (e) {
        var thisButton = self.$(e.target).closest('button');

        if (!thisButton.hasClass('button-active')) {
          // If it's already active don't do anything...
          if (thisButton.hasClass('buttonOff')) {
            self.editShiftDialog.$el.find('.buttonWorking').removeClass('button-active');
            self.editShiftDialog.$el.find('.buttonOff').addClass('button-active');
            self.editShiftDialog.$el.find('input').attr("readonly", "readonly"); // self.editShiftDialog.$el.find('input').val(''); // Clear all values...

            self.editShiftDialog.$el.find('.editShiftForm').hide();
            self.editShiftDialog.$el.find('.assignmentInput').attr('placeholder', 'OFF');
          } else {
            self.editShiftDialog.$el.find('.buttonWorking').addClass('button-active');
            self.editShiftDialog.$el.find('.buttonOff').removeClass('button-active');
            self.editShiftDialog.$el.find('input').removeAttr("readonly");
            self.editShiftDialog.$el.find('.editShiftForm').show();
            self.editShiftDialog.$el.find('.assignmentInput').removeAttr('placeholder');
          }
        }
      });
      self.moveOrCopyPopover.$el.find('a.moveShiftLink').on('click', function (e) {
        var thisATag = self.$(e.target).closest('a.moveShiftLink');
        var moveEvent = thisATag.attr('data-moveEvent'); // console.log('clicked '+actionPicked+'!',self.moveOrCopyPopoverFromDetails,self.moveOrCopyPopoverToDetails);

        self.$root.mdWebSocket.sendOverWebSocket({
          action: 'scheduleMoveShift',
          calendarId: self.calendarId,
          moveEvent: moveEvent,
          fromDetails: self.moveOrCopyPopoverFromDetails,
          toDetails: self.moveOrCopyPopoverToDetails
        });
        self.moveOrCopyPopoverFromDetails = {};
        self.moveOrCopyPopoverToDetails = {};
      });
      self.refreshPage();

      if (self.stickyNames) {
        self.$el.find('.page-content').on('scroll', function (e) {
          // console.log('CURRENT SCROLL TOP',self.$el.find('.page-content').scrollTop());
          // console.log(self.$el.find('.nameColumn').css('top'));
          self.$el.find('.nameColumn').each(function (e) {
            var thisItem = self.$(this);

            if (false) {}
          }); //self.$el.find('.nameColumn').css('top',(-1*self.$el.find('.page-content').scrollTop())+'px');
        });
      }
      /* // This for maybe swapping shifts?
      self.$el.on('click','td.label-cell',function(e) {
      	var thisRow= self.$(e.target).closest('tr');
      	var thisEmployeeIndex= parseInt(thisRow.attr('data-row-index'));
      		
      	if (self.editing) {
      		
      		
      	} else {
      		// ask questions...?
      		
      		
      	}
      	
      });
      */


      self.$el.on('dragstart', '.shiftChip', function (e) {
        if (e.target.getAttribute('draggable') == 'false') return true;
        var thisElement = self.$(e.target).closest('.droppable-cell').find('.shiftChip');
        thisElement.addClass('isDragging');
        e.dataTransfer.effectAllowed = 'copyLink';
        e.dataTransfer.setData('myBnMId', this.id);
        e.dataTransfer.setData('text/plain', this.innerText);
        console.log('startedDragging .shiftChip: ', this.id);
      });
      self.$el.on('dragend', '.shiftChip', function (e) {
        var thisElement = self.$(e.target).closest('.droppable-cell').find('.shiftChip');
        thisElement.removeClass('isDragging');
        console.log('dragend .shiftChip');
      });
      self.$el.on('dragenter', '.droppable-cell', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var thisElement = self.$(e.target).closest('.droppable-cell').find('.shiftChip');
        thisElement.addClass('over');
        console.log('dragenter .droppable-cell');
      });
      self.$el.on('dragover', '.droppable-cell', function (e) {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'link';
        console.log('dragover .droppable-cell');
      });
      self.$el.on('dragexit', '.droppable-cell', function (e) {
        e.stopPropagation();
        console.log('dragexit .droppable-cell');
      });
      self.$el.on('dragleave', '.droppable-cell', function (e) {
        e.stopPropagation();
        var thisElement = self.$(e.target).closest('.droppable-cell').find('.shiftChip');
        thisElement.removeClass('over');
        console.log('dragleave .droppable-cell');
      });
      self.$el.on('drop', '.droppable-cell', function (e) {
        e.stopPropagation();
        console.log('drop .droppable-cell');
        var toElement = self.$(e.target).closest('.droppable-cell').find('.shiftChip');

        if (toElement.length > 0) {
          toElement.removeClass('over');
          var fromElement = self.$('#' + e.dataTransfer.getData('myBnMId'));
          var fromElementParent = fromElement.parent();
          var fromRow = fromElement.closest('tr');
          var toRow = toElement.closest('tr');
          var fromIndex = parseInt(fromRow.attr('data-row-index'));
          var toIndex = parseInt(toRow.attr('data-row-index'));
          var dayIndexFrom = fromElement.attr('data-dayIndex');
          var dayIndexTo = toElement.attr('data-dayIndex');

          if (self.employees[fromIndex].days[dayIndexFrom].text !== self.employees[toIndex].days[dayIndexTo].text || self.employees[fromIndex].days[dayIndexFrom].assignment !== self.employees[toIndex].days[dayIndexTo].assignment) {
            self.moveOrCopyPopoverFromDetails = self.employees[fromIndex].days[dayIndexFrom];
            self.moveOrCopyPopoverToDetails = self.employees[toIndex].days[dayIndexTo]; // console.log('self.datesArray[dayIndexFrom]',self.datesArray[dayIndexFrom]);
            // console.log('self.datesArray[dayIndexTo]',self.datesArray[dayIndexTo]);

            self.moveOrCopyPopoverFromDetails.dayName = self.datesArray[dayIndexFrom].dayName;
            self.moveOrCopyPopoverFromDetails.date = self.datesArray[dayIndexFrom].date.format();
            self.moveOrCopyPopoverFromDetails.employeeId = self.employees[fromIndex].id;
            self.moveOrCopyPopoverFromDetails.employeeName = self.employees[fromIndex].name;
            self.moveOrCopyPopoverToDetails.dayName = self.datesArray[dayIndexTo].dayName;
            self.moveOrCopyPopoverToDetails.date = self.datesArray[dayIndexTo].date.format();
            self.moveOrCopyPopoverToDetails.employeeId = self.employees[toIndex].id;
            self.moveOrCopyPopoverToDetails.employeeName = self.employees[toIndex].name;
            self.moveOrCopyPopover.$el.find('.dividerText').html(self.employees[toIndex].name + ', ' + self.moveOrCopyPopoverToDetails.dayName);
            self.moveOrCopyPopover.$el.find('.swapExtraText').html(' with ' + self.employees[fromIndex].name + ', ' + self.moveOrCopyPopoverFromDetails.dayName);
            self.moveOrCopyPopover.$el.find('.copyExtraText').html(' [' + self.employees[fromIndex].days[dayIndexFrom].text + ']');
            self.moveOrCopyPopover.open(toElement);
          } else {// Do nothing, it's the same type shift..., nothing to swap...
          }
        }
      });
      self.$el.on('click', '.shiftChip', function (e) {
        var clickedElement = self.$(e.target).closest('.shiftChip');
        var clickedRow = clickedElement.closest('tr');
        var clickedIndex = parseInt(clickedRow.attr('data-row-index'));
        var clickedDayIndex = clickedElement.attr('data-dayIndex');
        var thisIsMyShift = false;
        thisIsMyShift = Object.keys(self.employees[clickedIndex].days[clickedDayIndex].eventObject).length === 0 && self.employees[clickedIndex].days[clickedDayIndex].eventObject.constructor === Object;

        if (!thisIsMyShift) {
          if (self.employees[clickedIndex].days[clickedDayIndex].eventObject && self.employees[clickedIndex].days[clickedDayIndex].eventObject.calendarId && self.employees[clickedIndex].days[clickedDayIndex].eventObject.calendarId == self.calendarId) {
            thisIsMyShift = true;
          }
        } //console.log('thisIsMyShift',thisIsMyShift);
        // console.log('DAY CLICKED',self.employees[clickedIndex].days[clickedDayIndex]);
        //console.log('self.datesArray',clickedDayIndex,self.datesArray);


        if (thisIsMyShift) {
          self.editShiftDialog.$el.attr('data-row-index', clickedIndex).attr('data-dayIndex', clickedDayIndex);
          self.editShiftDialog.$el.find('.dialog-title').html(self.employees[clickedIndex].name + ', ' + self.datesArray[clickedDayIndex].date.format('M/D ddd'));
          var startTimeString = '';
          var endTimeString = '';
          var assignmentString = '';
          var colorString = ''; // console.log('self.employees',self.employees);

          if (self.employees[clickedIndex].days[clickedDayIndex].text.indexOf('OFF') > -1) {
            // If OFF...
            // Highlight the OFF part of the dialog...
            self.editShiftDialog.$el.find('.buttonWorking').removeClass('button-active');
            self.editShiftDialog.$el.find('.buttonOff').addClass('button-active');
            self.editShiftDialog.$el.find('input').attr("readonly", "readonly");
            self.editShiftDialog.$el.find('.assignmentInput').attr('placeholder', 'OFF');
            self.editShiftDialog.$el.find('.editShiftForm').hide(); // TODO: should there be a type of PTO thing...?  Should be able to choose startDay / endDay
          } else {
            self.editShiftDialog.$el.find('.buttonWorking').addClass('button-active');
            self.editShiftDialog.$el.find('.buttonOff').removeClass('button-active');
            self.editShiftDialog.$el.find('input').removeAttr("readonly");
            self.editShiftDialog.$el.find('.assignmentInput').removeAttr('placeholder');
            self.editShiftDialog.$el.find('.editShiftForm').show();
            assignmentString = self.employees[clickedIndex].days[clickedDayIndex].assignment;
            colorString = self.employees[clickedIndex].days[clickedDayIndex].color;

            if (self.employees[clickedIndex].days[clickedDayIndex].eventObject) {
              startTimeString = moment(self.employees[clickedIndex].days[clickedDayIndex].eventObject.startDateTime).format('HH:mm');
              endTimeString = moment(self.employees[clickedIndex].days[clickedDayIndex].eventObject.endDateTime).format('HH:mm');
            }
          }

          self.editShiftDialog.$el.find('.startTimeInput').val(startTimeString);
          self.editShiftDialog.$el.find('.endTimeInput').val(endTimeString);
          self.editShiftDialog.$el.find('.assignmentInput').val(assignmentString);
          self.editShiftDialog.$el.find('.colorInput').val(colorString).css('background', colorString);
          self.editShiftDialog.open();
        } else {
          self.$app.dialog.alert('This shift belongs to another department.  You need to change/remove it from the other schedule instead of this one.', 'Other Department');
        }
      }); // TODO: destroy self.editShiftDialog on page close...?
    },
    pageAfterOut: function (e, page) {
      var self = this;

      if (self.pickDateCalendarModal) {
        self.pickDateCalendarModal.destroy();
      }
    }
  },
  id: '2a0cb32611',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="schedulePage">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.showAllDaysOfTheWeek, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.if.call(ctx_2, ctx_2.displayDatesArray, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.js.call(ctx_3, "this.displayDatesArray[0].date.format('M/D')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' – ';
              r += Template7Helpers.js.call(ctx_3, "this.displayDatesArray[6].date.format('M/D')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += 'Today\'s Schedule';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.user.isAdmin, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					';
          r += Template7Helpers.if.call(ctx_2, ctx_2.editing, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<a class="link" @click="saveSchedule">Done</a>\n					';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<a class="link icon-only" @click="editSchedule">\n						<!--<i class="icon f7-icons if-not-md">square_pencil</i>-->\n						<i class="icon material-icons">edit</i>\n		            </a>\n		            ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n		            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n			</div>\n		</div>\n		';
      r += Template7Helpers.if.call(ctx_1, ctx_1.editing, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n		<div class="toolbar toolbar-bottom">\n			<div class="toolbar-inner" style="justify-content: space-between !important;">\n				<a href="#" class="link icon-only popover-open" data-popover=".actions-menu">\n					<i class="icon f7-icons if-not-md">gear_alt_fill</i>\n					<i class="icon material-icons md-only">settings</i>\n				</a>\n				<button class="button button-raised button-fill submitFinalButton standOutButton" style="max-width: 300px; margin-left: auto;" @click="submitFinal">';
          r += c(ctx_2.statusButtonText, ctx_2);
          r += '</button>\n			</div>\n		</div>\n		';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n		<div class="page-content workSchedulePageContent">\n			';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="block block-strong loadingBlock">\n				<p><div class="preloader"></div> Loading...</p>\n			</div>\n			';
          r += Template7Helpers.if.call(ctx_2, ctx_2.$root.user.isAdmin, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.showAllDaysOfTheWeek, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.displayDatesArray, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.$root.user.isAdmin, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, root.employees.length, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.isFinalized, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.showAllDaysOfTheWeek, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.isToday, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.isToday, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.notes, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.showAllDaysOfTheWeek, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.employees.length, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.isEven, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.if.call(ctx_3, root.employees.length, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.if.call(ctx_3, ctx_3.nextShiftBadge.color, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.if.call(ctx_3, ctx_3.isToday, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.if.call(ctx_3, root.editing, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.if.call(ctx_3, root.editing, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.draggable, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += Template7Helpers.if.call(ctx_4, ctx_4.draggable, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.if.call(ctx_3, ctx_3.assignment, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.text, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += Template7Helpers.if.call(ctx_4, ctx_4.color, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += Template7Helpers.if.call(ctx_4, ctx_4.color, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.if.call(ctx_3, root.editing, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.if.call(ctx_3, root.showAllDaysOfTheWeek, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.editing, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.isToday, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.isToday, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, root.editing, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.notes, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.if.call(ctx_3, ctx_3.everyoneNotes, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, root.showAllDaysOfTheWeek, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.displayDatesArray, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, root.getCurrentCalendarNotes, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n			';
          r += Template7Helpers.unless.call(ctx_2, root.isFinalized, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n			';
              r += Template7Helpers.unless.call(ctx_3, root.editing, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n			';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.$root.user.isAdmin, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n			<h2 class="scheduleIsNotFinal">*This Schedule is not Final</h2>\n			';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n			';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n			';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n			<div class="card data-table data-table-init scheduleDataTable"><!-- data-table-collapsible-->\n				<div class="card-header">\n					<div class="data-table-title"><a class="link button button-fill popover-open" data-popover=".departmentSchedule-menu">';
          r += c(ctx_2.pageTitle, ctx_2);
          r += '</a></div>\n					<div class="data-table-actions" style="flex-direction: row-reverse;">\n						';
          r += Template7Helpers.if.call(ctx_2, ctx_2.showAllDaysOfTheWeek, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n						<a class="link button button-fill popover-open" style="margin-left: 1em;" data-popover=".weeks-menu">\n							<span>';
              r += Template7Helpers.if.call(ctx_3, ctx_3.displayDatesArray, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.js.call(ctx_4, "this.displayDatesArray[0].date.format('M/D')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' – ';
                  r += Template7Helpers.js.call(ctx_4, "this.displayDatesArray[6].date.format('M/D/YYYY')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</span>\n						</a>\n						';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						<!--\n						<a class="link icon-only">\n							<i class="icon f7-icons if-not-md">sort</i>\n							<i class="icon material-icons md-only">sort</i>\n						</a>\n						<a class="link icon-only">\n							<i class="icon f7-icons if-not-md">more_vertical_round</i>\n							<i class="icon material-icons md-only">more_vert</i>\n						</a>\n						-->\n						<!--\n						<a class="link icon-only">\n							<i class="icon f7-icons">print</i>\n						</a>\n						-->\n						';
          r += Template7Helpers.unless.call(ctx_2, ctx_2.editing, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n						<a class="link icon-only text-color-black popover-open" data-popover=".sharing-menu">\n							<i class="icon f7-icons if-not-md">square_arrow_up</i>\n							<i class="icon material-icons md-only">share</i>\n						</a>\n						';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n					</div>\n					\n				</div>\n				<div class="card-content"';
          r += Template7Helpers.unless.call(ctx_2, root.isFinalized, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.unless.call(ctx_3, root.editing, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.$root.user.isAdmin, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' style="color: #f00;"';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '>\n					';
          r += Template7Helpers.js_if.call(ctx_2, "((this.isFinalized) || (this.$root.user.isAdmin))", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<table class="weeklySchedule">\n						<thead>\n							<tr>\n								';
              r += Template7Helpers.if.call(ctx_3, root.employees.length, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n								<th class="label-cell nameColumn topLeftColumn" data-collapsible-title="Name" style="background: #fff;">\n									';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.isFinalized, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n									';
                      r += Template7Helpers.unless.call(ctx_5, ctx_5.editing, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n									';
                          r += Template7Helpers.unless.call(ctx_6, ctx_6.screenIsLarge, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n									<label class="toggle color-green showAllDates">\n										<input type="checkbox"';
                              r += Template7Helpers.if.call(ctx_7, ctx_7.showAllDaysOfTheWeek, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += ' checked';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                              });
                              r += '>\n										<span class="toggle-icon"></span>\n									</label>\n									';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '\n									';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '\n									';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n								</th>\n								';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n								';
              r += Template7Helpers.unless.call(ctx_3, ctx_3.editing, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n								';
                  r += Template7Helpers.unless.call(ctx_4, ctx_4.screenIsLarge, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n								';
                      r += Template7Helpers.unless.call(ctx_5, ctx_5.showAllDaysOfTheWeek, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n								<th class="label-cell nextInCell">Next In</th>\n								';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '\n								';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n								';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n								';
              r += Template7Helpers.each.call(ctx_3, ctx_3.displayDatesArray, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n								<th class="label-cell';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.isToday, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' todayCell';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '">';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.isToday, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += 'Today (';
                      r += Template7Helpers.js.call(ctx_5, "this.date.format('ddd')", {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += ')';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += c(ctx_5.dayName, ctx_5);
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '<span class="dateNumber"> ';
                  r += Template7Helpers.js.call(ctx_4, "this.date.format('M/D')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += Template7Helpers.if.call(ctx_4, ctx_4.notes, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<span style="font-style: italic;"> (* Notes Below)</span>';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</span></th>\n								';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n								';
              r += Template7Helpers.if.call(ctx_3, ctx_3.showAllDaysOfTheWeek, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n								<th class="label-cell">TOTAL</th>\n								';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n							</tr>\n						</thead>\n						<tbody>\n							';
              r += Template7Helpers.if.call(ctx_3, ctx_3.employees.length, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n							';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.employees, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n							<tr class="';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.isEven, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += 'even';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += 'odd';
                          return r;
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '" data-row-index="';
                      r += c(data_5 && data_5.index, ctx_5);
                      r += '">\n								';
                      r += Template7Helpers.if.call(ctx_5, root.employees.length, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '<td class="label-cell nameColumn" style="font-weight: bold;">';
                          r += Template7Helpers.unless.call(ctx_6, root.isFinalized, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += Template7Helpers.unless.call(ctx_7, root.editing, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '*';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                              });
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += c(ctx_6.name, ctx_6);
                          r += '</td>';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '\n								';
                      r += Template7Helpers.unless.call(ctx_5, root.editing, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n								';
                          r += Template7Helpers.unless.call(ctx_6, root.screenIsLarge, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n								';
                              r += Template7Helpers.unless.call(ctx_7, root.showAllDaysOfTheWeek, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n								<td class="label-cell nextInCell">';
                                  r += Template7Helpers.if.call(ctx_8, ctx_8.nextShiftBadge.color, {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function (ctx_9, data_9) {
                                      var r = '';
                                      r += '<span class="badge color-';
                                      r += c(ctx_9.nextShiftBadge.color, ctx_9);
                                      r += ' badge-empty">&nbsp;</span>';
                                      return r;
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [ctx_7, ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                                  });
                                  r += c(ctx_8.nextShiftText, ctx_8);
                                  r += '</th>\n								';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                              });
                              r += '\n								';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '\n								';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '\n								';
                      r += Template7Helpers.each.call(ctx_5, ctx_5.days, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n								<td class="label-cell droppable-cell';
                          r += Template7Helpers.if.call(ctx_6, ctx_6.isToday, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += ' todayCell';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += Template7Helpers.if.call(ctx_6, root.editing, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += ' editing';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '">';
                          r += Template7Helpers.if.call(ctx_6, root.editing, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '<div class="chip';
                              r += Template7Helpers.if.call(ctx_7, ctx_7.draggable, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += ' shiftChip';
                                  return r;
                                },
                                inverse: function (ctx_8, data_8) {
                                  var r = '';
                                  r += ' ptoChip';
                                  return r;
                                },
                                root: root,
                                parents: [ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                              });
                              r += '" data-day="';
                              r += c(ctx_7.dayNameLowercase, ctx_7);
                              r += '" data-dayIndex="';
                              r += c(data_7 && data_7.index, ctx_7);
                              r += '" id="';
                              r += c(ctx_7.dayNameLowercase, ctx_7);
                              r += c(ctx_7.employeeIndex, ctx_7);
                              r += '"';
                              r += Template7Helpers.if.call(ctx_7, ctx_7.draggable, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += ' draggable="true"';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                              });
                              r += '><div class="chip-label">';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += c(ctx_6.text, ctx_6);
                          r += Template7Helpers.if.call(ctx_6, ctx_6.assignment, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += Template7Helpers.if.call(ctx_7, ctx_7.text, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '<br>';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                              });
                              r += '<span class="assignment';
                              r += Template7Helpers.if.call(ctx_7, ctx_7.color, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += ' assignmentHasColor';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                              });
                              r += '"';
                              r += Template7Helpers.if.call(ctx_7, ctx_7.color, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += ' style="color: ';
                                  r += c(ctx_8.color, ctx_8);
                                  r += ';"';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                              });
                              r += '>';
                              r += c(ctx_7.assignment, ctx_7);
                              r += '</span>';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += Template7Helpers.if.call(ctx_6, root.editing, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '</div></div>';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '</td>\n								';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '\n								';
                      r += Template7Helpers.if.call(ctx_5, root.showAllDaysOfTheWeek, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n								<td class="label-cell">';
                          r += c(ctx_6.totalHours, ctx_6);
                          r += '</td>\n								';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '\n							</tr>\n							';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n							';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n							';
              r += Template7Helpers.if.call(ctx_3, ctx_3.editing, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n							<tr>\n								<th class="label-cell nameColumn" style="background: #fff;"></th>\n								';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.displayDatesArray, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n								<th class="label-cell';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.isToday, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += ' todayCell';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '" style="white-space: nowrap;">';
                      r += c(ctx_5.totalHours, ctx_5);
                      r += ' hours</th>\n								';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n								<th class="label-cell" style="white-space: nowrap;">';
                  r += c(ctx_4.employeesHoursTotals, ctx_4);
                  r += ' hours</th>\n							</tr>\n							';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n\n							<!-- TODO: check if there are ANY notes... -->\n							<tr>\n								<td class="label-cell nameColumn" style="background: #fff; text-align: right;">Notes:</td>\n								';
              r += Template7Helpers.unless.call(ctx_3, ctx_3.editing, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n								';
                  r += Template7Helpers.unless.call(ctx_4, ctx_4.screenIsLarge, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n								';
                      r += Template7Helpers.unless.call(ctx_5, ctx_5.showAllDaysOfTheWeek, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n								<td class="label-cell nextInCell">&nbsp;</td>\n								';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '\n								';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n								';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n								';
              r += Template7Helpers.each.call(ctx_3, ctx_3.displayDatesArray, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n								<td valign="top" class="label-cell';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.isToday, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' todayCell';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '">\n									';
                  r += Template7Helpers.if.call(ctx_4, root.editing, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n									<div>';
                      r += c(root.pageTitle, ctx_5);
                      r += ' Notes:</div>\n									<div @texteditor:change="editorChanged" data-dayIndex="';
                      r += c(data_5 && data_5.index, ctx_5);
                      r += '" class="text-editor text-editor-notes text-editor-init text-editor-resizable" data-placeholder="Notes..." data-buttons=\'["bold", "underline"]\'>\n										<div class="text-editor-content" contenteditable></div>\n									</div>\n									<!--\n									<div>All Dept. Notes:</div>\n									<div @texteditor:change="editorChanged" data-dayIndex="';
                      r += c(data_5 && data_5.index, ctx_5);
                      r += '" class="text-editor text-editor-everyone-notes text-editor-init text-editor-resizable" data-placeholder="All Schedule(s) Notes..." data-buttons=\'["bold", "underline"]\'>\n										<div class="text-editor-content" contenteditable></div>\n									</div>\n									-->\n									';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.notes, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += Template7Helpers.if.call(ctx_5, ctx_5.everyoneNotes, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n									';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.notes, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n									<div>';
                          r += c(ctx_6.notes, ctx_6);
                          r += '</div>\n									';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '\n									<!--\n									';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.everyoneNotes, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n									<div>';
                          r += c(ctx_6.everyoneNotes, ctx_6);
                          r += '</div>\n									';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '\n									-->\n									';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n								</td>\n								';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n								';
              r += Template7Helpers.if.call(ctx_3, root.showAllDaysOfTheWeek, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n								<td class="label-cell" style="white-space: nowrap;">&nbsp;</td>\n								';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n							</tr>\n						</tbody>\n					</table>\n					';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<div class="block">\n						<p style="padding: 1em; font-size: larger;"><strong>';
              r += c(ctx_3.pageTitle, ctx_3);
              r += ' Schedule</strong><br><br>Week ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.displayDatesArray, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.js.call(ctx_4, "this.displayDatesArray[0].date.format('M/D/YYYY')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' – ';
                  r += Template7Helpers.js.call(ctx_4, "this.displayDatesArray[6].date.format('M/D/YYYY')", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' has not been finalized and submitted.  Please talk to the manager of this department about the ETA for this schedule.</p>\n					</div>\n					';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n					';
          r += Template7Helpers.if.call(ctx_2, root.getCurrentCalendarNotes, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<div class="block">\n						<p style="padding: 1em;">Notes: ';
              r += c(root.getCurrentCalendarNotes, ctx_3);
              r += '</p>\n					</div>\n					';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				</div>\n			</div>\n			';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n		</div>\n\n		<div class="popover departmentSchedule-menu">\n			<div class="popover-angle"></div>\n			<div class="popover-inner">\n				<div class="list">\n					<ul>\n						';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.editing, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n						<!--<li><a href="#" class="list-button item-link popover-close" @click="clickedTab" data-calendarId="-1">Everybody</a></li>--><!-- TODO! -->\n						';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n						';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.taskMasterData.schedulesCalendars, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n						<li><a href="#" class="list-button item-link popover-close" @click="clickedTab" data-calendarId="';
          r += c(ctx_2.id, ctx_2);
          r += '">';
          r += c(ctx_2.name, ctx_2);
          r += '</a></li>\n						';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					</ul>\n				</div>\n			</div>\n		</div>\n\n\n\n		<div class="popover weeks-menu">\n			<div class="popover-angle"></div>\n			<div class="popover-inner">\n				<div class="list">\n					<ul>\n						<li><a href="#" class="list-button item-link popover-close currentWeek" @click="pickedWeek" data-week="currentWeek">Current Week</a></li>\n						<li><a href="#" class="list-button item-link popover-close nextWeek" @click="pickedWeek" data-week="nextWeek">Next Week</a></li>\n						<li><a href="#" class="list-button item-link popover-close twoWeeks" @click="pickedWeek" data-week="twoWeeks">Two Weeks</a></li>\n						<li><a href="#" class="list-button item-link popover-close pickWeek" @click="pickedWeek" data-week="pickWeek">Pick a Different Week</a></li>\n					</ul>\n				</div>\n			</div>\n		</div>\n\n\n		\n		<div class="popover actions-menu">\n			<div class="popover-angle"></div>\n			<div class="popover-inner">\n				<div class="list">\n					<ul>\n						<li class="item-divider">Admin Actions:</li>\n						';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.isFinalized, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n						<li><a href="#" class="list-button item-link popover-close" @click="copyPreviousWeek">Copy Previous Week</a></li>\n						';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n						<li><a href="#" class="list-button item-link popover-close" @click="toggleAllEmployees"><i class="icon f7-icons if-not-md">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.showAllEmployees, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'checkmark_circle';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += 'circle';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</i><i class="icon material-icons md-only">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.showAllEmployees, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'check_box';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += 'check_box_outline_blank';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</i> Show all employees</a></li>\n					</ul>\n				</div>\n			</div>\n		</div>\n		<div class="popover sharing-menu">\n			<div class="popover-angle"></div>\n			<div class="popover-inner">\n				<div class="list">\n					<ul>\n						<li><a href="#" class="list-button item-link popover-close" @click="printSchedule">Print Schedule</a></li>\n						<li><a href="#" class="list-button item-link popover-close" @click="emailSchedule">Email Schedule</a></li>\n					</ul>\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.loadingBlock {
		text-align: center;
	}

	.scheduleIsNotFinal {
		position: fixed;
		z-index: 20;
		top: 40%;
		left: 0px;
		right: 0px;
		pointer-events: none;
		opacity: 0.7;
		text-shadow: 0px 0px 3px #fff;
		font-style: italic;
		color: #f00;
		text-align: center;
	}

	.page-content.workSchedulePageContent {
		overflow: hidden;
	}

	.page-content.workSchedulePageContent .card {
		position: absolute;
		left: 0px;
		right: 0px;
		bottom: calc(var(--f7-page-toolbar-bottom-offset, 0px) + var(--f7-safe-area-bottom) + var(--f7-page-content-extra-padding-bottom, 0px));
		top: calc(var(--f7-page-navbar-offset, 0px) + var(--f7-page-toolbar-top-offset, 0px) + var(--f7-page-subnavbar-offset, 0px) + var(--f7-page-searchbar-offset, 0px) + var(--f7-page-content-extra-padding-top, 0px));
	}

	.page-content.workSchedulePageContent .card .card-content {
		position: absolute;
		left: 0px;
		right: 0px;
		bottom: 0px;/* var(--f7-table-footer-height); */
		top: var(--f7-table-card-header-height);
	}

	.page-content.workSchedulePageContent .card .nameColumn {
		position: -webkit-sticky;
		position: sticky;
		left: 0px;
		z-index: 16;
	}

	/*
	.page-content.workSchedulePageContent .card .label-cell:after {
		content: '';
		position: absolute;
		background-color: #666;
		display: block;
		z-index: 15;
		top: 0;
		right: 0;
		bottom: 0;
		left: auto;
		height: 100%;
		width: 1px;
		transform-origin: 50% 0%;
		transform: scaleY(calc(1 / 1));
		transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
	}
	*/

	.page-content.workSchedulePageContent .card .label-cell:after, .page-content.workSchedulePageContent .card th:after {
		content: '';
		pointer-events: none;
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		border-right: 1px solid #666;
	}

	.page-content.workSchedulePageContent .card th:after {
		border-bottom: 1px solid #666;
	}

	.page-content.workSchedulePageContent .card th/*, .device-android .page-content.workSchedulePageContent .card th.nameColumn*/ {
		position: -webkit-sticky;
		position: sticky;
		background: #fff;
		top: 0px;
		z-index: 17;
	}

	.page-content.workSchedulePageContent .card th.nameColumn {
		z-index: 18;
	}

	/* TODO: android is glitchy!  How can I polyfill it to make it better?
	.device-android .page-content.workSchedulePageContent .card .nameColumn, .device-android .page-content.workSchedulePageContent .card th {
		position: relative;
		top: unset;
		left: unset;
	}
	*/

	.page-content.workSchedulePageContent .card .nextInCell {
		border-right: 2px solid #000;
	}

	.label-cell.droppable-cell.editing {
		padding-left: 0px;
		padding-right: 0px;
		text-align: center;
	}
	/*
	.label-cell.droppable-cell {
		border-right: 1px solid #666;
		border-left: 1px solid #666;
	}
	*/
	.label-cell.droppable-cell.editing .chip {
		margin: 0px auto;
		text-align: left;
	}

	.label-cell .dateNumber {
		display: block;
	}
	/*
	.card-footer.scheduleFooter {
		dsisplay: block;
	}
	*/
	.scheduleFooter .button {
		margin: 0.5em;
	}
	
	table.weeklySchedule tr.even td {
		background: #eee;
	}
	
	table.weeklySchedule tr.odd td {
		background: #fff;
	}
	
	table.weeklySchedule tr td.todayCell, .page-content.workSchedulePageContent .card th.todayCell {
		background: #FFEDCC;
		
	}
	
	.page-content.workSchedulePageContent .card th.todayCell {
		color: #000;
		
	}

	.data-table.scheduleDataTable th.label-cell, .data-table.scheduleDataTable td.label-cell {
		--f7-table-cell-padding-left: calc(var(--f7-table-label-cell-padding-horizontal) / 2);
		--f7-table-cell-padding-right: calc(var(--f7-table-label-cell-padding-horizontal) / 2);
	}
	
	.shiftChip {
		cursor: pointer;
		line-height: 1em;
	}
	
	.shiftChip.over {
		background: #fff;
		color: #000;
		text-decoration: underline;
		font-style: italic;
		
	}
	
	.shiftChip.isDragging {
		opacity: 0.2;
	}
	
	.ptoChip {
		background: transparent;
		text-decoration: underline;
		font-style: italic;
		line-height: normal;
		height: auto;
		opacity: 0.5;
	}
	
	.ptoChip .chip-label {
		white-space: normal;
		
	}
	
	.assignment {
		white-space: nowrap;
		font-style: italic;
		opacity: 0.5;

	}

	.assignmentHasColor {
		opacity: 1;
		font-weight: bold;
	}
	
	/*
	.md .editShiftDialog .dialog-title + .dialog-text {
		margin-top: 0px;
		
	}
	*/
	
	.editShiftDialog .inputLabel {
		position: relative;
		top: 1.4em;
		
	}
	
	/* // if (self.stickyNames) {
	.data-table td.nameColumn, .data-table td.nameColumn {
		position: fixed;
		z-index: 10;
	}
	
	table.weeklySchedule tr.odd td.nameColumn {
		background: #fff;
	}
	*/
	
	.smallerPicker .color-picker-palette {
		justify-content: stretch;
	}

	.smallerPicker .color-picker-palette-value {
		width: unset;
		flex: 1;
	}

	.text-editor-notes .text-editor-toolbar, .text-editor-everyone-notes .text-editor-toolbar {
		z-index: unset;
	}

	.text-editor-notes, .text-editor-everyone-notes {
		min-width: 7em;
		margin-top: 0px;
	}

`,
  styleScoped: false
};
    