
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {
      loading: true,
      currentDate: moment().toDate(),
      rightNowDate: moment().toDate(),
      rightNowInterval: null,
      todaysTimecards: []
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && (data.data == 'employees' || data.data == 'timecards')) {
        self.refreshScreen();
      }
    },
    clickedClockInOrOut: function (e) {
      var self = this;
      var clickedButton = self.$(e.target).closest('.clock-button');
      var circleElement = clickedButton.find('.circle');
      self.$root.clockInOrOut();
    },
    rightNowIntervalStart: function (timeToWait) {
      var self = this;
      self.rightNowIntervalClear();
      self.rightNowInterval = setTimeout(function () {
        self.rightNowDate = moment().toDate();
        self.$el.find('.current-clock-date').html(moment(self.rightNowDate).format('dddd, MMM D, YYYY'));
        self.$el.find('.current-clock-time').html(moment(self.rightNowDate).format("h:mm:ss A"));

        if (self.todaysTimecards.length > 0) {
          if (self.todaysTimecards[0].clockOutTime == 0) {
            // If we are in and have not clocked out yet...
            self.refreshCurrentTodaysHours();
          }
        }

        self.rightNowIntervalStart(1000);
      }, timeToWait);
    },
    rightNowIntervalClear: function () {
      var self = this;

      if (self.rightNowInterval !== null) {
        clearTimeout(self.rightNowInterval);
        self.rightNowInterval = null;
      }
    },
    refreshScreen: function (afterDone) {
      var self = this;
      afterDone = typeof afterDone == 'function' ? afterDone : function () {};

      if (self.$root.user.timecards) {
        var circleElement = self.$el.find('.circle');
        var clockedInLabel = self.$el.find('.clock-button-label');
        circleElement.removeClass('green');
        circleElement.removeClass('red');

        if (self.$root.user.isClockedIn) {
          circleElement.addClass('green'); //clockedInLabel.html('Currently: Clocked In');
        } else {
          circleElement.addClass('red'); //clockedInLabel.html('Currently: Clocked Out');
        } // The logs at the bottom!


        var inOutLogsHeaderHTML = 'Today';
        var inOutLogsHTML = '';

        if (self.$root.user.timecards) {
          self.todaysTimecards = self.$root.user.timecards.filter(function (value, index) {
            var d1 = self.rightNowDate;
            var d2 = moment(value.clockInDateTime).toDate(); //new Date(value.clockInDateTime);

            var isSameDay = self.$root.datesAreSameDay(d1, d2);
            return isSameDay || value.clockOutTime == 0 && index == 0; // Haven't clocked out same record...
          });
        } else {
          self.todaysTimecards = [];
        } //console.log('refreshScreen');
        //console.log(self.todaysTimecards);


        if (self.todaysTimecards.length > 0) {
          for (var i = 0; i < self.todaysTimecards.length; i++) {
            var currentClockInHTML = 'IN';
            var currentClockOutHTML = 'OUT';
            var inNeedsApproval = self.todaysTimecards[i].clockinNeedsApproval;
            var outNeedsApproval = self.todaysTimecards[i].clockoutNeedsApproval;
            var clockInTempDate = moment(self.todaysTimecards[i].clockInDateTime).toDate(); //new Date(self.todaysTimecards[i].clockInDateTime);

            var clockOutTempDate = moment(self.todaysTimecards[i].clockOutDateTime).toDate(); //new Date(self.todaysTimecards[i].clockOutDateTime);

            /*
            if (self.todaysTimecards[i].clockOutTime > 0) { // We have a date/time
            	currentClockOutHTML+= ' - '+moment(clockOutTempDate).format('h:mm A');
            	
            	inOutLogsHTML+= '<li><a href="#"'+((outNeedsApproval)?' style="background: #ffd700;"':'')+'>'+currentClockOutHTML+'</a></li>';
            } else {
            	inOutLogsHTML+= '<li><a href="#" style="font-style: italic; opacity: 0.4;">'+currentClockOutHTML+'</a></li>';
            }
            */

            if (self.todaysTimecards[i].clockInTime > 0) {
              // We have a date/time
              currentClockInHTML += ' - ' + moment(clockInTempDate).format('h:mma');
              inOutLogsHTML += '<li><a href="/timeclock/requestFix/' + encodeURIComponent(self.rightNowDate.toUTCString()) + '/"' + (inNeedsApproval | outNeedsApproval ? ' style="background: #ffd700;"' : '') + '><span>' + currentClockInHTML;

              if (self.todaysTimecards[i].clockOutTime > 0) {
                // We have a date/time
                currentClockOutHTML += ' - ' + moment(clockOutTempDate).format('h:mma');
                inOutLogsHTML += '<span>, ' + currentClockOutHTML + '</span>';
              } else {//inOutLogsHTML+= '<span style="font-style: italic; opacity: 0.4;">, '+currentClockOutHTML+' 0:00</span>';
              }

              inOutLogsHTML += '</span></a></li>'; // +'</a></li>'
            } else {
              inOutLogsHTML += '<li><a href="#">&nbsp;</a></li>';
            }
          }
        } else {
          inOutLogsHTML += '<li><a href="#" style="font-size: 0.8em; color: #ccc; font-style: italic;">No Hours Logged</a></li>';
        }

        self.$el.find('.inOutLinks ul').html(inOutLogsHTML);
        self.refreshCurrentTodaysHours(); // TODO: set up link actions here.... for the in/outs... (notes maybe?)

        var startDayOfTheWeek = moment().startOf('week').toDate(); //new Date().getFirstDayOfWeek();

        var lastDayOfTheWeek = moment().endOf('week').toDate(); //new Date().getLastDayOfWeek();

        var yesterday = moment().subtract(1, 'days').toDate(); //new Date();
        //yesterday.setDate(yesterday.getDate()-1);
        // TODO: set TIME to midnight:01 on startDayOfTheWeek
        // TODO! This should change to use MOMENT (because of the timezone default set...)

        var startDayOfThePayPeriod = new Date();
        var lastDayOfThePayPeriod = new Date();

        if (startDayOfThePayPeriod.getDate() >= 16) {
          startDayOfThePayPeriod.setDate(16);
          lastDayOfThePayPeriod.setDate(1);
          lastDayOfThePayPeriod.setMonth(lastDayOfThePayPeriod.getMonth() + 1);
          lastDayOfThePayPeriod.setDate(lastDayOfThePayPeriod.getDate() - 1);
        } else {
          startDayOfThePayPeriod.setDate(1);
          lastDayOfThePayPeriod.setDate(15);
        } // TODO: set TIME to midnight:01 on startDayOfThePayPeriod & lastDay
        // TODO: weekToDate should show everyday even 0 hour days!


        var currentEndDateToUse = yesterday;
        currentEndDateToUse = moment().toDate(); //new Date();

        var weekToDateDataHTML = '<div class="block-title">Week to Date: (' + moment(startDayOfTheWeek).format('M/D') + '-' + moment(lastDayOfTheWeek).format('M/D/YY') + ')</div>';

        if (currentEndDateToUse >= startDayOfTheWeek) {
          self.$el.find('.weekToDateData').html(weekToDateDataHTML + self.$root.getPastTimeCardList(startDayOfTheWeek, currentEndDateToUse, true));
        } else {
          self.$el.find('.weekToDateData').html(weekToDateDataHTML);
        }

        var payPeriodToDateDataHTML = '<div class="block-title">Current Pay Period: (' + moment(startDayOfThePayPeriod).format('M/D') + '-' + moment(lastDayOfThePayPeriod).format('M/D/YY') + ')</div>';

        if (currentEndDateToUse >= startDayOfThePayPeriod) {
          self.$el.find('.currentPayPeriodData').html(payPeriodToDateDataHTML + self.$root.getPastTimeCardList(startDayOfThePayPeriod, currentEndDateToUse, true));
        } else {
          self.$el.find('.currentPayPeriodData').html(payPeriodToDateDataHTML);
        }

        self.$el.find('.loadingBlock').hide();
        self.$el.find('.clockOutInButton').show();
      }
    },
    refreshCurrentTodaysHours: function () {
      var self = this;
      var totalHours = 0;
      var currentOpenHours = 0; // NOT clocked out...

      if (self.todaysTimecards.length > 0) {
        for (var i = 0; i < self.todaysTimecards.length; i++) {
          var currentHoursHere = 0; //var clockInTempDate= new Date(self.todaysTimecards[i].clockInDateTime);

          var clockInTempDate = moment(self.todaysTimecards[i].clockInDateTime).toDate();

          if (self.todaysTimecards[i].ptoAmount !== 0) {
            // This is a PTO record!
            if (self.todaysTimecards[i].ptoAmount > 0) {
              // This means we are USING PTO....
              currentHoursHere = self.todaysTimecards[i].ptoAmount;
            }
          } else {
            if (self.todaysTimecards[i].clockOutTime > 0) {
              // We have a date/time
              //var clockOutTempDate= new Date(self.todaysTimecards[i].clockOutDateTime);
              var clockOutTempDate = moment(self.todaysTimecards[i].clockOutDateTime).toDate();
              currentHoursHere = Math.abs(clockOutTempDate - clockInTempDate) / 36e5;
            } else {
              currentHoursHere = Math.abs(self.rightNowDate - clockInTempDate) / 36e5;
              currentOpenHours += currentHoursHere;
            }
          }

          totalHours += currentHoursHere;
        }
      }

      var hoursString = '0';

      if (totalHours > 0) {
        hoursString = totalHours.toFixed(2);

        if (totalHours < 1) {
          hoursString = totalHours.toFixed(2);
        }
      }

      self.$el.find('.card-header').html('<strong>Today</strong><span>' + hoursString + ' hours</span>');

      if (currentOpenHours > 0) {
        self.$el.find('a.currentDateTimecardItemLink div.item-after').html(hoursString + ' hours');
        self.$el.find('div.thisIncludesCurrentDate').each(function (index, element) {
          var thisElement = self.$(element);
          var currentHours = parseFloat(thisElement.attr('data-original-total'));
          thisElement.html('Total: ' + (currentHours + currentOpenHours).toFixed(2) + ' Hours');
        });
      }
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.refreshScreen();
    },
    pageBeforeIn: function (e, page) {
      var self = this;
      self.rightNowIntervalStart(0);
    },
    pageBeforeOut: function (e, page) {
      var self = this;
      self.rightNowIntervalClear();
    }
  },
  id: 'b9d8ddf6f7',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="clockInOutPage">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">Clock In/Out</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text">Clock In/Out</div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="page-content">\n			<div class="block no-margin no-padding">\n				<div class="clock-date"><span class="current-clock-date"></span></div>\n				<div class="clock-time"><span class="current-clock-time"></span></div>\n				<div class="row" style="margin-bottom: 2em;">\n					<div class="col-100 loadingBlock">\n						<p><div class="preloader"></div> Loading...</p>\n					</div>\n					<div class="col-100 clockOutInButton" style="display: none;"><span id="clockout-button" class="clock-button"><div class="circle ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.user.isClockedIn, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'green';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += 'red';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" @click="clickedClockInOrOut" style="cursor: pointer;"><div class="hour-hand"></div><div class="minute-hand-out"></div></div></span></div>\n				</div>\n				<div class="card">\n					<div class="card-header"><strong>Today</strong></div>\n					<div class="card-content card-content-padding">\n						<div class="list links-list inOutLinks">\n							<ul>\n								<li><a href="#">IN/OUT</a></li>\n							</ul>\n						</div>\n					</div>\n				</div>\n				<div class="list links-list no-margin">\n					<ul>\n						<li><a href="/hr/" class="item-link item-content"><p><i class="icon material-icons">library_books</i> H.R. - See Timecard PDFs.</p></a></li>\n					</ul>\n				</div>\n				<div class="weekToDateData"></div>\n				<div class="currentPayPeriodData"></div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.loadingBlock {
		text-align: center;
	}

	.clock-date{
	  text-align: center;
	  padding: 10px;
	  padding-top: 25px;
	}
	.clock-time{
	  text-align: center;
	  padding: 20px;
	  padding-top: 0px;
	}
	.current-clock-date{
	  font-size: 20px;
	  color: rgba(0, 0, 0, 0.4);
	}
	.current-clock-time{
	  font-size: 30px;
	  color: rgba(0, 0, 0, 0.8);
	  
	  
	}
	.circle {
	  border: 20px solid rgb(255, 255, 255);
	  box-shadow: 0px 0px 5px #888888;
	  border-radius: 50%;
	  width: 80px;
	  height: 80px;
	  margin: auto;
	  /* -moz-box-sizing: border-box; */
	  /* box-sizing: border-box; */
	  
	}
	
	.circle.green {
	  background-color: green;
	}
	
	.circle.red {
	  background-color: red;
	}
	
	.hour-hand{
	  position: absolute;
	  background-color: white;
	  height: 45px;
	  width: 5px;
	  margin-left: 37px;
	}
	.minute-hand-in{
	  position: absolute;
	  background-color: white;
	  height: 5px;
	  width: 30px;
	  margin-left: 37px;
	  margin-top: 40px;
	}
	/*
	.minute-hand-out{
	
	}
	*/
	.clock-button-label{
	  text-align: center;
	  padding-top: 5px;
	}
	.history-section-label{
	  text-align: center;
	  font-size: 20px;
	  border-bottom: 1px solid black;
	  width: 80%;
	  margin: auto;
	  margin-top: 10px;
	}
	.inactive .circle.green, .inactive .circle.red {
	  background-color: grey;
	}
	.clockhistory-list-label{
	  margin-bottom: 5px !important;
	}
`,
  styleScoped: false
};
    