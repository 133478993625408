
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.reportsCategory = self.$route.params.reportsCategory;
  },
  data: function () {
    return {
      reportsCategory: '',
      pageTitle: '',
      reportsList: []
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'reports') {
        self.refreshPage();
      }
    },
    refreshPage: function () {
      var self = this;

      if (self.reportsCategory == 'sales') {
        self.pageTitle = 'Sales';
        self.reportsList = self.$root.taskMasterData.reports.sales.slice();
      } else if (self.reportsCategory == 'dc') {
        self.pageTitle = 'D.C.';
        self.reportsList = self.$root.taskMasterData.reports.dc.slice();
      } else if (self.reportsCategory == 'admin') {
        self.pageTitle = 'Admin';
        self.reportsList = self.$root.taskMasterData.reports.admin.slice();
      }

      self.$setState();
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.refreshPage();
    }
  },
  id: 'd835ab9915',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="blankPage">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">';
      r += c(ctx_1.pageTitle, ctx_1);
      r += ' Reports</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text">';
          r += c(ctx_2.pageTitle, ctx_2);
          r += ' Reports</div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="page-content">\n			<div class="list links-list no-margin">\n				<ul>\n					';
      r += Template7Helpers.each.call(ctx_1, ctx_1.reportsList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<li><a href="/reports/';
          r += c(root.reportsCategory, ctx_2);
          r += '/';
          r += Template7Helpers.js.call(ctx_2, "encodeURIComponent(this)", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '/" class="item-link item-content">';
          r += Template7Helpers.js.call(ctx_2, "(this.@index+1)", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '. ';
          r += c(ctx_2, ctx_2);
          r += '</a></li>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</ul>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	
`,
  styleScoped: false
};
    