
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.number = self.$route.params.number;
    self.name = decodeURIComponent(self.$route.params.name).trim();

    if (self.$app.data.taskMasterData['customerTextMessages' + self.number]) {
      self.messages = self.$app.data.taskMasterData['customerTextMessages' + self.number];
    }
  },
  data: function () {
    return {
      number: '',
      name: '',
      messagesObject: null,
      messageBar: null,
      conversationPhotoBrowser: null,
      messages: [],
      doneTransitioning: false
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data.indexOf('customerTextMessages') == 0) {
        if (data.phoneNumber == self.number) {
          self.messages = data[data.data];
          self.name = data.phoneNumberCallerId;
          self.$update(function () {
            self.refreshPage();
          });
        }
      }
    },
    refreshPage: function () {
      var self = this;

      if (!self.messagesObject) {
        self.messagesObject = self.$app.messages.create({
          el: '.messages',
          scrollMessages: true,
          scrollMessagesOnEdge: true,
          firstMessageRule: function (message, previousMessage, nextMessage) {
            if (message.isTitle) return false;
            if (!previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name) return true;
            return false;
          },
          lastMessageRule: function (message, previousMessage, nextMessage) {
            if (message.isTitle) return false;
            if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
            return false;
          },
          tailMessageRule: function (message, previousMessage, nextMessage) {
            if (message.isTitle) return false;
            if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
            return false;
          }
        });
      }

      if (!self.messageBar) {
        self.messageBar = self.$app.messagebar.create({
          el: '.messagebar',
          on: {
            focus: function () {
              var tempTimeout = setTimeout(function () {
                if (self.scrollToLatestMessage) {
                  self.scrollToLatestMessage(100);
                }
              }, self.$app.device.desktop ? 0 : 250); // TODO: timing just seems weird!
            }
          }
        });
      }

      var photosArray = [];

      if (!self.conversationPhotoBrowser) {
        // TODO: make this photobrowser and add images to it from below!
        self.conversationPhotoBrowser = self.$app.photoBrowser.create({
          theme: 'dark',
          toolbar: false,
          type: 'standalone',
          renderNavbar: function () {
            var navBarHTML = '';
            navBarHTML += '<div class="navbar">' + '<div class="navbar-bg"></div>' + '<div class="navbar-inner">' + '<div class="left">' + '<a href="#" class="link icon-only popup-close md-only"><i class="icon icon-back"></i></a>' + '<a href="#" class="link popup-close if-not-md">Close</a>' + '</div>' + '<div class="title sliding photosTitle">' + self.name + '</div>' + '<div class="right">' + '</div>' + '</div>' + '</div>';
            return navBarHTML;
          }
        });
      } else {
        photosArray = self.conversationPhotoBrowser.params.photos;
      }

      if (self.messages.length > 0) {
        var currentMoment = null; // TODO: this should be a global function....

        var emojiUnicodeRanges = ['\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]', ' ' // Also allow spaces
        ].join('|');

        var removeEmoji = function (str) {
          return str.replace(new RegExp(emojiUnicodeRanges, 'g'), '');
        };

        var isOnlyEmojis = function (str) {
          return !removeEmoji(str).length;
        };

        var howManyMinutesToSpitOutTitle = 45;

        if (self.messagesObject.messages.length == 0) {
          currentMoment = moment(new Date(self.messages[0].dateTime));
          self.messagesObject.addMessage({
            isTitle: true,
            text: currentMoment.calendar()
          }, 'append', self.doneTransitioning);
        } else {
          currentMoment = moment(new Date(self.messagesObject.messages[self.messagesObject.messages.length - 1].dateTime));
          self.messagesObject.removeMessage('.message.loadingSending');
        }

        currentMoment.add(howManyMinutesToSpitOutTitle, 'minutes');

        for (var i = 0; i < self.messages.length; i++) {
          var indexOfThisMessage = self.messagesObject.messages.map(function (elem) {
            return elem.id;
          }).indexOf(self.messages[i].id);

          if (indexOfThisMessage < 0) {
            // IF NOT FOUND...
            var thisMoment = moment(new Date(self.messages[i].dateTime));

            if (thisMoment.isSameOrAfter(currentMoment, 'minutes')) {
              self.messagesObject.addMessage({
                isTitle: true,
                text: thisMoment.calendar()
              }, 'append', self.doneTransitioning);
              thisMoment.add(howManyMinutesToSpitOutTitle, 'minutes');
              currentMoment = thisMoment;
            }

            var messageToAddObject = {
              id: self.messages[i].id,
              type: self.messages[i].isOutgoing ? 'sent' : 'received',
              dateTime: self.messages[i].dateTime
            };

            if (messageToAddObject.type == 'sent') {
              messageToAddObject.name = self.messages[i]._sendingEmployeeName;

              if (self.messages[i].sentSuccessfully) {
                messageToAddObject.avatar = self.$root.getImageURL(self.messages[i].sendingEmployeeId, 'jpg');
              } else {
                messageToAddObject.avatar = 'static/images/loader.svg';
              }
            } else {
              messageToAddObject.name = self.name;
              messageToAddObject.avatar = 'static/images/user.png';
            }

            if (self.messages[i].text !== '') {
              messageToAddObject.text = self.messages[i].text;

              if (isOnlyEmojis(messageToAddObject.text)) {
                messageToAddObject.cssClass = 'emojis';
                var emojisCount = messageToAddObject.text.replace(new RegExp(' ', 'g'), '').length / 2;

                if (emojisCount <= 3) {
                  messageToAddObject.cssClass += ' emojisZoom';
                }
              }
            } else {
              messageToAddObject.imageSrc = 'https://taskmaster.bedroomsandmore.com/4DACTION/getImage/textMessageAttachment/' + self.messages[i].id + '/';
              photosArray.push({
                url: messageToAddObject.imageSrc,
                caption: messageToAddObject.name + ', ' + moment(new Date(self.messages[i].dateTime)).calendar()
              });
              messageToAddObject.cssClass = 'photoBrowserImage';
              messageToAddObject.attrs = {
                'data-photo-index': photosArray.length - 1
              };
            }

            self.messagesObject.addMessage(messageToAddObject, 'append', self.doneTransitioning);
          }
        }
      }

      self.messages = []; // clear memory?  is this okay?

      self.conversationPhotoBrowser.params.photos = photosArray; // TODO: clone it?

      var tempTimeout = setTimeout(function () {
        if (self.scrollToLatestMessage) {
          self.scrollToLatestMessage(200);
        }
      }, 250); // TODO: how to make sure I scroll no matter what after an image loads...
    },
    sendMessage: function () {
      var self = this;
      var text = self.messageBar.getValue().trim();
      if (!text.length) return;
      self.messageBar.clear();
      self.messageBar.focus();
      var thisMessageId = -1 * (Math.floor(Math.random() * (99999 - 1 + 1)) + 1);
      self.messagesObject.addMessage({
        id: thisMessageId,
        text: text.replace(/\n/g, '<br>'),
        name: self.$app.data.user.name,
        avatar: 'static/images/loader.svg',
        cssClass: 'loadingSending'
      });
      self.$root.mdWebSocket.sendOverWebSocket({
        action: 'sendTextMessage',
        to: self.number,
        text: text,
        randomNumberId: thisMessageId
      });
    },
    scrollToLatestMessage: function (duration) {
      var self = this;
      duration = typeof duration == 'number' ? duration : 0;
      self.messagesObject.scroll(duration, 9999999); // TODO: set this to the height of page-content...
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.refreshPage();
      self.$el.on('click', '.photoBrowserImage', function (e) {
        var thisElement = self.$(e.target).closest('.photoBrowserImage');
        var imageIndexClicked = parseInt(thisElement.attr('data-photo-index'));

        if (imageIndexClicked > -1) {
          self.conversationPhotoBrowser.open(imageIndexClicked);
        }
      });
    },
    pageAfterIn: function (e, page) {
      var self = this;
      self.doneTransitioning = true;

      if (self.$app.device.desktop) {
        if (self.messageBar) {
          self.messageBar.focus();
        }
      }
    },
    pageBeforeOut: function (e, page) {
      var self = this;

      if (self.messagesObject) {
        self.messagesObject.destroy();
      }
    }
  },
  id: '47ffbe31be',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="customerTextsConversation">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.name, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += c(ctx_2.name, ctx_2);
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += c(ctx_2.number, ctx_2);
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text">';
          r += Template7Helpers.if.call(ctx_2, ctx_2.name, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.name, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.number, ctx_3);
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="toolbar messagebar">\n			<div class="toolbar-inner">\n				<div class="messagebar-area">\n					<textarea class="resizable" placeholder="Message"></textarea>\n				</div><a class="link send-link" href="#" @click="sendMessage">Send</a>\n			</div>\n		</div>\n		<div class="page-content messages-content">\n			<div class="messages">\n\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.message.emojis .message-bubble .message-text {
		zoom: 1.5;
	}
	/*
	.message.emojisZoom .message-bubble {
		background: none;
	}
	*/
	.message.emojisZoom .message-bubble .message-text {
		zoom: 3;
	}
`,
  styleScoped: false
};
    