
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {
      devices: [],
      devicePrettyNames: {
        payStations: 'Payment Station iPads',
        cardReaders: 'Credit Card Readers',
        priceTags: 'Digital Price Tags',
        phonePeers: 'Online Phones'
      }
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'devices') {
        self.refreshPage();
      }
    },
    refreshPage: function () {
      var self = this;
      self.devices = JSON.parse(JSON.stringify(self.$root.taskMasterData.devices)); // TODO!  Need to use the template above instead of HTML!!!!

      var deviceTypesArray = Object.keys(self.devices);
      deviceTypesArray.sort(); // TODO: need to sort the pretty names...

      for (var d = 0; d < deviceTypesArray.length; d++) {
        var key = deviceTypesArray[d];
        var thisName = key;

        if (self.devicePrettyNames[key]) {
          thisName = self.devicePrettyNames[key];
        }

        var thisDeviceHTML = '';
        thisDeviceHTML += '<a href="#" class="item-link item-content">' + '<div class="item-inner">' + '<div class="item-title">' + thisName + '</div>' + '<div class="item-after">&nbsp;</div>' + '</div>' + '</a>' + '<div class="accordion-item-content">' + '<div class="block">';

        if (key == 'priceTags') {
          for (var i = 0; i < self.devices[key].length; i++) {
            thisDeviceHTML += '<div class="list links-list">' + '<ul>' + '<li>' + '<a href="#">' + self.devices[key][i].name + '</a>' + '</li>' + '</ul>' + '</div>';
          }
        } else if (key == 'payStations') {
          // TODO: sort devices by location then floor...
          self.devices[key].sort(function (a, b) {
            if (a.locationId > b.locationId) return 1;
            if (b.locationId > a.locationId) return -1;
            return 0;
          });
          thisDeviceHTML += '<div class="list media-list">';
          var lastLocationId = -1;

          for (var i = 0; i < self.devices[key].length; i++) {
            if (self.devices[key][i].locationId !== lastLocationId) {
              if (i > 0) {
                thisDeviceHTML += '</ul>' + '</div>';
              }

              thisDeviceHTML += '<div class="list-group">' + '<ul>' + '<li class="list-group-title">' + self.devices[key][i]._locationName + '</li>';
            }

            if (!self.devices[key][i].cardReader) {
              self.devices[key][i].cardReader = {};
            }

            if (!self.devices[key][i].cardReader.status) {
              self.devices[key][i].cardReader.status = 'offline';
            }

            thisDeviceHTML += '<li>' + '<a class="item-link item-content">' + '<div class="item-inner">' + '<div class="item-title-row">' + '<div class="item-title">' + self.devices[key][i].name + '<span style="font-weight: normal; font-style: italic;">, ' + self.devices[key][i].nameAlternate + '</span></div>' + '<div class="item-after">' + (!self.devices[key][i].isOnline || self.devices[key][i].cardReader.status == 'offline' ? '<span class="badge badge-empty2 color-red"></span>' : self.devices[key][i].cardReader.status !== 'connected' ? '<span class="badge badge-empty2 color-yellow"></span>' : '<span class="badge badge-empty2 color-green"></span>') + '</div>' + '</div>' + '<div class="item-text"><em>' + self.devices[key][i].page + '<!--, Floor ' + self.devices[key][i].floorCode + '--></em></div>' + '<div class="item-footer">' + 'iPad: <strong>' + (self.devices[key][i].isOnline ? 'connected ' + (self.devices[key][i].batteryLevel == 100 ? '<i class="icon f7-icons ' + (self.devices[key][i].isPluggedIn ? 'color-green' : 'color-red') + '">battery_100</i>' : '<i class="icon f7-icons ' + (self.devices[key][i].isPluggedIn ? 'color-yellow' : 'color-red') + '">battery_25</i> (' + self.devices[key][i].batteryLevel + '%)') : '<span class="badge badge-empty color-red"></span> offline') + '</strong><br>Card Reader: <strong>' + (self.devices[key][i].cardReader.status == 'offline' ? '<span class="badge badge-empty color-red"></span> ' : '') + self.devices[key][i].cardReader.status + '</strong>' + '</div>' + '</div>' + '</a>' + '</li>';
            lastLocationId = self.devices[key][i].locationId;
          }

          thisDeviceHTML += '</ul>' + '</div>' + '</div>';
        } else if (key == 'phonePeers') {
          thisDeviceHTML += '<div class="block block-strong">';

          for (var i = 0; i < self.devices[key].length; i++) {
            var chipColor = 'gray';
            var originalCallerId = self.devices[key][i].callerId;

            if (self.devices[key][i].extensionStatus == 'Idle') {
              chipColor = 'green';
            } else if (self.devices[key][i].extensionStatus == 'Ringing') {
              chipColor = 'yellow';
            } else if (self.devices[key][i].extensionStatus == 'InUse') {
              chipColor = 'red';
            }

            if (self.devices[key][i].extensionStatus && self.devices[key][i].extensionStatus !== 'Unavailable') {
              var connectedCallerId = self.devices[key][i].extensionStatus;

              if (self.$app.data.user.isAdmin) {
                if (self.devices[key][i].ConnectedLineNum && self.devices[key][i].ConnectedLineNum !== '<unknown>') {
                  connectedCallerId = self.devices[key][i].ConnectedLineNum;

                  if (connectedCallerId !== '') {
                    connectedCallerId = self.$root.formatPhoneNumber(connectedCallerId);
                  }

                  if (self.devices[key][i].ConnectedLineName) {
                    connectedCallerId = self.devices[key][i].ConnectedLineName + ' - ' + connectedCallerId;
                  }
                }
              }

              if (connectedCallerId == 'Idle' || connectedCallerId == 'Unavailable') {
                connectedCallerId = '';
              }

              if (connectedCallerId !== '') {
                connectedCallerId = '<br>' + connectedCallerId;
                self.devices[key][i].callerId = '<strong>' + self.devices[key][i].callerId + '</strong>';
              }

              thisDeviceHTML += '<a class="chip phoneChip"' + (self.devices[key][i].extensionStatus == 'Unavailable' ? ' style="opacity: 0.3;"' : '') + ' data-extensionStatus="' + self.devices[key][i].extensionStatus + '" data-extension="' + self.devices[key][i].extension + '" data-name="' + originalCallerId + '">' + '<div class="chip-media bg-color-' + chipColor + '">' + '<i class="icon f7-icons if-not-md">phone_fill</i>' + '<i class="icon material-icons md-only">phone</i>' + '</div>' + '<div class="chip-label">' + self.devices[key][i].callerId + ' - ' + self.devices[key][i].extension + connectedCallerId + '</div>' + '</a>';
              /*
              thisDeviceHTML+= '<li>'+
              					'<a class="item-link item-content">'+
              						'<div class="item-inner">'+
              							'<div class="item-title-row">'+
              								'<div class="item-title">'+self.devices[key][i].callerId+'</div>'+
              								'<div class="item-floating-after"><div class="chip color-'+chipColor+'"><div class="chip-label">'+connectedCallerId+'</div></div></div>'+
              							'</div>'+
              							'<div class="item-footer">Ext. '+self.devices[key][i].extension+'</div>'+ // '&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;'+self.devices[key][i].name+
              						'</div>'+
              					'</a>'+
              				'</li>';
              */
            }
          }

          thisDeviceHTML += '</div>';
        } else if (key == 'phonePeers') {
          thisDeviceHTML += '<div class="list media-list phonePeers-list">' + '<ul>';

          for (var i = 0; i < self.devices[key].length; i++) {
            var chipColor = 'gray';

            if (self.devices[key][i].extensionStatus == 'Idle') {
              chipColor = 'green';
            } else if (self.devices[key][i].extensionStatus == 'Ringing') {
              chipColor = 'yellow';
            } else if (self.devices[key][i].extensionStatus == 'InUse') {
              chipColor = 'red';
            }

            if (self.devices[key][i].extensionStatus && self.devices[key][i].extensionStatus !== 'Unavailable') {
              var connectedCallerId = self.devices[key][i].extensionStatus;

              if (self.$app.data.user.isAdmin) {
                if (self.devices[key][i].ConnectedLineNum && self.devices[key][i].ConnectedLineNum !== '<unknown>') {
                  connectedCallerId = self.devices[key][i].ConnectedLineNum;

                  if (connectedCallerId !== '') {
                    connectedCallerId = self.$root.formatPhoneNumber(connectedCallerId);
                  }

                  if (self.devices[key][i].ConnectedLineName) {
                    connectedCallerId = self.devices[key][i].ConnectedLineName + ' - ' + connectedCallerId;
                  }
                }
              }

              thisDeviceHTML += '<li>' + '<a class="item-link item-content">' + '<div class="item-inner">' + '<div class="item-title-row">' + '<div class="item-title">' + self.devices[key][i].callerId + '</div>' + '<div class="item-floating-after"><div class="chip color-' + chipColor + '"><div class="chip-label">' + connectedCallerId + '</div></div></div>' + '</div>' + '<div class="item-footer">Ext. ' + self.devices[key][i].extension + '</div>' + // '&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;'+self.devices[key][i].name+
              '</div>' + '</a>' + '</li>';
            }
          }

          thisDeviceHTML += '</ul>' + '</div>';
        } else {
          thisDeviceHTML += '<p>' + self.devices[key].length + ' devices</p>';
        }

        thisDeviceHTML += '</div>' + '</div>';

        if (self.$el.find('.li' + key).length > 0) {
          self.$el.find('.li' + key).html(thisDeviceHTML);
        } else {
          self.$el.find('.accordionDeviceList').append('<li class="accordion-item li' + key + '">' + thisDeviceHTML + '<li>');
        }
      }
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.$el.on('click', '.phoneChip', function (e) {
        var thisChip = self.$(e.target).closest('.phoneChip');
        var thisName = thisChip.attr('data-name');
        var thisStatus = thisChip.attr('data-extensionStatus');
        var thisExtension = thisChip.attr('data-extension');

        if (thisStatus == 'Idle') {
          self.$app.dialog.confirm(thisName + '<br>' + thisExtension, 'Dial Extension?', function () {
            self.$app.methods.callNumber(thisExtension, thisName); // self.$root.PHONE.call(thisExtension);
          });
        }
      });
      self.refreshPage();
    }
  },
  id: '64dc2199a9',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="blankPage">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">Devices</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text">Devices</div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="page-content">\n			<div class="devicesList">\n				<div class="list accordion-list">\n				    <ul class="accordionDeviceList">\n				        \n				    </ul>\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.list.phonePeers-list .item-floating-after {
		position: absolute;
		right: 30px; /* TODO: find out the right width here... */
		color: var(--f7-list-item-after-text-color);
		font-size: var(--f7-list-item-after-font-size);
		line-height: var(--f7-list-item-after-line-height);
		font-weight: 400;
		font-weight: var(--f7-list-item-after-font-weight);
		
	}
	
	.chip.phoneChip {
		line-height: 15px;
		/*width: 50%;
		min-width: 20em;
		overflow: none;*/
	}
`,
  styleScoped: false
};
    