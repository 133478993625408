
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.ID = parseInt(self.$route.params.id);
    self.name = decodeURIComponent(self.$route.params.name);
    self.bucketObject = {
      ID: self.ID,
      name: self.name
    }; // console.log('self.$root.taskMasterData[bucketScans'+self.ID+']',self.$root.taskMasterData['bucketScans'+self.ID]);

    if (self.$root.taskMasterData['bucketScans' + self.ID]) {
      self.scannedList = self.$root.taskMasterData['bucketScans' + self.ID];
    }
  },
  data: function () {
    return {
      ID: 0,
      name: '',
      bucketObject: {},
      showActualScans: true,
      scannedList: [],
      condensedList: [],
      haveDeletedARow: false,
      hasDataToSend: false
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
    self.$app.on('barcodeEntered', self.barcodeEntered);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
    self.$app.off('barcodeEntered', self.barcodeEntered);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'event' && data.event == 'scanSaved' && data.bucketId == self.ID) {
        // IF it's this batch data...
        var sendingUnique = data.sendingUnique;
        var indexOfScan = self.scannedList.map(function (elem) {
          return elem.sendingUnique ? elem.sendingUnique : '';
        }).indexOf(sendingUnique);

        var refreshAfterFunction = function () {};

        if (indexOfScan > -1) {
          self.scannedList[indexOfScan].id = data.savedId;
          delete self.scannedList[indexOfScan].sendingUnique;
          delete self.scannedList[indexOfScan].sending;
        } else {
          self.scannedList.push({
            id: data.savedId,
            text: data.text,
            dataTimeScanned: data.dateTimeScanned
          });
          refreshAfterFunction = self.scrollToBottom;
        }

        self.refreshPage(refreshAfterFunction);
      } else if (data.type == 'data' && data.data == 'bucketScans' && data.bucketId == self.ID) {
        // TODO: merge with offline list (if not done uploading!)
        // That would affect the order, need to solve that!
        self.scannedList = data.bucketScans;
        self.refreshPage();
      } else if (data.type == 'event' && data.event == 'scanDeleted') {
        var indexOfScan = self.scannedList.map(function (elem) {
          return elem.id;
        }).indexOf(data.scanId);

        if (indexOfScan > -1) {
          self.scannedList.splice(indexOfScan, 1);
          self.refreshPage();
        }
      } else if (data.type == 'data' && data.data == 'buckets') {
        var indexForThisBucket = -1;

        if (self.ID > 0) {
          var indexForThisBucket = self.$root.taskMasterData.buckets.map(function (elem) {
            return elem.ID;
          }).indexOf(self.ID);
        } else if (self.ID == 0 && self.name !== '') {
          // TODO: is this okay?
          var indexForThisBucket = self.$root.taskMasterData.buckets.map(function (elem) {
            return elem.name;
          }).indexOf(self.name);
        }

        if (indexForThisBucket > -1) {
          self.bucketObject = self.$root.taskMasterData.buckets[indexForThisBucket];
          self.ID = self.bucketObject.ID;
          self.name = self.bucketObject.name;
          self.refreshPage();
        } // Or check if this OFFLINE bucket has been saved
        // TODO: if self.ID is not in the list... leave back to the list before!

      }
    },
    barcodeEntered: function (result) {
      var self = this;
      var barcodeText = result.text;
      var e = result.event;
      barcodeText = barcodeText.replace('https://bedroomsandmore.com/qr/', '').replace('https://45thstreetbedding.com/qr/', '');

      if (e.target.id == 'descrepancyReportKeyboardInput') {
        if (!result.scanned || result.scanned && result.text.indexOf('https://bedroomsandmore.com/') > -1) {
          if (self.scannedList.map(function (elem) {
            return elem.text;
          }).indexOf(barcodeText) == -1) {
            // IF NOT SCANNED BEFORE!
            self.scannedList.push({
              id: 0,
              text: barcodeText,
              dataTimeScanned: new Date().toISOString()
            });
            self.hasDataToSend = true;
            self.$update(function () {
              self.scrollToBottom();
              self.sendScansToTaskMaster();
            });
          } else {
            //if (self.$root.hasKeyboardScanner) {
            var tempVar = self.$app.toast.create({
              cssClass: 'error',
              text: 'Duplicate \'' + barcodeText + '\' scanned.',
              position: 'bottom',
              closeTimeout: 4000
            }).open();
            self.$root.errorBeep.play(); //}
          }
        }
      }
    },
    scanWithCamera: function () {
      var self = this;
    },
    scrollToBottom: function () {
      var self = this;
      var pageContentElement = self.$('.descrepancyReportPageContent'); // TODO: only scroll down if I did the scan OR if I was already at the bottom in case multiple people are scanning!
      //console.log(pageContentElement[0].offsetHeight,pageContentElement[0].scrollTop,pageContentElement[0].scrollHeight);

      pageContentElement[0].scrollTop = pageContentElement[0].scrollHeight;
    },
    refreshPage: function (afterRefresh) {
      var self = this;
      afterRefresh = typeof afterRefresh == 'function' ? afterRefresh : function () {};
      self.hasDataToSend = self.scannedList.filter(function (elem) {
        return elem.id == 0;
      }).length;

      if (true) {
        //(!self.haveDeletedARow) {
        self.$update(afterRefresh);
      } else {
        var tempTimeout = setTimeout(function () {
          self.$router.refreshPage();
          afterRefresh(); // TODO: delay?
        }, 250);
      }
    },
    clearData: function () {
      var self = this; // TODO: let TaskMaster know!

      self.$app.dialog.confirm('Are you sure?', 'Clear Data?', function () {
        self.$setState({
          scannedList: []
        });
      });
    },
    renameBucket: function () {
      var self = this;
      self.autoFocusEnd();
      var batchNamePrompt = self.$app.dialog.prompt('', 'Rename Bucket', function (newName) {
        self.$root.mdWebSocket.sendOverWebSocket({
          action: 'renameBucket',
          id: self.ID,
          name: newName
        });
        self.autoFocusStart();
      }, function () {
        self.autoFocusStart();
      }, self.name);
      batchNamePrompt.$el.find('input')[0].select();
    },
    clickedScan: function (e) {
      var self = this;
      var thisATag = self.$(e.target).closest('.item-link');
      var scanId = parseInt(thisATag.attr('data-scanId'));
      var scanData = thisATag.find('.item-title').text();
      var tempDialog = self.$app.dialog.create({
        title: 'Scan',
        text: scanData,
        buttons: [{
          text: 'Delete Scan',
          color: 'red',
          onClick: function () {
            var tempDialog = self.$app.dialog.confirm('Are you sure you want to delete this scan?', scanData, function () {
              self.$root.mdWebSocket.sendOverWebSocket({
                action: 'deleteScan',
                id: scanId
              });
            });
          }
        }, {
          text: 'Cancel'
        }],
        verticalButtons: true
      }).open();
    },

    /*
    deleteScanSwipe: function(e) {
    var self= this;
    			var thisLI= self.$(e.target).closest('.swipeout');
    var scanId= parseInt(thisLI.attr('data-scanId'));
    self.haveDeletedARow= true;
    if (scanId > 0) {
    self.$root.mdWebSocket.sendOverWebSocket({
    	action: 'deleteScan',
    	id: scanId
    });
    }
    // console.log('bucketId',bucketId);
    },*/
    sendScansToTaskMaster: function () {
      var self = this; //if (self.$root.appIsOnlineAndConnected) {

      for (var i = 0; i < self.scannedList.length; i++) {
        //if (self.$root.appIsOnlineAndConnected) {
        if (self.scannedList[i].id == 0 && !self.scannedList[i].sending) {
          self.scannedList[i].sending = true;
          self.scannedList[i].sendingUnique = 'unSaved' + Math.random();
          self.$root.mdWebSocket.sendOverWebSocket({
            action: 'saveScanToBucket',
            bucketId: self.ID,
            text: self.scannedList[i].text,
            sendingUnique: self.scannedList[i].sendingUnique,
            dateScanned: moment(self.scannedList[i].dataTimeScanned).format('L'),
            timeScanned: moment(self.scannedList[i].dataTimeScanned).format('HH:mm:ss')
          });
        } //}

      }

      self.refreshPage(); //}
    },
    autoFocusStart: function () {
      var self = this;

      if (self.$root.hasKeyboardScanner) {
        self.$root.autoKeepFocusInput(self.$('#descrepancyReportKeyboardInput'), self.$route.url);
      }
    },
    autoFocusEnd: function () {
      var self = this;

      if (self.$root.hasKeyboardScanner) {
        self.$root.removeAutoKeepFocusInput(self.$('#descrepancyReportKeyboardInput'));
      }
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
    },
    pageBeforeOut: function (e, page) {
      var self = this;
      self.autoFocusEnd();
    },
    pageAfterIn: function (e, page) {
      var self = this;
      self.autoFocusStart();
    }
  },
  id: '194c0e0d6b',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page no-swipeback pageDescrepancyReportScan page-with-subnavbar" data-name="descrepancyReportScan">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title sliding">';
      r += c(ctx_1.name, ctx_1);
      r += ' Scans</div>\n				<div class="right">\n					<a href="#" class="link icon-only popover-open" data-popover=".popover-scanListMenu">\n					  <i class="icon f7-icons if-not-md">menu</i>\n					  <i class="icon material-icons if-md">menu</i>\n					</a>\n				</div>\n				<div class="subnavbar">\n					<div class="subnavbar-inner">\n						<input type="text" id="descrepancyReportKeyboardInput" class="barcodeEntryInput" style="height: 2em;" value="" placeholder="Barcode Entry">\n						<span class="input-clear-button descrepancyReportKeyboardInputClear" style="right: 1em;"></span>\n					</div>\n				</div>\n			</div>\n		</div>\n		<div class="page-content descrepancyReportPageContent">\n			';
      r += Template7Helpers.if.call(ctx_1, ctx_1.showActualScans, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="list no-margin-top">\n				<ul>\n					<li class="item-divider">Warehouse</li>\n					';
          r += Template7Helpers.each.call(ctx_2, ctx_2.scannedList, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<li>\n						<a href="#" class="item-content item-link" data-scanId="';
              r += c(ctx_3.id, ctx_3);
              r += '" @click="clickedScan">\n							<div class="item-inner">\n								<div class="item-title">\n									';
              r += c(ctx_3.text, ctx_3);
              r += '\n									<div class="item-footer">';
              r += Template7Helpers.js.call(ctx_3, "moment(this.dateTimeScanned).format('M/D, h:mma')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div>\n								</div>\n								<div class="item-after">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.sending, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="preloader"></div>';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.id, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.id, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<i class="icon f7-icons">checkmark_alt</i>';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '<i class="icon f7-icons text-color-red">arrow_2_circlepath</i>';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div>\n							</div>\n						</a>\n					</li>\n					';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				</ul>\n			</div>\n			';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n			\n			';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n		</div>\n		<div class="popover popover-scanListMenu">\n			<div class="popover-inner">\n				<div class="list links-list">\n					<ul>\n						<li><a href="#" @click="renameBucket" class="list-button item-link popover-close">Rename Bucket</a></li>\n						<li><a href="#" @click="sendScansToTaskMaster" class="list-button item-link popover-close';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.hasDataToSend, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">Force Send to Server</a></li>\n						<!--<li><a href="#" @click="clearData" class="list-button item-link popover-close disabled" style="color: #ff3b30;">Clear Data</a></li>-->\n					</ul>\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	#descrepancyReportKeyboardInput {
		
	}
`,
  styleScoped: false
};
    