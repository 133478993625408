
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;

    if (!self.$app.data.taskMasterData.currentLocationId) {
      self.$app.data.taskMasterData.currentLocationId = 11;
    }

    self.currentLocation = self.$root.taskMasterData.locations.filter(function (elem) {
      return elem.id == self.$app.data.taskMasterData.currentLocationId;
    })[0]; // console.log('self.currentLocation',self.currentLocation);
  },
  data: function () {
    return {
      saleAlreadyInProgress: false,
      currentLocation: {}
    };
  },
  mounted: function () {
    var self = this; //self.$app.params.panel.leftBreakpoint = 768; // TODO!

    self.$app.on('webSocketMessage', self.websocketMessageReceived);
    self.$app.data.invoiceBackHref = '/taskmaster/';
  },
  beforeDestroy: function () {
    var self = this; //self.$app.params.panel.leftBreakpoint = 0; // TODO!

    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;
    },
    startNewSale: function () {
      var self = this; // Clear/save current data...
      //self.$app.dialog.alert('Writing a new sale is not live yet...','Not Live');

      self.$router.navigate('/taskmaster/invoices/new/');
    },
    setCurrentLocation: function (locationId) {
      var self = this;
      self.currentLocation = self.$root.taskMasterData.locations.filter(function (elem) {
        return elem.id == locationId;
      })[0];
      self.$app.data.taskMasterData.currentLocationId = locationId;
      self.$app.methods.emitEventToApp('changedOnScreenInvoiceDetails', {
        locationId: locationId
      });
      self.$setState();
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
    }
  },
  id: '99c6ca24c0',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="taskMasterPage">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="/" class="link back" data-force="true">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">TaskMaster 2.0</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<a href="#" class="link icon-only" @click="$root.scanBarcode">\n						<i class="icon f7-icons">qrcode</i>\n					</a>\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text">TaskMaster 2.0</div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="toolbar toolbar-bottom">\n			<div class="toolbar-inner">\n		        <a href="#" class="link icon-only panel-open openLeftPanelLinkBottom" data-panel=".mainScreenLeftPanel"><i class="icon f7-icons">menu</i></a>\n		        <!--<a href="/taskmaster/orders/" class="link">Open Orders</a>-->\n		        <a href="/taskmaster/invoices/';
      r += c(ctx_1.currentLocation.retailInvoicePrefix, ctx_1);
      r += '-LATEST/" class="link icon-only" data-animate="false"><i class="icon f7-icons">arrow_left</i></a>\n		        <a href="#" class="link icon-only currentLocationNameToolbar popover-open" data-popover=".locationsPopoverMenu">';
      r += c(ctx_1.currentLocation.retailInvoicePrefix, ctx_1);
      r += '</a>\n		        <a href="#" class="link icon-only disabled"><i class="icon f7-icons">arrow_right</i></a>\n		        <a href="#" class="link icon-only" style="visibility: hidden;">Edit</a>\n			</div>\n		</div>\n		<div class="page-content">\n			<div class="block block-strong no-hairlines-md no-margin-top no-margin-bottom currentLocationBlock" style="text-align: center;">\n				<div class="row">\n					<div class="col-100 medium-50 popover-open" data-popover=".locationsPopoverMenu">\n						<span><img src="https://taskmaster.bedroomsandmore.com/4DACTION/getImage/Locations/';
      r += c(ctx_1.currentLocation.id, ctx_1);
      r += '.svg" width="100%" height="*"><br>';
      r += c(ctx_1.currentLocation.name, ctx_1);
      r += '</span>\n					</div>\n					<div class="col-100 medium-50">\n						<button class="button centeredLimitedButton button-large';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.saleAlreadyInProgress, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' button-raised button-fill';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' button-outline';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" style="margin-top: 1em;" @click="startNewSale"><i class="icon f7-icons">plus_circle</i><span>&nbsp;&nbsp;Start New Sale</span></button>\n					</div>\n				</div>\n		    </div>\n			';
      r += Template7Helpers.if.call(ctx_1, ctx_1.saleAlreadyInProgress, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n		    <div class="block">\n			    <div class="row newInvoiceActionsNewSaleButtons">\n				    <div class="col"><button class="button centeredLimitedButton button-large button-raised button-fill">Continue Sale</button></div>\n				</div>\n			</div>\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			<!--\n			<div class="block">\n			    <div class="row newInvoiceActionsNewSaleButtons">\n				    <div class="col"><button class="button centeredLimitedButton button-large';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.saleAlreadyInProgress, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' button-raised button-fill';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' button-outline';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" @click="startNewSale"><i class="icon f7-icons">plus_circle</i><span>&nbsp;&nbsp;Start New Sale</span></button></div>\n			    </div>\n			</div>\n			-->\n			<div class="list links-list no-margin-top">\n				<ul>\n					<li><a href="/taskmaster/search/invoices/">Search Invoices</a></li>\n					<li><a href="/taskmaster/search/inventory/">Search Inventory</a></li>\n					<li><a href="/taskmaster/search/customers/">Search Customers</a></li>\n					<li><a href="/priceTags/print/">Print Price Tags</a></li>\n					<!--<li><a href="#">Delivery Costs</a></li>-->\n					<li><a href="/taskmaster/tables/">Table Lists</a></li>\n					<li><a href="/taskmaster/orders/">Open Orders</a></li>\n					';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user.id == 16", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<li><a href="/taskmaster/server/">Server Functions</a></li>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</ul>\n			</div>\n		</div>\n	    <div class="popover locationsPopoverMenu">\n			<div class="popover-angle"></div>\n			<div class="popover-inner">\n				<div class="list">\n					<ul>\n						';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.taskMasterData.locations, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n						';
          r += Template7Helpers.if.call(ctx_2, ctx_2.retailInvoicePrefix, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n						<li><a href="#" class="list-button item-link popover-close" @click="setCurrentLocation(';
              r += c(ctx_3.id, ctx_3);
              r += ')">';
              r += c(ctx_3.name, ctx_3);
              r += '</a></li>\n						';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					</ul>\n				</div>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.currentLocationBlock img {
		max-width: 375px;
		max-height: 57.2188px;
		text-align: center;
	}
`,
  styleScoped: false
};
    