
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
  },
  data: function () {
    return {
      showAllLocations: false,
      showAllLocationsToggle: null
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'todaySales') {
        self.refreshPage();
      }
    },
    refreshPage: function () {
      var self = this; //if (self.$el.hasClass('ptr-refreshing')) { // TODO: if statement necessary?

      self.$app.ptr.done(); //}

      self.$setState();
    },
    checkedShowAllLocations: function () {
      var self = this;
      self.showAllLocations = self.showAllLocationsToggle.checked;
      self.$setState();
    },
    loadLatestInvoice: function (e) {
      var self = this;
      var thisTRTag = self.$(e.target).closest('tr'); //var locationPrefix= ;

      self.$app.data.invoiceBackHref = '/reports/';
      self.$router.navigate('/taskmaster/invoices/' + thisTRTag.attr('data-locationLatestInvoice') + '/');
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.$el.on('ptr:refresh', function (e) {
        self.$root.sendStatus();
      });
      self.showAllLocationsToggle = self.$app.toggle.create({
        el: '.showAllLocations',
        on: {
          change: self.checkedShowAllLocations
        }
      });
    }
  },
  id: '9b0e54c0f9',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="reportPage">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<!--<a href="/" class="link back" data-force="true">-->\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">Reports</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.user.isAdmin, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<label class="toggle color-green showAllLocations">\n						<input type="checkbox">\n						<span class="toggle-icon"></span>\n					</label>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text">Reports</div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="page-content ptr-content" data-ptr-distance="55" data-ptr-mousewheel="true">\n			<div class="ptr-preloader">\n				<div class="preloader"></div>\n				<div class="ptr-arrow"></div>\n			</div>\n			';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.taskMasterData.todaySales, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="list accordion-list no-margin">\n				<ul>\n					<li class="accordion-item accordion-item-opened">\n						<a href="#" class="item-content item-link">\n							<div class="item-inner">\n								<div class="item-title">Today (';
          r += c(ctx_2.$root.taskMasterData.todaySales.dateShortString, ctx_2);
          r += '): <strong>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.showAllLocations, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.$root.taskMasterData.todaySales.todaySalesString, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.$root.taskMasterData.todaySales.todaySalesRetailString, ctx_3);
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</strong> (';
          r += c(ctx_2.$root.taskMasterData.todaySales.timeString, ctx_2);
          r += ')</div>\n							</div>\n						</a>\n						<div class="accordion-item-content">\n							<div class="data-table card">\n								<table>\n									<tbody>\n										';
          r += Template7Helpers.each.call(ctx_2, ctx_2.$root.taskMasterData.todaySales.locations, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n										';
              r += Template7Helpers.js_if.call(ctx_3, "(this.@root.showAllLocations) || (this.isRetail)", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n										<tr style="cursor: pointer;" @click="loadLatestInvoice" data-locationLatestInvoice="';
                  r += c(ctx_4.latestInvoice, ctx_4);
                  r += '">\n											<td class="label-cell">';
                  r += c(ctx_4.name, ctx_4);
                  r += '</td>\n											<td class="numeric-cell dollar-numeric-cell">';
                  r += c(ctx_4.todaySalesString, ctx_4);
                  r += '</td>\n										</tr>\n										';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n										';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n									</tbody>\n								</table>\n							</div>\n						</div>\n					</li>\n				</ul>\n			</div>\n			';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="block block-strong">\n				<p>Loading Today\'s Sales...</p>\n			</div>\n			';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			<div class="block block-strong no-margin-top">\n				';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.user.isAdmin, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<p class="row">\n					<a href="/reports/admin/" class="col button button-large button-raised button-fill">Admin Reports</a>\n				</p>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				<p class="row">\n					<a href="/reports/sales/" class="col button button-large button-raised button-fill">Sales Reports</a>\n					<a href="/reports/dc/" class="col button button-large button-raised button-fill">D.C. Reports</a>\n				</p>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.md .toggle.showAllLocations {
		padding-right: 3em;
		
	}

	.dollar-numeric-cell {
		white-space: nowrap !important;
		
	}
`,
  styleScoped: false
};
    