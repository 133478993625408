
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;
    self.numberOfTags = self.$app.methods.array_sumProperty(self.$app.data.printTagPrintList, 'quantity');
  },
  data: function () {
    return {
      numberOfTags: 0,
      productsSearchTimeout: null,
      productSearchAutocomplete: null
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;
    },
    getProductFromSKU: function (sku) {
      var self = this;
      self.$app.dialog.preloader('Loading Product...'); // TODO: use websocket...?

      self.$root.mdWebSocket.ajaxToServer({
        method: 'GET',
        showSpinner: false,
        url: '/4DACTION/api/getItemInfo',
        data: {
          action: 'getItemInfo',
          sku: sku
        },
        timeout: 5000,
        success: function (data) {
          self.$app.dialog.close();
          var tempTimeout = setTimeout(function () {
            if (data.ok) {
              var dataToAdd = data;
              dataToAdd.template = 'Generic';
              dataToAdd.pageSize = 'quarter';
              dataToAdd.quantity = 1;
              dataToAdd.printSingularTag = false; // Assume FALSE for now...

              dataToAdd.showCurrentSales = false;
              dataToAdd.asIsId = 0; // For CLEARANCE tags...

              if (dataToAdd.product.variations && dataToAdd.product.variations.length == 1) {
                dataToAdd.printSingularTag = true; // IF ONLY 1...
              }

              console.log('*** dataToAdd', dataToAdd);

              if (dataToAdd.template == 'Mattresses') {// dataToAdd.pageSize= 'full'; // NOT UNTIL READY!
              }

              var indexOfThisPageSize = self.$root.printTagPrintList.map(function (e) {
                return e.pageSize;
              }).indexOf(dataToAdd.pageSize);

              if (indexOfThisPageSize > -1) {
                self.$root.printTagPrintList.splice(indexOfThisPageSize, 0, dataToAdd);
              } else {
                self.$root.printTagPrintList.push(dataToAdd);
                indexOfThisPageSize = self.$root.printTagPrintList.length - 1;
              }

              self.askWhichTemplate(indexOfThisPageSize, false, function (productIndex) {
                // TODO: change these to use productIndex instead...?
                //if ((dataToAdd.printSingularTag) && (dataToAdd.productHasSingularAsIsItems)) {
                // console.log('HERE dataToAdd.printSingularTag',dataToAdd.printSingularTag,dataToAdd.productHasSingularAsIsItems);
                self.askForSpecificItem(productIndex, true); //}
              });
            } else {// TODO: alert error...
            }
          }, 0);
        },
        error: function (data) {
          self.$app.dialog.close(); // TODO: alert error...
        }
      });
    },
    scanBarcode: function () {
      var self = this;
      self.$root.scanBarcode({
        success: function (result) {
          //self.$app.dialog.alert(result.data,'Result');
          self.getProductFromSKU(result);
        }
      });
    },
    refreshList: function (onSuccessFunction) {
      var self = this;
      onSuccessFunction = typeof onSuccessFunction == 'function' ? onSuccessFunction : function () {};
      self.$root.printTagPrintList.sort(self.$root.array_dynamicSort('pageSize'));
      self.numberOfTags = self.$app.methods.array_sumProperty(self.$app.data.printTagPrintList, 'quantity');
      var groupCodeNames = [];
      var groupTitles = []; // TODO: count all the sizes and built the titles...

      var groupTagCounts = [];

      for (var i = 0; i < self.$root.printTagPrintList.length; i++) {
        var currentPaperSize = self.$root.printTagPrintList[i].pageSize;
        var indexOfCodeName = groupCodeNames.indexOf(currentPaperSize);

        if (indexOfCodeName < 0) {
          // Does not exist!
          groupCodeNames.push(currentPaperSize);
          groupTitles.push('');
          groupTagCounts.push(self.$root.printTagPrintList[i].quantity);
        } else {
          // Already Exists!
          groupTagCounts[indexOfCodeName] += self.$root.printTagPrintList[i].quantity;
        } // Fix up visible NAME...


        var isMattressItem = false;

        if (self.$root.printTagPrintList[i].product.categoriesTree.length > 0) {
          isMattressItem = self.$root.printTagPrintList[i].product.categoriesTree[0].id == 22;
        }

        self.$root.printTagPrintList[i].frontFacingTitle = self.$root.printTagPrintList[i].product.name;

        if (isMattressItem) {
          self.$root.printTagPrintList[i].frontFacingTitle = self.$root.printTagPrintList[i].item.model;

          if (self.$root.printTagPrintList[i].item.firmness !== '') {
            self.$root.printTagPrintList[i].frontFacingTitle += ' - ' + self.$root.printTagPrintList[i].item.firmness;
          } //console.log('LOOPING, ',self.$root.printTagPrintList[i]);

        }

        if (self.$root.printTagPrintList[i].printSingularTag == true) {
          self.$root.printTagPrintList[i].frontFacingTitle = self.$root.printTagPrintList[i].item.name; // TODO: check asis id...
        } // TODO: check if "show current sales"

      }

      for (var i = 0; i < groupCodeNames.length; i++) {
        if (groupCodeNames[i].indexOf('quarter') > -1) {
          groupTitles[i] = 'Quarter Page Size (' + groupTagCounts[i] + ')';
        } else if (groupCodeNames[i].indexOf('half') > -1) {
          groupTitles[i] = 'Half Page Size (' + groupTagCounts[i] + ')';
        } else if (groupCodeNames[i].indexOf('full') > -1) {
          groupTitles[i] = 'Full Page Size (' + groupTagCounts[i] + ')';
        } else {
          groupTitles[i] = groupCodeNames[i] + ' size (' + groupTagCounts[i] + ')';
        }
      }

      var lastPaperSize = '';

      for (var i = 0; i < self.$root.printTagPrintList.length; i++) {
        var currentPaperSize = self.$root.printTagPrintList[i].pageSize;

        if (i == 0 || currentPaperSize !== lastPaperSize) {
          var indexOfCodeName = groupCodeNames.indexOf(currentPaperSize);

          if (indexOfCodeName > -1) {
            self.$root.printTagPrintList[i].listDivider = '<li class="item-divider">' + groupTitles[indexOfCodeName] + '</li>';
          }
        } else {
          self.$root.printTagPrintList[i].listDivider = '';
        }

        lastPaperSize = currentPaperSize;
      }

      self.$root.setItemLocalStorage('printTagPrintList', self.$root.printTagPrintList);
      self.$setState({}, function () {
        onSuccessFunction();
      });
    },
    clearPrintList: function () {
      var self = this;
      self.$app.dialog.confirm('Are you sure you want to clear this price tag list?<br><br><strong>There is no undo.</strong>', 'Clear List', function () {
        self.$app.data.printTagPrintList = [];
        self.$root.removeItemLocalStorage('printTagPrintList');
        self.refreshList();
      });
    },
    printList: function () {
      var self = this; // TODO: use websocket?

      self.$app.preloader.show();
      self.$root.mdWebSocket.ajaxToServer({
        method: 'POST',
        showSpinner: false,
        url: '/4DACTION/api/listPrinters',
        data: {
          action: 'listPrinters'
        },
        timeout: 5000,
        success: function (data) {
          self.$app.preloader.hide();

          if (data.ok) {
            var printButtons = [];

            if (self.$root.user.emailaddress !== '') {
              printButtons.push({
                text: 'Email Me (' + self.$root.user.name + ')',
                actualPrinterName: 'email:' + self.$root.user.emailaddress,
                onClick: function () {
                  var thisButton = this;
                  self.$app.preloader.show(); // TODO: websocket...

                  self.$root.mdWebSocket.ajaxToServer({
                    method: 'POST',
                    showSpinner: false,
                    url: '/4DACTION/api/printPriceTags',
                    data: {
                      action: 'printPriceTags',
                      priceTags: self.$root.printTagPrintList,
                      printer: this.actualPrinterName
                    },
                    timeout: 10000,
                    success: function (data) {
                      self.$app.preloader.hide();

                      if (data.ok) {
                        self.$app.dialog.alert('The PDF has been emailed to: ' + self.$root.user.emailaddress + '!', 'Sent');
                      } else {
                        self.$app.dialog.alert(data.error, 'Error');
                      }
                    },
                    error: function (data) {
                      self.$app.preloader.hide(); // TODO: alert error...
                    }
                  });
                }
              });
            }

            for (var i = 0; i < data.altnames.length; i++) {
              printButtons.push({
                text: data.altnames[i],
                actualPrinterName: data.names[i],
                onClick: function () {
                  var thisButton = this;
                  self.$app.preloader.show(); // TODO: websocket...

                  self.$root.mdWebSocket.ajaxToServer({
                    method: 'POST',
                    showSpinner: false,
                    url: '/4DACTION/api/printPriceTags',
                    data: {
                      action: 'printPriceTags',
                      priceTags: self.$root.printTagPrintList,
                      printer: this.actualPrinterName
                    },
                    timeout: 10000,
                    success: function (data) {
                      self.$app.preloader.hide();

                      if (data.ok) {
                        self.$app.dialog.alert('Your print job was sent to printer: ' + thisButton.text, 'Sent');
                      } else {
                        self.$app.dialog.alert(data.error, 'Error');
                      }
                    },
                    error: function (data) {
                      self.$app.preloader.hide(); // TODO: alert error...
                    }
                  });
                }
              });
            }

            printButtons.push({
              text: 'Cancel',
              color: 'red'
            });
            self.$app.dialog.create({
              title: 'Choose Printer:',
              buttons: printButtons,
              verticalButtons: true,
              destroyOnClose: true,
              closeByBackdropClick: true
            }).open();
          } else {// TODO: alert error...
          }
        },
        error: function (data) {
          self.$app.preloader.hide(); // TODO: alert error...
        }
      });
    },
    askForSpecificItem: function (indexOfPriceTag, allowSkippingIfOnlyOne) {
      var self = this;
      indexOfPriceTag = typeof indexOfPriceTag == 'number' ? indexOfPriceTag : -1;
      allowSkippingIfOnlyOne = typeof allowSkippingIfOnlyOne == 'boolean' ? allowSkippingIfOnlyOne : false;

      if (indexOfPriceTag > -1) {
        var productIndex = indexOfPriceTag;
        var itemSku = self.$root.printTagPrintList[productIndex].item.sku;
        var asIsItems = self.$root.printTagPrintList[productIndex].product.singularAsIsVariations.filter(function (elem) {
          return elem.sku == itemSku && elem.deleted == false;
        });
        console.log('item', self.$root.printTagPrintList[productIndex].item);
        console.log('asIsItems', asIsItems);
        var asIsItemsRemapped = asIsItems.map(function (elem) {
          return {
            'asisNumber': elem.asisNumber,
            'title': elem.sku + '-asis-' + elem.asisNumber,
            'text': 'Single Product Tag, ' + elem.description,
            'subtitle': '<div class="chip color-red"><div class="chip-label">"AS-IS" Clearance</div></div>',
            'after': elem.retailAmount + '<br><span style="text-decoration:line-through;">' + self.$root.printTagPrintList[productIndex].item.retailAmount + '</span>'
          };
        });
        asIsItemsRemapped.unshift({
          'asisNumber': 0,
          'title': itemSku,
          'text': 'Single Product Tag, Sold at ' + (self.$root.printTagPrintList[productIndex].item.clearanceAmount > 0 ? '"Discounted Last Call"' : 'Retail') + ' Price',
          'subtitle': self.$root.printTagPrintList[productIndex].item.clearanceAmount > 0 ? '<div class="chip color-blue" style="background: #408480"><div class="chip-label">Last Call Item</div></div>' : '',
          'after': self.$root.printTagPrintList[productIndex].item.clearanceAmount > 0 ? self.$root.printTagPrintList[productIndex].item.clearanceAmount + '<br><span style="text-decoration:line-through;">' + self.$root.printTagPrintList[productIndex].item.retailAmount + '</span>' : self.$root.printTagPrintList[productIndex].item.retailAmount
        });

        if (self.$root.printTagPrintList[productIndex].product.variations && self.$root.printTagPrintList[productIndex].product.variations.length > 1) {
          asIsItemsRemapped.unshift({
            'asisNumber': 0,
            'title': 'Product Tag (Show All Variations)',
            'text': "Product Tag (Show All Variations)",
            'after': ''
          });
        }
        /*
        	if ((self.$app.data.printTagPrintList[productIndex].product.variations) && (self.$app.data.printTagPrintList[productIndex].product.variations.length > 1)) {
        		// GREATER THAN 1 MEANS THERE CAN BE A CHOICE...
        		clickedProductInListButtons.push({
        			text: 'Change to a '+((self.$app.data.printTagPrintList[productIndex].printSingularTag)?' Product(s) Tag':' Singular Item Tag'),
        			onClick: function() {
        				self.$app.data.printTagPrintList[productIndex].printSingularTag= !self.$app.data.printTagPrintList[productIndex].printSingularTag;
        				
        				if (self.$app.data.printTagPrintList[productIndex].printSingularTag) {
        					// Just SWITCHED to a singular tag...
        					// Ask if they want the "regular" product or pick an as-is.
        					//self.$app.data.printTagPrintList[productIndex].asIsId= 1;
        						self.askForSpecificItem(productIndex);
        				} else {
        					self.refreshList();
        				}
        			}
        		});
        	}
        */


        if (asIsItemsRemapped.length == 1 && allowSkippingIfOnlyOne) {
          self.$root.printTagPrintList[productIndex].asIsId = asIsItemsRemapped[0].asisNumber;

          if (asIsItemsRemapped[0].text.indexOf('Product Tag') == 0) {
            self.$root.printTagPrintList[productIndex].printSingularTag = false;
          } else {
            self.$root.printTagPrintList[productIndex].printSingularTag = true;
          }

          self.refreshList();
        } else {
          self.$root.mdChoiceList({
            title: 'Choose Product or Singular Item Tag',
            array: asIsItemsRemapped
          }, function (index) {
            self.$root.printTagPrintList[productIndex].asIsId = asIsItemsRemapped[index].asisNumber;

            if (asIsItemsRemapped[index].text.indexOf('Product Tag') == 0) {
              // They picked the PRODUCT option...
              self.$root.printTagPrintList[productIndex].printSingularTag = false;
            } else {
              self.$root.printTagPrintList[productIndex].printSingularTag = true;
            }

            self.refreshList();
          }, function () {
            self.refreshList();
          });
        }
      }
    },
    askWhichTemplate: function (indexOfPriceTag, returnButtonObject, onSuccessFunction) {
      var self = this;
      indexOfPriceTag = typeof indexOfPriceTag == 'number' ? indexOfPriceTag : -1;
      returnButtonObject = typeof returnButtonObject == 'boolean' ? returnButtonObject : false;
      onSuccessFunction = typeof onSuccessFunction == 'function' ? onSuccessFunction : function () {};
      var clickedProductInListButtons = [];
      console.log('indexOfPriceTag: ' + indexOfPriceTag);

      if (indexOfPriceTag > -1) {
        // console.log('self.$root.printTagPrintList[indexOfPriceTag].product',self.$root.printTagPrintList[indexOfPriceTag].product);
        var productIndex = indexOfPriceTag;
        var productCategories = [];

        if (self.$root.printTagPrintList[productIndex].product.categories) {
          productCategories = self.$root.printTagPrintList[productIndex].product.categories;
        } else {
          for (var i = 0; i < self.$root.printTagPrintList[productIndex].product.categoriesTree.length; i++) {
            productCategories.push(self.$root.printTagPrintList[productIndex].product.categoriesTree[i].name);
          } // categoriesTree

        }

        var availableSizesAndTemplates = [];
        var priceTagTemplates = JSON.parse(JSON.stringify(self.$root.taskMasterData.priceTagTemplates));

        for (var i = 0; i < priceTagTemplates.length; i++) {
          var addThisTemplate = false;

          if (priceTagTemplates[i].categoryWhiteList.length == 0) {
            addThisTemplate = true;
          } else {
            for (var j = 0; j < priceTagTemplates[i].categoryWhiteList.length; j++) {
              if (productCategories.indexOf(priceTagTemplates[i].categoryWhiteList[j]) > -1) {
                addThisTemplate = true;
                break;
              }
            }
          }

          if (priceTagTemplates[i].categoryBlackList.length > 0) {
            for (var j = 0; j < priceTagTemplates[i].categoryBlackList.length; j++) {
              if (productCategories.indexOf(priceTagTemplates[i].categoryBlackList[j]) > -1) {
                addThisTemplate = false;
                break;
              }
            }
          }

          if (addThisTemplate) {
            var sizesToLoop = JSON.parse(JSON.stringify(priceTagTemplates[i].sizes));

            for (var j = 0; j < sizesToLoop.length; j++) {
              var indexOfThisPageSizeButton = -1; // console.log('availableSizesAndTemplates: '+availableSizesAndTemplates);

              if (availableSizesAndTemplates.length > 0) {
                indexOfThisPageSizeButton = availableSizesAndTemplates.map(function (e) {
                  return e.size;
                }).indexOf(priceTagTemplates[i].sizes[j]);
              }

              if (indexOfThisPageSizeButton > -1) {
                availableSizesAndTemplates[indexOfThisPageSizeButton].templates.push(priceTagTemplates[i].name);
              } else {
                availableSizesAndTemplates.push({
                  size: priceTagTemplates[i].sizes[j],
                  templates: [priceTagTemplates[i].name]
                });
              }
            }
          }
        }

        for (var i = 0; i < availableSizesAndTemplates.length; i++) {
          clickedProductInListButtons.push({
            text: availableSizesAndTemplates[i].size,
            templates: availableSizesAndTemplates[i].templates,
            productIndex: productIndex,
            templateSize: availableSizesAndTemplates[i].size,
            onClick: function () {
              var thisButton = this;
              console.log('**** self.$root.printTagPrintList[thisButton.productIndex]', self.$root.printTagPrintList[thisButton.productIndex]);

              if (thisButton.templates.length > 1) {
                // Ask
                // NEED TO ASK HERE...
                var clickedTemplateSizeButtons = [];

                for (var j = 0; j < thisButton.templates.length; j++) {
                  clickedTemplateSizeButtons.push({
                    text: thisButton.templates[j],
                    templateSize: thisButton.templateSize,
                    productIndex: productIndex,
                    templateName: thisButton.templates[j],
                    onClick: function () {
                      var thisButton = this;
                      self.$root.printTagPrintList[thisButton.productIndex].pageSize = thisButton.templateSize;
                      self.$root.printTagPrintList[thisButton.productIndex].template = thisButton.templateName;
                      console.log('**** self.$root.printTagPrintList[thisButton.productIndex]', self.$root.printTagPrintList[thisButton.productIndex]); // If Mattress Full Template... need to ask for firmness!

                      self.refreshList(function () {
                        onSuccessFunction(thisButton.productIndex);
                      });
                    }
                  });
                }

                var clickedTemplateSizeDialog = self.$app.dialog.create({
                  title: 'Which Template Design?',
                  text: self.$root.printTagPrintList[thisButton.productIndex].product.name,
                  buttons: clickedTemplateSizeButtons,
                  verticalButtons: true,
                  closeByBackdropClick: false,
                  destroyOnClose: true
                }).open();
              } else {
                // We know what it is...
                self.$root.printTagPrintList[thisButton.productIndex].pageSize = thisButton.templateSize;
                self.$root.printTagPrintList[thisButton.productIndex].template = thisButton.templates[0];

                if (self.$root.printTagPrintList[thisButton.productIndex].template == 'Mattress Full Page') {// Need to clarify the Attributes required...!
                }

                self.refreshList(function () {
                  onSuccessFunction(thisButton.productIndex);
                });
              }
            }
          });
        }

        if (!returnButtonObject) {
          /*
          clickedProductInListButtons.push({
          	text: 'Cancel',
          	color: 'red'
          });
          */
          var clickedProductDialog = self.$app.dialog.create({
            title: 'Price Tag Page Size',
            text: self.$root.printTagPrintList[productIndex].product.name,
            buttons: clickedProductInListButtons,
            verticalButtons: true,
            closeByBackdropClick: false,
            destroyOnClose: true,
            on: {
              open: function (dialog) {
                if (dialog.params.buttons.length == 1) {
                  dialog.params.buttons[0].onClick();
                  dialog.close();
                }
              }
            }
          }).open();
        }
      } else {// error?
      }

      if (returnButtonObject) {
        return clickedProductInListButtons;
      }
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      self.productsSearchTimeout = null;
      self.productSearchAutocomplete = self.$app.autocomplete.create({
        openIn: 'page',
        //open in page
        openerEl: '.autoCompleteSearch',
        //link that opens autocomplete
        multiple: false,
        //allow multiple values
        animate: !self.$app.data.isIOS9,
        valueProperty: 'id',
        //object's "value" property name
        textProperty: 'name',
        //object's "text" property name
        limit: 50,
        closeOnSelect: true,
        preloader: true,
        //enable preloader
        renderNavbar: function () {
          var ac = this;
          var navbarHtml = '<div class="navbar"><div class="navbar-bg"></div><div class="navbar-inner"><div class="left sliding"><a href="#" class="link back"><i class="icon icon-back"></i><span class="ios-only">Close</span></a></div><div class="title">Search Products</div><div class="subnavbar sliding">' + ac.renderSearchbar() + '</div></div></div>';
          return navbarHtml;
        },
        renderItem: function (item, index) {
          var ac = this;
          var itemHTML = '<li>' + '<label class="item-radio item-content">' + '<input type="radio" name="' + item.inputName + '" value="' + item.value + '">' + '<i class="icon icon-radio"></i>' + '<div class="item-inner">' + '<div class="item-title white-space-normal">' + item.text + '</div>' + '</div>' + '</label>' + '</li>';
          return itemHTML;
        },
        source: function (query, render) {
          var autocomplete = this;
          var results = [];

          if (query.length === 0) {
            render(results);
            return;
          } // Show Preloader
          //autocomplete.preloaderShow();


          self.$app.preloader.show(); // Do Ajax request to Autocomplete data

          if (self.productsSearchTimeout !== null) {
            clearTimeout(self.productsSearchTimeout);
            self.productsSearchTimeout = null;
          }

          self.productsSearchTimeout = setTimeout(function () {
            // TODO: use websocket...?
            self.$root.mdWebSocket.ajaxToServer({
              method: 'POST',
              showSpinner: false,
              url: '/4DACTION/api/searchAllProducts',
              data: {
                action: 'searchAllProducts',
                q: query,
                extraInfo: true
              },
              timeout: 5000,
              success: function (data) {
                self.$app.preloader.hide();

                if (data.ok) {
                  render(data.results);
                } else {
                  render([]); // TODO: alert error...
                }
              },
              error: function (data) {
                self.$app.preloader.hide();
                render([]); // TODO: alert error...
              }
            });
          }, 500);
        },
        on: {
          opened: function (ac) {
            ac.searchbar.$el.find('input').focus();
          },
          change: function (value) {
            var ac = this;
            self.getProductFromSKU(value[0].id);
            ac.value = [];
          }
        }
      });
      self.$el.on('click', '.productLink', function (e) {
        var aTag = self.$(e.target).closest('.productLink');
        var productIndex = parseInt(aTag.attr('data-index'));
        var clickedProductInListButtons = [];
        clickedProductInListButtons = self.askWhichTemplate(productIndex, true);

        for (var i = 0; i < clickedProductInListButtons.length; i++) {
          clickedProductInListButtons[i].text = 'Set Paper Size to ' + clickedProductInListButtons[i].text;
        }

        clickedProductInListButtons.unshift({
          text: 'Change Quantity (' + self.$app.data.printTagPrintList[productIndex].quantity + ')',
          onClick: function () {
            var tempDialogPrompt = self.$app.dialog.prompt(self.$app.data.printTagPrintList[productIndex].product.name, 'Change Quantity', function (value) {
              self.$app.data.printTagPrintList[productIndex].quantity = parseInt(value);
              self.refreshList();
            }, function () {}, self.$app.data.printTagPrintList[productIndex].quantity);
            var tempTimeout = setTimeout(function () {
              tempDialogPrompt.$el.find('input').focus();
              tempDialogPrompt.$el.find('input')[0].select();
            }, 250);
          }
        });
        /*
        if ((self.$app.data.printTagPrintList[productIndex].product.variations) && (self.$app.data.printTagPrintList[productIndex].product.variations.length > 1)) {
        	// GREATER THAN 1 MEANS THERE CAN BE A CHOICE...
        	clickedProductInListButtons.push({
        		text: 'Change to a '+((self.$app.data.printTagPrintList[productIndex].printSingularTag)?' Product(s) Tag':' Singular Item Tag'),
        		onClick: function() {
        			self.$app.data.printTagPrintList[productIndex].printSingularTag= !self.$app.data.printTagPrintList[productIndex].printSingularTag;
        			
        			if (self.$app.data.printTagPrintList[productIndex].printSingularTag) {
        				// Just SWITCHED to a singular tag...
        				// Ask if they want the "regular" product or pick an as-is.
        				//self.$app.data.printTagPrintList[productIndex].asIsId= 1;
        					self.askForSpecificItem(productIndex);
        			} else {
        				self.refreshList();
        			}
        		}
        	});
        }
        */
        //if (self.$app.data.printTagPrintList[productIndex].printSingularTag) {

        clickedProductInListButtons.unshift({
          text: 'Choose Product or Specific Item...',
          onClick: function () {
            self.askForSpecificItem(productIndex);
          }
        }); //}

        clickedProductInListButtons.push({
          text: 'Delete this Item',
          color: 'red',
          onClick: function () {
            self.$app.dialog.confirm(self.$app.data.printTagPrintList[productIndex].product.name, 'Delete Price Tag?', function () {
              self.$app.data.printTagPrintList.splice(productIndex, 1);
              self.refreshList();
            });
          }
        });
        clickedProductInListButtons.push({
          text: 'Cancel',
          color: 'red'
        });
        var clickedProductDialog = self.$app.dialog.create({
          title: self.$app.data.printTagPrintList[productIndex].product.name,
          text: self.$app.data.printTagPrintList[productIndex].product.brand + '<br>Template: ' + self.$app.data.printTagPrintList[productIndex].template + '<br>Paper Size: ' + self.$app.data.printTagPrintList[productIndex].pageSize,
          buttons: clickedProductInListButtons,
          verticalButtons: true,
          closeByBackdropClick: true,
          destroyOnClose: true
        }).open(); //self.$app.dialog.alert(self.$app.data.printTagPrintList[productIndex].product.name,'Clicked Product');
      });
      self.refreshList();
    }
  },
  id: 'a66974a23f',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="blankPage">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">Print Queue, ';
      r += Template7Helpers.js.call(ctx_1, "this.$root.printTagPrintList.length", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' Product';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.printTagPrintList.length !== 1", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 's';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<a href="#" class="link icon-only autoCompleteSearch">\n						<i class="icon f7-icons if-not-md">search</i>\n						<i class="icon material-icons md-only">search</i>\n					</a>\n					<a href="#" class="link icon-only" @click="scanBarcode">\n						<i class="icon f7-icons">qrcode</i>\n					</a>\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text">Print Queue, ';
          r += Template7Helpers.js.call(ctx_2, "this.$root.printTagPrintList.length", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' Product';
          r += Template7Helpers.js_if.call(ctx_2, "this.$root.printTagPrintList.length !== 1", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 's';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="toolbar tabbar toolbar-bottom">\n			<div class="toolbar-inner" style="justify-content: space-between !important;">\n			    <a href="#" class="col link';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.printTagPrintList.length == 0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" style="max-width: 130px !important;" @click="clearPrintList">Clear All</a>\n			    ';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.printTagPrintList.length > 0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			    <button class="col button button-big button-raised button-fill standOutButton" style="max-width: 300px;" @click="printList">Print';
          r += Template7Helpers.if.call(ctx_2, ctx_2.numberOfTags, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += c(ctx_3.numberOfTags, ctx_3);
              r += ' Tag';
              r += Template7Helpers.js_if.call(ctx_3, "this.numberOfTags !== 1", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 's';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</button>\n			    ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n			    <a href="#" class="link">&nbsp;</a>\n			    ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="page-content">\n			';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.printTagPrintList.length > 0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="list media-list inset">\n				<ul>\n					';
          r += Template7Helpers.each.call(ctx_2, ctx_2.$root.printTagPrintList, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					';
              r += Template7Helpers.if.call(ctx_3, ctx_3.listDivider, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						';
                  r += c(ctx_4.listDivider, ctx_4);
                  r += '\n					';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n					<li>\n						<a href="#" class="item-link item-content productLink" data-index="';
              r += c(data_3 && data_3.index, ctx_3);
              r += '">\n							<div class="item-inner">\n								<div class="item-title-row">\n									<div class="item-title" style="white-space: unset;">';
              r += c(ctx_3.frontFacingTitle, ctx_3);
              r += '</div>\n									<div class="item-after">Qty: ';
              r += c(ctx_3.quantity, ctx_3);
              r += '</div>\n								</div>\n								<div class="item-subtitle">\n									';
              r += c(ctx_3.product.brand, ctx_3);
              r += '\n									';
              r += Template7Helpers.if.call(ctx_3, ctx_3.asIsId, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n									<br>\n									<div class="chip color-red">\n										<div class="chip-label">"AS-IS" Clearance</div>\n									</div>\n									';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.printSingularTag, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.item.clearanceAmount, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n									';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.printSingularTag, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n									';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.item.clearanceAmount, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n									<br>\n									<div class="chip color-blue" style="background: #408480">\n										<div class="chip-label">Last Call Item</div>\n									</div>\n									';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '\n									';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n									';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n								</div>\n								<div class="item-text">Template: ';
              r += c(ctx_3.template, ctx_3);
              r += Template7Helpers.if.call(ctx_3, ctx_3.printSingularTag, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ', Singular Item Tag, ';
                  r += c(ctx_4.item.sku, ctx_4);
                  r += Template7Helpers.if.call(ctx_4, ctx_4.asIsId, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '-asis-';
                      r += c(ctx_5.asIsId, ctx_5);
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ', Product(s) Tag';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div>\n							</div>\n						</a>\n					</li>\n					';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				</ul>\n			</div>\n			';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="block block-strong">\n				<p>No Products added yet, scan a barcode or search above to start your print queue.</p>\n			</div>\n			';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			';
      r += Template7Helpers.if.call(ctx_1, false, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<!--\n			<div class="block">\n				<p class="row">\n					<button class="col button button-big button-raised';
          r += Template7Helpers.js_if.call(ctx_2, "this.$root.printTagPrintList.length == 0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="clearPrintList">Clear All</button>\n					<button class="col button button-big button-raised button-fill buttonPrint';
          r += Template7Helpers.js_if.call(ctx_2, "this.$root.printTagPrintList.length == 0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="printList">Print';
          r += Template7Helpers.if.call(ctx_2, ctx_2.numberOfTags, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += c(ctx_3.numberOfTags, ctx_3);
              r += ' Tag';
              r += Template7Helpers.js_if.call(ctx_3, "this.numberOfTags !== 1", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 's';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</button>\n				</p>\n			</div>\n			-->\n			<!-- TODO! -->\n			<!--\n			<div class="block-title">Previous Print Jobs:</div>\n			<div class="list links-list">\n				<ul>\n					<li>\n						<a href="#">Link Item 1</a>\n					</li>\n					<li>\n						<a href="#">Link Item 2</a>\n					</li>\n					<li>\n						<a href="#">Link Item 3</a>\n					</li>\n				</ul>\n			</div>\n			-->\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	
`,
  styleScoped: false
};
    