
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate: function () {
    var self = this;

    if (self.$app.data.taskMasterData.serverFunctions) {
      self.serverFunctions = self.$app.data.taskMasterData.serverFunctions;
      self.serverFunctions.unshift(self.restartWebsocketServiceObject); // self.refreshScreen(); // Is this one necessary?
    } else {
      self.serverFunctions.unshift(self.restartWebsocketServiceObject);
    }
  },
  data: function () {
    return {
      restartWebsocketServiceObject: {
        label: "Restart WebSocket Service",
        transport: "ajax",
        confirmPrompt: {
          title: 'Restart Websocket Service?',
          text: 'Are you sure you want to restart the WebSocket Service?  This is not harmful.'
        },
        data: {
          action: "restartWebSocketService"
        }
      },
      serverFunctions: []
    };
  },
  mounted: function () {
    var self = this;
    self.$app.on('webSocketMessage', self.websocketMessageReceived);
  },
  beforeDestroy: function () {
    var self = this;
    self.$app.off('webSocketMessage', self.websocketMessageReceived);
  },
  methods: {
    websocketMessageReceived: function (data) {
      var self = this;

      if (data.type == 'data' && data.data == 'serverFunctions') {
        self.serverFunctions = data.serverFunctions;
        self.serverFunctions.unshift(self.restartWebsocketServiceObject);
        self.refreshScreen();
      }
    },
    refreshScreen: function () {
      var self = this;
      self.$update();
    },
    runServerFunction: function (e) {
      var self = this;
      var thisATag = self.$(e.target);
      var serverFunctionsIndex = parseInt(thisATag.attr('data-index')); // console.log('serverFunctionsIndex',serverFunctionsIndex,'thisATag',thisATag);

      if (serverFunctionsIndex > -1) {
        var serverFunctionsObject = self.serverFunctions[serverFunctionsIndex];

        var continueFunction = function (serverFunctionsObject) {
          if (serverFunctionsObject.protocol && serverFunctionsObject.protocol == 'ajax') {
            self.$app.data.mdWebSocket.ajaxToServer({
              method: 'GET',
              showSpinner: true,
              url: '/4DACTION/api/' + serverFunctionsObject.data.action,
              data: serverFunctionsObject.data,
              timeout: 10000,
              success: function (data) {
                if (data.ok) {
                  if (serverFunctionsObject.afterAlert) {
                    var alertTitle = 'Alert';
                    var alertText = '';

                    if (serverFunctionsObject.afterAlert.title) {
                      alertTitle = serverFunctionsObject.afterAlert.title;
                    }

                    if (serverFunctionsObject.afterAlert.text) {
                      alertText = serverFunctionsObject.afterAlert.text;
                    }

                    self.$app.dialog.alert(alertText, alertTitle);
                  }
                } else {
                  self.$app.dialog.alert(data.error, 'Error');
                }
              },
              error: function (data) {
                self.$app.dialog.alert('Connection Error', 'Error');
              }
            });
          } else {
            self.$root.mdWebSocket.sendOverWebSocket(serverFunctionsObject.data);

            if (serverFunctionsObject.afterAlert) {
              var alertTitle = 'Alert';
              var alertText = '';

              if (serverFunctionsObject.afterAlert.title) {
                alertTitle = serverFunctionsObject.afterAlert.title;
              }

              if (serverFunctionsObject.afterAlert.text) {
                alertText = serverFunctionsObject.afterAlert.text;
              }

              self.$app.dialog.alert(alertText, alertTitle);
            }
          }
        };

        if (serverFunctionsObject.confirmPrompt) {
          var confirmTitle = 'Continue?';
          var confirmText = '';

          if (serverFunctionsObject.confirmPrompt.title) {
            confirmTitle = serverFunctionsObject.confirmPrompt.title;
          }

          if (serverFunctionsObject.confirmPrompt.text) {
            confirmText = serverFunctionsObject.confirmPrompt.text;
          }

          self.$app.dialog.confirm(confirmText, confirmTitle, function () {
            continueFunction(serverFunctionsObject);
          });
        } else {
          continueFunction(serverFunctionsObject);
        }
      }
    }
    /*
    ,
    restartZoneMinder: function() {
    	var self= this;
    	
    	self.$app.dialog.confirm('Are you sure you want to restart the ZoneMinder camera proxy?  This is not harmful but you will not see any cameras in the app for about 4-5 minutes.','Restart Cameras?',function() {
    		// self.$app.preloader.show();
    		self.$root.mdWebSocket.sendOverWebSocket({
    			action: 'restartZoneMinderServer'
    		});
    		
    		// If okay...
    		
    		self.$app.dialog.alert('Please allow up to 4 to 5 minutes for the cameras to come back online.','Restarted');
    		
    	});
    },
    restartWebSocket: function() {
    	var self= this;
    	
    	self.$app.dialog.confirm('Are you sure you want to restart the WebSocket Service?  This is not harmful','Restart WebSocket?',function() {
    		self.$app.preloader.show();
    		
    		
    		self.$app.data.mdWebSocket.ajaxToServer({
    			method: 'GET',
                showSpinner: false,
                url: '/4DACTION/api/restartWebSocketService',
                data: {
    	            action: 'restartWebSocketService'
    	        },
                timeout: 10000,
                success: function(data) {
    	            self.$app.preloader.hide();
    	            if (data.ok) {
    							self.$app.dialog.alert('The WebSocket Service is restarting, it will take about 10 seconds before it comes back online.','Restarted');
    				    	} else {
    					self.$app.dialog.alert('Could not restart it....','Error');
    		    	}
    	        },
    	        error: function(data) {
    				self.$app.preloader.hide();
    				// TODO: Error alert?
    			        }
    		});
    		
    		
    		
    		// If okay...
    		
    		
    		
    	});
    },
    restartPhoneAMI: function() {
    	var self= this;
    	
    	self.$app.dialog.confirm('Are you sure you want to restart the Phone AMI Service?','Restart AMI?',function() {
    		self.$app.preloader.show();
    		
    		self.$app.data.mdWebSocket.ajaxToServer({
    			method: 'GET',
                showSpinner: false,
                url: '/4DACTION/api/restartPhoneAMIService',
                data: {
    	            action: 'restartPhoneAMIService'
    	        },
                timeout: 10000,
                success: function(data) {
    	            self.$app.preloader.hide();
    	            if (data.ok) {
    							self.$app.dialog.alert('The Phone AMI Service is restarting, it will take about 10 seconds before it comes back online.','Restarted');
    				    	} else {
    					self.$app.dialog.alert('Could not restart it....','Error');
    		    	}
    	        },
    	        error: function(data) {
    				self.$app.preloader.hide();
    				// TODO: Error alert?
    			        }
    		});
    		
    		
    		
    		// If okay...
    		
    		
    		
    	});
    }
    */

  },
  on: {
    pageInit: function (e, page) {
      var self = this;
    }
  },
  id: '9efc849a3d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="taskMasterServer">\n		<div class="navbar';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' navbar-large';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md">Back</span>\n					</a>\n				</div>\n				<div class="title">Server Functions</div>\n				<div class="right">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.showBugReportIcon, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/bugreport/" class="link icon-only">\n						<i class="icon material-icons">bug_report</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n				';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.$root.turnOffLargeTitles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<div class="title-large">\n					<div class="title-large-text">Server Functions</div>\n				</div>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<div class="page-content">\n			<div class="list links-list">\n				<ul>\n					';
      r += Template7Helpers.each.call(ctx_1, ctx_1.serverFunctions, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<li><a href="#" data-index="';
          r += c(data_2 && data_2.index, ctx_2);
          r += '" @click="runServerFunction">';
          r += c(ctx_2.label, ctx_2);
          r += '</a></li>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<!--\n					<li><a href="#" @click="restartZoneMinder">Restart ZoneMinder Cameras</a></li>\n					<li><a href="#" @click="restartWebSocket">Restart WebSocket Service</a></li>\n					<li><a href="#" @click="restartPhoneAMI">Restart Phone AMI Service</a></li>\n					-->\n				</ul>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	
`,
  styleScoped: false
};
    